import React, { useContext, useEffect, useState } from "react";
import { Resources } from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import * as model from "./ResourcesFirebase";
import { DropResult } from "react-beautiful-dnd";
import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { logAnalyticsEvent } from "../../../firebase";
import analyticsLogs from "../../../analyticsLogs";

interface ResourcesControllerProps {}

const ResourcesController = ({}: ResourcesControllerProps) => {
  const authContext = useContext(AuthContext);
  const [categories, setCategories] = useState<Resources.CategoryDict>({});
  const [categoryOrder, setCategoryOrder] = useState<string[]>([]);
  const [resources, setResources] = useState<Resources.ResourceDict>({});
  const [savedCategories, setSavedCategories] =
    useState<Resources.CategoryDict>({});
  const [savedCategoryOrder, setSavedCategoryOrder] = useState<string[]>([]);
  const [savedResources, setSavedResources] = useState<Resources.ResourceDict>(
    {},
  );

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const initialLoad = (specialistUid: string) => {
    //add process state
    setProcessState(ProcessState.running);

    model
      .getResources(specialistUid)
      .then((resources) => {
        setProcessState(ProcessState.idle);

        setCategories(resources.categories);
        setCategoryOrder(resources.categoryOrder);
        setResources(resources.resources);

        // Set saved state to deep copy of initial state
        setSavedCategories(JSON.parse(JSON.stringify(resources.categories)));
        setSavedCategoryOrder(
          JSON.parse(JSON.stringify(resources.categoryOrder)),
        );
        setSavedResources(JSON.parse(JSON.stringify(resources.resources)));

        //Wait for state to propagate, then set first load to false
        setTimeout(() => {
          setFirstLoad(false);
        }, 100);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error fetching your resources",
          analyticsLog: analyticsLogs.specialistResources.fetchFail,
        });
      });
  };

  const saveTemplates = () => {
    const resourcesToSave: Resources.Resources = {
      resources,
      categories,
      categoryOrder,
    };
    setProcessState(ProcessState.running);
    model
      .saveResources(authContext.uid, resourcesToSave)
      .then(() => {
        setProcessState(ProcessState.success);
        logAnalyticsEvent(analyticsLogs.specialistResources.save);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);

        // Update saved state to deep copy of current state
        setSavedCategories(JSON.parse(JSON.stringify(categories)));
        setSavedCategoryOrder(JSON.parse(JSON.stringify(categoryOrder)));
        setSavedResources(JSON.parse(JSON.stringify(resources)));
        // Remove unsaved changes flag
        setUnsavedChanges(false);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error saving changes. Please try again.",
          analyticsLog: analyticsLogs.specialistResources.saveFail,
        });
      });
  };

  const discardChanges = () => {
    // Revert state back to deep copy of the saved state
    setCategories(JSON.parse(JSON.stringify(savedCategories)));
    setCategoryOrder(JSON.parse(JSON.stringify(savedCategoryOrder)));
    setResources(JSON.parse(JSON.stringify(savedResources)));

    // Remove unsaved changes flag, wait for propagation
    setTimeout(() => {
      setUnsavedChanges(false);
    }, 100);
  };

  // ID must be unique across resources and categories
  // The actual ID doesn't matter, as long as it is unique. Using name as a starting point for a unique ID
  const createUniqueID = (name: string): string => {
    let id = `${name.toLowerCase()}`;
    let idx = 0;
    while (categories[id] !== undefined || resources[id] !== undefined) {
      id = `${name.toLowerCase()}_${idx}`;
      idx += 1;
    }
    return id;
  };

  const updateCategoryHandler = (category: Resources.Category) => {
    const newCategories = { ...categories };
    newCategories[category.id] = category;
    setCategories(newCategories);
  };

  const addCategoryHandler = (name: string) => {
    // create id
    const categoryId = createUniqueID(name);
    const newCategory: Resources.Category = {
      id: categoryId,
      name: name,
      resourceIds: [],
    };
    //Add to dictionary
    const newCategories = { ...categories };
    newCategories[categoryId] = newCategory;
    setCategories(newCategories);

    //Add to bottom of order list
    const newCategoryOrder = [...categoryOrder];
    newCategoryOrder.push(categoryId);
    setCategoryOrder(newCategoryOrder);
  };

  const deleteCategoryHandler = (categoryId: string, index: number) => {
    //Delete resources that are contained within this category
    const newResources = { ...resources };
    const resourceIds = categories[categoryId].resourceIds;
    resourceIds.forEach((id) => {
      delete newResources[id];
    });
    setResources(newResources);

    //Remove from dictionary
    const newCategories = { ...categories };
    delete newCategories[categoryId];
    setCategories(newCategories);

    //Remove id from order list
    const newCategoryOrder = [...categoryOrder];
    newCategoryOrder.splice(index, 1);
    setCategoryOrder(newCategoryOrder);
  };

  const updateResourceHandler = (resource: Resources.Resource) => {
    const newResources = { ...resources };
    newResources[resource.id] = resource;
    setResources(newResources);
  };

  const addResourceHandler = (resourceName: string, categoryId: string) => {
    // create id
    const resourceId = createUniqueID(resourceName);
    const newResource: Resources.Resource = {
      id: resourceId,
      resourceName: resourceName,
      fileName: "",
      filePath: "",
      downloadUrl: "",
    };
    //Add new resource to resource dictionary
    const newResources = { ...resources };
    newResources[resourceId] = newResource;
    setResources(newResources);

    //Add resource to category it was created in
    const newCategories = { ...categories };
    newCategories[categoryId].resourceIds.push(resourceId);
    setCategories(newCategories);
  };

  const deleteResourceHandler = (
    resourceId: string,
    index: number,
    categoryId: string,
  ) => {
    //Delete from dictionary
    const newResources = { ...resources };
    delete newResources[resourceId];
    setResources(newResources);

    //Delete resource from category list
    const newCategories = { ...categories };
    const newResourceIds = [...newCategories[categoryId].resourceIds];
    newResourceIds.splice(index, 1);
    newCategories[categoryId].resourceIds = newResourceIds;
    setCategories(newCategories);
  };

  const reorderCategoryHandler = (
    sourceIdx: number,
    destIdx: number,
    categoryId: string,
  ) => {
    //Remove ID in order list, and move it to new index
    const newCategoryOrder = [...categoryOrder];
    newCategoryOrder.splice(sourceIdx, 1);
    newCategoryOrder.splice(destIdx, 0, categoryId);
    setCategoryOrder(newCategoryOrder);
  };

  const reorderResourceHandler = (
    sourceIdx: number,
    destIdx: number,
    sourceCategoryId: string,
    destCategoryId: string,
    resourceId: string,
  ) => {
    //Create copy of categories
    const newCategories = { ...categories };

    //Remove resource from original list
    const newSourceResourceOrder = [
      ...newCategories[sourceCategoryId].resourceIds,
    ];
    newSourceResourceOrder.splice(sourceIdx, 1);
    //If we aren't moving categories, then add resource to the source list in the right spot
    if (sourceCategoryId === destCategoryId) {
      newSourceResourceOrder.splice(destIdx, 0, resourceId);
    } else {
      //Add to new list
      const newDestResourceOrder = [
        ...newCategories[destCategoryId].resourceIds,
      ];
      newDestResourceOrder.splice(destIdx, 0, resourceId);
      //Update destination category resource order
      newCategories[destCategoryId].resourceIds = newDestResourceOrder;
    }
    //Update source destination category resource order
    newCategories[sourceCategoryId].resourceIds = newSourceResourceOrder;

    //Update state to with new orders
    setCategories(newCategories);
  };

  const onDragEndHandler = (result: DropResult) => {
    const { source, destination, draggableId, type } = result;

    //Dragged out of a droppable zone, return
    if (destination === undefined || destination === null) return;

    //Didn't move at all, return
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    )
      return;

    if (type === "category") {
      reorderCategoryHandler(source.index, destination.index, draggableId);
    } else if (type === "resource" || type === "item") {
      reorderResourceHandler(
        source.index,
        destination.index,
        source.droppableId,
        destination.droppableId,
        draggableId,
      );
    }
  };

  useEffect(() => {
    if (authContext.uid !== "") {
      initialLoad(authContext.uid);
    }
  }, [authContext.uid]);

  useEffect(() => {
    //Don't show unsaved changes if loading for the first time (nothing will have been changed)
    if (!firstLoad) {
      setUnsavedChanges(true);
    }
  }, [categories, categoryOrder, resources]);

  return {
    // state
    categories,
    categoryOrder,
    resources,
    // handlers
    addCategoryHandler,
    deleteCategoryHandler,
    updateCategoryHandler,
    addResourceHandler,
    deleteResourceHandler,
    updateResourceHandler,
    onDragEndHandler,
    // saving
    saveTemplates,
    discardChanges,
    unsavedChanges,
    processState,
    processErrorMessage,
    setProcessState,
  };
};

export default ResourcesController;
