import React, { useState, useEffect, useContext, useRef } from "react";
import Button from '@material-ui/core/Button';
import { AuthContext } from "../../AuthProvider";
import { fbFirestore, fbFunctions, logAnalyticsEvent } from "../../firebase"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import firebase from "firebase";
import { getDocumentData } from "@alethea-medical/utilities";
import { AISpecialty, Specialist, UnlabelledImage } from "../../../shared/types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useProcessState } from "@alethea-medical/alethea-components";
import { ProcessState, ProcessStatus } from "@alethea-medical/alethea-components";
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LabelIcon from '@material-ui/icons/Label';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import useAITranslation from "../useAITranslation";
import Label from "../../components/Label";
import LinearProgress from '@material-ui/core/LinearProgress';
import ImageToAnnotate from "./ImageToAnnotate";
import palette from "../../palette";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
        },
        autoCompleteOption: {
            // Hover
            '&[data-focus="true"]': {
                backgroundColor: theme.palette.secondary.light,
                borderColor: 'transparent',
                },
            // Selected
            '&[aria-selected="true"]': {
                backgroundColor: theme.palette.secondary.light,
                borderColor: 'transparent',
            },
        },
        imageStatus: {
            marginTop: theme.spacing(3),
            textAlign: "center"
        },
        centerText: {
            textAlign: "center"
        },
        aiPredictionText: {
            fontSize: "1.2em",
            color: palette.orange,
        },
        yourPredictonText: {
            fontSize: "1.2em",
            color: theme.palette.primary.main,
        },
        textIcon: {
            verticalAlign: "middle",
            marginRight: theme.spacing(1)
        },
        labelProgress: {
            backgroundColor: palette.orangeLight
        },
        labelProgressBar: {
            backgroundColor: palette.orange
        }
    }),
);

export interface DisplayImage extends UnlabelledImage {
    src: string,
    fileUri: string,
    uid: string,
    doctorLabels: string[],
    specialty: AISpecialty
}

//For backend
interface DoctorLabelImageData {
    aiPrediction: string[]
    aiVersion: string,
    doctorLabels: string[]
    created: firebase.firestore.Timestamp,
    fileUri: string,
    specialty: string,
    doctorUid: string,
}

interface ImageAnnotatorProps {
    imagesToShow: DisplayImage[],
    setImagesToShow: (newImagesToShow: DisplayImage[]) => void,
    pointer: number,
    setPointer: (pointer: number) => void,
    userSpecialty: AISpecialty | undefined,
    setUserSpecialty: (userSpecialty: AISpecialty) => void,
    loadImageState: ProcessState,
    loadImageError?: string,
    loadAIState: ProcessState,
    loadAIError?: string,
    loadAIMessage?: string,
    onNext?: (pointer: number) => void,
    onPrev?: (pointer: number) => void,
    onFinish?: () => void,
    onLabelImage?: (fileUri: string, doctorLabels: string[]) => void,
    fetchNextBatch?: () => Promise<any | void>,
    showSpecialtyChange?: boolean,
    layout?: "horizontal" | "vertical",
    imageHeight?: number,
    addPreviousLabels?: boolean
}

const ImageAnnotator = ( { imagesToShow, setImagesToShow, pointer, setPointer, userSpecialty, setUserSpecialty, loadImageState, loadImageError, loadAIState, loadAIMessage, loadAIError, 
    onNext = () => {}, onPrev = () => {}, onFinish = () => {}, onLabelImage = () => {}, fetchNextBatch,
    showSpecialtyChange, layout="horizontal", imageHeight, addPreviousLabels}: ImageAnnotatorProps ) => {
    const authContext = useContext(AuthContext);
    const classes = useStyles();

    const doctorLabelImage = fbFunctions.httpsCallable("ai-doctorLabelImage");
    const updateImageSpecialty = fbFunctions.httpsCallable("ai-updateImageSpecialty");
    
    const [currentLabels, setCurrentLabels] = useState<string[]>([]);
    const [changeSpecialty, setChangeSpecialty] = useState<AISpecialty | "">("");
    const [finished, setFinished] = useState(false);

    const labelRef = useRef<HTMLElement>();

    const { spToAi, aiToSp, spLabelList } = useAITranslation({useSpToAi: true, useSpLabelList: true})

    const { processState, processErrorMessage, setProcessState, errorHandler, } = useProcessState({ logAnalyticsEvent });



    //Set specialty to fetch images for based on the signed-in user's specialty
    useEffect(() => {
        fbFirestore.collection("specialists").doc(authContext.uid).get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    return getDocumentData(snapshot)
                        .then((specialist: Specialist.Profile) => {
                            if (specialist.specialties["Otolaryngology"] !== undefined) {
                                setUserSpecialty("Otolaryngology");
                            }
                            else if (specialist.specialties["Dermatology"] !== undefined) {
                                setUserSpecialty("Dermatology");
                            }
                        })
                }
            })
            .catch((error: Error) => {
                console.error(`Error fetching specialist objet: ${error}`);
                console.error(error);
            })

    }, [authContext.uid]);



    useEffect(() => {
        if (imagesToShow.length !== 0 && imagesToShow[pointer] !== undefined) {
            if(imagesToShow[pointer].doctorLabels.length > 0)//If doctor press prev, reset to what they entered before
                setCurrentLabels(imagesToShow[pointer].doctorLabels);
            else {
                const newLabels: string[] = [];
                if(!addPreviousLabels || currentLabels.length === 0)//Don't force add AI prediction if pre-populating with previous label
                    newLabels.push(...imagesToShow[pointer].aiPrediction)//Pre-populate with ai prediction
                if(addPreviousLabels) {
                    currentLabels.forEach((label) => {//Append the previous labels to the ai prediction if it doesn't exist. Since most images are of the same thing, its likely the label is the same.
                        if(!newLabels.includes(label)) {
                            newLabels.push(label)
                        }
                    })
                }
                setCurrentLabels(newLabels);
            }
                
        }
    }, [imagesToShow[pointer]?.aiPrediction, imagesToShow[pointer]?.doctorLabels])


    //Add current labels to labelled_image
    const labelImageHandler = () => {
        addLabelsToImage(imagesToShow[pointer], "label", currentLabels);
    }

    //Add exclude label to labelled_image
    const excludeImageHandler = () => {
        addLabelsToImage(imagesToShow[pointer], "exclude", ["Exclude"]);
    }

    //Add unknown label to labelled_image
    const unknownImageHandler = () => {
        addLabelsToImage(imagesToShow[pointer], "unknown", ["Unknown"]);
    }

    const addLabelsToImage = (image: DisplayImage, analyticsString: string, labels: string[]) => {
        if (image !== undefined && authContext.uid !== "") {
            setProcessState(ProcessState.running);

            logAnalyticsEvent(`image_annotation_${analyticsString}_started`, {specialty: image.specialty });
            const data: DoctorLabelImageData = {
                aiPrediction: image.aiPrediction,
                aiVersion: image.aiVersion,
                doctorLabels: labels,
                created: image.created,
                fileUri: image.fileUri,
                specialty: image.specialty,
                doctorUid: authContext.uid
            }

            //Create entry in labelled_images
            doctorLabelImage(data)
            .then(() => {
                setProcessState(ProcessState.idle);

                //Update image in our state in case the doctor presses previous
                const newImagesToShow: DisplayImage[] = [...imagesToShow];
                newImagesToShow[pointer].doctorLabels = labels;
                setImagesToShow(newImagesToShow);

                logAnalyticsEvent(`image_annotation_${analyticsString}_success`);

                //Callback - if annotating econsult, this will add labels to econsult
                onLabelImage(image.fileUri, labels)

                goToNextImage();
            })
            .catch((error: Error) => {
                errorHandler({
                    userMessage: "Error labelling image",
                    error: error,
                    analyticsLog: `image_annotation_${analyticsString}_failed`
                })
            })
        }
    }

    const updateSpecialtyHandler = () => {
        const unlabelledImage = imagesToShow[pointer];
        if (unlabelledImage !== undefined && authContext.uid !== "" && changeSpecialty !== "") {
            setProcessState(ProcessState.running);

            logAnalyticsEvent("image_annotation_change_specialty_started");
            
            updateImageSpecialty({ id: unlabelledImage.uid, oldSpecialty: unlabelledImage.specialty, newSpecialty: changeSpecialty})
            .then((result) => {
                setProcessState(ProcessState.idle);

                //Update image in our state in case the doctor presses previous
                const newImagesToShow: DisplayImage[] = [...imagesToShow];
                newImagesToShow[pointer].specialty = changeSpecialty;
                newImagesToShow[pointer].uid = result.data;
                setImagesToShow(newImagesToShow);

                logAnalyticsEvent("image_annotation_change_specialty_success");

                goToNextImage();
            })
            .catch((error: Error) => {
                errorHandler({
                    userMessage: "Error updating image specialty",
                    error: error,
                    analyticsLog: "image_annotation_change_specialty_failed"
                })
            })
        }
    }

    const goToNextImage = () => {
        if (pointer + 1 === imagesToShow.length) {
            setFinished(true);
            onFinish();
        }
        setChangeSpecialty("");
        setPointer(pointer + 1);
        onNext(pointer + 1);
    }

    const goToPrevImage = () => {
        if (pointer > 0) {
            setPointer(pointer - 1);
            onPrev(pointer - 1)
        }
            
    }

    const disableNext = () => {
        return imagesToShow[pointer] === undefined || imagesToShow[pointer]?.src === "" || currentLabels.length === 0;
    }
    const disableExcludeOrUnknown = () => {
        return imagesToShow[pointer] === undefined || imagesToShow[pointer]?.src === "";
    }

    const disableChangeSpecialty = () => {
        return imagesToShow[pointer] === undefined
    }

    const disableInput = () => {
        return processState === ProcessState.running;
    }

    const handleAddLabel = (label: string) => {
        const newLabel = spToAi(label, true)
        
        if(!currentLabels.includes(newLabel)) {
            const newCurrentLabels = [...currentLabels];
            newCurrentLabels.push(newLabel);
            setCurrentLabels(newCurrentLabels);

            //Need to wait for autocomplete to update with empty string before focusing again
            setTimeout(() => {
                if(labelRef?.current !== undefined) {
                    labelRef.current.focus()
                }
            }, 1)
            
        }
        
    }

    const handleRemoveLabel = (labelName: string) => {
        const newLabels = currentLabels.filter((label) => {
            return label != labelName
        })
        setCurrentLabels(newLabels);
    }

    const renderAILabels = () => {
        if(imagesToShow[pointer]?.aiPrediction !== undefined && imagesToShow[pointer].aiPrediction.length > 0 ) {
            return (
                <Grid container spacing={1}>
                    {imagesToShow[pointer].aiPrediction.map((labelName, index) => 
                        <Grid item xs={12} key={`${labelName}_${index}`}>
                            <Label text={labelName ? aiToSp(labelName, true) : "No Label Found"} color={"orange"}/>
                        </Grid>
                    )}
                </Grid>
            )
        }
    }

    const renderDoctorLabels = () => {
        return (
            <Grid container spacing={1}>
                {currentLabels.map((labelName, index) => 
                    <Grid item xs={12} key={`${labelName}_${index}`}>
                        <Label text={labelName ? aiToSp(labelName, true) : "No Label Found"} color={"dark"} closeButton labelKey={labelName} handleRemove={handleRemoveLabel}/>
                    </Grid>
                )}
            </Grid>
        )
    }

    const renderImageViewer = () => {
        if (loadImageState !== ProcessState.running && loadImageState !== ProcessState.error && imagesToShow.length === 0) {
            return (
                <div className={classes.imageStatus}>
                    <Typography>No images to fetch</Typography>
                </div>
            );
        }
        return (
            <ImageToAnnotate src={imagesToShow[pointer]?.src} imageHeight={imageHeight}/>
        );
    }

    const aiPredictionProps = {
        options: userSpecialty ? spLabelList[userSpecialty] : [],
        getOptionLabel: (option: string) => option,
        autoComplete: true,
        autoSelect: true
    };



    return (
            <Grid container spacing={2} className={classes.content}>

                <Grid item xs={layout === "horizontal" ? 6 : 12}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                        </Grid>
                        {imagesToShow.length > 0 && (
                        <Grid item xs={12}>
                                <Typography>Image {finished ? imagesToShow.length : pointer + 1} of {imagesToShow.length}</Typography>
                                <LinearProgress variant="determinate" 
                                    classes={{colorPrimary: classes.labelProgress, barColorPrimary: classes.labelProgressBar}}
                                    value={Math.min((imagesToShow.length > 0 ? (pointer)/imagesToShow.length : 1) * 100, 100)}/>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        fullWidth
                                        disabled={pointer === 0} 
                                        onClick={goToPrevImage}
                                        startIcon={<ArrowBackIcon/>}
                                    >Prev</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={disableInput() || disableNext()}
                                        onClick={labelImageHandler}
                                        endIcon={<SaveIcon/>}
                                    >Save and Next</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={6} md={3}>
                                    <Tooltip title="Mark image as unknown if none of the labels describe the image.">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={disableInput() || disableExcludeOrUnknown()}
                                            onClick={unknownImageHandler}
                                            endIcon={<HelpIcon/>}
                                        >Unknown</Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Tooltip title="Exclude an image if it is of poor quality.">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={disableInput() || disableExcludeOrUnknown()}
                                            onClick={excludeImageHandler}
                                            endIcon={<DeleteIcon/>}
                                        >Exclude</Button>
                                    </Tooltip>
                                </Grid>                        
                            </Grid>
                        </Grid>


                        {showSpecialtyChange && (
                            <>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        options={Object.keys(spLabelList)}
                                        getOptionLabel={(option: string) => option}
                                        getOptionDisabled={(option: string) => option === imagesToShow[pointer]?.specialty}
                                        onChange={(e: any, v: any) => {
                                            setChangeSpecialty(v ? v : "")
                                        }}
                                        disabled={disableInput() || disableChangeSpecialty()}
                                        value={changeSpecialty}
                                        renderInput={(params: any) => <TextField {...params} label="Change Specialty" margin="normal" />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={disableInput() || disableChangeSpecialty() || changeSpecialty === "" || imagesToShow[pointer]?.specialty === changeSpecialty}
                                        onClick={updateSpecialtyHandler}
                                    >Change and Next</Button>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Grid item xs={6}>
                                    <Typography className={classes.aiPredictionText}><VisibilityIcon className={classes.textIcon}/>AI Prediction</Typography>                  
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.yourPredictonText}><LabelIcon className={classes.textIcon}/>Your Labels</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <ProcessStatus state={loadAIState} loadingMessage={loadAIMessage !== undefined ? loadAIMessage : "Loading AI Prediction..."} errorMessage={loadAIError}/>

                                    {renderAILabels()}
                                </Grid>
                                <Grid item xs={6}>
                                    {renderDoctorLabels()}
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        {...aiPredictionProps}
                                        key={`autocomplete_${currentLabels.length}`}//Force autocomplete to update when we add a label (so we can reset the value)
                                        // freeSolo//Allow any input (not doing for now)
                                        // onInputChange={(_, v) => setLabelField(v)}
                                        classes={{
                                            option: classes.autoCompleteOption
                                        }}
                                        getOptionDisabled={(option: string) => currentLabels.includes(spToAi(option))}
                                        onChange={(e, v, reason) =>{
                                            if(reason === "select-option")
                                                handleAddLabel(v ? v : "")
                                        }}
                                        autoHighlight   
                                        disabled={disableInput()}
                                        renderInput={(params: any) => <TextField {...params} inputRef={labelRef} placeholder="Press arrow key down to see all options" margin="small" />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={disableInput()}
                                        onClick={() => {
                                            setCurrentLabels([]);
                                        }}
                                    >Clear Labels</Button>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item xs={layout === "horizontal" ? 6 : 12}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                            <ProcessStatus state={loadImageState} loadingMessage="Loading Image..." errorMessage={loadImageError}/>
                        </Grid>
                        {imagesToShow[pointer] !== undefined && (
                            <Grid item xs={12}>
                                <Typography className={classes.centerText}>{imagesToShow[pointer]?.filename} - Image captured on {imagesToShow[pointer]?.created.toDate().toLocaleString()}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {renderImageViewer()}
                        </Grid>                                
                        {finished && fetchNextBatch !== undefined && (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loadImageState === ProcessState.running}
                                    onClick={() => {
                                        fetchNextBatch()
                                        .then(() => {
                                            setFinished(false);
                                        })
                                    }}
                                    fullWidth
                                >
                                    Fetch Next Batch
                                </Button>
                            </Grid>                                
                        )}
                    </Grid>
                </Grid>
            </Grid>            
    );
}

export default ImageAnnotator;