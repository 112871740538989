import { useEffect, useRef, useState, MutableRefObject } from "react";
import { PhoneConsult } from "../../../../../shared/types";
import { timeRowHeight } from "../Views/Calendar/TimeRow";
import BlockDragController from "./BlockDragController";
import { convertTimeslotsToBlocks, sortTimeslots, isTimeInBlock } from "../Models/SpecialistScheduleModel";

interface ScheduleDayControllerProps {
    times: string[],
    dayIndex: number,
    scheduleDay: PhoneConsult.ScheduleDay,
    blocksRef: MutableRefObject<PhoneConsult.Timeslot[][]>,
    dragContainerRef: MutableRefObject<HTMLDivElement | null>,
    setDirty: () => void
}

const ScheduleDayController = ({ times, dayIndex, scheduleDay, blocksRef, dragContainerRef, setDirty }: ScheduleDayControllerProps) => {
    
    const [blocks, setBlocks] = useState<PhoneConsult.Timeslot[]>([])

    useEffect(() => {
        setBlocks(convertTimeslotsToBlocks(scheduleDay.timeslots))
    }, [scheduleDay])

    useEffect(() => {
        blocksRef.current[dayIndex] = blocks
    }, [blocks])

    const removeBlockHandler = (index: number) => {
        const newBlocks = [...blocks]
        newBlocks.splice(index, 1)

        sortTimeslots(newBlocks)
        setBlocks(newBlocks)

        setDirty()
    }

    const updateBlockHandler = (index: number, newBlock: PhoneConsult.Timeslot, dragReverse?: boolean): PhoneConsult.Timeslot | undefined => {
        return addOrEditBlock(newBlock, dragReverse, index)
    }

    const currentDragIndexRef = useRef<number>(0)
    const startDragIndexRef = useRef<number>(0)

    // Pass values in as args to explicitly require them in the use effect for the window mouse up listener
    const dragFinishedHandler = (dragReverse?: boolean) => {

        // Do nothing if this is the last timeslot
        if(currentDragIndexRef.current === startDragIndexRef.current && currentDragIndexRef.current === times.length - 1)
            return;
            
        const newBlock = createBlockFromIndexes(startDragIndexRef.current, currentDragIndexRef.current)

        addOrEditBlock(newBlock, dragReverse)
    }

    const { mouseDownHandler: blockDragHandler, isDragging } = BlockDragController({ 
        currentDragIndexRef: currentDragIndexRef,
        startingIndexRef: startDragIndexRef,
        pxPerIndex: timeRowHeight,
        minDragIndex: 0,
        maxDragIndex: times.length - 1,
        dragFinishedHandler,
        dragContainerRef
    })

    const mouseDownHandler = (index: number) => {
        //If time is in another block, don't do anything
        if(isTimeInBlock(times[index], blocks))
            return;

        // Initialize the current value with the index
        // block drag handler will automatically set the start drag index value to this value
        currentDragIndexRef.current = index;
        blockDragHandler()
    }

    /**
     * Create a block from indexes. Start and end can be in any order (reverse, equal or normal)
     * Makes sure the block doesn't overflow below 0 or past the end of the times list
     * If start and end are equal, return a block of length 60 minutes, truncated to the end of the times list
     * @param startIdx Start index of the block
     * @param endIdx End index of the block
     * @returns The new block
     */
    const createBlockFromIndexes = (startIdx: number, endIdx: number): PhoneConsult.Timeslot => {
        let newBlock: PhoneConsult.Timeslot;
        if(endIdx === startIdx) {
            // Create 1 hour timeslot if user only clicked and didn't drag past the starting index
            const start = times[endIdx]
            const end = endIdx + 6 <= (times.length - 1) ? times[endIdx + 6] : times[times.length - 1]
            newBlock = { start, end }
        }
        else if(startIdx < endIdx) {
            // If user dragged down
            const start = times[startIdx]
            const end = (endIdx) <= (times.length - 1) ? times[endIdx] : times[times.length - 1]
            newBlock = { start: start, end: end }
        }
        else {
            // If user dragged up
            const end = (startIdx + 1) <= (times.length - 1) ? times[startIdx + 1] : times[times.length - 1]
            const start = (endIdx) >= 0 ? times[endIdx] : times[0]
            newBlock = { start: start, end: end }
        }
        return newBlock
    }

    /**
     * Adds or edits a block. Makes sure it doesn't overlap with pre-existing blocks
     * @param newBlock Block to add or edit
     * @param addInReverse If adding in reverse, reverse logic will be used when overlapping blocks
     * @param editIndex If block is an edited version of an old block, then provide the index of that block to remove it
     * @returns The new block added to the list
     */
    const addOrEditBlock = (newBlock: PhoneConsult.Timeslot, addInReverse?: boolean, editIndex?: number): PhoneConsult.Timeslot | undefined => {
        
        let invalidOperation = false;
        const listToIterate = addInReverse ? [...blocks].reverse() : blocks
        listToIterate.some((block, index) => {
            // If comparing to itself, then don't check overlap
            if(editIndex !== undefined) {
                // If reversing, then editIndex needs to be inverted
                if(index === (addInReverse ? blocks.length - 1 - editIndex : editIndex))
                    return false;
            }
            // Check if only end happens within the block
            if(newBlock.start < block.start && newBlock.end > block.start && newBlock.end <= block.end) {
                newBlock.end = block.start
                return true;
            }
            // Check if only start happens within the block
            else if(newBlock.end > block.end && newBlock.start < block.end && newBlock.start >= block.start) {
                newBlock.start = block.end
                return true;
            }
            // Check if block surrounds another block
            else if(newBlock.start < block.start && newBlock.end > block.end) {
                // If dragged up instead of down, then set start to end instead
                if(addInReverse) 
                    newBlock.start = block.end
                else
                    newBlock.end = block.start
                return true;
            }
            // Check if block is inside another block
            else if(newBlock.start >= block.start && newBlock.end <= block.end) {
                // Dragging a block completely inside another block is an invalid operation and will result in nothing happening
                invalidOperation = true
                return true;
            }
            return false;
        })

        if(invalidOperation)
            return undefined

        const newBlocks = [...blocks]

        // Remove index of block being edited
        if(editIndex !== undefined)
            newBlocks.splice(editIndex, 1)


        newBlocks.push(newBlock)

        sortTimeslots(newBlocks)
        setBlocks(newBlocks)
        
        setDirty()

        return newBlock;
    }

    return {
        blocks,
        startDragIndex: startDragIndexRef.current, endDragIndex: currentDragIndexRef.current,
        isCreatingBlock: isDragging,
        mouseDownHandler,
        removeBlockHandler,
        updateBlockHandler
    }
}

export default ScheduleDayController;