import { ProcessState } from "@alethea-medical/alethea-components";
import { useEffect, useState } from "react";
import devConsole from "../../models/devConsole";


interface useExternalScriptProps {
    /** Script URL to load */
    url: string,
}

const useExternalScript = ({ url }: useExternalScriptProps) => {
    const [processState, setProcessState] = useState<ProcessState>(ProcessState.idle)

    const handleScript = (e: any) => {
        setProcessState(e.type === "load" ? ProcessState.success : ProcessState.error);
    };

    useEffect(() => {
        let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`);

        if (!script) {
            devConsole.log(`Loading script for first time from ${url}`)
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener("load", handleScript);
            script.addEventListener("error", handleScript);
            return () => {
                if(script === null)
                    return;

                // Remove element and event listeners so that script loading events are always triggered properly if this hook remounts
                script.removeEventListener("load", handleScript);
                script.removeEventListener("error", handleScript);
                script.remove()
            };
        }
    }, [url]);

    return processState;
}

export default useExternalScript;