import { useMobileSizes } from "@alethea-medical/alethea-components";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import clsx from "clsx";
import React from "react";
import { Activity } from "../../../../../../shared/types";
import palette from "../../../../../palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconRead: {
      color: palette.darkGreen,
    },
    iconUnread: {
      color: palette.orange,
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
    },
    labelContainerDesktop: {
      padding: theme.spacing(1),
    },
  }),
);

interface LabelContainerProps {
  activity: any;
  read: boolean;
}

const LabelContainer: React.FC<LabelContainerProps> = ({ activity, read }) => {
  const classes = useStyles();
  const isMobileSize = useMobileSizes();

  return (
    <Grid
      item
      className={
        isMobileSize ? classes.labelContainer : classes.labelContainerDesktop
      }
    >
      {activity.sharedActivity.activityType === "econsult" && (
        <QuestionAnswerIcon
          className={clsx({
            [classes.iconRead]: read,
            [classes.iconUnread]: !read,
          })}
        />
      )}
      {activity.sharedActivity.activityType === "phoneConsult" && (
        <PermPhoneMsgIcon
          className={clsx({
            [classes.iconRead]: read,
            [classes.iconUnread]: !read,
          })}
        />
      )}
    </Grid>
  );
};

export default LabelContainer;
