import React from "react";
import { createStyles, makeStyles, Typography, Theme, Paper, Grid } from "@material-ui/core";
import { HeadCell } from "../types";
import useBillingTable from "./useBillingTable";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(1)
		},
		title: {
			textAlign: "center",

		}
	})
);

const headCells: HeadCell[] = [
	{ id: 'created', label: 'Service Date' },
	{ id: 'hlinkResultReceivedAt', label: 'Date Rejected' },
	{ id: 'type', label: 'Activity'},
	{ id: 'subType', label: 'Sub-Activity'},
	{ id: 'patientId', label: 'PHN'},
	{ id: 'status', label: 'Status'},
	{ id: 'errorCode', label: 'Error Code'},
];


export const RejectionTable = () => {

	const classes = useStyles();
	const { createBillingTable } = useBillingTable({headCells: headCells, fetchBy: "status", statuses: ["refused"], tableKey: "refused" })

	return (
		<Paper className={classes.container} variant="outlined">
			<Grid container>
				<Grid item xs={12}>
					<span className={classes.title}>
						<Typography variant="h6" color="primary">
							Rejected
						</Typography>
					</span>
				</Grid>
				<Grid item xs={12}>
					{createBillingTable()}
				</Grid>
			</Grid>
		</Paper >
	);

}


