import React from 'react';
import Grid from '@material-ui/core/Grid';
import sharedStyles from "../../../../../../sharedStyles";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../../../../palette';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
        header: {
            backgroundColor: palette.darkGreen,
            width: "100%",
            padding: theme.spacing(6, 4)
        },
        title: {
            color: "white",
            fontSize: "3em"
        }
    })
)


interface PageHeaderProps {
    title: string
}
const PageHeader = ({ title }: PageHeaderProps) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <a 
                        className={classes.unstyledLink}
                        href="https://aletheamedical.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img className={classes.imgFluid} width="200px" src="/Alethea Logo Reverse.svg"/>
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default PageHeader;