import React from "react";
import { makeStyles } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	LineController,
	Title,
	Tooltip,
	Legend,
	BarElement,
	PointElement,
	LineElement,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	LineController,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

interface EarningBarChartProps {
	data: any

}
const useStyles = makeStyles({
	chartContainer: {
		height:'40vh', 
		width:"60vw"
	},
});

const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
	},
	maintainAspectRatio: false,
	scales: {
		y: {
			ticks: {
				// Include a dollar sign in the ticks
				callback: function(value: any) {
					return '$' + value;
				}
			}
		}	
	}     
};

const EarningTrends = (props: EarningBarChartProps) => {
	const classes = useStyles();
	return (
		<div className={classes.chartContainer}>
			<Bar
				options={options}
				data={props.data}
			/>

		</div>
	);
}

export default EarningTrends;