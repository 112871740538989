
import React, { useEffect, useState } from 'react';
import { CameraButtons } from './CameraButtons'
import useUploadMediaFile from './useUploadMediaFile';
import clsx from "clsx";
import { isIOS, isSafari } from 'react-device-detect';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Webcam from "react-webcam";
import { useUpdateCameraDevices } from './useUpdateCameraDevices';
import { HeightLayout, HeightLayoutChild } from "@alethea-medical/alethea-components";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cameraButtons: {
			padding: theme.spacing(1, 0)
		},
		video: {
			width: '100%',
			height: '100%'
		}
	}),
);

interface CameraContainerProps {
	height: number,
}



const CameraContainer = ({ height }: CameraContainerProps) => {
	const classes = useStyles();
	const [recordedChunks, setRecordedChunks] = React.useState([]);
	const [browserMimeType, setBrowserMimeType] = useState<string>()

	const webcamRef = React.useRef() as React.MutableRefObject<any>
	const mediaRecorderRef = React.useRef() as React.MutableRefObject<any>

	const [mirrored, setMirrored] = useState(false)
	const [recordingModeActive, setrecordingModeActive] = useState(false)
	const [capturing, setCapturing] = React.useState(false);

	//gets the currently connected devices 
	const { devices, setDevices, deviceId, setDeviceId } = useUpdateCameraDevices()

	const {
        uploadFileFromURI,
		uploadFileFromBlob,
		processState,
        setProcessState,
		processErrorMessage,
    } = useUploadMediaFile();


	//default constraints for the camera. height and width define resolution
	const defaultVideoConstraints = {
		facingMode: 'environment'
	}

	//set which camera to use, by default it gets set to the first device in the list of devices
	const [videoConstraints, setvideoConstraints] = useState({
		...defaultVideoConstraints,
		deviceId: devices[0]

	})

	//take a single frame image
	const capture = () => {
		if (webcamRef.current !== undefined && webcamRef.current.getScreenshot() !== null) {
			const dataURI = webcamRef.current.getScreenshot();
			uploadFileFromURI(dataURI, "image")
		}
	}

	//begin recording
	const handleStartRecordClick = () => {
		if (mediaRecorderRef !== undefined) {

			try {
				//! video recording insanity
				/*
				https://stackoverflow.com/questions/59860029/how-to-use-mediarecorder-as-mediasource
				https://stackoverflow.com/questions/44392027/webrtc-convert-webm-to-mp4-with-ffmpeg-js
				Safari doesn't support recording in webm
				Safari does support recording in mp4
					No matter what you pass it, it will always give you a video/mp4                    
				Chrome and Firefox both support recording in webm
					Use video/webm;codecs=opus,vp8
				Chrome and Firefox do not support recording in mp4
				Android and iOS are untested
				*/


				let mimeType = '';
				if (isSafari || isIOS)
					//Safari will only record in mp4
					mimeType = 'video/mp4'
				else//Firefox/Chrome
					//Firefox and Chrome will not record in mp4                    
					mimeType = 'video/webm;codecs=vp8'
				setBrowserMimeType(mimeType)

				//webm files will be automatically converted to mp4 in the backend when its uploaded to allow playback on safari

				mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
					//Add firebase content type here
					mimeType: mimeType
				});

				mediaRecorderRef.current.addEventListener(
					"dataavailable",
					handleDataAvailable
				);

				if (mediaRecorderRef.current.stream.active) {
					setCapturing(true)
					mediaRecorderRef.current.start();
				}
			} catch (error) {
				setCapturing(false)
				console.log(error)
			}
		}
	}

	//get the stream data from the camera
	const handleDataAvailable = React.useCallback(
		({ data }) => {
			setRecordedChunks([])
			if (data.size > 0) {
				setRecordedChunks(prev => prev.concat(data));
			}

		},
		[setRecordedChunks]
	);


	//stop recording 
	const handleStopRecordClick = () => {
		if (mediaRecorderRef !== undefined) {
			if (mediaRecorderRef.current.stream.active) {
				mediaRecorderRef.current.stop();
				setCapturing(false);
			}
		}
	}


	//begin file upload when recording stops
	useEffect(() => {
		if (recordedChunks.length && mediaRecorderRef !== undefined) {
			const blob = new Blob(recordedChunks, {
				type: browserMimeType
			});
			uploadFileFromBlob(blob, "video")
		}
	}, [recordedChunks])

	//update video constraints when the available device list is updated
	useEffect( () =>
	{
		if ( devices != undefined )
		{
			setvideoConstraints( { ...videoConstraints, deviceId: deviceId } )
		}
	}, [ devices, deviceId ] )

	const camerabuttonProps = {
		setProcessState, processState, processErrorMessage,
		webcamRef, setDevices, capture, recordingModeActive,
		setrecordingModeActive, capturing, handleStopRecordClick, handleStartRecordClick, devices,
		setDeviceId, deviceId, setMirrored, mirrored
	}


	return (
		<>
			<HeightLayout height={height}>
				<HeightLayoutChild flexDriven>
					{devices.length === 0 ?
						<div style={ { textAlign: 'center' } }>
							<Typography>No Available Cameras...</Typography>
							<Typography>Please refresh the device list after plugging a device in.</Typography>
							<Typography>If your device is already plugged in try refreshing the device list</Typography>
						</div> :

						( <Webcam
							className={ classes.video }
							audio={ false }
							ref={ webcamRef }
							screenshotQuality={ 1 }
							imageSmoothing={ true }
							forceScreenshotSourceSize={ true }
							screenshotFormat="image/jpeg"
							videoConstraints={ videoConstraints }
							mirrored={ mirrored }
							autoFocus
						/> 
					)}
				</HeightLayoutChild>
				<HeightLayoutChild flexDriver className={clsx(classes.cameraButtons)}>
					<CameraButtons {...camerabuttonProps}/>
				</HeightLayoutChild>
			</HeightLayout>
		</>
	)
}
export default CameraContainer




