import { createStyles, Theme } from '@material-ui/core/styles';
import palette from '../palette';

const sharedStyles = (theme: Theme) => {
    return createStyles({
        dangerButton: {
            backgroundColor: theme.palette.error.main,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            }
        },
        tooltipHover: {
            cursor: "pointer",
            color: theme.palette.primary.main
        },
        imgFluid: {
            maxWidth: "100%",
            height: "auto"
        },
        unstyledLink: {
            textDecoration: "none",
            "&:hover": {
                color: theme.palette.primary.main,
                textDecoration: "none"
            },
            "&:visited": {
                color: theme.palette.primary.main,
                textDecoration: "none"
            },
            "&:active": {
                color: theme.palette.primary.dark,
                textDecoration: "none"
            },
        },
   
    })
}

export const orangeButton = {
    color: "white",
    background: palette.orange,
    "&:hover": {
        background: "#d8530c"
    }
}

export default sharedStyles;