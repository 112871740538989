import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Paper, Grid, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Resources } from "@alethea-medical/aletheamd-types";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Resource from "./Resource";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import AddResourceButton from "../AddResourceButton";
import DeleteButton from "../DeleteButton";
import InternalStateTextField from "../../../../components/InternalStateTextField";
import palette from "../../../../palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    category: {
      backgroundColor: palette.lightGreenLight,
    },
    categoryEditing: {
      padding: theme.spacing(1),
    },
    categoryNotEditing: {
      padding: theme.spacing(1),
    },
    dragging: {
      backgroundColor: palette.lightGreen,
    },
  }),
);

interface CategoryProps {
  index: number;
  category: Resources.Category;
  deleteCategoryHandler: (categoryId: string, index: number) => void;
  updateCategoryHandler: (newCategory: Resources.Category) => void;
  resources: Resources.ResourceDict;
  addResourceHandler: (name: string, categoryId: string) => void;
  deleteResourceHandler: (
    resourceId: string,
    index: number,
    categoryId: string,
  ) => void;
  updateResourceHandler: (newResource: Resources.Resource) => void;
  isEditing: boolean;
  resourceInsertHandler: (resource: Resources.Resource) => void;
  disableDrag?: boolean;
}

const Category = ({
  index,
  category,
  deleteCategoryHandler,
  updateCategoryHandler,
  resources,
  addResourceHandler,
  deleteResourceHandler,
  updateResourceHandler,
  isEditing,
  resourceInsertHandler,
  disableDrag,
}: CategoryProps) => {
  const classes = useStyles();

  const [expandNewResource, setExpandNewResource] = useState(false);

  // When new resource is added, automatically expand it
  const addResource = (resourceName: string) => {
    setExpandNewResource(true);
    addResourceHandler(resourceName, category.id);
  };

  const deleteResource = (id: string, index: number) => {
    deleteResourceHandler(id, index, category.id);
  };

  const categoryNameChangeHandler = (name: string) => {
    const newCategory = { ...category };
    newCategory.name = name;
    updateCategoryHandler(newCategory);
  };

  // When resources update, reset expand new resource
  useEffect(() => {
    setExpandNewResource(false);
  }, [category.resourceIds]);

  return (
    <Draggable
      draggableId={category.id}
      index={index}
      isDragDisabled={!isEditing}
    >
      {(provided, snapshot) => (
        <Paper
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          className={clsx(classes.category, {
            [classes.categoryEditing]: isEditing,
            [classes.categoryNotEditing]: !isEditing,
            [classes.dragging]: snapshot.isDragging,
          })}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid {...provided.dragHandleProps} item>
              {/* Only disable icon. If dragHandleProps are not found, then react-beautiful-dnd throws an error. Dragging is disabled when not editing */}
              {isEditing && !disableDrag && <MenuIcon />}
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <InternalStateTextField
                  value={category.name}
                  setValue={categoryNameChangeHandler}
                  multiline
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rules={(value: string) =>
                    value === "" ? "Category name cannot be empty." : undefined
                  }
                />
              ) : (
                <Typography>{category.name}</Typography>
              )}
            </Grid>
            <Grid item xs={true}>
              <Grid container justifyContent="flex-end">
                {isEditing && (
                  <Grid item>
                    <DeleteButton
                      id={category.id}
                      index={index}
                      name={category.name}
                      deleteHandler={deleteCategoryHandler}
                      type="category"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Droppable droppableId={category.id} type="item">
                {(provided) => (
                  <Grid
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    container
                    spacing={2}
                  >
                    {category.resourceIds.map((resourceId, index) => {
                      if (resources[resourceId] === undefined) return null;

                      const id = `resource_${resourceId}`;
                      return (
                        <Grid item xs={12} key={id}>
                          <Resource
                            index={index}
                            resource={resources[resourceId]}
                            deleteResourceHandler={deleteResource}
                            updateResourceHandler={updateResourceHandler}
                            isEditing={isEditing}
                            resourceInsertHandler={resourceInsertHandler}
                            // Default to expanded, if last resource in list, and an resource has been added
                            defaultExpand={
                              expandNewResource &&
                              index === category.resourceIds.length - 1
                            }
                            disableDrag={disableDrag}
                          />
                        </Grid>
                      );
                    })}
                    {provided.placeholder}
                    {isEditing && (
                      <Grid item xs={12}>
                        <AddResourceButton addResourceHandler={addResource} />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

export default Category;
