import React, { useContext } from 'react';
import { SidebarItem } from '@alethea-medical/alethea-components';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import Badge from '@material-ui/core/Badge';
import { AndroidAPKUpdateContext } from './AndroidAPKUpdateProvider';

interface AndroidAPKUpdateSidebarProps {
    showText: boolean
}

const AndroidAPKUpdateSidebar = ({ showText }: AndroidAPKUpdateSidebarProps) => {
    const { updateAvailable, checkForUpdate } = useContext(AndroidAPKUpdateContext);

    return (
        <>
            {updateAvailable && (
                <SidebarItem label="Update Now" tooltip="Update Now" showText={showText} icon={
                    <Badge color="error" badgeContent={"!"}><SystemUpdateIcon color="primary" /></Badge>} 
                    onClick={checkForUpdate}/>
            )}
        </>
    );
}

export default AndroidAPKUpdateSidebar;