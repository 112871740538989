import { HeightLayout, HeightLayoutChild, ProcessStatus } from "@alethea-medical/alethea-components";
import Typography from '@material-ui/core/Typography';
import SpecialistVacationController from "../../Controllers/SpecialistVacationController";
import VacationRow from "./VacationRow";
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SaveDiscardToolbar from "../../../../../components/SaveDiscardToolbar";
import HelpModal from "../../../../../components/HelpModal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1)
        },
        paper: {
            padding: theme.spacing(1)
        },
        containerChild: {
            margin: theme.spacing(1)
        },
        helpIcon: {
            marginLeft: theme.spacing(1)
        }
    })
)


interface SpecialistVacationViewProps {
    hasPhoneConsultPermission: boolean,
    height: number | string
}

const helpText1 = "Family physicians will not be able to send new consults to you starting at 12:00 AM on the start date until 11:59 PM on the end date."
const helpText2 = "You can choose to be unavailable for only eConsults, only Phone Consults, or both."
const helpText3 = "If you choose to be unavailable for phone consults, your phone consult schedule will be overridden for the dates you select."
const helpText4 = "These dates can be changed or deleted at any time. Once saved, any changes made are implemented immediately."

const helpTextPhoneConsult = [helpText1, helpText2, helpText3, helpText4]
const helpTextNoPhoneConsult = [helpText1, helpText4]

const SpecialistVacationView = ({ hasPhoneConsultPermission, height }: SpecialistVacationViewProps) => {
    const classes = useStyles();
    const { vacation, isDirty,
        addRowHandler,
        updateRowHandler,
        removeRowHandler,
        saveHandler, discardHandler,
        processState, processErrorMessage
     } = SpecialistVacationController()

    return (
        <HeightLayout height={height}>
            <HeightLayoutChild flexDriver >
                <SaveDiscardToolbar className={classes.containerChild} show={isDirty} modalText={"Discard changes to your vacation settings?"} saveHandler={saveHandler} discardHandler={discardHandler} unsavedText="Unsaved Vacation Settings"/>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriver>
                <ProcessStatus className={classes.containerChild} state={processState} errorMessage={processErrorMessage}/>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriver className={classes.container}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography>
                            Set yourself as unavailable to receive eConsults {hasPhoneConsultPermission && "or Phone Consults "}for a period of time.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.helpIcon}>
                        <HelpModal helpText={hasPhoneConsultPermission ? helpTextPhoneConsult : helpTextNoPhoneConsult} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Dates are shown in your current timezone.
                        </Typography>
                    </Grid>
                </Grid>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriver className={classes.container}>
                <Button startIcon={<AddIcon/>} onClick={addRowHandler}>Add Vacation</Button>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriven allowOverflowY className={classes.container}>
                <Grid container spacing={1}>
                    {vacation.map((v, i) => 
                        <Grid item xs={12} key={`vacation_${v.id}_${i}`} >
                            <VacationRow
                                index={i}
                                vacationPeriod={v.data} 
                                removeRowHandler={removeRowHandler}
                                updateRowHandler={updateRowHandler}
                                hasPhoneConsultPermission={hasPhoneConsultPermission}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Button startIcon={<AddIcon/>} onClick={addRowHandler}>Add Vacation</Button>
                    </Grid>
                </Grid>
            </HeightLayoutChild>
        </HeightLayout>
    );
}

export default SpecialistVacationView;