import { Activity } from "../../../../../shared/types";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { formatTimeLong } from "../../../../models/formatTime";
import AddLinks from "../../../../components/AddLinks";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userActionText: {
      textAlign: "center",
      fontSize: "0.9em",
    },
    messageText: {
      zIndex: 100,
      position: "relative",
    },
  }),
);

interface UserActionMessageProps {
  message: Activity.Message;
  sentByUser: Activity.ActivityUser | undefined;
  nameColor: string;
}

const UserActionMessage = ({
  message,
  sentByUser,
  nameColor,
}: UserActionMessageProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" className={classes.userActionText}>
      <Grid item xs={12}>
        <Typography style={{ color: nameColor }}>
          {sentByUser?.firstName ?? ""} {sentByUser?.lastName ?? ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {formatTimeLong(message.sentAt.toDate())}
        </Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.messageText)}>
        <Typography variant="subtitle1">
          <AddLinks text={message.message} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserActionMessage;
