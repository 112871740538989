import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { orangeButton } from '../../../../sharedStyles';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DoneIcon from '@material-ui/icons/Done';
import CallCompleteController, { billingCodes } from './CallCompleteController';
import { PaperModal, ProcessStatus } from '@alethea-medical/alethea-components';
import Typography from '@material-ui/core/Typography';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import Grid from '@material-ui/core/Grid';
import OutcomesInput from '../SpecialistTools/Outcomes/OutcomesInput';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { outcomes } from '../SpecialistTools/Outcomes/EconsultOutcomes/EconsultOutcomesModal';
import StatusPaper from '../../../../components/StatusPaper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        incomplete: orangeButton,
        modal: {
            maxHeight: "90vh",
            overflowY: "auto"
        },
    })
)


interface CallCompleteButtonProps {
    activityId: string,
    callComplete: boolean,
    userActivityRole: string
}

const CallCompleteButton = ({ activityId, callComplete, userActivityRole }: CallCompleteButtonProps) => {
    const classes = useStyles();
    
    const { callCompleteHandler, showModal, setShowModal, enableButtonControl, processState, processErrorMessage, outcomesEmpty, disableSubmit, disableCancel,
        billingCodeKey, setBillingCodeKey,
        selectedOutcome, setSelectedOutcome, selectedDiagnosis, setSelectedDiagnosis
    } = CallCompleteController({ activityId, userActivityRole })


    const showModalHandler = () => {
        setShowModal(true)
    }

    const hideModalHandler = () => {
        setShowModal(false)
    }

    return (
        <> 
            {enableButtonControl === true && 
                <>
                    {callComplete ? 
                        <StatusPaper icon={<DoneIcon color="primary"/>}>
                            Call Complete
                        </StatusPaper>
                        :
                        <Button className={classes.incomplete} variant="contained" startIcon={<PhoneInTalkIcon/>} onClick={showModalHandler}>Mark Call as Complete</Button>
                    }
                </>
            }
            {enableButtonControl === false && 
                <>
                    {callComplete ? 
                        <StatusPaper icon={<DoneIcon color="primary"/>}>
                            Call Complete
                        </StatusPaper>
                        :
                        <StatusPaper icon={<SettingsPhoneIcon color="primary"/>}>
                            Waiting for Call
                        </StatusPaper>
                    }
                </>
            }
            <PaperModal className={classes.modal} show={showModal} flexWidth flexHeight enablePadding setShow={disableCancel ?  () => {} : setShowModal}>
                <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={12}>
                        <Typography>Mark call as complete and bill the phone consult for you and the referring physician?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <OutcomesInput outcomes={outcomes} selectedOutcome={selectedOutcome} setSelectedOutcome={setSelectedOutcome} selectedDiagnosis={selectedDiagnosis} setSelectedDiagnosis={setSelectedDiagnosis}/>
                    </Grid>
                    {outcomesEmpty && 
                        <Grid item xs={12}>
                            <Typography>Please fill out the Outcome before marking as complete</Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Select the Billing Code that is Appropriate for this Consult</FormLabel>
                            <RadioGroup aria-label="billing-code" value={billingCodeKey} onChange={(e) => {setBillingCodeKey(e.target.value)}}>
                                {Object.keys(billingCodes).map((key) => 
                                    <FormControlLabel key={`call_complete_billing_code_${key}`} value={key} control={<Radio />} label={`${billingCodes[key].description} (${billingCodes[key].code})`} />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="outlined" color="primary" onClick={hideModalHandler} fullWidth disabled={disableCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button className={classes.incomplete} variant="contained" onClick={callCompleteHandler} fullWidth disabled={disableSubmit}>Mark as Complete and Bill</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                    </Grid>
                </Grid>
            </PaperModal>
        </>
    );
}

export default CallCompleteButton;