import { FileType } from "@alethea-medical/aletheamd-types";
import { UserMediaMetadataItem } from "./GalleryModel";
import { fbStorage } from "../../../firebase";
import firebase from "firebase";

export function getFileType (filename: string): FileType {
    if(/.*\.jpg|.*\.png|.*.jpeg/.test(filename)) {
        return "image"
    }
    else if(/.*\.mp4/.test(filename)) {
        return "video";
    }
    else {
        return "other";
    }
}

//Get the filename from path, without the random 16 character prefix
export function getCleanedFileNameFromActivityPath(filePath: string)
{
    return removeHashFromActivityMedia(filePath.replace(/^.*[\\/]/, ''))
}

//Remove random 16 character prefix from filename
export function removeHashFromActivityMedia(filename: string) {
    const match = filename.match(/^[a-z,0-9]{16}_(.*)/)
    if (match !== null && match.length > 1) {
        return match[1];
    }
    else {
        return filename;
    }
}

export function tryGetTimestampFromName (filename: string): firebase.firestore.Timestamp | undefined {
    try {
        //Find the day
        const unixTimestamp = parseInt(filename);
        if(isNaN(unixTimestamp)) {
            return undefined
        }
        const timestamp = firebase.firestore.Timestamp.fromMillis(unixTimestamp);
        return timestamp;
    }
    catch {
        return undefined
    }
}

//Parse millisecond string as timestamp. Returns current time if string can't be parsed
export function getTimestampFromName (filename: string): firebase.firestore.Timestamp {
    const timestamp = tryGetTimestampFromName(filename);
    if(timestamp === undefined)
        return firebase.firestore.Timestamp.now();
    return timestamp;
}



//Create mock user media metadata items from referral media uris so they can be used in the gallery components
export function getMockMetadataForReferralMedia(referralMediaURIs: string[], fileTypesToIgnore?: FileType[]): Promise<{
    otherMedia: UserMediaMetadataItem[],
    imageVideoMedia: UserMediaMetadataItem[]
}> {
    const otherMedia: UserMediaMetadataItem[] = []
    const imageVideoMedia: UserMediaMetadataItem[] = []
    return Promise.all(referralMediaURIs.map((uri, index) => {
        const fileType = getFileType(uri)
        if(fileTypesToIgnore !== undefined && fileTypesToIgnore.includes(fileType))
            return;

        const fileRef = fbStorage.ref(uri);
            
        let timestamp: firebase.firestore.Timestamp;
        if(fileType === "other") {
            timestamp = firebase.firestore.Timestamp.now();
        }
        else {
            const filename = removeHashFromActivityMedia(fileRef.name)
            timestamp = getTimestampFromName(filename.split('.')[0]);
        }
        const result: UserMediaMetadataItem = {
            id: index.toString(),
            data: {
                created: timestamp,
                filePath: uri,
                fileType: fileType,
                notes: "",
                tags: []
            }
        }
        return fileRef.getDownloadURL()
        .then((fileSrc) => {
            result.data.fileDownloadUrl = fileSrc
            if(fileType === "other") {
                otherMedia.push(result)
            }
            else {
                imageVideoMedia.push(result)
            }
        })
    }))
    .then(() => {
        return {
            otherMedia,
            imageVideoMedia
        }
    })
}