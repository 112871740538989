import { FieldValues } from 'react-hook-form';
import { TypedControlledInputProps } from '../types';
import PhoneInput from './PhoneInput';

function TypedPhoneInput<T extends FieldValues> (props : TypedControlledInputProps<T>) {
    
    return (
        <PhoneInput
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export default TypedPhoneInput;