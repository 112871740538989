import { useEffect, useState } from "react";
import clsx from 'clsx';
import { HeightLayout, HeightLayoutChild, useScreenSize, useSizeManager } from "@alethea-medical/alethea-components";
import SpecialistScheduleView from "./Calendar/SpecialistScheduleView";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpecialistVacationView from "./Vacation/SpecialistVacationView";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Alert from '@material-ui/lab/Alert';
import usePermissions from "../../../../components/usePermissions";
import isMobileDevice from "../../../../models/isMobileDevice";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hidden: {
            display: "none"
        },
        notAvailableOnMobile: {
            margin: theme.spacing(1),
        }
    })
)

const vacationTab = "vacation"
const phoneConsultCalendarTab = "phoneConsultCalendar"

const EditSchedulePage = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(vacationTab)

    const { heightMinusAppBar } = useScreenSize({})

    const { height, sizeRef, updateSize } = useSizeManager()



    const { granted: phoneConsultGranted } = usePermissions({ resourceKey: resourceKeys.phoneConsult, onPermissionFetched: (granted: boolean) => {
        if(granted) {
            setTab(phoneConsultCalendarTab)
        }
        else {
            setTab(vacationTab)
        }
    }})

    useEffect(() => {
        updateSize()
    }, [tab])

    return (
        <HeightLayout height={heightMinusAppBar}>
            <HeightLayoutChild flexDriver>
                <Tabs value={tab} 
                    onChange={(_: any, newTab: string) => {
                        setTab(newTab);
                    }}
                    orientation={"horizontal"}
                >
                    <Tab className={clsx({ [classes.hidden]: !phoneConsultGranted })} value={phoneConsultCalendarTab} label="Phone Consult Schedule"/>
                    <Tab value={vacationTab} label="Vacation"/>
                </Tabs>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriven ref={sizeRef}>
                {phoneConsultGranted && 
                    <div className={clsx({ [classes.hidden]: tab !== phoneConsultCalendarTab })}>
                        {!isMobileDevice() && 
                            <SpecialistScheduleView height={height}/>
                        }
                        {isMobileDevice() && 
                            <Alert severity="info" className={classes.notAvailableOnMobile}>
                                Phone Consult Schedule Editing is not available on mobile devices. Please visit this page on a desktop or laptop PC to edit your phone consult schedule.
                            </Alert>
                        }
                    </div>
                }
                <div className={clsx({ [classes.hidden]: tab !== vacationTab })}>
                    <SpecialistVacationView hasPhoneConsultPermission={phoneConsultGranted} height={height}/>
                </div>
            </HeightLayoutChild>
        </HeightLayout>

    );
}

export default EditSchedulePage;