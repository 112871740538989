import { TypedFormSelect } from "src/components/FormInputFields/FormSelect";
import { ROLE_WATCH_KEY } from "../../Signup";
import FormattedInputLabel from "src/components/FormattedInputLabel";
import { Grid } from "@material-ui/core";
import { Control } from "react-hook-form";
import { SignupInfo, Plan, Role } from "@alethea-medical/aletheamd-types";


const SelectRoleDropdown = ({
    control,
    plan
}: {
    control: Control<SignupInfo.SignupInfo, any>,
    plan: Plan.Plan
}) => <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3} xl={2}>
            <FormattedInputLabel>Select a Role</FormattedInputLabel>
            <TypedFormSelect
                control={control}
                name={ROLE_WATCH_KEY}
                options={Object.values(Role.Roles)}
                rules={{
                    required: { value: true, message: "Role is required" },
                    validate: {
                        isRoleAndPlanValid: (role: Role.Role) => {
                            if (!Role.isEligibleForPlan(role, plan))
                                return `${Role.getLongDisplayName(role)}s cannot sign up for Alethea plus at this time.`
                            return undefined
                        },
                    }
                }}
                getOptionLabel={(option: Role.Role) => Role.getLongDisplayName(option)}
            />
        </Grid>
    </Grid>

export default SelectRoleDropdown;