import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import SectionNumber from '../Styling/section/SectionNumber';
import SectionHeader from '../Styling/section/SectionHeader';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useMobileSizes } from '@alethea-medical/alethea-components';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentContainerWeb: {
            paddingLeft: theme.spacing(9)
        }
    })
)

interface SectionContainerProps {
    title?: string,
    number?: number
}

const SectionContainer: React.FC<SectionContainerProps> = ({ title, number, children }) => {
    const classes = useStyles();

    const isMobileSize = useMobileSizes();

    return (
        <Grid container justifyContent='flex-start' alignItems='center' spacing={3}>
            {number !== undefined &&            
                <Grid item>
                    <SectionNumber number={number}/>
                </Grid>
            }
            {title !== undefined &&
                <Grid item>
                    <SectionHeader title={title}/>
                </Grid>
            }
            <Grid item xs={12}>
                <Grid className={clsx({
                    [classes.contentContainerWeb]: !isMobileSize
                })} container spacing={1}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid> 
    );
}

export default SectionContainer;