import { FieldValues } from "react-hook-form";
import  { TypedControlledInputProps } from "../types";
import EmailInput from "./EmailInput";


function TypedEmailInput<T extends FieldValues> (props: TypedControlledInputProps<T>) {

    return (
        <EmailInput
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export * from "./EmailInput";

export default TypedEmailInput;