import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState, useContext } from "react";
import { searchByString } from "./ApproverSearchFunctions"
import { AuthContext } from "../../../../../AuthProvider";

interface ApproverDropdownUser {
    uid: string,
    firstName: string,
    lastName: string,
}

interface ApproverDropdownProps {
    selectedApproverUid: string | undefined,
    selectApproverUid: (uid: string) => void
    deselectApproverUid: () => void
}

function approverDropdown({ selectedApproverUid, selectApproverUid, deselectApproverUid } : ApproverDropdownProps) {
    const authContext = useContext(AuthContext);
    const [ searchText, setSearchText ] = useState<string>("");
    const [ approvers, setApprovers ] = useState<ApproverDropdownUser[]>([]);
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const getUsers = () => {
        setProcessState(ProcessState.running);
        searchByString( { typesenseUsersSearchApiKey: authContext.typesenseUsersSearchApiKey, searchText: searchText } )
        .then((approvers) => {
            // set approvers but turn each approver into an ApproverDropdownUser
            setApprovers(approvers.map((approver) => { 
                return approver as ApproverDropdownUser
            }));

            // setApprovers(approvers as ApproverDropdownUser[]);
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: error.message
            })
        })
    }

    useEffect(() => {
        searchText.length > 0 ? getUsers() : setApprovers([]);
    }, [searchText]);


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={approvers}
                        getOptionSelected={(option: ApproverDropdownUser, value: ApproverDropdownUser) => option.uid === value.uid}
                        getOptionLabel={(option: ApproverDropdownUser) => `${option.firstName} ${option.lastName}`}
                        onChange={(event: any, newValue: ApproverDropdownUser | null) => {
                            if (newValue !== null) {
                                selectApproverUid(newValue.uid)
                            }
                            else {
                                deselectApproverUid()
                            }
                        }}
                        inputValue={searchText}
                        onInputChange={(event, newInputValue) => {
                            setSearchText(newInputValue);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Type to search for a user"
                                variant="outlined"
                            />
                        }
                        // getOptionDisabled={isUserDisabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} />
                </Grid>
            </Grid>
        </>
    )
}

export default approverDropdown;