import { PaperModal } from '@alethea-medical/alethea-components';
import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        modal: {
            padding: theme.spacing(8, 2, 4, 2)
        },
	}),
);

interface AgreementModalProps {
    show: boolean,
    setShow: (show: boolean) => void,
}

const AgreementModal: React.FC<AgreementModalProps> = ({ show, setShow, children }) => {
    const classes = useStyles();

    return (
        <>
            <PaperModal className={classes.modal} show={show} setShow={setShow} enablePadding>
                {children}
            </PaperModal>
        </>
    );
}

export default AgreementModal;