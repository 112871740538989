import React from "react";
import ResourcesView from "../../Resources/Views/ResourcesView";
import isMobileDevice from "../../../../models/isMobileDevice";

const ResourcesPullout = () => {
  return (
    <ResourcesView alwaysEnableEditing={false} disableDrag={isMobileDevice()} />
  );
};

export default ResourcesPullout;
