import React from "react";
import { PatientMessage } from "./types";
import MessageSubheader from "./MessageSubheader";
import MessageListItem from "../../../components/MessageListItem";
import AttachmentIcon from '@material-ui/icons/Attachment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            marginRight: theme.spacing(2)
        }
    })
)

interface PatientMessageListItemProps {
    message: PatientMessage,
    selected: boolean,
    setSelected: (id: string, checked: boolean) => void,
    openMessage: (id: string) => void
}

const PatientMessageListItem = ({ message, selected, setSelected, openMessage }: PatientMessageListItemProps) => {
    const classes = useStyles();
    return (
        <MessageListItem
            selected={selected}
            setSelected={setSelected}
            openMessage={openMessage}
            id={message.id}
            read={message.read}
            primary={message.subject}
            secondary={<MessageSubheader message={message}/>}
        >
            <>
                {message?.messageMediaURIs?.length > 0 && (
                    <AttachmentIcon className={classes.icon} color="primary"/>
                )}
                {!message.read && (
                    <FiberManualRecordIcon className={classes.icon} color="secondary"/>
                )}
            </>
        </MessageListItem>
    );
}

export default PatientMessageListItem;