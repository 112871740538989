import React from "react";
import { PaperModal } from "@alethea-medical/alethea-components";
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            textAlign: 'center'
        }
    }),
);
interface SuccessModalProps {
    text: string,    
    show: boolean
}

const SuccessModal = ({text, show}: SuccessModalProps) => {
    const classes = useStyles();
    return (
        <PaperModal show={show} flexWidth flexHeight enablePadding>
            <Typography className={classes.text}>{text}</Typography>
        </PaperModal>
    );
}

export default SuccessModal;
