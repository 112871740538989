import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { formatTimeslot } from "../../Models/SpecialistScheduleModel";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeslot: {
            fontSize: "0.9em",
            // Disable text select
            "-webkit-user-select": "none", /* Safari */
            "-ms-user-select": "none", /* IE 10 and IE 11 */
            "user-select": "none" /* Standard syntax */
        }
    })
)
interface TimeslotRangeDisplayProps {
    start: string,
    end: string,
    className?: string
}

const TimeslotRangeDisplay = ({ start, end, className }: TimeslotRangeDisplayProps) => {
    const classes = useStyles();
    return (
        <Typography className={clsx(classes.timeslot, className)}>{formatTimeslot(start)} - {formatTimeslot(end)}</Typography>
    );
}

export default TimeslotRangeDisplay;