import { useEffect } from "react";
import {
  ProcessState,
  ProcessStatus,
  useProcessState,
  PaperModal,
} from "@alethea-medical/alethea-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useFileDrop from "../../../components/useFileDrop";
import { FileRejection } from "react-dropzone";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import isMobileDevice from "../../../models/isMobileDevice";
import useUploadMediaFile from "../Camera/useUploadMediaFile";
import { FileType } from "@alethea-medical/aletheamd-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      //Add minWidth to modal so it isn't too small on desktop.
      //If on mobile, modal can grow more to take up more screen size since 50% of screen is too small in that case
      minWidth: "50vw",
    },
    //Make sure thumbnails don't overflow modal
    gridItem: {
      overflowY: "auto",
      maxHeight: "50vh",
    },
  }),
);

const acceptedFileTypes = ["image/jpg", "image/jpeg", "image/png", "video/mp4"];

interface UploadFilesModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  uploadFinishedHandler?: () => void;
}

const UploadFilesModal = ({
  show,
  setShow,
  uploadFinishedHandler,
}: UploadFilesModalProps) => {
  const classes = useStyles();

  const { uploadFileFromBlobSynchronous } = useUploadMediaFile();

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const dropRejectedHandler = (fileRejections: FileRejection[]) => {
    errorHandler({
      userMessage: `The following files could not be uploaded:${fileRejections.map(
        (file) => ` ${file.file.name}`,
      )}. Please only upload jpg, jpeg, png, or mp4 files.`,
    });
  };
  const {
    files,
    resetFiles,
    removeFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
  } = useFileDrop({
    disabled: processState === ProcessState.running,
    acceptedFileTypes,
    onDropRejected: dropRejectedHandler,
  });

  const handleClose = () => {
    if (processState !== ProcessState.running) {
      resetFiles();
      setProcessState(ProcessState.idle);
    }
  };

  const handleSubmit = () => {
    setProcessState(ProcessState.running);
    if (Object.keys(files).length > 0) {
      const uploadPromises = Object.keys(files).map((key: string) => {
        const file = files[key].file;
        let fileType: FileType = "image";

        //We only allow mp4 videos to be uploaded, so anything else will be an image
        if (file.name.split(".")[1] === "mp4") {
          fileType = "video";
        }

        return uploadFileFromBlobSynchronous(file, fileType)
          .then(() => {
            return {
              name: file.path,
              key: key,
              success: true,
            };
          })
          .catch((error: Error) => {
            console.error(error);
            return {
              name: file.path,
              key: key,
              success: false,
            };
          });
      });

      const successFileUploadKeys: string[] = [];

      Promise.all(uploadPromises).then((results) => {
        let oneFailed = false;
        results.forEach((result) => {
          if (!result.success) {
            oneFailed = true;
          } else {
            successFileUploadKeys.push(result.key);
          }
        });
        removeFiles(successFileUploadKeys);

        if (uploadFinishedHandler) uploadFinishedHandler();

        if (oneFailed) {
          errorHandler({
            userMessage: "The remaining files failed to upload.",
          });
        } else {
          setProcessState(ProcessState.success);
          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 1000);
        }
      });
    } else {
      errorHandler({
        userMessage: "Select at least one file.",
      });
      setTimeout(() => {
        setProcessState(ProcessState.idle);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!show) {
      handleClose();
    }
  }, [show]);

  return (
    <PaperModal
      className={classes.container}
      show={show}
      setShow={setShow}
      flexHeight
      flexWidth
      enablePadding
    >
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Upload files</Typography>
        </Grid>
        <Grid item xs={12}>
          {createDropzone(
            isMobileDevice()
              ? "Tap to Upload"
              : "Click Here or Drag and Drop to Upload",
            "150px",
          )}
        </Grid>
        <Grid item xs={12}>
          {createFileList()}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          {createFileThumbs()}
        </Grid>
        <Grid item xs={12}>
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            successMessage="Upload successful"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={processState === ProcessState.running}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </PaperModal>
  );
};

export default UploadFilesModal;
