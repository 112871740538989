import React from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import Grid, { GridSize } from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import RoundedButton from "../../RoundedButton";
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import palette from "../../../palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonUnselected: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[300],
      "&:hover": {
        backgroundColor: theme.palette.grey[400],
      },
    },
    buttonSelected: {
      backgroundColor: palette.orange,
    },
  }),
);

interface FormRadioGroupProps extends ControlledInputProps {
  options: string[];
  breakpoints?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize };
  displayOption?: (option: string) => string;
  onClick?: (option: string) => void;
}

const FormRadioGroup = ({
  name,
  control,
  defaultValue,
  options,
  disabled,
  displayOption,
  rules,
}: FormRadioGroupProps) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue !== undefined ? defaultValue : false}
      rules={rules}
      render={({ field }) => {
        const handleClickOption = (optionSelected: string) => {
          field.onChange(optionSelected);
        };

        return (
          <RadioGroup>
            {options.map((option, index) => (
              <FormControlLabel
                key={`rounded_radio_button_${option}_${index}`}
                label={displayOption ? displayOption(option) : option}
                value={option}
                control={
                  <Radio
                    checked={field.value === option}
                    color="primary"
                    disableRipple
                    disabled={disabled}
                    onClick={() => {
                      handleClickOption(option);
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        );
      }}
    />
  );
};

export default FormRadioGroup;
