import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListItem from '@material-ui/core/ListItem';

interface NotificationCenterToolbarProps {
    markAllAsReadHandler: () => void,
    refresh: () => void
}

const NotificationCenterToolbar = ({ markAllAsReadHandler, refresh }: NotificationCenterToolbarProps) => {

    return (
        <ListItem divider>
            <Grid container spacing={2} alignItems="center">

                <Grid item>
                    <IconButton onClick={refresh}>
                        <RefreshIcon/>
                    </IconButton>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={markAllAsReadHandler}>
                        Mark all as read
                    </Button>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default NotificationCenterToolbar;