import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import routes from "../../../config/routes";

import { ProcessState, ProcessStatus, useMobileSizes } from "@alethea-medical/alethea-components";

import Grid from "@material-ui/core/Grid";
import { GridSize } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper'
import Alert from '@material-ui/lab/Alert';

import Typography from '@material-ui/core/Typography';
import EmailInput from "../../../components/FormInputFields/EmailInput";
import FormCheck from "../../../components/FormInputFields/FormCheckbox";
import FormDatePicker from '../../../components/FormInputFields/FormDatePicker';
import FormTextArea from "../../../components/FormInputFields/FormTextArea";
import FormTextField from "../../../components/FormInputFields/FormTextField";

import { Location } from "../../../../shared/types";

import * as QueryString from "query-string"
import { Link, withRouter } from 'react-router-dom';
import PhoneInput from '../../../components/FormInputFields/PhoneInput';
import FormSelect from '../../../components/FormInputFields/FormSelect';
import PHNInput from '../../../components/FormInputFields/PHNInputLookup/PHNInput';
import sharedStyles from '../../../sharedStyles';
import isNativeMobile from '../../../models/isNativeMobile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		...sharedStyles(theme),
		webRoot: {
			margin: '0 auto',
			marginTop: '50px',
			marginBottom: '50px',
			width: '80%',
			padding: theme.spacing(2),
			minHeight: "80vh"
		},
		mobileRoot: {
			padding: theme.spacing(3),
			width: "100%"
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		logo: {
			minWidth: "130px"
		},
		gridItem: {
			textAlign: 'center'
		},
		gridInfoWeb: {
			textAlign: 'right'
		},
		gridInfoMobile: {
			textAlign: 'left'
		},
		saveButton: {
			textAlign: 'center',
			margin: theme.spacing(1, 0)
		},
		checkboxContainer: {
			marginLeft: theme.spacing(2)
		},
		sectionFieldsWeb: {
			marginLeft: theme.spacing(5)
		},
		formattedString: {
			overflowWrap: "break-word"//Prevent overflow of strings if there are no whitespaces
		},
		validatePhnForm: {
			margin: '0 auto',
			width: "50%",
			padding: theme.spacing(2),
			marginTop: '50px',
			marginBottom: '50px',
		}
	}),
);


interface FormTemplate {
	name: string,
	instructions: string,
	sections: any[],
	comments: string
}

const CareForm = withRouter(({ location }) => {
	const classes = useStyles();

	const renderForm = fbFunctions.httpsCallable("forms-renderForm");
	const submitForm = fbFunctions.httpsCallable("forms-submitForm");
	const previewForm = fbFunctions.httpsCallable("forms-previewForm");

	const formSafeEncode = (str: string): string => {
		return btoa(str);//encode base64 since react hook form doesn't like special characters in variable names
	}
	const baseName = formSafeEncode("fields")

	const { handleSubmit, control, trigger } = useForm({ mode: "onTouched" });

	const [status, setStatus] = useState(ProcessState.idle);
	const [errorMessage, setErrorMessage] = useState('');

	const isMobileSize = useMobileSizes();

	const [formLoadStatus, setFormLoadStatus] = useState(ProcessState.idle);
	const [formLoadError, setFormLoadError] = useState("");


	const [formTemplate, setFormTemplate] = useState<FormTemplate>();
	const [physician, setPhysician] = useState({
		firstName: "",
		lastName: ""
	});
	const [clinic, setClinic] = useState<Location>();


	const [isPreview, setIsPreview] = useState<boolean>(false);
	const [needToValidatePhn, setNeedToValidatePhn] = useState<boolean>(false);
	const patientProvince = useWatch({control, name: "patientProvince" });

	useEffect(() => {
		trigger(`${baseName}.${formSafeEncode("phn")}`)
	}, [patientProvince])

	const onSubmitValidatePhnForm = (data: any) => {
		const params = QueryString.parse(location.search);
		setFormLoadStatus(ProcessState.running);
		if (params.code) {
			renderForm({ jwt: params.code, phn: data[baseName][formSafeEncode("phn")] })
				.then((result) => {
					const formInfo = result.data as {
						formTemplate: FormTemplate,
						patientMessageId: string,
						physicianInfo: {
							firstName: string,
							lastName: string,
							location: Location,
							uid: string
						}
					}

					setFormTemplate(formInfo.formTemplate);
					setPhysician({
						firstName: formInfo.physicianInfo.firstName,
						lastName: formInfo.physicianInfo.lastName
					});
					setClinic(formInfo.physicianInfo.location);
					setIsPreview(false);
					setNeedToValidatePhn(false);

				})
				.catch((error: Error) => {
					setFormLoadStatus(ProcessState.error);
					setFormLoadError(error.message);
				});
		}
	}

	useEffect(() => {
		const params = QueryString.parse(location.search);

		// If preview mode, no need to validate PHN (this is for doctor's to see what their forms look like)
		if (params.preview === "true") {
			if (params.formId && params.locationIdx) {

				setFormLoadStatus(ProcessState.running);
				previewForm({ formId: params.formId, locationIdx: params.locationIdx })
					.then((result) => {
						const formInfo = result.data as {
							formTemplate: FormTemplate,
							physicianInfo: {
								firstName: string,
								lastName: string,
								location: Location,
								uid: string
							}
						}

						setFormTemplate(formInfo.formTemplate);
						setPhysician({
							firstName: formInfo.physicianInfo.firstName,
							lastName: formInfo.physicianInfo.lastName
						});
						setClinic(formInfo.physicianInfo.location);
						setIsPreview(true);
					})
					.catch((error: Error) => {
						setFormLoadStatus(ProcessState.error);
						setFormLoadError(error.message);
					});
			}
			else {
				setFormLoadStatus(ProcessState.error);
				setFormLoadError("Invalid form");
			}
		} else {
			// Need to validate PHN
			setNeedToValidatePhn(true);
		}
	}, []);


	const onSubmit = (data: any) => {
		const params = QueryString.parse(location.search);

		setStatus(ProcessState.running);
		setErrorMessage("");
		const fields = data !== undefined ? decodeObject(data).fields : {};

		if (fields.ccPatient === true) {
			logAnalyticsEvent("forms_cc_patient");
		}

		logAnalyticsEvent("forms_submit_start");

		submitForm({
			jwt: params.code,//Pass JWT to validate request and get form and physician information
			formData: fields,
			patientInfo: {
				firstName: fields.firstName ? fields.firstName : "",
				lastName: fields.lastName ? fields.lastName : "",
				phn: fields.phn ? fields.phn : "",
				email: fields.patientEmail ? fields.patientEmail : ""
			},
			ccPatient: fields.ccPatient,
		})
			.then(() => {
				logAnalyticsEvent("forms_submit_success");

				setStatus(ProcessState.success);
			})
			.catch((error: Error) => {
				logAnalyticsEvent("forms_submit_failed");

				console.log(error)
				setErrorMessage(error.message);
				setStatus(ProcessState.error);
			})
	}


	const decodeObject = (subObj: any) => {
		//Is object?
		if (subObj.constructor === ({}).constructor) {
			const newObj: any = {}
			Object.keys(subObj).forEach((key) => {
				const newKey = atob(key);//decode base64
				newObj[newKey] = decodeObject(subObj[key]);
			});
			return newObj;
		}//Otherwise its a value, just return
		else {
			//Check if date, if it is, return the time value rather than the date object
			if (typeof subObj.toLocaleString === 'function') {
				return (subObj.valueOf())
			}
			return subObj;
		}
	}

	const onFormLoadError = () => {
		setFormLoadError("Check form for errors.");
		setFormLoadStatus(ProcessState.error)
	}

	const onError = () => {
		setErrorMessage("Check form for errors.");
		setStatus(ProcessState.error);
	}


	const isDisabled = () => {
		return status === ProcessState.running || status === ProcessState.success;
	}

	const createTextField = (header: string, name: string) => {

		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		if (name === "phn") {

			return (
				<Grid item xs={12} md={6} key={`phn_${fieldName}`}>
					<Typography>{formatString(header)}</Typography>
					<PHNInput
						control={control}
						name={fieldName}
						province={patientProvince}
						rules={{ required: { value: true, message: "PHN is required" } }}
					/>
				</Grid>
			);
		}
		else {
			return (
				<Grid item xs={12} md={6} key={`textfield_${fieldName}`}>
					<Typography>{formatString(header)}</Typography>
					<FormTextField
						control={control}
						name={fieldName}
					/>
				</Grid>
			);


		}
	}

	const formatString = (paragraph: string) => {
		const sentences = paragraph.split('<br/>');
		return (
			<>
				{sentences.map((sentence, i) =>
					<span key={`${sentence}_${i}`} className={classes.formattedString}>
						{sentence}<br />
					</span>
				)}
			</>
		)
	}

	const createLabel = (label: string) => {
		return (
			<Grid item xs={12}>
				<Typography>{formatString(label)}</Typography>
			</Grid>);
	}


	const createTextArea = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12}>
				<Typography>{formatString(header)}</Typography>
				<FormTextArea
					name={fieldName}
					control={control}
					initRows={3}
					disabled={isDisabled()}
				/>
			</Grid>
		);
	}

	const createEmailField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12} md={6}>
				<EmailInput
					control={control}
					name={fieldName}
					label={header}
				/>
			</Grid>
		);
	}

	const createPatientEmail = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		const ccPatientFieldName = `${baseName}.${formSafeEncode("ccPatient")}`;
		return (
			<Grid item xs={12}>
				<Typography>{formatString(header)}</Typography>
				<Grid container alignContent="flex-start" alignItems="center" spacing={2}>
					<Grid item xs={12} md={6}>
						<EmailInput
							control={control}
							name={fieldName}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormCheck
							name={ccPatientFieldName}
							control={control}
							label={"Send me a copy of my form"}
						/>
					</Grid>
				</Grid>
			</Grid>

		);
	}

	const createPhoneField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12} md={6}>
				<Typography>{formatString(header)}</Typography>
				<PhoneInput
					control={control}
					name={fieldName}
				/>
			</Grid>
		);
	}

	const createCheckbox = (value: string, name: string, gridSize?: GridSize) => {

		//react hook form doesn't like its input names having commas and some other special characters
		//Use uri encoding to get around this
		const fieldName = `${baseName}.${formSafeEncode(name)}.${formSafeEncode(value)}`;

		return (
			<>
				<Grid item xs={gridSize}>
					<FormCheck
						name={fieldName}
						control={control}
						label={value}
					/>
				</Grid>
			</>
		);
	}
	const createCheckboxes = (header: string, values: string[], name: string) => {
		return (
			<>
				<Grid item xs={12}>
					<Grid container spacing={1} alignItems="flex-start">
						<Grid item xs={12}>
							<Typography>{formatString(header)}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1} className={classes.checkboxContainer}>
								{values.map((_, i) => //Put checkboxes beside each other if only 2 values (ie its a yes or no question)
									createCheckbox(values[i], name, values.length === 2 ? undefined : 12)
								)}
							</Grid>
						</Grid>

					</Grid>
				</Grid>
			</>
		);
	}

	const createDateField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		let dValue;
		if (name === "today") {
			dValue = new Date();
		}
		else {
			dValue = ""

		}
		return (
			<>
				<Grid item xs={12} md={6}>
					<Typography>{formatString(header)}</Typography>
					<FormDatePicker
						name={fieldName}
						control={control}
						defaultValue={dValue}
						label={header}
						rules={{ required: { value: true, message: `${header} is required` } }}
					/>
				</Grid>
			</>
		);
	}

	const createBinaryField = (header: string, name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (
			<Grid item xs={12}>
				<FormCheck
					name={fieldName}
					control={control}
					label={header}
				/>
			</Grid>
		);
	}

	const createSelectMenu = (header: string, values: string[], name: string) => {
		const fieldName = `${baseName}.${formSafeEncode(name)}`;
		return (

			<Grid item xs={12} md={6}>
				<Typography>{header}</Typography>
				<FormSelect
					name={fieldName}
					control={control}
					options={values}
				/>
			</Grid>


		);
	}

	const renderContactUsError = (errorState: ProcessState) => {
		if(errorState === ProcessState.error) {
			return (
				<Grid item xs={12}>
					<Typography variant="subtitle1" style={{textAlign: 'center'}}>
						Please contact us at <a href={`mailto:${"support@aletheamedical.com"}`}>{"support@aletheamedical.com"}</a> for assistance if your error persists.
					</Typography>
				</Grid>
			)
		}	
		return null;
	}

	return (
		<>

			<Paper className={clsx(classes.root, {
				[classes.webRoot]: !isMobileSize && !needToValidatePhn,
				[classes.mobileRoot]: isMobileSize,
				[classes.validatePhnForm]: needToValidatePhn && !isMobileSize
			})} elevation={isMobileSize ? 0 : 1}>
				{/* Show loading status for preview form */}
				{formTemplate === undefined && !needToValidatePhn && (
					<Grid container alignItems="flex-start" justifyContent="center">
						<Grid item xs={12}>
							<ProcessStatus state={formLoadStatus} errorMessage={formLoadError} loadingMessage='Loading form preview...' />
						</Grid>
					</Grid>
				)}

				{/* Show success page */}
				{status === ProcessState.success && (
					<>
						<Grid container alignItems="flex-start" justifyContent="center">
							<Grid item xs={6}>
								<ProcessStatus state={status} errorMessage={errorMessage} successMessage={"Form submitted successfully."} />
							</Grid>
						</Grid>
						<Grid container alignItems="flex-start" justifyContent="center">
							<Typography variant="subtitle1">
								You may now close this page.
							</Typography>
						</Grid>
					</>
				)}

				{/* Show PHN validation form */}
				{formTemplate == undefined && needToValidatePhn && (
					<form>
						<fieldset disabled={isDisabled()} >
							<Grid container alignItems="flex-start" spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5">Validate Patient Health Number</Typography>
								</Grid>

								<Grid item xs={12}>
									Please validate your Patient Health Number in order to access the form.
								</Grid>

								<Grid item xs={12}>
									<div className={clsx({
										[classes.sectionFieldsWeb]: !isMobileSize
									})}>
										<Grid container alignItems="center" spacing={2}>
											<Grid item xs={12}>
												<Typography>Province</Typography>
												<FormSelect
													name="patientProvince"
													control={control}
													options={["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]}
													rules={{required: {value: true, message: "Province is required."}}}
													defaultValue=""
												/>
											</Grid>

											<Grid item xs={12} key={`validate_phn`}>
												<Typography>Patient Health Number</Typography>
												<PHNInput
													control={control}
													name={`${baseName}.${formSafeEncode("phn")}`}
													province={patientProvince}
												/>
											</Grid>
											<Grid item xs={12}>
												<Alert severity="info">If you have received this form on behalf of a family member, you may need to enter their Patient Health Number instead.</Alert>
											</Grid>
										</Grid>
									</div>
								</Grid>
								<Grid item xs={12}>
									<ProcessStatus state={formLoadStatus} errorMessage={formLoadError} successMessage={""} />
								</Grid>
								{renderContactUsError(formLoadStatus)}
								<Grid item xs={12} className={classes.saveButton}>
									<Button id="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmitValidatePhnForm, onFormLoadError)} disabled={isDisabled() || isPreview}>Submit</Button>
								</Grid>
								
							</Grid>
						</fieldset>
					</form>
				)}

				{/* Show actual form */}
				{status !== ProcessState.success && formTemplate !== undefined && !needToValidatePhn && (
					<>
						<Grid container direction="row" alignItems="center" spacing={1}>
							<Grid item xs={12}>
								<Grid container spacing={1} justifyContent={isMobileSize ? "flex-start" : "space-between"} alignItems="center">
									{isPreview && (
										<Grid item xs={12}>
											<Grid container spacing={1} justifyContent={isMobileSize ? "flex-start" : "center"} alignItems="center">
												{isNativeMobile() && (
													<Grid item>
														<Link to={routes.forms.path}>
															<Button variant="outlined" color="primary">Back to Forms</Button>
														</Link>
													</Grid>
												)}
												<Grid item className={classes.gridItem}>
													<Typography variant="h5">Form Preview</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									<Grid item xs={2}>
										<img className={clsx(classes.imgFluid, classes.logo)} src="/Alethea Logo.png" alt="Alethea Logo" />
									</Grid>
									<Grid item xs={10}>
										{/* Spacer */}
									</Grid>
									<Grid item xs={12} md={8}>
										<Typography variant="h5" color="primary">
											{formTemplate.name}
										</Typography>
									</Grid>
									<Grid item className={clsx({
										[classes.gridInfoWeb]: !isMobileSize,
										[classes.gridInfoMobile]: isMobileSize
									})} xs={12} md={4}>
										<Grid container>
											<Grid item xs={12}>
												{`${physician.firstName} ${physician.lastName}`}
											</Grid>
											<Grid item xs={12}>
												{`${clinic?.clinicName} - ${clinic?.city}`}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1">{formTemplate.instructions ? formatString(formTemplate.instructions) : ""}</Typography>
							</Grid>
							<Grid item xs={12}>
								<form>
									<fieldset disabled={isDisabled()} >
										<Grid container alignItems="flex-start" spacing={2}>
											{formTemplate.sections?.map((section: any, i: any) =>
												<React.Fragment key={`section_${i}`}>
													<Grid item xs={12}>
														<Typography variant="h5">{section.sectionName}</Typography>
													</Grid>
													<Grid item xs={12}>
														<div className={clsx({
															[classes.sectionFieldsWeb]: !isMobileSize
														})}>
															<Grid container alignItems="center" spacing={2}>
																<Grid item xs={12}>
																	<Typography style={{ whiteSpace: 'pre-wrap' }}>{(section.description ? formatString(section.description) : "")}</Typography>
																</Grid>
																{
																	section.fields ?
																		section.fields.map((field: any) => {
																			switch (field.inputType) {
																				case "Text":
																					if (field?.label)
																						return (createTextField(field.label, field.fieldName))
																					return null;
																				case "Checkbox":
																					if (field?.label && field?.options)
																						return (createCheckboxes(field.label, field.options, field.fieldName))
																					return (createBinaryField(field.label, field.fieldName))
																				case "Date":
																					if (field?.label)
																						return (createDateField(field.label, field.fieldName))
																					return null;
																				case "Email":
																					if (field?.label)
																						return (createEmailField(field.label, field.fieldName))
																					return null;
																				case "PatientEmail":
																					if (field?.label)
																						return (createPatientEmail(field.label, field.fieldName))
																					return null;
																				case "Phone":
																					if (field?.label)
																						return (createPhoneField(field.label, field.fieldName))
																					return null;
																				case "TextArea":
																					if (field?.label)
																						return (createTextArea(field.label, field.fieldName))
																					return null;
																				case "Label":
																					if (field?.label)
																						return (createLabel(field.label))
																					return null;
																				case "Select":
																					if (field?.label && field?.options)
																						return (createSelectMenu(field.label, field.options, field.fieldName))
																					return null;
																				default:
																					return null;
																			}
																		}) : ""}

															</Grid>
														</div>
													</Grid>
												</React.Fragment>
											)}
											<Grid item xs={12}>
												{formTemplate.comments ? formatString(formTemplate.comments) : ""}
											</Grid>
											<Grid item xs={12}>
												<ProcessStatus state={status} errorMessage={errorMessage} successMessage={""} />
											</Grid>
											{renderContactUsError(status)}
											<Grid item xs={12} className={classes.saveButton}>
												<Button id="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit, onError)} disabled={isDisabled() || isPreview}>Submit</Button>
											</Grid>
										</Grid>
									</fieldset>
								</form>
							</Grid>
						</Grid>
					</>
				)}
			</Paper>
		</>
	);
})

export default CareForm;