import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { Controller, useForm } from "react-hook-form";
import { ControlledInputProps } from "../FormInputFields/types";
import {
  Location,
  LocationBilling,
  Province,
} from "@alethea-medical/aletheamd-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/core/styles";
import { sortAndFilterLocations } from "../../utils/locationUtils";

const useStyles = makeStyles((theme) => {
  return {
    ...sharedStyles(theme),
  };
});

interface LocationDropdownProps extends ControlledInputProps {
  onSelectLocation?: (newLocation: number) => void;
  setValue?: (name: string, value: any) => void;
}

const LocationDropdown = ({
  name,
  control,
  label,
  disabled,
  onSelectLocation = () => {},
  setValue,
  errorTextPadding,
  ...rest
}: LocationDropdownProps) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  interface LocationWithTrueIndex {
    originalIndex: number;
    billable: boolean;
    billing?: LocationBilling | undefined;
    city: string;
    clinicName: string;
    clinicPhone: string;
    emr?: string | undefined;
    fax: string;
    province: Province;
    division?: string | undefined;
    disabled?: boolean | undefined;
    default?: boolean | undefined;
  }

  const [defaultLocationIdx, setDefaultLocationIdx] = useState<number>(0); // This state needed because otherwise the dropdown doesn't update
  const [enabledLocations, setEnabledLocations] = useState<
    LocationWithTrueIndex[]
  >([]);

  useEffect(() => {
    const filteredLocations = sortAndFilterLocations(
      authContext.profile?.locations ?? [],
    );

    setEnabledLocations(filteredLocations);
  }, [authContext]);

  useEffect(() => {
    setDefaultLocationIdx(enabledLocations[0]?.originalIndex ?? 0);
    if (setValue) setValue(name, enabledLocations[0]?.originalIndex ?? 0); // Manually set the value of the controller
  }, [enabledLocations]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultLocationIdx}
      rules={{
        validate: {
          locationSelected: (): string | undefined => {
            return enabledLocations === undefined ||
              enabledLocations.length === 0
              ? "Please select a location"
              : undefined;
          },
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            {...rest}
            onChange={(e) => {
              // Sets the "original" index values, before filtering
              field.onChange(e);
              onSelectLocation(e.target.value as unknown as number);
            }}
            className={classes.canDisable}
            disabled={
              disabled ||
              authContext?.profile?.locations === undefined ||
              authContext.profile.locations.length <= 1
            }
            select
            label={label}
            error={fieldState.error !== undefined}
            helperText={
              errorTextPadding
                ? fieldState.error?.message
                  ? fieldState.error.message
                  : " "
                : fieldState.error?.message
            }
            margin="dense"
            fullWidth
            variant="outlined"
          >
            {enabledLocations && enabledLocations.length > 0 ? (
              enabledLocations.map((loc) => (
                <MenuItem
                  key={`location_${loc.originalIndex}`}
                  value={loc.originalIndex}
                >
                  {loc.clinicName}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={`location_${0}`} value="">
                No clinics found
              </MenuItem>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default LocationDropdown;
