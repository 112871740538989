import { useState } from "react";
import { SidebarItem } from "@alethea-medical/alethea-components";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import InviteAColleague from "./InviteAColleague";

interface InviteAColleagueButtonProps {
    showText: boolean
}

const InviteAColleagueButton = ({ showText }: InviteAColleagueButtonProps) => {
    
    const [show, setShow] = useState(false);

    return (
        <>
            <SidebarItem
                label="Invite a Colleague"
                onClick={() => setShow(true)}
                showText={showText}
                icon={<EmojiPeopleIcon color="primary" />} 
                {...{divider: true}}
            />
            {show &&
            // Don't mount until show is true (saves on resources)
                <InviteAColleague show={show} setShow={setShow}/>
            }
        </>
    );
}

export default InviteAColleagueButton;