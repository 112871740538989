import clsx from 'clsx';
import { Grid, Paper } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(1, 2)
		},
	}),
);

type ConsultFormCardProps = {
	title?: string,
    className?: string
    style?: React.CSSProperties
}



const ConsultFormCard: React.FC<ConsultFormCardProps> = ({ title, className, children, style }) => {
	const classes = useStyles();

	return (
        <Paper
            className={clsx(classes.container, className)}
            variant="outlined"
            style={style}
        >
            {title ? 
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
                :
                children
            }
        </Paper>
	);
}

export default ConsultFormCard;