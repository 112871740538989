import clsx from 'clsx';
import { HeightLayout, HeightLayoutChild, ProcessStatus } from "@alethea-medical/alethea-components";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationCenterController from "./NotificationCenterController";
import NotificationListItem from "./NotificationListItem";
import NotificationCenterToolbar from "./NotificationCenterToolbar";
import NotificationView from "./NotificationView/NotificationView";
import NotificationViewController from './NotificationView/NotificationViewController';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hidden: {
			display: "none"
		},
    })
)

interface NotificationCenterProps {
    height: number,
    setShowTabs: (showTabs: boolean) => void
}

const NotificationCenter = ({height, setShowTabs}: NotificationCenterProps) => {
    const classes = useStyles();

    const { sortedNotifications, markAllAsReadHandler, openNotificationHandler, closeNotificationHandler, loadMoreHandler, refresh, updateNotification, disableLoadMoreLoading, disableLoadMoreEndOfResults,
        loadingMessage, processState, setProcessState, processErrorMessage, errorHandler
    } = NotificationCenterController()

    const { openNotification } = NotificationViewController({ updateNotification, errorHandler })

    useEffect(() => {
        setShowTabs(openNotification === undefined)
    }, [openNotification])

    return (
        <>
            <div className={clsx({
                [classes.hidden]: openNotification !== undefined
            })}>
                <HeightLayout height={height}>
                    <HeightLayoutChild flexDriver>
                        {/* Toolbar */}
                        <NotificationCenterToolbar markAllAsReadHandler={markAllAsReadHandler} refresh={refresh}/>
                    </HeightLayoutChild>
                    <HeightLayoutChild flexDriven allowOverflowY>
                        {/* Notifications */}
                        {sortedNotifications.map((n) =>
                            <NotificationListItem
                                key={`notification_list_item${n.id}`} 
                                id={n.id}
                                notification={n.notification}
                                openNotification={openNotificationHandler}
                            />
                        )}	
                        <ListItem
                            button
                            onClick={loadMoreHandler}
                            disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
                            alignItems="center"
                            divider
                        >
                            <ListItemIcon>
                                <ArrowDownwardIcon color="primary"/>
                            </ListItemIcon>
                            <ListItemText
                                primary={disableLoadMoreLoading ? "Loading" : (disableLoadMoreEndOfResults ? "End of Notifications" : "Load More")}
                            />
                        </ListItem>
                    </HeightLayoutChild>
                </HeightLayout>
            </div>
            {(openNotification) &&
                <NotificationView notification={openNotification} goBack={closeNotificationHandler}/>
            }
            <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar loadingMessage={loadingMessage}/>
        </>
    );
}

export default NotificationCenter;