import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

interface RemovePatientButtonProps {
    index: number,
    remove: (index?: number | number[] | undefined) => void,
    disabled: boolean
}

const RemovePatientButton = ({index, remove, disabled}: RemovePatientButtonProps) => {
    
    
    const handleClick = () => {
        remove(index);
    }

    return (
        <IconButton
            onClick={handleClick}            
            disabled={disabled}
        >
            <CloseIcon color="primary"/>
        </IconButton >
    );
}

export default RemovePatientButton;