import { Filesystem, Directory } from "@capacitor/filesystem";
import { promises } from "dns";
import isAndroid from "./isAndroid";
import isNativeMobile from "./isNativeMobile";

function downloadBlob (blob: Blob, fileName: string): Promise<string | undefined> {
    if(isNativeMobile()) {  
        return convertBlobToBase64(blob)
        .then((data) => {
            return Filesystem.checkPermissions()
            .then((permission) => {
                if(permission.publicStorage === "granted"){
                    return Promise.resolve(permission)
                }
                else {
                    return Filesystem.requestPermissions()
                }
            })
            .then((permission) => {
                if(permission.publicStorage === "granted"){
                    //Set folder to save to to Download
                    let folderName = ""
                    let promise = Promise.resolve();

                    //Create folder on Android if it doesn't exist
                    if(isAndroid()) {
                        folderName = "Pictures/Alethea/"
                        promise = Filesystem.mkdir({
                            path: folderName,
                            directory: Directory.ExternalStorage,
                            recursive: true
                        })
                        .catch((error: Error) => {
                            //Throws error if directory already exists
                            return;
                        })
                    }
                    
                    return promise.then(() => {
                        return Filesystem.writeFile({
                            path: `${folderName}${fileName}`,
                            data: data as string,
                            directory: Directory.ExternalStorage
                        })          
                    })
                    .then((writeResult) => {
                        return writeResult.uri
                    })     
                }
                else {
                    alert("Permissions to download were not granted.")
                    return Promise.resolve(undefined);
                }
            })
        })
    }
    else {
        const url = window.URL.createObjectURL(blob);
        downloadURL(url, fileName);
        setTimeout(() => {
            return window.URL.revokeObjectURL(url);
        }, 1000);
        return Promise.resolve(undefined);
    }
}
function downloadURL (data: string, fileName: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

function convertBlobToBase64 (blob: Blob) { 
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            if(reader.result !== null)
                resolve(reader.result);
            else
                reject(new Error("Convert to base64: File does not exist."));
        };
        reader.readAsDataURL(blob);
    });
}

export default downloadBlob;