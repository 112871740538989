function isDateString(dob: string) {
  if (dob === "") {
    return undefined;
  }

  const [year, month, day] = dob.split("/").map(Number);
  const date = new Date(year, month - 1, day); //Month is zero-based in Date object

  if (
    isNaN(date.getTime()) || // Invalid Date object
    date.getFullYear() !== year || // Year does not match
    date.getMonth() + 1 !== month || // Month does not match
    date.getDate() !== day || // Day does not match
    date > new Date() // Date of birth is in the future
  ) {
    return "Date of birth is invalid";
  }

  return undefined; // Return undefined if date is valid
}

export default isDateString;
