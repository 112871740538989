import { Activity } from "../../../../../shared/types";
import MessageListItem from "../../../../components/MessageListItem";
import { formatTimeShort } from "../../../../models/formatTime";
import Typography from '@material-ui/core/Typography';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from "../../../../palette";
import { ActivityItem } from "../types";
import EconsultOutcomesLabel from "../EconsultComponents/EconsultOutcomesLabel";
import Grid from '@material-ui/core/Grid';
import ConsultStatus from "../Toolbar/ConsultStatus";
import usePermissions from "../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    infoContainer: {
        marginRight: theme.spacing(2)
    },
    phoneText: {
        color: palette.orange,
        fontWeight: 600,
        fontSize: "1.0em"
    },
    labelContainer: {
        padding: theme.spacing(1, 1, 0, 1)
    },
    icon: {
        color: palette.darkGreen
    },
    timestamp: {
        whiteSpace: "nowrap"
    }
  }),
);

interface MOAConsultListItemProps {
    activity: ActivityItem<Activity.ClinicActivity>
    openActivityHandler: (id: string) => void
}

const MOAConsultListItem = ({  activity, openActivityHandler }: MOAConsultListItemProps) => {
    const classes = useStyles();

    const { granted: hasEditStatusPermissions } = usePermissions({ resourceKey: resourceKeys.editActivityStatus })

    return (
        <MessageListItem
            selected={false}
            openMessage={openActivityHandler}
            id={activity.id}
            read={true}
            labelComponent={
                <div className={classes.labelContainer}>
                    {(activity.sharedActivity.activityType ==="econsult") && 
                        <QuestionAnswerIcon className={classes.icon}/>
                    }
                    {activity.sharedActivity.activityType === "phoneConsult" && 
                        <PermPhoneMsgIcon className={classes.icon}/>
                    }
                </div>
            }
            primary={
                <Grid container alignItems="center" spacing={1}>
                    {(activity.metadataActivity.status !== undefined && hasEditStatusPermissions) &&
                        <Grid item>
                            <ConsultStatus status={activity.metadataActivity.status} viewerRole={"moa"}/>
                        </Grid>
                    }
                    <Grid item>
                        {activity.sharedActivity.subject}
                    </Grid>
                        
                </Grid>
            }
            secondary={
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <EconsultOutcomesLabel specialistResponse={activity.sharedActivity.exposedEconsultData?.specialistResponse} fontSize="small"/>
                    </Grid>
                </Grid>
            }
        >
            <div className={classes.infoContainer}>
                <Typography className={classes.timestamp}> {formatTimeShort(activity.metadataActivity.lastMessageReceivedAt.toDate())}</Typography>
            </div>
        </MessageListItem>
    );
}

export default MOAConsultListItem;