import React, { useEffect, useState } from "react";
import { fbAuth, fbAnalytics } from "./firebase";
import { UserProfile } from "@alethea-medical/aletheamd-types";
import getProfile from "./models/getProfile";
import firebase from "firebase";
import { AletheaMedicalCapacitorFirebase } from "@alethea-medical/capacitor-firebase";
import isNativeMobile from "./models/isNativeMobile";
import updateUserMetaData from "./models/updateUserMetaData";
import versionStrings from "./versionStrings";
import { defaultRoles } from "@alethea-medical/aletheamd-db-keys";
import getTypesenseApiKey from "./models/getTypesenseApiKey";

import hasPermissions from "./models/hasPermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { fbFunctions, fbStorage, fbFirestore } from "./firebase";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";

interface ContextProps {
  user?: firebase.User;
  savedUser?: firebase.User | null;
  loginSavedUser?: (user: firebase.User) => void;
  logoutSavedUser?: () => void;
  uid: string;
  profile?: UserProfile;
  authenticated: boolean;
  loading: boolean;
  typesenseServicesSearchApiKey: string | undefined;
  typesenseUsersSearchApiKey: string | undefined;
  setUser: (user: firebase.User) => void;
  setProfile: (profile: UserProfile) => void;
  signIn: (
    email: string,
    password: string,
  ) => Promise<firebase.auth.UserCredential | null>;
  signOut: () => Promise<void>;
}

export const AuthContext = React.createContext<ContextProps>({
  uid: "",
  authenticated: false,
  loading: false,
  typesenseServicesSearchApiKey: undefined,
  typesenseUsersSearchApiKey: undefined,
  setUser: () => {
    console.log("setUser is not yet initialized");
  },
  setProfile: () => {
    console.log("setProfile is not yet initialized");
  },
  signIn: () => {
    console.log("signIn is not yet initialized");
    return Promise.resolve(null);
  },
  signOut: () => {
    console.log("signOut is not yet initialized");
    return Promise.resolve();
  },
});

export const AuthProvider: React.FC = ({ children }) => {
  const [savedUser, setSavedUser] = useState<firebase.User | undefined | null>(
    undefined,
  );
  const [user, setUser] = useState<firebase.User>();
  const [uid, setUid] = useState<string>("");
  const [profile, setProfile] = useState<UserProfile>();
  const [loading, setLoading] = useState(false);
  const [typesenseServicesSearchApiKey, setTypesenseServicesSearchApiKey] =
    useState<string | undefined>(undefined);
  const [typesenseUsersSearchApiKey, setTypesenseUsersSearchApiKey] = useState<
    string | undefined
  >(undefined);
  const [unsubscribeProfile, setUnsubscribeProfile] = useState<() => void>(
    () => {},
  ); // For listening to changes made in the profile

  useEffect(() => {
    const unsubscribe = fbAuth.onAuthStateChanged(
      (newUser: firebase.User | null) => {
        //? Note: The saved user mechanism is only used on web (not native mobile), but the state is still set because it is used to correctly display the login page
        if (newUser) setSavedUser(newUser);
        else setSavedUser(null);

        //If on iOS, and we are not authenticated, log the user out, so that both that native and web layer can be authenticated at the same time
        if (isNativeMobile()) {
          AletheaMedicalCapacitorFirebase.isAuthenticated().then(
            ({ authenticated }) => {
              if (!authenticated) {
                console.log(
                  "Not logged in in native layer on iOS. Logging out...",
                );
                return signOut();
              } else {
                handleUserStateChanged(newUser); // Where auto login happens! (This was removed from web)
              }
            },
          );
        } else {
          // Is the environment dev? => then auto login like before
          if (process.env.NODE_ENV === "development") {
            console.log("Dev environment, auto logging in");
            handleUserStateChanged(newUser);
          }
        }
      },
    );

    return () => {
      unsubscribeProfile();
      unsubscribe();
    };
  }, []);

  const handleUserStateChanged = (newUser: firebase.User | null) => {
    if (newUser !== null) {
      handleLogin(newUser);
    } else {
      handleLogout();
    }
  };

  const handleLogin = (newUser: firebase.User) => {
    console.log("Logged in");

    //Make sure uid is set before settings user, so it is available when authenticated is set to true (when user is not undefined)
    setUid(newUser.uid);
    setUser(newUser);
    fbAnalytics.setUserId(newUser.uid);

    setLoading(true);

    const profileRef = fbFirestore.collection(dbNames.users).doc(newUser.uid);
    const observer = profileRef.onSnapshot(
      (docSnapshot) => {
        // Update profile
        const newProfile = docSnapshot.data() as UserProfile;
        if (newProfile !== undefined) {
          setProfile(newProfile);
        } else {
          alert("There is no profile associated with this account.");
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      },
    );

    setUnsubscribeProfile(() => observer);

    getProfile(newUser.uid)
      .then((newProfile) => {
        if (newProfile !== undefined) {
          setProfile(newProfile);
        } else {
          alert("There is no profile associated with this account.");
        }
      })
      .catch((error: Error) => {
        console.error(error);
        alert("Error loading profile");
      })
      .finally(() => {
        setLoading(false);
      });

    // Don't error out if metadata update fails
    updateUserMetaData(newUser.uid).catch((error: Error) => {
      console.error(error);
    });

    // Check if user has a typesense search API key for services saved
    getTypesenseApiKey("services")
      .then((key) => {
        setTypesenseServicesSearchApiKey(key);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    console.log("Logged out");
    setUser(undefined);
    setProfile(undefined);
    if (unsubscribeProfile) unsubscribeProfile();
  };

  const signIn = (email: string, password: string) => {
    console.log("Logging in...");
    if (isNativeMobile()) {
      return AletheaMedicalCapacitorFirebase.signIn({
        email: email,
        password: password,
      })
        .catch((error: Error) => {
          console.error(error);
          return Promise.reject(
            new Error(
              "An error occurred while signing in. Please check that your email and password are correct, and that you are connected to the internet.",
            ),
          );
        })
        .then(() => {
          return fbAuth.signInWithEmailAndPassword(email, password);
        });
    } else {
      return fbAuth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          if (userCredential.user) {
            handleLogin(userCredential.user);
            return userCredential;
          }

          return null;
        });
    }
  };

  const signOut = () => {
    console.log("Logging out...");
    if (isNativeMobile()) {
      return AletheaMedicalCapacitorFirebase.signOut().then(() => {
        return fbAuth.signOut().then(handleLogout);
      });
    } else {
      //Manually trigger handle logout to clear profile immediately
      return fbAuth.signOut().then(handleLogout);
    }
  };

  const loginSavedUser = (user: firebase.User) => {
    handleUserStateChanged(user);
  };

  const logoutSavedUser = () => {
    setSavedUser(undefined);
    signOut();
  };

  useEffect(() => {
    if (profile !== undefined) {
      //ONLY CALL THIS HERE
      //Any other calls to setUserProperties will overwrite what is set here
      const analyticsProperties: {
        portalVersion: string;
        name: string;
        plan?: string;
      } = {
        portalVersion: `${versionStrings.version}`,
        name: `${profile.firstName} ${profile.lastName}`,
      };
      let plan: string | undefined;
      if (profile.userRoles.includes(defaultRoles.adminFee)) plan = "plus";
      if (profile.userRoles.includes(defaultRoles.free)) plan = "econsult";
      if (plan !== undefined) analyticsProperties.plan = plan;

      fbAnalytics.setUserProperties(analyticsProperties);
    }

    // Once profile is set, check if we should get the typesense search API key for users
    // If user has 'forward_econsult' role, check if they have typesense search API key for userssaved
    hasPermissions(resourceKeys.forwardEconsult, profile).then(
      (hasForwardEconsult) => {
        if (hasForwardEconsult) {
          getTypesenseApiKey("users")
            .then((key) => {
              setTypesenseUsersSearchApiKey(key);
            })
            .catch((error: Error) => {
              console.error(error);
            });
        }
      },
    );
  }, [profile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        savedUser,
        loginSavedUser,
        logoutSavedUser,
        profile,
        uid,
        authenticated: user !== undefined,
        loading,
        typesenseServicesSearchApiKey,
        typesenseUsersSearchApiKey,
        setUser,
        setProfile,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
