import { useMediaQuery } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/HelpOutlineOutlined";
import clsx from "clsx";
import React, { useMemo } from "react";
import { AppRoute } from "src/config/routes";
import palette from "src/palette";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    minWidth: "45px",
  },
  helpIcon: {
    color: palette.darkGreen,
  },
  premiumTooltipArrow: {
    color: theme.palette.background.paper,
    "&::before": {
      boxShadow: theme.shadows[1],
    },
  },
  premiumTooltip: {
    maxWidth: "325px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.primary,
    position: "relative",
    "& a": {
      color: theme.palette.secondary,
      textDecoration: "underline",
    },
  },
  drawerButton: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
    },
  },
  drawerButtonSelected: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export interface AletheaSidebarItemProps {
  /** Route to navigate to (path and name) */
  route?: AppRoute;
  /** Label to show beside the icon */
  label?: string;
  /** Hover tooltip text */
  tooltip?: string;
  /** Class for the label text */
  textClass?: string;
  /** Show or hide label text */
  showText: boolean;
  /** Icon to show */
  icon: React.ReactElement;
  /** Current path. If route.path matches currentPath, then highlight this item */
  currentPath?: string;
  /** onClick listener */
  onClick?: () => void;
  /** Link component from react-router-dom */
  linkComponent?: any;
  /** Show this component in the list. Default true */
  enabled?: boolean;
  /** Class overrides */
  classes?: {
    icon?: string;
    drawerButton?: string;
    drawerButtonSelected?: string;
  };
  /** Additional custom class name */
  className?: string;
  /** Additional custom styles */
  style?: React.CSSProperties;
}

const AletheaSidebarItem: React.FC<AletheaSidebarItemProps> = ({
  route,
  label = route?.name ? route.name : "",
  tooltip = route?.name ? route.name : "",
  textClass,
  showText,
  icon,
  currentPath,
  onClick = () => {},
  linkComponent,
  enabled: mayAccess = true,
  classes: classOverrides,
  className,
  style,
  ...rest
}: AletheaSidebarItemProps) => {
  const classes = useStyles();

  const shouldHide = !mayAccess;
  // const shouldHide = useMemo(
  //   () => !mayAccess && !route?.isPremium,
  //   [mayAccess, route?.isPremium],
  // );
  // const shouldShowGreyedOut = useMemo(
  //   // Double bang prevents undefined
  //   () => !mayAccess && !!route?.isPremium,
  //   [mayAccess, route?.isPremium],
  // );

  const shouldShowGreyedOut = false;

  const isSmallScreen = useMediaQuery("(max-width:650px)");

  const tooltipMessage = useMemo(
    () =>
      shouldShowGreyedOut ? (
        <>
          This is a premium feature. To learn more, contact sales at{" "}
          <a href="mailto:sales@aletheamedical.com">sales@aletheamedical.com</a>{" "}
        </>
      ) : (
        ""
      ),
    [shouldShowGreyedOut],
  );

  if (shouldHide) {
    return null;
  }

  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      placement={isSmallScreen ? "top" : "right"}
      title={tooltipMessage}
      interactive
      classes={{
        tooltip: classes.premiumTooltip,
        arrow: classes.premiumTooltipArrow,
      }}
    >
      {/* Div needed to allow MUI to show tooltip on disabled button */}
      <div>
        <ListItem
          className={clsx(
            classOverrides?.drawerButton ?? classes.drawerButton,
            {
              [classOverrides?.drawerButtonSelected ??
              classes.drawerButtonSelected]:
                currentPath !== undefined && currentPath === route?.path,
            },
            className,
          )}
          button
          component={linkComponent}
          to={route?.path}
          onClick={onClick}
          style={style}
          disabled={shouldShowGreyedOut}
          {...rest}
        >
          <ListItemIcon className={classOverrides?.icon ?? classes.icon}>
            <Tooltip title={tooltip}>{icon}</Tooltip>
          </ListItemIcon>
          <Fade in={showText}>
            <ListItemText className={textClass} primary={label} />
          </Fade>
          {shouldShowGreyedOut && <Help className={classes.helpIcon} />}
        </ListItem>
      </div>
    </Tooltip>
  );
};

export default AletheaSidebarItem;
