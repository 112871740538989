import { useContext } from "react";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { SidebarItem } from "@alethea-medical/alethea-components";
import { AuthContext } from "../../../AuthProvider";


interface LogoutButtonProps {
    showText: boolean
}
const LogoutButton = ({ showText }: LogoutButtonProps) => {

    const history = useHistory();
    const authContext = useContext(AuthContext);

    const handleClick = () => {
        authContext.signOut()
        .then(() => {
            history.push("/login");
        })
    }
    
    
    return (
        <SidebarItem 
            route={{
                path: "/auth/login",
                name: "Logout",
            }}
            showText={showText}
            icon={<ExitToAppIcon color="primary" />} 
            onClick={handleClick} 
            {...{divider: true}}
        />
    )
}

export default LogoutButton;