import StatusPaper from "../../../../components/StatusPaper";
import palette from "../../../../palette";
import { Activity } from '../../../../../shared/types';


interface ConsultStatuses {
    statuses: {
        [key: string]: {
            label: string,
            important?: { [role in Activity.ActivityRole.ActivityRole]?: boolean },
            id: string,
        }
    },
    order: string[]
}
        
export const importantLabelColor = palette.orange;
export const importantTextColor = "white"

export const defaultConsultStatuses: ConsultStatuses = {
    statuses: {
        pending: {
            label: "Pending",
            important: {
                consultant: true,
            },
            id: "pending",
        },
        to_book: {
            label: "To Book",
            important: {
                moa: true
            },
            id: "to_book",
        },
        to_book_urgent: {
            label: "To Book (Urgent)",
            important: {
                moa: true
            },
            id: "to_book_urgent",
        },
        booked: {
            label: "Booked",
            id: "booked",
        },
        completed: {
            label: "Completed",
            id: "completed",
        },
        no_status: {
            label: "No Status",
            id: "no_status",
        },
    },
    order: ["pending", "to_book", "to_book_urgent", "booked", "completed"]
}

interface ConsultStatusProps {
    status: string,
    endIcon?: React.ReactChild,
    viewerRole: Activity.ActivityRole.ActivityRole,
    style?: React.CSSProperties,
    className?: string,
    onClick?: () => void
}

const ConsultStatus = ({ status, endIcon, viewerRole, onClick, style, className, ...rest}: ConsultStatusProps) => {

    return (
        <StatusPaper 
            style={{ 
                color: defaultConsultStatuses.statuses[status]?.important?.[viewerRole] ? importantTextColor : undefined, 
                backgroundColor: defaultConsultStatuses.statuses[status]?.important?.[viewerRole] ? importantLabelColor : undefined,
                ...style
            }}
            className={className}
            endIcon={endIcon}
            onClick={onClick}
            {...rest}
        >
            {defaultConsultStatuses.statuses[status]?.label}
        </StatusPaper>
    );
}

export default ConsultStatus;