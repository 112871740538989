import {
  useContext,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import { fbFirestore } from "../../../../firebase";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AuthContext } from "../../../../AuthProvider";
import Grid from "@material-ui/core/Grid";
import { Activity } from "../../../../../shared/types";
import { aletheaMDCrypto } from "@alethea-medical/aletheamd-crypto";
import Typography from "@material-ui/core/Typography";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import palette from "../../../../palette";
import UserMessage from "./UserMessage";
import UserActionMessage from "./UserActionMessage";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import globalStrings from "../../../../globalStrings";
import usePermissions from "../../../../components/usePermissions";

const nameColors = [
  palette.darkGreen,
  palette.orange,
  "#3671C9",
  "#C93671",
  "#AE44BB",
  "#60A55A",
  "#B67E49",
  "#878631",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageFormatting: {
      whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
      wordWrap: "break-word", //break lines on word to fit
    },
    billingMessage: {
      padding: theme.spacing(2),
      fontSize: "0.9em",
      textAlign: "center",
    },
  }),
);

/** Only shown in MD view (Not MOA view) */
const econsultBillingTextBillable =
  "We bill on your behalf each time you send a message. Only your first message in a 24 hour period will be billed. Subsequent messages will not be billed.";
const econsultBillingTextNotBillable = (
  <>
    If you are on the Alethea Plus plan, we bill on your behalf each time you
    send a message. For more information about Alethea Plus contact us at{" "}
    {globalStrings.salesEmailAsLink}
  </>
);
const phoneConsultBillingTextBillable =
  "Until your phone consult is marked as complete, we will not bill any messages. Afterwards, we bill on your behalf each time you send a message. Only your first message in a 24 hour period will be billed. Subsequent messages will not be billed.";
const phoneConsultBillingTextNotBillable = (
  <>
    If you are on the Alethea Plus plan, we bill on your behalf. Until your
    phone consult is marked as complete, we will not bill any messages.
    Afterwards, we bill on your behalf each time you send a message. For more
    information about Alethea Plus contact us at{" "}
    {globalStrings.salesEmailAsLink}
  </>
);

interface MessagesProps {}

export interface MessagesElement {
  scrollToBottom: () => void;
}

const Messages = forwardRef<MessagesElement, MessagesProps>(({}, ref) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const activityContext = useContext(ActivityContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<Activity.Message[]>([]);
  const [atLeastOneOtherUserRead, setAtLeastOneOtherUserRead] =
    useState<boolean>(false);

  const { granted: hasBillingPermissions } = usePermissions({
    resourceKey: resourceKeys.billing,
  });

  const subscribeToNewMessages = () => {
    //Subscribe to new messages
    const unsubscribeMessages = fbFirestore
      .collection(dbNames.activities)
      .doc(activityContext.activityId)
      .collection(dbNames.activities_messages)
      .orderBy("sentAt", "asc")
      .onSnapshot((snapshot) => {
        const newMessages = snapshot.docs.map((doc) => {
          return doc.data() as Activity.Message;
        });
        aletheaMDCrypto
          .encryptDecryptMessages(
            newMessages,
            fbFirestore
              .collection(dbNames.system)
              .doc("keystore")
              .collection("keys")
              .doc("firestoreData"),
            { decrypt: true },
          )
          .then(() => {
            setMessages(newMessages);
          });
      });
    return () => {
      unsubscribeMessages();
    }; //Unsubscribe from message stream when activity changes
  };

  useEffect(() => {
    return subscribeToNewMessages();
  }, [activityContext.activityId]);

  useEffect(() => {
    // Set true if readBy contains a UID that isn't yours
    setAtLeastOneOtherUserRead(
      activityContext.sharedActivity.recentMessage.readBy.some(
        (uid) => uid !== authContext.uid,
      ),
    );
  }, [activityContext.sharedActivity.recentMessage.readBy]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current !== null) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 200);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }));

  const getNameColor = (uid: string): string => {
    try {
      return nameColors[
        activityContext.sharedActivity.users.indexOf(uid) % nameColors.length
      ];
    } catch {
      return palette.darkGreen;
    }
  };

  return (
    <Grid container spacing={5}>
      {activityContext.viewType === "md" && (
        <Grid item xs={12}>
          <Typography className={classes.billingMessage} variant="subtitle1">
            {activityContext.sharedActivity.activityType === "econsult" && (
              <>
                {" "}
                {hasBillingPermissions
                  ? econsultBillingTextBillable
                  : econsultBillingTextNotBillable}
              </>
            )}
            {activityContext.sharedActivity.activityType === "phoneConsult" && (
              <>
                {" "}
                {hasBillingPermissions
                  ? phoneConsultBillingTextBillable
                  : phoneConsultBillingTextNotBillable}
              </>
            )}
          </Typography>
        </Grid>
      )}
      {messages.length === 0 && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            No messages
          </Typography>
        </Grid>
      )}
      {messages.map((message, index) => {
        const mine = message.sentBy === authContext.uid;
        const sentByUser: Activity.ActivityUser | undefined =
          activityContext.sharedActivity.profiles[message.sentBy];
        const nameColor = getNameColor(message.sentBy);

        const messageType = !message.messageAction
          ? "message"
          : message.messageActionData?.messageActionType == "shareResource"
            ? "shareResourceMessageAction"
            : "otherMessageAction";

        return (
          <Grid
            item
            xs={12}
            key={`message_${index}`}
            className={classes.messageFormatting}
          >
            {(messageType === "message" ||
              messageType === "shareResourceMessageAction") && (
              <UserMessage
                message={message}
                mine={mine}
                sentByUser={sentByUser}
                nameColor={nameColor}
                atLeastOneOtherUserRead={atLeastOneOtherUserRead}
                isLast={index === messages.length - 1}
                sharedResource={messageType === "shareResourceMessageAction"}
              />
            )}
            {messageType === "otherMessageAction" && (
              <UserActionMessage
                message={message}
                sentByUser={sentByUser}
                nameColor={nameColor}
              />
            )}
            {index === messages.length - 1 && <div ref={messagesEndRef} />}
          </Grid>
        );
      })}
    </Grid>
  );
});

export default memo(Messages);
