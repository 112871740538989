import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../palette';
import globalStrings from '../../../globalStrings';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(3)
        },
        title: {
            color: palette.darkGreen,
            fontSize: "1.5em"
        }
    })
)


const ValidationRequired = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.container} container spacing={2}>
            <Grid item>
                <Typography className={classes.title}>
                    Before you can get started with Alethea, we need to verify your Practitioner Identification Number (Prac ID). 
                </Typography>
                <Typography >Please contact us at {globalStrings.salesEmail} or {globalStrings.salesPhone} to provide us with your correct Prac ID.</Typography>
            </Grid>
        </Grid>
    );
}

export default ValidationRequired;