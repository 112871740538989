import { useContext, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import sharedStyles from "../sharedStyles";
import {
  thinScrollbar,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import Messages, { MessagesElement } from "../MessageComponents/Messages";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import MessageInput from "../MessageComponents/MessageInput";
import usePermissions from "../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { ActivityUser } from "shared/types/dist/namespaces/Activity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...thinScrollbar,
    ...sharedStyles(theme),
    messagesHeaderBox: {
      padding: theme.spacing(1, 1, 1, 2),
    },
    messageInputBox: {
      padding: theme.spacing(1),
    },
    messageFileDropBox: {
      padding: theme.spacing(1),
    },
    hidden: {
      display: "none",
    },
    fullHeight: {
      height: "100%",
    },
  }),
);

interface MOAMessageViewProps {
  goToEconsult: () => void;
  visible: boolean;
}

const MOAMessageView = ({ goToEconsult, visible }: MOAMessageViewProps) => {
  const classes = useStyles();

  const activityContext = useContext(ActivityContext);
  const messagesRef = useRef<MessagesElement>(null);

  const scrollToMessagesBottom = () => {
    if (visible && messagesRef.current !== null) {
      messagesRef.current.scrollToBottom();
    }
  };

  //Update sizes when templates slide out
  useEffect(scrollToMessagesBottom, [visible]);

  const { heightMinusAppBar } = useScreenSize({});
  const isMobileSize = useMobileSizes();

  const { granted: hasSecureMessagingSendMessagesPermission } = usePermissions({
    resourceKey: resourceKeys.secureMessagingSendMessages,
  });

  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <Box
          borderBottom={1}
          className={clsx(classes.boxBorderColor, classes.messagesHeaderBox)}
        >
          <Grid container justifyContent="flex-start" alignItems="center">
            {isMobileSize && (
              <Grid item>
                <IconButton onClick={goToEconsult}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <Typography>
                {Object.values(activityContext.sharedActivity.profiles)
                  .filter(
                    (activityUser): activityUser is ActivityUser =>
                      activityUser != undefined,
                  )
                  .map((profile) => `${profile.firstName} ${profile.lastName}`)
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </HeightLayoutChild>
      <HeightLayoutChild
        flexDriven
        allowOverflowY
        className={clsx(classes.thinScrollbar, classes.boxPadding)}
      >
        <Messages ref={messagesRef} />
      </HeightLayoutChild>
      {hasSecureMessagingSendMessagesPermission && (
        <HeightLayoutChild
          flexDriver
          maxHeight="50%"
          allowOverflowY
          className={classes.thinScrollbar}
        >
          <Box
            className={clsx(classes.boxBorderColor, classes.messageInputBox)}
            borderTop={1}
          >
            <MessageInput viewType="moa" />
          </Box>
        </HeightLayoutChild>
      )}
    </HeightLayout>
  );
};

export default MOAMessageView;
