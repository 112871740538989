import AveiroPaymentForm from "./AveiroPaymentForm";
import Grid from '@material-ui/core/Grid';
import useAveiroItemsDropdown from "./useAveiroItemsDropdown";
import { Typography } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import AveiroRetryForm from "./AveiroRetryForm";
import ActivityPayment from "../SecureMessaging/EconsultComponents/ActivityPayment";
import { logAnalyticsEvent } from "../../../firebase";
import AveiroExternalPaymentForm from "./AveiroExternalPaymentForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        totalDivider: {
            margin: theme.spacing(0, 5, 0, 0)
        },
        totalText: {
            margin:theme.spacing(1, 0)
        },
        
    })
)

interface AveiroPaymentPageProps {
    activityId: string,
}


const AveiroPaymentPage = ({ activityId }: AveiroPaymentPageProps) => {
    const classes = useStyles();

    const { selectedItem, availableItems, getItems, processState: dropdownState, processErrorMessage: dropdownError, renderDropdown } = useAveiroItemsDropdown({ activityId })
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const doShowPaymentForm = () => {
        return selectedItem !== undefined && selectedItem.existingPayment?.payment.status !== 'pending' && !selectedItem.isExternalPayment
    }

    const doShowRetryForm = () => {
        return selectedItem !== undefined && selectedItem.existingPayment?.payment.status === 'pending' && !selectedItem.isExternalPayment
    }

    const doShowExternalPaymentForm = () => {
        return selectedItem !== undefined && selectedItem.isExternalPayment === true
    }

    const doShowSuccessPage = () => {
        return processState === ProcessState.success
    }
    

    return (
        <Grid container spacing={2} justifyContent="center">
            {doShowSuccessPage() ?
            <>
                <Grid item xs={12}>
                    {selectedItem?.existingPayment !== undefined && 
                        <ActivityPayment payment={selectedItem?.existingPayment}/>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography style={{ textAlign: "center"}}>You may now close this popup</Typography>
                </Grid>
            </>
            :
            <>
                <Grid item xs={12}>
                    <Typography variant="h5">Make a Payment</Typography>
                </Grid>
                <Grid item xs={12}>
                    {renderDropdown(selectedItem, availableItems)}
                </Grid>
                {selectedItem !== undefined && 
                    <Grid item xs={12}>
                        <Typography className={classes.totalText}>Subtotal: ${(selectedItem.cost / 100).toFixed(2)}</Typography>
                        <Typography className={classes.totalText} variant="subtitle1">Tax ({(selectedItem.taxRate * 100).toFixed(2)}%): ${(selectedItem.cost / 100 * selectedItem.taxRate).toFixed(2)}</Typography>
                        <Divider className={classes.totalDivider}/>
                        <Typography className={classes.totalText}>Total: ${((selectedItem.cost + selectedItem.cost * selectedItem.taxRate) / 100).toFixed(2)}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <AveiroPaymentForm visible={doShowPaymentForm()} itemCode={selectedItem?.itemCode} activityId={activityId} 
                        refetchPayments={getItems}
                        processState={processState} setProcessState={setProcessState} errorHandler={errorHandler}
                    />
                    <AveiroRetryForm visible={doShowRetryForm()} itemCode={selectedItem?.itemCode} activityId={activityId} 
                        refetchPayments={getItems}
                        processState={processState} setProcessState={setProcessState} errorHandler={errorHandler}
                    />
                    <AveiroExternalPaymentForm visible={doShowExternalPaymentForm()} itemCode={selectedItem?.itemCode} activityId={activityId}
                        refetchPayments={getItems}
                        processState={processState} setProcessState={setProcessState} errorHandler={errorHandler}
                    />
                </Grid>
            </>
            }
            <Grid item xs={12}>
                <ProcessStatus state={dropdownState} errorMessage={dropdownError} loadingMessage="Loading items"/>
            </Grid>
            <Grid item xs={12}>
                <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage="Payment successful"/>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{ textAlign: "center"}} variant="subtitle1">Secure Payments Powered by Clover</Typography>
                <Typography style={{ textAlign: "center"}} variant="subtitle1"><a href="https://www.clover.com/privacy-policy" rel="noreferrer noopener" target="_blank">Privacy Policy</a></Typography>
            </Grid>
        </Grid>
    );
}

export default AveiroPaymentPage;