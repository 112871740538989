import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fbAnalytics, logAnalyticsEvent } from "../firebase";

const logCurrentPage = () => {
    fbAnalytics.setCurrentScreen(window.location.pathname)
    logAnalyticsEvent("screen_view");
};
  
const Analytics = () => {
    const history = useHistory();

    useEffect(() => {
        if(history !== undefined) {
            logCurrentPage(); // log the first page visit
            history.listen(() => {
                logCurrentPage();
            });
        }
    }, [history]);

    return (
        <>
        </>
    );
};

export default Analytics;