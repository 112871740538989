import React, { useCallback, useContext, useState } from "react";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";

import EconsultCard from "./EconsultCard";
import clsx from "clsx";

import {
  useProcessState,
  thinScrollbar,
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";

interface EconsultMessagingProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...thinScrollbar,
    messageInputBox: {
      position: "relative",
      [`& fieldset`]: {
        borderRadius: "15px",
      },
    },
    inputProps: {
      minHeight: "20px",
    },
    iconButton: {
      marginRight: theme.spacing(1),
    },
    sendText: {
      marginLeft: theme.spacing(1),
      fontWeight: "bold",
    },
  }),
);

const EconsultMessaging = ({}: EconsultMessagingProps) => {
  const activityContext = useContext(ActivityContext);
  const classes = useStyles();
  const [text, setText] = useState("");
  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
  } = useProcessState({
    logAnalyticsEvent,
  });

  const onSendPressed = useCallback(async () => {
    setProcessState(ProcessState.running);
    try {
      await fbFunctions.httpsCallable("fax-queueMOAEConsultFax")({
        messageToMOA: text,
        activityId: activityContext.activityId,
      });
      setText("");
      setProcessState(ProcessState.success);
    } catch (e: any) {
      setProcessErrorMessage(e?.message);
      setProcessState(ProcessState.error);
    }
  }, [text, activityContext.activityId, setProcessState, setText]);

  const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      onSendPressed();
    }
  };

  const isDisabled = processState === ProcessState.running;

  return (
    <EconsultCard>
      <Grid item xs={true}>
        <TextField
          className={clsx(classes.messageInputBox, classes.thinScrollbar)}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={keydownHandler}
          variant="outlined"
          fullWidth
          multiline
          margin="dense"
          placeholder="Message to MOA (Optional)"
          InputProps={{ classes: { input: classes.inputProps } }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
        <IconButton
          className={classes.iconButton}
          onClick={onSendPressed}
          disabled={isDisabled}
        >
          <SendIcon color={isDisabled ? undefined : "primary"} />
          <Typography
            variant="body2"
            color={isDisabled ? "textSecondary" : "primary"}
            className={classes.sendText}
          >
            Send
          </Typography>
        </IconButton>
      </Grid>
      <ProcessStatus
        state={processState}
        errorMessage={`Error sending fax: ${processErrorMessage}`}
        loadingMessage="Sending fax..."
        successMessage="Fax sent successfully!"
      />
    </EconsultCard>
  );
};

export default EconsultMessaging;
