import Sidebar from "./Sidebar";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        
    },
    appBarOffset: {
       height: theme.spacing(6)
    },
   
}));

const Dashboard = (props: any) => {
    const classes = useStyles();    
    return (
        <>
       
            <Sidebar />
            <main className={classes.content}>
                {/* Add offset equal to size of toolbar so appbar doesn't cover content on mobile */}
                <div className={classes.appBarOffset}/>
                {props.children}
            </main>
           
        </>
    );
}

export default Dashboard;