import { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { fbFunctions, logAnalyticsEvent } from '../../../../firebase';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperModal, ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MuiAlert from '@material-ui/lab/Alert';
import { Activity } from '../../../../../shared/types';
import { DropdownMenuItem } from '../../../../components/DropdownMenuButton/DropdownMenuButton';
import { AuthContext } from '../../../../AuthProvider';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontSize: "1.3em"
        },
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
            maxWidth: "1000px",
            minHeight: "200px"
        },
        menuItemTextWrap: {
            whiteSpace: "normal",
            overflow: "hidden",
            maxWidth: "100%",
            wordWrap: "break-word"
        }
    })
)

interface AddConsultantButtonProps {
    viewType: "md"| "moa",
    variant: "button" | "dropdown",
    activityId: string,
    sharedActivity: Activity.Activity,
    onConsultantAdded?: () => void
}


const AddConsultantButton = ({ viewType, variant, activityId, sharedActivity, onConsultantAdded=() => {} }: AddConsultantButtonProps) => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false)
    const [availableUsers, setAvailableUsers] = useState<{ label: string, uid: string }[]>([])
    const [selectedUserId, setSelectedUserId] = useState<string>("")
    const authContext = useContext(AuthContext)
    
    const addConsultantToActivity = fbFunctions.httpsCallable('secureMessaging-addUserToActivity')
    const addSelfToActivityAsMoa = fbFunctions.httpsCallable('activity-addSelfToActivityAsMoa_v1')
    const getConsultantsAvailableToUser = fbFunctions.httpsCallable('secureMessaging-getConsultantsAvailableToUser')

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });
    const isDisabled = () => {
        return processState === ProcessState.running || availableUsers.length === 0
    }

    const isAddButtonDisabled = () => {
        return isDisabled() || selectedUserId === ""
    }

    const addConsultantHandler = () => {
        setProcessState(ProcessState.running)

        let addUserPromise = Promise.resolve();
        // If user is not on activity as MOA, then add them to the activity
        if(viewType === "moa") {
            if(!sharedActivity.users.includes(authContext.uid)) {
                addUserPromise = addSelfToActivityAsMoa({ activityId })
                .then(() => {
                    return Promise.resolve()
                })
            }
        }

        return addUserPromise.then(() => {
            return addConsultantToActivity({ activityId, newUserId: selectedUserId, activityRole: "consultant"})
        })
        .then(() => {
            setProcessState(ProcessState.idle)
            setShowModal(false)
            onConsultantAdded()
        })
        .catch((error: Error) => {
            errorHandler({
                error,
                userMessage: "Error adding user"
            })
        })
    }
    

    const openModalHandler = () => {
        setShowModal(true)
        setProcessState(ProcessState.running)
        return getConsultantsAvailableToUser()
        .then((result) => {
            setAvailableUsers(result.data)
            if(result.data.length > 0)
                trySelectUserId(result.data[0].uid)
            setProcessState(ProcessState.idle)
        })
        .catch((error: Error) => {
            errorHandler({
                error,
                userMessage: "Error retrieving available users"
            })
        })
    }

    const trySelectUserId = (uid: string) => {
        if(sharedActivity.users.includes(uid))
            return;
        setSelectedUserId(uid)
    }


    return (
        <>
            {variant === "button" &&
                <Button color="primary" variant="outlined" startIcon={<PersonAddIcon />} onClick={openModalHandler}>            
                    Add Consultant
                </Button>
            }
            {variant === "dropdown" &&
                <DropdownMenuItem label="Add Consultant" icon={<PersonAddIcon color="primary" />} onClick={openModalHandler} color='primary' />            
            }
            <PaperModal className={classes.modal} show={showModal} setShow={setShowModal} flexHeight enablePadding>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Choose a Consultant to Add"
                            value={selectedUserId}
                            onChange={(e) => {trySelectUserId(e.target.value as string)}}
                            variant="outlined"
                            margin="dense"
                            fullWidth                   
                            select   
                            disabled={isDisabled()}  
                        >
                            {availableUsers.length > 0 && availableUsers.map((user) => {
                                const alreadyAdded = sharedActivity.users.includes(user.uid)
                                return (
                                    <MenuItem 
                                        key={`available_user_to_add_${user.uid}`} 
                                        value={user.uid} 
                                        disabled={alreadyAdded}
                                        className={classes.menuItemTextWrap}
                                        >
                                        {user.label}{alreadyAdded ? " (Added)" : ""}   
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                        {(availableUsers.length === 0 && processState !== ProcessState.running) && 
                            <MuiAlert severity="warning">No consultants available</MuiAlert>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => { setShowModal(false) }}
                            disabled={isDisabled()}
                        >Cancel</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => { addConsultantHandler() }}
                            disabled={isAddButtonDisabled()}
                        >Add</Button>
                    </Grid>
                </Grid>
            </PaperModal>
        </>
    );
}


export default AddConsultantButton;