import { FieldValues } from "react-hook-form";
import  { TypedControlledInputProps } from "../types";
import FormSelect from "./FormSelect";

interface TypedFormSelectProps<T extends FieldValues> extends TypedControlledInputProps<T> {
    options: any[],
    getOptionLabel?: (option: any) => any,
    getOptionLabelSecondary?: (option: any) => any,
    getOptionValue?: (option: any) => any,
    getOptionDisabled?: (option: any) => boolean,
    getEndAdornment?: (option: any) => React.ReactNode,
    disableWhenOne?: boolean,//Disable the field when only one option is available,
    defaultEmpty?: boolean,
    rules?: any,
    disabled?: boolean
}

function TypedFormSelect<T extends FieldValues> ( props: TypedFormSelectProps<T>) {

    return (
        <FormSelect
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export default TypedFormSelect

