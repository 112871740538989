import React  from 'react';
import { Grid } from '@material-ui/core';
import MonthlyBillingTable from './MonthlyBillingTable/MonthlyBillingTable';
import { NewAndPendingTable } from './Components/NewAndPendingTable';
import { RejectionTable } from './Components/RejectionsTable';
import { ExplanationPanel } from './Components/ExplanationsPanel';
import EarningSummary from './EarningSummary/EarningSummary';
import { ClosedTable } from './Components/ClosedTable.tsx';
import { PaperPage } from '@alethea-medical/alethea-components';



const BillingReport = () => {

	
	return (
		
		<PaperPage enablePadding>
			<Grid container spacing={1}>				
					<EarningSummary />							
				{/* rejection and new Tables */}
				<Grid item xs={6}>
					<NewAndPendingTable />
				</Grid>
				<Grid item xs={6}>
					<RejectionTable />
				</Grid>
				<Grid item xs={12}>
					<ClosedTable />
				</Grid>
				{/* Explanations */}
				<Grid item xs={12}>
					<ExplanationPanel />
				</Grid>
				{/* Billing */}
				<Grid item xs={12}>
					<MonthlyBillingTable />
				</Grid>

			</Grid>
		</PaperPage>

	);
}

export default BillingReport;  