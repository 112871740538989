import React from "react";
import clsx from 'clsx';
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../types";
import Grid, { GridSize } from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import RoundedButton from "../../RoundedButton";
import { FormHelperText } from "@material-ui/core";
import palette from "../../../palette";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonUnselected: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey[300],
            "&:hover": {
                backgroundColor: theme.palette.grey[400]
            }
        },
        buttonSelected: {
            backgroundColor: palette.orange
        }
    })
)

interface FormRoundedRadioButtonsProps extends ControlledInputProps{
    options: string[],
    breakpoints?: {xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize},
    displayOption?: (option: string) => string,
    onClick?: (option: string) => void
}


const FormRoundedRadioButtons = ({ name, control, defaultValue, options, disabled, errorTextPadding, breakpoints={}, displayOption, onClick, rules }: FormRoundedRadioButtonsProps) => {
    const classes = useStyles();
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue !== undefined ? defaultValue : false}
            rules={rules}
            render={({field, fieldState}) => {

                const handleClickOption = (optionSelected: string) => {
                    field.onChange(optionSelected);
                }

                return (
                    <Grid container spacing={2}>
                        {options.map((option, index) => 
                            <Grid key={`rounded_radio_button_${option}_${index}`} item xs={breakpoints?.xs ?? (Math.floor(12/options.length) as GridSize)} {...breakpoints}>
                                <RoundedButton
                                    className={clsx({
                                        [classes.buttonUnselected]: option !== field.value,
                                        [classes.buttonSelected]: option === field.value
                                    })}
                                    disabled={disabled}
                                    onClick={() => {
                                        handleClickOption(option);
                                        onClick && onClick(option)
                                    }} 
                                    fullWidth>{displayOption ? displayOption(option) : option}</RoundedButton>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormHelperText error>
                                {errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                )
            }}
        />
    );
}

export default FormRoundedRadioButtons;