import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { UserMediaMetadataItem } from '../../Models/GalleryModel';
import MediaEditController from '../../Controllers/MediaEditController';
import MediaEditNotes from './MediaEditNotes';
import MediaEditTags from './MediaEditTags';
import MediaEditDelete from './MediaEditDelete';
import { ProcessStatus } from '@alethea-medical/alethea-components';
import SaveIcon from '@material-ui/icons/Save';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import MediaDownloadButton from './MediaDownloadButton';
import LabelIcon from '@material-ui/icons/Label';
import NotesIcon from '@material-ui/icons/Notes';


interface MediaEditViewProps {
    mediaItem: UserMediaMetadataItem,
    closeMediaItem: () => void,
    modifyItemHandler: (id: string, newItem: UserMediaMetadataItem) => void,
    removeMediaItems?: (ids: string[]) => void,
    fullWidth?: boolean
}

const MediaEditView = ({ mediaItem, closeMediaItem, modifyItemHandler, removeMediaItems, fullWidth }: MediaEditViewProps) => {

    const {
        // states
        tags,
        notes,
        // handlers
        tagsChangedHandler,
        notesChangedHandler,
        mediaDeleteHandler,
        deleteState, deleteError
    } = MediaEditController({ mediaItem, closeMediaItem, modifyItemHandler, removeMediaItems })

    const [showEdit, setShowEdit] = useState(false);

    const showEditHandler = () => {
        setShowEdit(!showEdit)
    }

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={fullWidth ? 12 : 6} md={fullWidth ? 12 : 8} sm={fullWidth ? 12 : 10} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Collapse in={showEdit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={1}>
                                            <LabelIcon color="primary"/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <MediaEditTags tags={tags} tagsChanged={tagsChangedHandler} placeholder="Add Tags"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={1}>
                                            <NotesIcon color="primary"/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <MediaEditNotes notes={notes} notesChanged={notesChangedHandler}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <IconButton onClick={showEditHandler}>{showEdit ? <SaveIcon color="primary"/> : <EditOutlinedIcon/>}</IconButton>
                            </Grid>
                            <Grid item>
                                <MediaDownloadButton mediaItem={mediaItem}/>
                            </Grid>
                            {removeMediaItems !== undefined && (
                                <Grid item>
                                    <MediaEditDelete mediaDelete={mediaDeleteHandler}/>
                                    <ProcessStatus state={deleteState} errorMessage={deleteError}/>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MediaEditView;