import { useContext, useState } from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import EconsultView from "./EconsultComponents/EconsultView";
import { InputProvider } from "./Utilities/InputProvider";
import { ProcessState, thinScrollbar } from "@alethea-medical/alethea-components";
import { useMobileSizes, SwipeableOrGrid, HeightLayout, HeightLayoutChild, useScreenSize } from "@alethea-medical/alethea-components";
import sharedStyles from "./sharedStyles";
import ViewSecureMessageToolbar from "./Toolbar/ViewSecureMessageToolbar";
import MessageView from "./MessageComponents/MessageView";
import { ActivityContext } from "./Inbox/Contexts/ActivityProvider";
import MOAViewSecureMessageToolbar from "./MOAConsultInbox/MOAViewSecureMessageToolbar";
import MOAMessageView from "./MOAConsultInbox/MOAMessageView";
import LoadingPage from "../../../components/LoadingPage";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        ...sharedStyles(theme),
        loading: {
            marginTop: "50px",
        }
    }),
);



const ViewSecureMessage = () => {

    const classes = useStyles();

    const isMobileSize = useMobileSizes();
    const activityContext = useContext(ActivityContext)

    const [swipeIndex, setSwipeIndex] = useState(0);

    const goToMessages = () => {
        setSwipeIndex(1);
    }
    
    const goToEconsult = () => {
        setSwipeIndex(0);
    }

    const { heightMinusAppBar } = useScreenSize({});
    


    if((activityContext !== null && activityContext.isActivityOpen && activityContext.loaded)) {
        return (
            <SwipeableOrGrid showSwipe={isMobileSize} gridSize={6} index={swipeIndex} onChangeIndex={setSwipeIndex} onSwipedEdgeRight={activityContext.closeActivity} enableStepper>
                {/* Econsult view */}
                <Box
                    className={classes.boxBorderColor}
                    borderRight={1}
                >
                    <HeightLayout height={heightMinusAppBar}>
                        <HeightLayoutChild flexDriver>
                            {activityContext.viewType === "md" && 
                                <ViewSecureMessageToolbar goToMessages={goToMessages}/>
                            }
                            {activityContext.viewType === "moa" &&
                                <MOAViewSecureMessageToolbar goToMessages={goToMessages} />
                            }
                        </HeightLayoutChild>
                        <HeightLayoutChild flexDriven allowOverflowY className={classes.thinScrollbar}>
                            <EconsultView />
                        </HeightLayoutChild>
                    </HeightLayout>
                </Box>
                {/* Messages and image annotation */}
                <Box>
                    <InputProvider>
                        {activityContext.viewType === "md" &&
                            <MessageView visible={swipeIndex === 1 || !isMobileSize} goToEconsult={goToEconsult}/>    
                        }
                        {activityContext.viewType === "moa" &&
                            <MOAMessageView visible={swipeIndex === 1 || !isMobileSize} goToEconsult={goToEconsult}/>
                        }
                    </InputProvider>
                </Box>
            </SwipeableOrGrid>
            
        );
    }

    if(activityContext !== null && activityContext.isActivityOpen && !activityContext.loaded && activityContext.processState !== ProcessState.error) {
        return (
            <LoadingPage label="Loading Consult" value={activityContext.getLoadPercentage()}/>
        )
    }
    
    return null;
}

export default ViewSecureMessage;