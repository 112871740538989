import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../FormInputFields/types";
import LocationDropdown from "./LocationDropdown";

interface TypedLocationDropdownProps<T extends FieldValues>
  extends TypedControlledInputProps<T> {
  onSelectLocation?: (newLocation: number) => void;
  setValue?: (name: keyof T, value: any) => void;
}

function TypedLocationDropdown<T extends FieldValues>(
  props: TypedLocationDropdownProps<T>,
) {
  return (
    <LocationDropdown
      {...props}
      control={props.control as any}
      name={props.name as string}
    />
  );
}

export default TypedLocationDropdown;
