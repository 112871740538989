import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        breakWords: {
            whiteSpace: 'pre-wrap',//Show newlines, and don't collapse sequential spaces
            wordWrap: 'break-word',//break lines on word to fit
        },
    })
)

interface OutcomesInputProps {
    outcomes: string[],
    selectedOutcome: string,
    setSelectedOutcome: (selectedOutcome: string) => void,
    selectedDiagnosis: string,
    setSelectedDiagnosis: (selectedDiagnosis: string) => void,
    handleSubmitOutcomes?: () => void,
    diagnosisLabel?: string,
    diagnosisMinRows?: number
}

const OutcomesInput = ({ outcomes, selectedOutcome, setSelectedOutcome, selectedDiagnosis, setSelectedDiagnosis, handleSubmitOutcomes=()=>{}, diagnosisLabel='Enter a Diagnosis', diagnosisMinRows=1 }: OutcomesInputProps) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                    <InputLabel>Select an Outcome</InputLabel>
                    <Select
                        onChange={(e) => {setSelectedOutcome(e.target.value as string)}}
                        value={selectedOutcome}
                        classes={{
                            root: classes.breakWords
                        }}
                    >
                        {outcomes.map((outcome, i) => 
                            <MenuItem key={`outcome_${i}_${outcome}`} className={classes.breakWords} value={outcome}>{outcome}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <InputLabel>{diagnosisLabel}</InputLabel>
                <TextField 
                    onChange={(e) => {setSelectedDiagnosis(e.target.value as string)}}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            handleSubmitOutcomes();
                        }
                    }}
                    value={selectedDiagnosis}

                    fullWidth
                    margin="dense"
                    multiline
                    minRows={diagnosisMinRows}
                />
            </Grid>
        </Grid>
    );
}

export default OutcomesInput;