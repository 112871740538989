import { useState } from "react";
import MomentUtils from '@date-io/moment';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
  } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface VacationDatePickerProps {
    value: Date,
    label: string,
    dateChangeHandler: (newDate: MaterialUiPickersDate) => void,
}

const VacationDatePicker = ({ value, label, dateChangeHandler }: VacationDatePickerProps) => {
    
    const [open, setOpen] = useState(false);

 
    return (

        <MuiPickersUtilsProvider utils={MomentUtils}>

            <KeyboardDatePicker
                label={label}
                openTo="date"
                open={open}
                onClick={() => setOpen(true)}//Open dialog whenever the input field is clicked
                onClose={() => setOpen(false)}
                autoOk          
                variant="inline"
                inputVariant="outlined"       
                format="MM/DD/yyyy"          
                value={value}
                onChange={dateChangeHandler}
                InputProps={{ readOnly: true }}
                size="small"
                fullWidth
            />

        </MuiPickersUtilsProvider>     

    );
}

export default VacationDatePicker;