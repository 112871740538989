import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import SignaturePad from 'react-signature-canvas'
import palette from '../../../../../palette';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        SigContainer: {
            backgroundColor: 'rgb(209, 209, 209)',
            borderBottom: '1px gray solid',
            borderTop: '1px gray solid',
            borderRight: '1px gray solid',
            borderLeft: '1px gray solid',
            borderRadius: '10px',
            width: '100%',
            height: '10vh',
        },
        SigPad: {
            width: '100%',
            height: '100%',
            backgroundColor: "white",
            borderRadius: '10px'
        },
        clearBtn: {
            height: '20px',
            backgroundColor: palette.darkGreen,
            borderRadius: '7px',
            color: "white"
        }
    })
);
interface SignatureCardProps {
    savedSignature: string | undefined,
    setSavedSignature: (value: string | undefined) => void
}

export const SignatureCard = ({ savedSignature, setSavedSignature }: SignatureCardProps ) => {

    const classes = useStyles()


    const signCanvas = React.useRef() as React.MutableRefObject<any>;


    const clear = () => {
        signCanvas.current.clear();
        setSavedSignature(undefined)
    }

    const onSignatureFinish = () => {
        setSavedSignature(signCanvas.current.toDataURL())
    }

    //Load saved signature if opened again
    useEffect(() => {
        if(savedSignature !== undefined) {
            signCanvas.current.fromDataURL(savedSignature)
        }
    }, [])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography>Please sign below.</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.SigContainer}>
                    <SignaturePad clearOnResize={false} penColor="black" canvasProps={{ className: classes.SigPad }} ref={signCanvas} onEnd={onSignatureFinish}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth className={classes.clearBtn} onClick={clear} id={"clear-sig-button"}>
                    Clear
                </Button>
            </Grid>
        </Grid>
    )
}

export default SignatureCard

