import React, { useState, useContext, useEffect } from 'react';
import { dbNames } from '@alethea-medical/aletheamd-db-keys';
import { AuthContext } from '../../../../../AuthProvider';
import { fbFirestore } from '../../../../../firebase';


const useCustomAILabels = () => {
    
    const authContext = useContext(AuthContext);

    const [customAILabels, setCustomAILabels] = useState<string[]>([]);
     
    /** Add new labels, only in local state. Does not update the database */
    const addNewLabelsToState = (labels: string[]) => {
        const newLabels = [...customAILabels].concat(labels)
        setCustomAILabels(newLabels)
    }

    useEffect(() => {
        if(authContext.uid !== "")
        {
            const unsubscribe = fbFirestore.collection(dbNames.customAILabels).doc(authContext.uid).onSnapshot((snapshot) => {
                if(snapshot.exists) {
                    const data = snapshot.data();
                    setCustomAILabels(data?.customLabels ?? [])
                }
            })
            return () => { unsubscribe() }
        }
    }, [authContext.uid])

    return {
        customAILabels,
        addNewLabelsToState
    }
}

export default useCustomAILabels;