import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bar: {
            backgroundColor: palette.darkGreenDark,
            height: "25px",
            width: "100%",
        },
        header: {
            top: 0,
            left: 0,
            position: "absolute"
        },
        footer: {
            bottom: 0,
            left: 0,
            position: "absolute"
        }
    })
)

interface PageHeaderFooterBarsProps {
    position: "header" | "footer"
}

const PageHeaderFooterBars = ({ position }: PageHeaderFooterBarsProps) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.bar, {
            [classes.header]: position === "header",
            [classes.footer]: position === "footer" 
        })}/>
    );
}

export default PageHeaderFooterBars;