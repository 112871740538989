import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ViewSecureMessage from "../ViewSecureMessage";
import {
  ProcessState,
  thinScrollbar,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import Typography from "@material-ui/core/Typography";
import {
  ProcessStatus,
  HeightLayout,
  HeightLayoutChild,
} from "@alethea-medical/alethea-components";
import InboxController from "../Inbox/Controllers/InboxController";
import ConsultSearchBar from "../Inbox/ConsultSearchBar";
import ActivityProvider from "../Inbox/Contexts/ActivityProvider";
import MOAConsultListItem from "./MOAConsultListItem";
import { useContext, useState } from "react";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import InboxModel from "../Inbox/Models/InboxModel";
import { ClaimsContext } from "../../../../config/ClaimsProvider";
import { Activity } from "../../../../../shared/types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...thinScrollbar,
    threadSidebar: {
      padding: 0,
    },
    hidden: {
      display: "none",
    },
    centerListItem: {
      justifyContent: "center",
    },
    noConsults: {
      textAlign: "center",
    },
  }),
);

interface MOAConsultInboxViewProps {
  hasEditStatusPermissions: boolean;
}

const MOAConsultInboxView = ({
  hasEditStatusPermissions,
}: MOAConsultInboxViewProps) => {
  const classes = useStyles();

  const claimsContext = useContext(ClaimsContext);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [statuses, setStatuses] = useState<string[] | undefined>(
    hasEditStatusPermissions ? ["to_book", "to_book_urgent"] : undefined,
  );

  const setStatusesAll = () => {
    setStatuses(undefined);
  };
  const setStatusesToBook = () => {
    setStatuses(["to_book", "to_book_urgent"]);
  };

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const {
    sortedActivities,
    loadMoreHandler,
    disableLoadMoreLoading,
    disableLoadMoreEndOfResults,
    updateActivitiesInState,

    isActivityOpen,
    openActivityHandler,
    // Search
    enableSearch,
    runSearch,
    clearSearch,
    fetchSearchFilters,
    saveSearchFilters,

    processState,
    setProcessState,
    processErrorMessage,
  } = InboxController<Activity.ClinicActivity>({
    inboxModel: new InboxModel(
      claimsContext.userClaims?.clinicId ?? "",
      dbNames.activities,
      dbNames.clinicActivities,
      dbNames.clinicActivities_activities,
      "lastMessageReceivedAt",
    ),
    metadataFieldToSortBy: "lastMessageReceivedAt",
    statuses: statuses,
    setShowTabs,
  });

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <div className={isActivityOpen ? classes.hidden : ""}>
        <List className={classes.threadSidebar}>
          <HeightLayout height={heightMinusAppBar}>
            {showTabs && hasEditStatusPermissions && (
              <HeightLayoutChild flexDriver>
                <ListItem divider>
                  <ToggleButtonGroup>
                    <ToggleButton
                      onClick={setStatusesAll}
                      selected={statuses === undefined}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      onClick={setStatusesToBook}
                      selected={statuses !== undefined}
                    >
                      To Book
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ListItem>
              </HeightLayoutChild>
            )}
            <HeightLayoutChild flexDriver>
              <ListItem divider>
                <ConsultSearchBar
                  enableSearch={enableSearch}
                  runSearch={runSearch}
                  clearSearch={clearSearch}
                  fetchSearchFilters={fetchSearchFilters}
                  saveSearchFilters={saveSearchFilters}
                  placeholderText={
                    "PHN, Patient Name, Specialty, Subsite, Referring Provider/Consultant name"
                  }
                  maxSearchTerms={10}
                  onSelectedOptionsChange={setSelectedOptions}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                />
              </ListItem>
              <ProcessStatus
                state={processState}
                setState={setProcessState}
                errorMessage={processErrorMessage}
                useSnackbar={true}
              />
            </HeightLayoutChild>
            <HeightLayoutChild
              flexDriven
              allowOverflowY
              className={classes.thinScrollbar}
            >
              {sortedActivities.length == 0 &&
                processState !== ProcessState.running && (
                  <ListItem className={classes.centerListItem}>
                    <Typography className={classes.noConsults}>
                      No Consults
                    </Typography>
                  </ListItem>
                )}
              {sortedActivities.map((a) => (
                <MOAConsultListItem
                  key={`moa_inbox_list_item_${a.id}`}
                  activity={a}
                  openActivityHandler={openActivityHandler}
                />
              ))}
              <ListItem
                button
                onClick={loadMoreHandler}
                disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
                alignItems="center"
                divider
              >
                <ListItemIcon>
                  <ArrowDownwardIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    disableLoadMoreLoading
                      ? "Loading"
                      : disableLoadMoreEndOfResults
                        ? "End of Consults"
                        : enableSearch
                          ? "Load More Search Results"
                          : "Load More"
                  }
                />
              </ListItem>
            </HeightLayoutChild>
          </HeightLayout>
        </List>
      </div>
      <ActivityProvider
        viewType="moa"
        metadataRefId={claimsContext.userClaims?.clinicId ?? ""}
        updateActivitiesInListState={updateActivitiesInState}
        metadataCollection={dbNames.clinicActivities}
        metadataSubCollection={dbNames.clinicActivities_activities}
      >
        <ViewSecureMessage />
      </ActivityProvider>
    </>
  );
};

export default MOAConsultInboxView;
