import React from 'react';
import TextField from '@material-ui/core/TextField';

interface MediaEditNotesProps {
    notes: string,
    notesChanged: (notes: string, updateFiretore: boolean) => void
}

const MediaEditNotes = ({ notes, notesChanged }: MediaEditNotesProps) => {
    
    return (
        <TextField
            value={notes}
            onChange={(e) => { notesChanged(e.target.value as string, false)}}//Update notes in state only
            onBlur={(e) => { notesChanged(e.target.value as string, true)}}//Update notes in state and firestore on blur
            minRows={3}
            multiline      
            maxRows={Infinity}
            margin="dense"
            fullWidth
            variant="outlined"
            placeholder="Add notes"
            autoComplete="off"
            inputProps={{
                "aria-autocomplete": "none",
                "list": "autocompleteOff"                        
            }}
        />
    );
}

export default MediaEditNotes;