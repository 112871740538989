//https://www.npmjs.com/package/react-process-string
import React from 'react';
import Linkify from 'linkify-react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            wordBreak: 'break-all'//Break links wherever to stop width of parent div expanding
        }
    }),
);

interface AddLinksProps {
    text: string
}

const AddLinks = ({ text }: AddLinksProps) => {
    const classes = useStyles();
    const linkProps = {        
        onClick: (event: any) => {
            if (!window.confirm(`Open this link in a new tab?\n${event.target.href}`)) {
                event.preventDefault()
            }
        }   
    };
    return (
        <Linkify options={{
            className: classes.link,
            attributes: linkProps,
            target: "_blank",
            rel: "noopener noreferrer"
        }}>
            {text}
        </Linkify>
    );
}

export default AddLinks;