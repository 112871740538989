import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useConfirmDestructiveModal from '../useConfirmDestructiveModal';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper:{
            padding: theme.spacing(1),
            backgroundColor: palette.orange,
            color: "white"
        },
        white: {
            color: "white"
        }
    })
)

interface SaveDiscardToolbarProps {
    show: boolean,
    modalText: string,
    saveHandler: () => void,
    discardHandler: () => void,
    unsavedText?: string,
    className?: string
}

const SaveDiscardToolbar = ({ show, modalText, saveHandler, discardHandler, unsavedText="Unsaved Changes", className }: SaveDiscardToolbarProps) => {
    const classes = useStyles();
    const { ConfirmDestructiveModal, showConfirmDestructiveModal } = useConfirmDestructiveModal({
        confirmText: modalText,
        confirmButtonText: "Discard",
        action: discardHandler
    })

    return (
        <Collapse in={show}>
            <Paper className={clsx(classes.paper, className)}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                        <Typography>
                            {unsavedText}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/* Put in container so save and discard on same line when line breaks */}
                        <Grid container alignItems='center' spacing={1}>                            
                            <Grid item>
                                <Button 
                                    className={classes.white}
                                    onClick={saveHandler} 
                                    startIcon={<SaveIcon/>}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.white}
                                    onClick={showConfirmDestructiveModal} 
                                    startIcon={<ClearIcon/>}
                                >
                                    Discard
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {ConfirmDestructiveModal}
        </Collapse>
    );
}

export default SaveDiscardToolbar;