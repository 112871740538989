/**
 * Returns error message for phone number input field if invalid
 * @param ID Practice ID to validate
 * @param province Province to validate against
 * @returns Undefined if valid or empty, error message if invalid
 */
const isValidPracIDInput = (
  ID: string,
  province: string,
): string | undefined => {
  return isPracID(ID, province) || ID === ""
    ? undefined
    : "Practice ID is invalid";
};

/**
 * Validates a practice ID
 * @param ID Practice ID to validate
 * @param province Province to validate against
 * @returns true if practice ID is formatted correctly based on the province
 */
export function isPracID(ID: string, province: string): boolean {
  const patterns: { [key: string]: RegExp } = {
    AB: /^[0-9]{9}$/,
    BC: /^[0-9]{5}$/,
  };

  // Return true if the province is not "AB" or "BC"
  if (!patterns[province]) {
    return true;
  }

  return patterns[province].test(ID);
}

export default isValidPracIDInput;
