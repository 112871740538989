import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useHistory } from 'react-router-dom';
import usePushNotifications from './usePushNotifications'; import MessageIcon from '@material-ui/icons/Message';



//This is a component so that it can live within the react router scope
const PushNotifications = () =>
{
    const { androidMsg, setAndroidMsg, route, setRoute } = usePushNotifications();
    const history = useHistory()
    const handleClose = () =>
    {
        setAndroidMsg( undefined )
        setRoute( undefined )
    }


    const handleClick = () =>
    {
        if ( route !== undefined )
        {
            history.push( route );
            handleClose()
        }
    };
    return (
        <>
          
            <Snackbar open={ androidMsg !== undefined } onClose={ handleClose } anchorOrigin={ { vertical: 'top', horizontal: 'center' } }>
                <Alert onClose={ handleClose } severity="info" style={ { width: '100%' } } icon = { < MessageIcon /> }>
                    <div onClick={ handleClick }>
                        { androidMsg }
                    </div>
                </Alert>
            </Snackbar>

        </>
    );
}

export default PushNotifications;