import { BillingRecord } from "@alethea-medical/admin-types";
import parseJSONTimestamp from "../../../models/parseJSONTimestamp";
import { SortableKeys, Order } from "./types";
import firebase from "firebase"


export function formatStatus(status: string){
    const result = status.replace( /([A-Z])/g, " $1" );
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isTimestampField(orderBy: SortableKeys): orderBy is 'created' | 'hlinkResultReceivedAt' | 'paidAt' |'submittedAt' | 'lastUpdatedAt' {
    return orderBy === 'created' || orderBy === 'hlinkResultReceivedAt' || orderBy === 'paidAt' || orderBy === 'submittedAt' || orderBy === 'lastUpdatedAt'
}

function descendingComparator(a: BillingRecord, b: BillingRecord, orderBy: SortableKeys) {
    if(isTimestampField(orderBy)) {
        const aTime: firebase.firestore.Timestamp | undefined = a[orderBy];
        const bTime: firebase.firestore.Timestamp | undefined = b[orderBy];
        if(aTime === undefined && bTime === undefined)
            return 0;
        if(aTime === undefined)
            return 1;
        if(bTime === undefined)
            return -1;
        if (bTime.toMillis() < aTime.toMillis())
            return -1;
        if (bTime.toMillis() > aTime.toMillis())
            return 1;
        return 0;
    }
    
    else {
        const aOrder = a[orderBy]
        const bOrder = b[orderBy]
    
        if(bOrder === undefined && aOrder !== undefined) {
            return -1;
        }
        if(bOrder !== undefined && aOrder === undefined) {
            return 1;
        }
        if(bOrder === undefined && aOrder === undefined) {
            return 0;
        }
        if(bOrder !== undefined && aOrder !== undefined) {
            if (bOrder < aOrder) {
                return -1;
            }
            if (bOrder > aOrder) {
                return 1;
            }
        }
        return 0;
    }

}
export function stableSort(array: BillingRecord[], comparator: (a: BillingRecord, b: BillingRecord) => number) {
    const stabilizedThis = array.map((el: BillingRecord, index: number) => { return {el: el, index: index} });
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0)
            return order;
        return a.index - b.index ;
    });
    return stabilizedThis.map((el) => el.el);
}



export function getComparator(order: Order, orderBy: SortableKeys): (a: BillingRecord, b: BillingRecord) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function parseRecordFromAPI(record: BillingRecord): BillingRecord {
    const timestamps: Partial<BillingRecord> = {
        created: parseJSONTimestamp(record.created),
        hlinkResultReceivedAt: parseJSONTimestamp(record.hlinkResultReceivedAt),
        paidAt: parseJSONTimestamp(record.paidAt),
        lastUpdatedAt: parseJSONTimestamp(record.lastUpdatedAt),
        submittedAt: parseJSONTimestamp(record.submittedAt),
    }
    const comments = record.comments ? /Alethea system comment.*Record is a duplicate of.*/.test(record.comments) ? "Duplicate Billing" : "N/A" : "N/A"
    return Object.assign({}, record, timestamps, { comments: comments })
}


export function formatDollars(num: number) {
    return num.toFixed(2)
}