import Typography from "@material-ui/core/Typography";
import useConsultFormStyles from "../useConsultFormStyles";
import ServiceCard from "./ServiceCard";
import { ErrorBoundary } from "react-error-boundary";
import { ServiceItem } from '../../../../db/Service';


interface ServiceSearchResultsProps {
    searchResults: ServiceItem[];
    noResults: boolean;
    selectService: (serviceId: string) => void;   
}

const ServiceSearchResults = ({ searchResults, noResults, selectService }: ServiceSearchResultsProps) => {
    const classesConsult = useConsultFormStyles();

    //TODO: What do we do in case of rendering error for a service? Log it?
    return (
        <div>
            {searchResults.length === 0 && noResults && (
                <div className={classesConsult.spacing}>
                    <Typography variant="body1" color="textSecondary" style={{textAlign: "center"}}>
                        No services found.
                    </Typography>
                </div>
            )}
            {searchResults.length > 0 && (
                <>
                    {searchResults.map((serviceItem) => (
                        <div className={classesConsult.spacing} key={serviceItem.id}>
                            <ErrorBoundary fallback={<></>}>
                                <ServiceCard serviceId={serviceItem.id} service={serviceItem.service} selectService={selectService} showStartConsultButton/>
                            </ErrorBoundary>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default ServiceSearchResults;