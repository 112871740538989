import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

interface ServiceSearchProps {
    searchText: string,
    handleSearchText: (text: string) => void,
    onStringSearch: (searchText: string) => void,
    handleRestartSearchResults: () => void,
}

const ServiceSearch = ({ searchText, handleSearchText, onStringSearch, handleRestartSearchResults, }: ServiceSearchProps) => {

    const handleSearch = (text: string) => {
        if (text == "") handleRestartSearchResults();

        onStringSearch(text)
    }

    const feelGoodForClickingSearch = () => {
        handleRestartSearchResults();
        onStringSearch(searchText)
    }

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1} alignItems='center'>

                <Grid item xs={12} sm={12} md={true}>
                    <TextField
                        placeholder={"Service name, specialty, keywords..."}
                        onChange={(event) => { handleSearchText(event.target.value); handleSearch(event.target.value); }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                </Grid>

                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button color="primary" variant="contained" onClick={feelGoodForClickingSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    );
}

export default ServiceSearch;