import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../types";
import TextField from '@material-ui/core/TextField';
import isValidEmailInput from "./isEmail";
import { createStyles, makeStyles } from "@material-ui/core";

const errorTextOffset = 27;

export const useOffsetEmailInputErrorTextStyles = makeStyles(() =>
    createStyles({
        flexAlignedEnd: {
            marginBottom: errorTextOffset
        },
        flexAlignedStart: {
            marginTop: errorTextOffset
        }
    })
)

const EmailInput = ({ name, control, defaultValue, rules, label, errorTextPadding, ...rest }: ControlledInputProps) => {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={Object.assign(rules ? rules : {}, { validate: {
                isEmail: isValidEmailInput
            }})}
            render={({field, fieldState}) =>        
                <TextField
                    {...field}
                    {...rest}
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{
                        disableAnimation: true,
                    }}
                    inputProps={{
                        "aria-autocomplete": "none",
                        "list": "autocompleteOff"                        
                    }}
                />
            }
        />
    );
}

export default EmailInput;