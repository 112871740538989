import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '../../../../../components/ToggleButton';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import palette from '../../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toggleButtonOn: {
            color: "white",
            background: palette.orange,
            "&:hover": {
                background: "#d8530c"
            }
        },
        toggleButtonOff: {
            color: palette.orange
        }, 
    })
)

interface StartStopLabellingButtonProps {
    show: boolean,
    startLabellingHandler: () => void,
    stopLabellingHandler: () => void,
    disabled: boolean
}

const StartStopLabellingButton = ({ show, startLabellingHandler, stopLabellingHandler, disabled}: StartStopLabellingButtonProps) => {
    const classes = useStyles();

    const toggleHandler = () => {
        if(show) {
            stopLabellingHandler()
        }
        else {
            startLabellingHandler()
        }
    }

    return (
        <ToggleButton className={clsx({
            [classes.toggleButtonOn]: show,
            [classes.toggleButtonOff]: !show
        })} startIcon={<VisibilityIcon/>} toggleState={show} onClick={toggleHandler} {...{fullWidth: true, color: "inherit"}} disabled={disabled}>
            {show ? "Save Labels" : "Start Labelling"}
            {show ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </ToggleButton>
    );
}

export default StartStopLabellingButton;