import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { parse } from "query-string";
import { useContext, useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";
import { Activity, Service } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import analyticsLogs from "../../../../analyticsLogs";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../../components/Gallery/Models/GalleryModel";
import {
  FileDict,
  FileWithPreview,
} from "../../../../components/useFileDrop/useFileDrop";
import { ServiceItem } from "../../../../db/Service";
import { logAnalyticsEvent } from "../../../../firebase";
import {
  discardDraft,
  loadDraft,
  saveOrUpdateDraft,
} from "./ConsultDraftFunctions";

export interface ConsultDraftControllerProps {
  /** ID of the current draft. If undefined, no draft is currently loaded */
  draftId: string | undefined;
  /** Allows the draft controller to set a new draft ID when a draft is saved */
  setDraftId: (draftId: string | undefined) => void;
  /** Clean the form, setting dirty flags to false. Used after a draft is loaded or saved */
  cleanForm: (formData?: Activity.ConsultFormFields) => void;
  /** Clear form fully  */
  clearForm: () => void;
  /** Allows the draft controller to get current form state and save it when saving draft */
  getValues: UseFormGetValues<Activity.ConsultFormFields>;
  /** Gives access to selected media to save in draft */
  selectedMedia: UserMediaMetadataSelectedDict;
  /** Gives access to attachments to save in draft */
  files: FileDict;
  /** Allows the draft controller to select the service if there was one saved in the draft */
  selectService: (service: ServiceItem) => void;
  /** Allows the draft controller to select multiple gallery files */
  selectMultipleHandler: (
    items: UserMediaMetadataItem[],
    shouldDirty?: boolean,
  ) => void;
  /** Allows the draft controller to upload files in the other attachments section */
  handleUploadFilesWithPreview: (
    acceptedFiles: FileWithPreview[],
    shouldDirty?: boolean,
  ) => void;
  econsultForwarder?: Activity.EconsultForwarder;
  setEconsultForwarder?: (
    econsultForwarder: Activity.EconsultForwarder | undefined,
  ) => void;
}

const ConsultDraftController = ({
  draftId,
  setDraftId,
  cleanForm,
  clearForm,
  getValues,
  selectedMedia,
  files,
  selectService,
  selectMultipleHandler,
  handleUploadFilesWithPreview,
  econsultForwarder,
  setEconsultForwarder,
}: ConsultDraftControllerProps) => {
  const authContext = useContext(AuthContext);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [draftSubject, setDraftSubject] = useState("");

  useEffect(() => {
    const params = parse(location.search);
    if (params.consultDraftId !== undefined && authContext.uid !== "") {
      const draftCollection = params.collection ?? "user_drafts";

      setProcessState(ProcessState.running);
      setIsLoadingDraft(true);
      setLoadingMessage("Loading draft...");
      loadDraft(draftCollection, authContext.uid, params.consultDraftId)
        .then(
          ({
            formData,
            subject,
            service,
            gallerySelectedMedia,
            files,
            econsultForwarder,
          }) => {
            logAnalyticsEvent(analyticsLogs.consultDraft.open);

            // Load form data and set isDirty flags for form, gallery, and attachments to false
            cleanForm(formData);

            // Select gallery files
            selectMultipleHandler(gallerySelectedMedia, false);

            // Select external attachments
            handleUploadFilesWithPreview(files, false);

            // Add subject
            setDraftSubject(subject);

            // Set forwarder
            if (econsultForwarder && setEconsultForwarder) {
              setEconsultForwarder(econsultForwarder);
            }

            // Set service if not undefined
            if (service) selectService({ id: formData.serviceId, service });

            //setTimeout to allow changes to propagate
            setTimeout(() => {
              setProcessState(ProcessState.idle);

              // Delay setting ID so that form can be set to not dirty before changing query params (which normally triggers a leave page prompt)
              setDraftId(params.consultDraftId);
            }, 1);
          },
        )
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error loading draft",
            analyticsLog: analyticsLogs.consultDraft.openFail,
          });
        })
        .finally(() => {
          setIsLoadingDraft(false);
        });
    }
  }, [authContext.uid]);

  // Reset the draft subject when we are no longer viewing a saved draft
  useEffect(() => {
    if (draftId === undefined) setDraftSubject("");
  }, [draftId]);

  const saveDraftHandler = (subject: string) => {
    if (authContext.uid !== "") {
      const params = parse(location.search);
      const draftCollection = params.collection ?? "user_drafts";

      const formData = getValues();
      setProcessState(ProcessState.running);
      setLoadingMessage("Saving draft...");
      saveOrUpdateDraft(
        draftCollection,
        authContext.uid,
        draftId,
        formData,
        subject,
        selectedMedia,
        files,
        econsultForwarder,
      )
        .then(({ newDraftId, newDraftSubject }) => {
          logAnalyticsEvent(
            draftId === undefined
              ? analyticsLogs.consultDraft.save
              : analyticsLogs.consultDraft.update,
          );

          // Update subject
          setDraftSubject(newDraftSubject);

          // Load form data and set isDirty flags for form, gallery, and attachments to false
          cleanForm(formData);

          setTimeout(() => {
            // Set new draft ID after resetting page to not dirty, so leavePagePrompt doesn't activate when query parameters update
            setDraftId(newDraftId);
          }, 1);

          setProcessState(ProcessState.success);
          setSuccessMessage("Draft saved.");
          setTimeout(() => {
            // Either new ID if saving new, or same as before if updating
            setProcessState(ProcessState.idle);
          }, 2000);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error saving draft",
            analyticsLog:
              draftId === undefined
                ? analyticsLogs.consultDraft.saveFail
                : analyticsLogs.consultDraft.updateFail,
          });
        });
    }
  };

  const discardDraftHandler = () => {
    const params = parse(location.search);
    const draftCollection = params.collection ?? "user_drafts";

    if (draftId !== undefined) {
      setProcessState(ProcessState.running);
      setLoadingMessage("Discarding draft...");
      discardDraft(draftCollection, draftId)
        .then(() => {
          logAnalyticsEvent(analyticsLogs.consultDraft.discard);

          // Remove all data from the form
          clearForm();

          setTimeout(() => {
            // Set new draft ID after resetting page to not dirty, so leavePagePrompt doesn't activate when query parameters update
            setDraftId(undefined);
            setDraftSubject("");
          }, 1);

          setProcessState(ProcessState.success);
          setSuccessMessage("Draft discarded.");
          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 2000);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error discarding draft",
            analyticsLog: analyticsLogs.consultDraft.discardFail,
          });
        });
    }
  };

  return {
    saveDraftHandler,
    discardDraftHandler,
    draftSubject,
    setDraftSubject,
    draftState: processState,
    draftError: processErrorMessage,
    setDraftState: setProcessState,
    draftLoadingMessage: loadingMessage,
    draftSuccessMessage: successMessage,
    isLoadingDraft,
  };
};

export default ConsultDraftController;
