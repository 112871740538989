import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import Done from "@material-ui/icons/Done";
import Save from "@material-ui/icons/Save";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import isWeb from "../../../../models/isWeb";
import usePermissions from "../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { defaultConsultStatuses } from "../Toolbar/ConsultStatus";
import {
  ProcessState,
  useMobileSizes,
  useProcessState,
} from "@alethea-medical/alethea-components";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  useMediaQuery,
  withWidth,
} from "@material-ui/core";
import SnackbarMessage from "src/components/SnackbarMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
    },
    helperText: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1.5),
    },
    noMarginBottom: {
      "& .MuiTextField-root": {
        marginBottom: 0,
      },
    },
    autocomplete: {
      flexGrow: 1,
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px 4px !important",
        minHeight: "44px",
      },
      "& .MuiAutocomplete-input": {
        padding: "6px 4px !important",
      },
      "& .MuiChip-root": {
        height: 28,
        margin: "3px 2px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[400],
      },
    },
    searchBarContainer: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
      gap: theme.spacing(1),
    },
    button: {
      whiteSpace: "nowrap",
      height: "44px",
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    statusField: {
      minWidth: 120,
      marginRight: theme.spacing(1),
      width: "100%",
    },
    optionsContainer: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface ConsultSearchBarProps {
  enableSearch: boolean;
  runSearch: (
    params: string[],
    status: string,
    initialSearch?: boolean,
    searchFilters?: string[],
  ) => void;
  clearSearch: () => void;
  saveSearchFilters: (filters: string[]) => void;
  fetchSearchFilters: () => Promise<string[]>;
  maxSearchTerms?: number;
  placeholderText: string;
  onSelectedOptionsChange: (options: string[]) => void;
  showOptions: boolean;
  setShowOptions: (show: boolean) => void;
}

const ConsultSearchBar = ({
  enableSearch,
  runSearch,
  clearSearch,
  saveSearchFilters,
  fetchSearchFilters,
  maxSearchTerms = 10,
  placeholderText,
  onSelectedOptionsChange,
  showOptions,
  setShowOptions,
}: ConsultSearchBarProps) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const isMobileSize = useMobileSizes();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [showSearch, setShowSearch] = useState<boolean>(!isMobileSize);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const subjectFields = [
    "Specialty",
    "Subsite",
    "PHN",
    "Patient Name",
    "Referrer Name",
    "Consultant Name",
  ];

  const { processState, setProcessState } = useProcessState({
    defaultErrorMessage: "An error occurred",
  });

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  useEffect(() => {
    if (!showSearch) setShowOptions(false);
  }, [showSearch]);

  useEffect(() => {
    const initializeSearchFilters = async () => {
      const filters = await fetchSearchFilters();
      setSelectedOptions(filters);
      onSelectedOptionsChange(filters);
    };
    initializeSearchFilters();
  }, []);

  useEffect(() => {
    onSelectedOptionsChange(selectedOptions);
  }, [selectedOptions, onSelectedOptionsChange]);

  const handleSearch = () => {
    runSearch(searchParams, status, true, selectedOptions);
  };

  const handleClearSearch = () => {
    setSearchParams([]);
    setStatus("");
    clearSearch();
  };

  const handleOptionChange = (option: string) => {
    setSelectedOptions((prev) => {
      if (prev.includes(option)) {
        // Prevent unchecking if it's the last selected option
        if (prev.length === 1) {
          setShowSnackbar(true);
          return prev;
        }
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const onSaveSearchFilters = () => {
    setProcessState(ProcessState.running);
    saveSearchFilters(selectedOptions);
    setProcessState(ProcessState.success);
    setTimeout(() => {
      setProcessState(ProcessState.idle);
      setShowOptions(false);
    }, 1000);
  };

  const getHelperText = () => {
    if (inputValue.length > 0 || searchParams.length > 0) {
      return "Press enter to add multiple search terms";
    }
    return undefined;
  };

  return (
    <Grid container direction="column" spacing={1}>
      {(isMobileSize || !showSearch) && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowSearch(!showSearch)}
          >
            {showSearch ? "Hide" : "Show"} Search
          </Button>
        </Grid>
      )}
      <Grid item>
        <Collapse in={showSearch}>
          <Grid className={classes.searchBarContainer}>
            <Grid xs={isMobileSize ? 12 : true}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={searchParams}
                filterSelectedOptions
                autoSelect
                limitTags={maxSearchTerms}
                onChange={(e, value) => {
                  setSearchParams(
                    (value as string[]).map((str) => {
                      try {
                        return str.trim();
                      } catch (e) {
                        return str;
                      }
                    }),
                  );
                }}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholderText}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      style: { paddingTop: 0, paddingBottom: 0 },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: { padding: "10px 4px" },
                      enterkeyhint: "done",
                    }}
                  />
                )}
                className={classes.autocomplete}
              />
            </Grid>
            {hasEditStatusPermissions && (
              <Grid item xs={isMobileSize ? 12 : 2}>
                <TextField
                  select
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value as string)}
                  variant="outlined"
                  margin="dense"
                  className={classes.statusField}
                  InputProps={{
                    endAdornment:
                      status !== "" ? (
                        <IconButton size="small" onClick={() => setStatus("")}>
                          <ClearIcon />
                        </IconButton>
                      ) : null,
                  }}
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {defaultConsultStatuses.order.map((status, i) => (
                    <MenuItem
                      key={`search_status_${status}_${i}`}
                      value={status}
                    >
                      <ListItemText
                        primary={defaultConsultStatuses.statuses[status]?.label}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              className={classes.button}
            >
              Search
            </Button>
            <Grid>
              {enableSearch && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClearSearch}
                  className={classes.button}
                >
                  Clear
                </Button>
              )}
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      {isWeb() && getHelperText() && (
        <Grid item>
          <Typography variant="caption" className={classes.helperText}>
            {getHelperText()}
          </Typography>
        </Grid>
      )}
      {showOptions && (
        <Grid item>
          <Collapse in={showOptions}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              className={classes.optionsContainer}
            >
              <Grid item>
                <Grid container spacing={1}>
                  {subjectFields.map((option) => (
                    <Grid item key={option}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleOptionChange(option)}
                            name={option}
                          />
                        }
                        label={option}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color={
                    processState === ProcessState.success
                      ? "secondary"
                      : "primary"
                  }
                  onClick={onSaveSearchFilters}
                  startIcon={
                    processState === ProcessState.success ? <Done /> : <Save />
                  }
                >
                  {processState === ProcessState.success
                    ? "Subject Line Saved"
                    : "Save Subject Line"}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )}
      <SnackbarMessage
        message="At least one column must be selected"
        show={showSnackbar}
        setShow={setShowSnackbar}
      />
    </Grid>
  );
};

export default ConsultSearchBar;
