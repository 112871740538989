import { createStyles, Theme } from '@material-ui/core/styles';

const sharedStyles = (theme: Theme) => {
    return createStyles({
        boxBorderColor: {
            borderColor: theme.palette.grey[300],
        },
        boxPadding: {
            padding: theme.spacing(1),
        }
    })
}

export default sharedStyles;