import React from 'react';
import Button from '@material-ui/core/Button';
import { ImageAnnotationSelectedDict } from './EconsultImageAnnotationModel';

interface IgnoreImagesButtonProps {
    ignoreHandler: (items: ImageAnnotationSelectedDict) => void,
    unselectAllHandler: () => void,
    selectedMedia: ImageAnnotationSelectedDict
}

const IgnoreImagesButton = ({ ignoreHandler, unselectAllHandler, selectedMedia }: IgnoreImagesButtonProps) => {

    const handleIgnore = () => {
        ignoreHandler(selectedMedia);
        unselectAllHandler();
    }
    return (
        <Button fullWidth variant='contained' color="primary" onClick={handleIgnore} disabled={Object.keys(selectedMedia).length === 0}>Ignore</Button>
    );
}

export default IgnoreImagesButton;