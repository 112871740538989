import Grid from '@material-ui/core/Grid';
import strings from '../../Common/strings';
import FormattedInputLabel from '../../../../../components/FormattedInputLabel';
import { TypedFormTextField } from '../../../../../components/FormInputFields/FormTextField';
import { Control } from 'react-hook-form';
import { TypedPhoneInput } from '../../../../../components/FormInputFields/PhoneInput';
import HelpModal from '../../../../../components/HelpModal';
import useCms from '../../../../../utils/useCms';
import { TypedFormSelectAutocomplete } from '../../../../../components/FormInputFields/FormSelectAutocomplete';
import { SignupInfo } from '@alethea-medical/aletheamd-types';

interface ClinicInformationProps {
    control: Control<SignupInfo.SignupInfo>,
    disabled: boolean
}

const ClinicInformation = ({ control, disabled }: ClinicInformationProps) => {
    const clinicsContent = useCms({ cmsId: "clinics" })

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
                <HelpModal
                    buttonText="Multiple Clinics?" helpText={[strings.clinicNote]} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormattedInputLabel>Clinic Name</FormattedInputLabel>
                <TypedFormSelectAutocomplete
                    control={control}
                    name="clinicName"
                    rules={{required: {value: true, message: "Clinic name is required."}}}

                    disabled={disabled}
                    options={clinicsContent.getArray<string>("clinicNames")}
                    errorTextPadding
                    {...{freeSolo: true, autoSelect: true}}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormattedInputLabel>City</FormattedInputLabel>
                <TypedFormTextField
                    control={control}
                    name="city"
                    rules={{ required: { value: true, message: "City is required." } }}
                    errorTextPadding
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormattedInputLabel>Clinic Phone Number</FormattedInputLabel>
                <TypedPhoneInput
                    control={control}
                    name="clinicPhone"
                    rules={{ required: { value: true, message: "Clinic phone number is required." } }}
                    errorTextPadding
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormattedInputLabel>Clinic Fax Number</FormattedInputLabel>
                <TypedPhoneInput
                    control={control}
                    name="fax"
                    rules={{ required: { value: true, message: "Clinic fax is required." } }}
                    errorTextPadding
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
}

export default ClinicInformation;