import React from 'react';
import './App.css';
import ApplicationRoutes from './config/ApplicationRoutes';
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline  from '@material-ui/core/CssBaseline';
import ServiceWorkerWrapper from './config/ServiceWorkerWrapper';
import { grey } from '@material-ui/core/colors';
import palette from './palette';
import isAndroid from './models/isAndroid';
import AndroidAPKUpdateProvider from './config/AndroidAPKUpdateProvider';
import ClaimsProvider from './config/ClaimsProvider';

const theme = createTheme({
    palette: {
        primary: {
            main: palette.darkGreen,
        },
        secondary: {
            main: palette.lightGreen,
            light: palette.lightGreenLight
        },
        text: {
            primary: grey[800]
        }
    },
    typography: {
        fontFamily: [
            'Proxima Nova',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        subtitle1: {
            color: "#888",
            fontSize: "1.15em"
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1.0em"
            }
        }, 
        MuiAlert: {
            standardError: {
                backgroundColor: palette.orangeLightLight,//background
                "& .MuiAlert-icon": {
                    color: palette.orange//icon
                }
            },
            standardInfo: {
                color: palette.darkGreen,//text
                backgroundColor: palette.lightGreenLight,
                "& .MuiAlert-icon": {
                    color: palette.darkGreen
                }
            }
        }
    }
});

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        
    },
    container: {
        padding: 0,
    }
}));

const App: React.FC = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <ClaimsProvider>
                <ServiceWorkerWrapper />
                <CssBaseline/>
                <Container maxWidth={false} className={classes.container}>
                    <div className={classes.root}>
                        {isAndroid() ? (
                            <AndroidAPKUpdateProvider>
                                <ApplicationRoutes />
                            </AndroidAPKUpdateProvider>
                        ) :
                            <ApplicationRoutes />
                        }
                    </div>
                </Container>
            </ClaimsProvider>
        </ThemeProvider>
    );
}

export default App;
