import Typesense from "typesense";
import { config, fbFirestore } from "../../../../../firebase";

const usersSchemaCollectionName = `${config.projectId}.users`
export const error_noTypesenseKey = new Error("Typesense search API key not found");
export const error_noSearchText = new Error("No search text provided");
export const error_noSearchResults = new Error("No search results found");

interface SearchByStringProps {
    typesenseUsersSearchApiKey: string | undefined,
    searchText: string,
}

interface Approver {
    /** User title  */
    title?: string;
    /** User credentials (MD; MTM; FRCSC) */
    credentials?: string;
    /** UID of the user*/
    uid: string;
    /** First and last name of the user combined for searching */
    userName: string;
    /** User's first name */
    firstName: string;
    /** User's last name */
    lastName: string;
    /** Ie Dr. */
    salutation?: string;
}

interface typesenseUserDocument {
    id: string;
    firstName: string;
    lastName: string;
}

export async function searchByString({typesenseUsersSearchApiKey, searchText} : SearchByStringProps): Promise<Approver[]>
{
    if (typesenseUsersSearchApiKey === undefined || typesenseUsersSearchApiKey === "") return Promise.reject(error_noTypesenseKey);
    if (searchText === undefined || searchText === "") return Promise.reject(error_noSearchText);

    // Create Typesense client
    const cloudClient = new Typesense.Client({
        'nodes': [{
            'host': 'yg5aj4dz7potcv23p-1.a1.typesense.net',
            'port': 443,
            'protocol': 'https'
        }],
        'apiKey': typesenseUsersSearchApiKey,
        'connectionTimeoutSeconds': 2
    });

    //? TEMP: Get the Typesense search parameters from firebase
    const parameters = await getTypesenseSearchParameters();

    //* Make the search
    //? I am not sure why, but page & per_page parameters weren't working (even without the limit_hits)
    const searchParameters : {q: string, query_by: string, filter_by?: string, sort_by?: string, page?: number, per_page?: number, limit_hits?: number} = {
        q: searchText,
        query_by: parameters.query_by,
        filter_by: parameters.filter_by,
        sort_by: parameters.sort_by,
        limit_hits: 50
    }

    return cloudClient.collections(usersSchemaCollectionName)
        .documents()
        .search(searchParameters)
        .then(function (searchResults) {
            if (!searchResults.hits || searchResults.hits.length == 0) return Promise.resolve([])

            const results : Approver[] = []

            for (let i = 0; i < searchResults.hits.length; i++) {
                const hit = searchResults.hits[i];

                if (hit.document === undefined) continue

                const doc = hit.document as typesenseUserDocument

                const approver : Approver = {
                    uid: doc.id,
                    userName: `${doc.firstName } ${doc.lastName}`,
                    firstName: doc.firstName,
                    lastName: doc.lastName,
                }
            
                results.push(approver)
            }

            return Promise.resolve(results)
    })
}

export async function getTypesenseSearchParameters()
{
    // Get correct object from firebase
    const snapshot = await fbFirestore.collection("system").doc("typesenseSearchParameters").get()
    const typesenseSearchParameters = snapshot.data()?.approverSearch

    //* Default parameters (incase they don't exist in firebase)
    const defaultParameters = {
        query_by: 'firstName, lastName',
        filter_by: "userRoles:=['approver']", // TODO: Should there be enabled true?
        sort_by: 'lastName:asc, firstName:asc' // TODO: Can add more eg. filtering by city or province
    }

    const parameters = {
        query_by: '',
        filter_by: '',
        sort_by: ''
    }

    // Grab them from Firebase, if not, use the default ones defined here
    parameters.query_by = typesenseSearchParameters?.query_by ?? defaultParameters.query_by
    parameters.filter_by = typesenseSearchParameters?.filter_by ?? defaultParameters.filter_by
    parameters.sort_by = typesenseSearchParameters?.sort_by ?? defaultParameters.sort_by

    return parameters
}