import { useContext, useEffect, useState } from "react";
import { Fcm } from "../../../../../shared/types";
import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import useQueryParamRouting from "../../../../components/useQueryParamRouting/useQueryParamRouting";
import * as notificationModel from "./NotificationModel";
import { AuthContext } from "../../../../AuthProvider";

export interface NotificationItem {
    id: string,
    notification: Fcm.UserNotification
}

export interface NotificationDict {
    [id: string]: Fcm.UserNotification
}

const loadMoreAmount = 10

const NotificationCenterController = () => {

    const authContext = useContext(AuthContext)
    const [notifications, setNotifications] = useState<NotificationDict>({})
    const [sortedNotifications, setSortedNotifications] = useState<NotificationItem[]>([])
    const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);
    
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ });
    const [loadingMessage, setLoadingMessage] = useState<string>("Loading notifications...")

    const history = useHistory();
    const { addOrRemoveFromQueryParams } = useQueryParamRouting({ paramName: "campaignId" })
    
    // Handler for when the activity back button is pressed
    const closeNotificationHandler = () => {
        addOrRemoveFromQueryParams(undefined)
    }

    const openNotificationHandler = (id: string) => {
        addOrRemoveFromQueryParams(id)
    }
    
    // Listen to the browser back button. If user presses back, close the activity
    useEffect(() => {
        return history.listen(() => {
            if(history.action === 'POP') {
                closeNotificationHandler()
            }
        })
    }, [history]);

    // Initial load
    useEffect(() => {
        if(authContext.uid !== "")
            refresh()
    }, [authContext.uid])

    const refresh = () => {
        setNotifications({})
        loadMore(authContext.uid, firebase.firestore.Timestamp.now(), {})
    }


    /** Load more and return the new notifications loaded in a dictionary */
    const loadMore = (uid: string, fetchEarlierThan: firebase.firestore.Timestamp, prevNotifications: NotificationDict): Promise<NotificationDict> => {
        setLoadingMessage("Loading notifications...")
        setProcessState(ProcessState.running);
        setDisableLoadMore(true);

        return notificationModel.loadNotifications(uid, fetchEarlierThan, loadMoreAmount)
        .then(({ results, didReturnResults }) => {
            if(!didReturnResults)
                setDisableLoadMore(true);

            setProcessState(ProcessState.idle);
            const newNotifications: NotificationDict = {}
            results.forEach((item) => {
                newNotifications[item.id] = item.notification
            })

            setNotifications({ ...prevNotifications, ...newNotifications })
            return {didReturnResults, newNotifications}

        })
        .catch((error: Error) => {
			errorHandler({
				error: error, 
				userMessage: "Error loading notifications"
			});
            return {didReturnResults: false, newNotifications: {}}
		})
		.then(({didReturnResults, newNotifications}) => {
			setDisableLoadMore(!didReturnResults)
            return newNotifications
		})
    }

    const loadMoreHandler = () => {
        loadMore(authContext.uid, sortedNotifications[sortedNotifications.length - 1].notification.sentAt, notifications)
    }

    const updateNotification = (id: string, notificationUpdate: Fcm.UserNotification) => {
        setNotifications({
            ...notifications,
            [id]: {
                ...notificationUpdate
            }
        })
    }

    const markAllAsReadHandler = () => {
        setProcessState(ProcessState.running)
        setLoadingMessage("Marking notifications as read...")
        notificationModel.markAllAsRead()
        .then(() => {
            // Manually update the state
            const now = firebase.firestore.Timestamp.now()
            const newNotifications = { ...notifications }
            Object.keys(newNotifications).forEach((key) => {
                newNotifications[key].read = true;
                newNotifications[key].readAt = now;
            })

            setNotifications(newNotifications)
            setProcessState(ProcessState.idle)
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error marking notifications as read"
            })
        })
    }

    useEffect(() => {
        setSortedNotifications(Object.entries(notifications).map(([id, notification]) => ({ id, notification })).sort((a, b) => b.notification.sentAt.toMillis() - a.notification.sentAt.toMillis()))
    }, [notifications])

    return {
        notifications,
        sortedNotifications,
        openNotificationHandler,
        closeNotificationHandler,
        markAllAsReadHandler,
        updateNotification,
        loadMoreHandler,
        refresh,
        disableLoadMoreLoading: disableLoadMore && processState === ProcessState.running, 
        disableLoadMoreEndOfResults: disableLoadMore && processState !== ProcessState.running,
        loadingMessage,
        processState, setProcessState, processErrorMessage, errorHandler
    }
}

export default NotificationCenterController;