import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import { useCallback, useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { ServiceItem } from "../../../../../db/Service";
import { fbFunctions, logAnalyticsEvent } from "../../../../../firebase";
import versionStrings from "../../../../../versionStrings";
import { ServiceSearchControl } from "../ServiceSearchController";
import analyticsLogs from "src/analyticsLogs";

export interface ServiceDirectoryControllerProps {
    control: Control<ServiceSearchControl>;
    searchProcessState: ProcessState;
    searchResults: ServiceItem[];
    selectedService: ServiceItem | null;
    searchDirectory: (specialty: string, subsite: string) => void;
    resetSubsite: () => void;
    selectService: (serviceId: string) => void;
    deselectService: () => void;
}

const getSpecialties = fbFunctions.httpsCallable('services-getSpecialties');

const ServiceDirectoryController = ({ control, searchProcessState, searchResults, selectedService, searchDirectory, resetSubsite, selectService, deselectService }: ServiceDirectoryControllerProps) => {
    
    const [specialties, setSpecialties] = useState<{ [specialty: string]: { subsites: string[] }}>({})
    const selectedSpecialty = useWatch({ control, name: "specialty", defaultValue: ""})
    const selectedSubsite = useWatch({ control, name: "subsite", defaultValue: ""})

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });
    
    // Load the specialties dropdown on component mount
    useEffect(() => {
        setProcessState(ProcessState.running)
        getSpecialties({ appVersion: versionStrings.version }).then((result) => {
            setProcessState(ProcessState.idle)
            setSpecialties(result.data);
        })
        .catch((error: Error) => {
            errorHandler({
                error,
                userMessage: "Error getting specialties. Please try reloading the page.",
            })
        })
    }, [])

    // Handler when specialty is selected from dropdown
    const handleSelectSpecialty = useCallback((event: any) => {
        resetSubsite()
        deselectService()
    }, [searchDirectory, resetSubsite, deselectService])

    // Handler when subsite is selected from dropdown
    const handleSelectSubsite = useCallback((event: any) => {
        deselectService()
    }, [selectedSpecialty, searchDirectory, deselectService])

    // This is a bit redundant since the specialist dropdown uses react-hook-form, however we need to synchronize the serviceId with the service loaded in state, so we call the handler here
    const handleSelectSpecialist = useCallback((event: any) => {
        const serviceId = event.target.value as string;
        if(serviceId === "") {
            deselectService()
            return
        }

        logAnalyticsEvent(analyticsLogs.services.selectDirectoryService);

        selectService(serviceId)
    }, [selectService])

    // Side-effect to trigger whenever specialty or subsite changes (such as from the dropdown or loading a draft)
    useEffect(() => {
        searchDirectory(selectedSpecialty, selectedSubsite)
    }, [selectedSpecialty, selectedSubsite])
    
    return {
        control, searchProcessState,
        searchResults,
        selectedService,
        specialties,
        selectedSpecialty,
        selectedSubsite,
        isDisabled: processState === ProcessState.running, processState, processErrorMessage,
        handleSelectSpecialty,
        handleSelectSubsite,
        handleSelectSpecialist
    }
}

export default ServiceDirectoryController;