import React from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import palette from '../../palette';

const RoundedButton = styled(Button)(({ theme }) => {
    
    return ({
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: "30px",
        textTransform: "none",
        padding: theme.spacing(1, 4),
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: palette.orange
        },
        '&:disabled': {
            backgroundColor: palette.disabledBGColor
        }
    })
});

export default RoundedButton;