import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


export const timeRowHeight = 15

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeRowMajor: {
            borderTop: "1px solid #ddd"
        },
        timeRow: {
            height: `${timeRowHeight}px`,
        }
    })
)

export function isTimeRowMajor(index: number): boolean {
    return index % 3 === 0
}

interface TimeRowProps {
    time: string,
    index: number
}

const TimeRow: React.FC<TimeRowProps> = ({ time, index, children }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.timeRow, {
            [classes.timeRowMajor]: isTimeRowMajor(index)
        })}>{children}</div>
    );
}

export default TimeRow;