import { HeightLayout, HeightLayoutChild, useScreenSize } from "@alethea-medical/alethea-components";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
            marginTop: "50px",
        }
    }),
);

interface LoadingPageProps {
    label: string,
    value?: number
}

const LoadingPage = ({ label, value }: LoadingPageProps) => {
    const classes = useStyles();
    
    const { heightMinusAppBar } = useScreenSize({});
    
    return (
        <HeightLayout height={heightMinusAppBar}>
            <HeightLayoutChild flexDriver>
                <div className={classes.loading}>
                    <Grid 
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >

                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            {value !== undefined &&
                                <CircularProgressWithLabel value={value} />
                            }
                            {value === undefined &&
                                <CircularProgress/>
                            }
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography>{label}</Typography>
                        </Grid>
                    </Grid>  
                </div>
            </HeightLayoutChild>
        </HeightLayout>
    );
}

export default LoadingPage;