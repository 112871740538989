import clsx from "clsx";
import palette from "../../../palette";
import { AppRoute } from "../../../config/routes";
import { useRoutePermissions } from "../../../components/PermissionRoute";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useContext, useEffect } from "react";
import { CollapseSidebarContext } from "./CollapseSidebarItem";
import AletheaSidebarItem, {
  AletheaSidebarItemProps,
} from "./components/AletheaSidebarItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerButtonSelected: {
      backgroundColor: palette.darkGreen,
      "&:hover": {
        backgroundColor: palette.darkGreen,
      },
    },
    drawerButtonTextSelected: {
      color: "white",
    },
    icon: {
      paddingTop: theme.spacing(0.5),
    },
    iconSelected: {
      color: "white",
    },
    iconUnselected: {
      color: palette.darkGreen,
    },
  }),
);

interface SidebarItemProps extends AletheaSidebarItemProps {
  route: AppRoute;
}
const SidebarItem = (props: SidebarItemProps) => {
  const classes = useStyles();
  const { permissionGranted } = useRoutePermissions(props.route);

  // Tell the parent collapse item that there is at least one sidebar item that is enabled
  // If this item is not a child of a collapse item, then the function call just calls an empty function
  const collapseSidebarContext = useContext(CollapseSidebarContext);
  useEffect(() => {
    if (permissionGranted) collapseSidebarContext.atLeastOneAvailable();
  }, [permissionGranted]);

  const selected =
    props.currentPath !== undefined && props.currentPath === props.route?.path;

  return (
    <AletheaSidebarItem
      {...props}
      enabled={permissionGranted}
      classes={{
        drawerButtonSelected: classes.drawerButtonSelected,
      }}
      textClass={clsx(props.textClass, {
        [classes.drawerButtonTextSelected]: selected,
      })}
      icon={
        <span
          className={clsx(classes.icon, {
            [classes.iconSelected]: selected,
            [classes.iconUnselected]: !selected,
          })}
        >
          {props.icon}
        </span>
      }
      className={"aletheaSidebarItem"}
    />
  );
};

export default SidebarItem;
