import React, { useState } from "react";
import { useWatch, Control } from "react-hook-form";
import FormSelectAutocomplete from "../../../components/FormInputFields/FormSelectAutocomplete";
import Typography from "@material-ui/core/Typography";
import AddFavoriteButton from "./AddFavoriteButton";
import {useAvailableForms} from "./useAvailableForms";
import Grid from "@material-ui/core/Grid";
import isNativeMobile from "../../../models/isNativeMobile";
import { Link } from "react-router-dom";

export interface FormListItem {
    uid: string,
    name: string,
    favoritedStatus: string
    
}


interface FormSelectProps {
    control: Control,
    setValue: (name: string, value: any) => void,
    disabled?: boolean
}

export const FormsSelect = ({control, setValue, disabled}: FormSelectProps) => {
    const selectedForm = useWatch({control, name: "selectedForm", defaultValue: undefined});
    const locationIdx = useWatch({control, name: "locationIdx", defaultValue: 0})

    const [formList, setFormList] = useState<FormListItem[]>([]);
    const [isLoading, setLoading] = useState(false);

    //retrieve all forms possible given possible admin defined constraints
    useAvailableForms(setValue, selectedForm, setFormList)

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                    <FormSelectAutocomplete
                        name="selectedForm"
                        label="Selected Form"
                        control={control}
                        options={formList}
                        getOptionLabel={(form: FormListItem) => form.name}
                        rules={{ required: { value: true, message: "Please select a form" } }}
                        disabled={disabled}
                        isLoading = {isLoading}
                    /> 
                </Grid>
                {selectedForm?.uid !== undefined && (
                    <Grid item md={6} xs={12}>
                        <AddFavoriteButton 
                            control={control}
                            disabled = {disabled}
                            formList = {formList}
                            setFormList = {setFormList}
                            isLoading = {isLoading}
                            setLoading = {setLoading}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {selectedForm !== undefined && (
                        //Use a link component, since we are loading a page within our domain
                        //If you use an <a tag, it will cause the mobile app to reload the page completely, even if target is not _blank
                        <Link to={`/forms?preview=true&formId=${selectedForm.uid}&locationIdx=${locationIdx}`} target={isNativeMobile() ? undefined : "_blank"} rel="noopener noreferrer">
                            <Typography>Preview your selected form</Typography>
                        </Link>
                    )}
                    {selectedForm === undefined && (
                        <Typography>Select a form to see a preview</Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

