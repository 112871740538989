import { Fcm } from "../../../../../../shared/types";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { formatTimeLong } from "../../../../../models/formatTime";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper  from '@material-ui/core/Paper';
import AddLinks from "../../../../../components/AddLinks";
import { HeightLayout, HeightLayoutChild, useScreenSize } from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notifContainer: {
            padding: theme.spacing(3),
            margin: theme.spacing(1)
        },
        body: {
            whiteSpace: 'pre-wrap',//Show newlines, and don't collapse sequential spaces
        }
    })
)

interface NotificationViewProps {
    notification: Fcm.UserNotification
    goBack: () => void,
}

const NotificationView = ({ notification, goBack }: NotificationViewProps) => {
    const classes = useStyles();

    const { heightMinusAppBar } = useScreenSize({})

    return (
        <HeightLayout height={heightMinusAppBar}>
            <HeightLayoutChild flexDriver>
                <IconButton onClick={goBack}><ChevronLeftIcon/></IconButton>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriven allowOverflowY>
                <Paper className={classes.notifContainer}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="h5">{notification.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{formatTimeLong(notification.sentAt.toDate())}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.body}><AddLinks text={notification.body}/></Typography>
                        </Grid>            
                    </Grid>
                </Paper>
                
            </HeightLayoutChild>
        </HeightLayout>
    );
}

export default NotificationView;