import React, { useEffect, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { HeightLayout, HeightLayoutChild, PaperModal, useSizeManager } from "@alethea-medical/alethea-components";
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../../../palette';
import { ImageAnnotationItem } from './EconsultImageAnnotationModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {//Center image
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
        },
        mobileImage: {
            verticalAlign: "middle"
        },
        zoomInfo: {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: palette.disabledBGColor,
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            borderRadius: "5px"
        },
        modalCloseButton: {
            color: "white",
            backgroundColor: theme.palette.grey[500],
            margin: theme.spacing(1),
            zIndex: 1,
            // For iOS notch, close button is on top of status bar without this
            marginTop: `calc(env(safe-area-inset-top) + ${theme.spacing(1)}px)`
        },
        imageContainer: {
            //Center image vertically and horizontally
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }
    }),
);

interface ImageToAnnotateModalProps {
    modalImage: ImageAnnotationItem | undefined,
    setModalImage: (item: ImageAnnotationItem | undefined) => void
}

const zoomScales = [1, 2, 4, 8]
const ImageToAnnotateModal = ({ modalImage, setModalImage }: ImageToAnnotateModalProps) => {
    const classes = useStyles();
    const [imageWidth, setImageWidth] = useState<number>(0);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const [nativeDimensions, setNativeDimensions] = useState<{width: number, height: number}>({width: 0, height: 0})
    const { sizeRef, height, width, updateSize } = useSizeManager();
    const [zoomIndex, setZoomIndex] = useState(0);
    const [showZoomScale, setShowZoomScale] = useState(false)

    const handleCloseModal = () => {
        setModalImage(undefined)
    }

    const handleZoom = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // Don't zoom on touch (since its used to pan instead)
        if((e.nativeEvent as any).pointerType === "touch")
            return;
    
        const nextZoomIndex = zoomIndex + 1 === zoomScales.length ? 0 : zoomIndex + 1
        setZoomIndex(nextZoomIndex)

        //Trigger fade to show zoom scale
        setShowZoomScale(true)
    }

    const handleZoomTouch = () => {
        // On mobile, use 2x scale by default, since zooming in on click is disabled
        setZoomIndex(1)
    }

    
    const hideZoomScale = () => {
        setShowZoomScale(false)
    }

    // Add listener to image to populate native dimensions for use in calculation later
    const handleImageOpen = (fileDownloadUrl: string | undefined) => {
        const img = new Image();
        img.src = fileDownloadUrl ?? "";
        const listener = function() {
            setNativeDimensions({
                width: img.naturalWidth,
                height: img.naturalHeight
            })
        }
        img.addEventListener("load", listener);
        return () => {
            img.removeEventListener("load", listener)
        }
    }

    // Reset dimensions and zoom
    const handleImageClose = () => {
        setNativeDimensions({width: 0, height: 0})
        setZoomIndex(0)
    }

    const calculateImageDimensions = (width: number, height: number, nativeDimensions: {width: number, height: number}) => {
        // Set image height to max modal height, and calculate width to maintain aspect ratio
        const calcWidth = height * nativeDimensions.width/nativeDimensions.height;
        if(calcWidth <= width) {
            setImageWidth(calcWidth)
            setImageHeight(height)
        }
        else {
            //If calculated width is larger than modal, set image width to max modal width, and calculate height to maintain aspect ratio
            setImageWidth(width)
            setImageHeight(width * nativeDimensions.height/nativeDimensions.width)
        }
    }

    useEffect(() => {
        if(modalImage !== undefined) {
            return handleImageOpen(modalImage.data.fileDownloadUrl)
        }
        else {
            handleImageClose()
        }
    }, [modalImage?.data.fileDownloadUrl])


    // Calculate image dimensions so that it fits the modal
    useEffect(() => {
        if(nativeDimensions.width !== 0 && nativeDimensions.height !== 0 && width !== 0 && height !== 0)
            calculateImageDimensions(width, height, nativeDimensions)
    }, [width, height, nativeDimensions])



    return (
        <>
            <PaperModal show={modalImage !== undefined} setShow={handleCloseModal} updateSize={updateSize}
                transparentBackground
                closeButtonClassName={classes.modalCloseButton}
                disableContainerPadding
                height={"99vh"}
                width={"99vw"}
            >
                <Fade in={showZoomScale} onEntered={hideZoomScale} timeout={{enter: 100, exit: 1500}}>
                    <div className={classes.zoomInfo}>
                        {zoomScales[zoomIndex]}x
                    </div>
                </Fade>
                <HeightLayout className={classes.imageContainer}>
                    <HeightLayoutChild flexDriven ref={sizeRef} >
                        <div onClick={handleZoom} onTouchStart={handleZoomTouch} className={classes.imageContainer}>
                            <ReactImageMagnify
                                className={classes.image}
                                isHintEnabled
                                hintTextTouch='Touch to zoom'
                                pressDuration={0}//0 press duration since we don't need to scroll for this modal
                                style={{ cursor: zoomIndex < zoomScales.length - 1 ? "zoom-in" : "zoom-out"}}
                                smallImage={{
                                    src: modalImage?.data.fileDownloadUrl ?? "",
                                    height: imageHeight,
                                    width: imageWidth,
                                    isFluidWidth: false,
                                    onLoad: updateSize
                                }}
                                largeImage={{
                                    src: modalImage?.data.fileDownloadUrl ?? "",
                                    width: (imageWidth)*zoomScales[zoomIndex],
                                    height: (imageHeight)*zoomScales[zoomIndex]
                                }}
                                enlargedImagePosition='over'
                                hoverDelayInMs={0}
                                fadeDurationInMs={100}
                            
                            />
                        </div>
                    </HeightLayoutChild>
                </HeightLayout>
            </PaperModal>
        </>

    );
}

export default ImageToAnnotateModal;