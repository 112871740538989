import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Paper,
  Grid,
  Typography,
  Collapse,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { SpecialistTemplates } from "@alethea-medical/aletheamd-types";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteButton from "../DeleteButton";
import InternalStateTextField from "../../../../components/InternalStateTextField";
import InsertTemplateButton from "../InsertTemplateButton";
import AddLinks from "../../../../components/AddLinks";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemEditing: {
      padding: theme.spacing(1),
    },
    itemNotEditing: {
      padding: theme.spacing(1),
    },
    expandArea: {
      cursor: "pointer",
    },
    itemText: {
      marginTop: theme.spacing(1),
      fontSize: "1.1em",
    },
    breakWords: {
      whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
      wordWrap: "break-word", //break lines on word to fit
    },
    dragging: {
      backgroundColor: theme.palette.grey[300],
    },
  }),
);

interface ItemProps {
  index: number;
  item: SpecialistTemplates.Item;
  outcomesList: string[];
  deleteItemHandler: (itemId: string, index: number) => void;
  updateItemHandler: (newItem: SpecialistTemplates.Item) => void;
  isEditing: boolean;
  templateInsertHandler: (item: SpecialistTemplates.Item) => void;
  defaultExpand?: boolean;
  disableDrag?: boolean;
}

const Item = ({
  index,
  item,
  outcomesList,
  deleteItemHandler,
  updateItemHandler,
  isEditing,
  templateInsertHandler,
  defaultExpand,
  disableDrag,
}: ItemProps) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(defaultExpand);

  const nameChangeHandler = (name: string) => {
    const newItem = { ...item };
    newItem.name = name;
    updateItemHandler(newItem);
  };

  const textChangeHandler = (text: string) => {
    const newItem = { ...item };
    newItem.text = text;
    updateItemHandler(newItem);
  };

  const diagnosisChangeHandler = (diagnosis: string) => {
    const newItem = { ...item };
    newItem.diagnosis = diagnosis;
    updateItemHandler(newItem);
  };

  const outcomeChangeHandler = (outcome: string | undefined) => {
    const newItem = { ...item };

    // If undefined, delete the entry. Leaving as undefined will cause the firestore write to crash
    if (outcome === undefined) delete newItem.outcome;
    else newItem.outcome = outcome;
    updateItemHandler(newItem);
  };

  return (
    <Draggable draggableId={item.id} index={index} isDragDisabled={!isEditing}>
      {(provided, snapshot) => (
        <Paper
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          className={clsx({
            [classes.itemEditing]: isEditing,
            [classes.itemNotEditing]: !isEditing,
            [classes.dragging]: snapshot.isDragging,
          })}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid {...provided.dragHandleProps} item>
              {isEditing && !disableDrag && <MenuIcon />}
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <InternalStateTextField
                  value={item.name}
                  setValue={nameChangeHandler}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rules={(value: string) =>
                    value === "" ? "Template name cannot be empty." : undefined
                  }
                />
              ) : (
                <Typography>{item.name}</Typography>
              )}
            </Grid>
            <Grid
              item
              xs={true}
              className={classes.expandArea}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
            {isEditing && (
              <Grid item>
                <DeleteButton
                  deleteHandler={deleteItemHandler}
                  id={item.id}
                  index={index}
                  name={item.name}
                  type="item"
                />
              </Grid>
            )}
            {!isEditing && (
              <Grid item>
                <InsertTemplateButton
                  item={item}
                  templateInsertHandler={templateInsertHandler}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Collapse in={expand}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isEditing ? (
                      <>
                        <FormHelperText>Body</FormHelperText>
                        <InternalStateTextField
                          value={item.text}
                          setValue={textChangeHandler}
                          multiline
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          minRows={3}
                        />
                      </>
                    ) : (
                      <>
                        <Divider />
                        <Typography
                          className={clsx(classes.itemText, classes.breakWords)}
                        >
                          {/* Add links add wordBreak: break-all to links only, so that they don't expand this div */}
                          <AddLinks text={item.text} />
                        </Typography>
                      </>
                    )}
                  </Grid>
                  {isEditing && (
                    <>
                      <Grid item xs={12}>
                        <FormHelperText>
                          Diagnosis (eConsult Diagnosis will be prefilled with
                          this value)
                        </FormHelperText>
                        <InternalStateTextField
                          value={item.diagnosis}
                          setValue={diagnosisChangeHandler}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText>
                          Outcome (eConsult Outcome will be prefilled with this
                          value)
                        </FormHelperText>
                        <Select
                          value={item.outcome}
                          onChange={(e) =>
                            outcomeChangeHandler(
                              e.target.value as string | undefined,
                            )
                          }
                          label="Outcome"
                          variant="outlined"
                          fullWidth
                          displayEmpty
                          margin="dense"
                          classes={{
                            root: classes.breakWords,
                          }}
                        >
                          <MenuItem
                            value={undefined}
                            className={classes.breakWords}
                          >
                            <em>None</em>
                          </MenuItem>
                          {outcomesList.map((outcome, i) => (
                            <MenuItem
                              key={`outcome_${i}_${outcome}`}
                              className={classes.breakWords}
                              value={outcome}
                            >
                              {outcome}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

export default Item;
