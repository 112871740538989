import {  Control } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import FormCheckbox from "../../../components/FormInputFields/FormCheckbox";
import EmailInput from "../../../components/FormInputFields/EmailInput";
import PHNInputLookup from "../../../components/FormInputFields/PHNInputLookup";
import RemovePatientButton from "./RemovePatientButton";
import Divider from '@material-ui/core/Divider';

interface PatientRowProps {
    control: Control,
    index: number,
    setValue: (name: string, value: any, options?: any) => void,
    remove: (index?: number | number[] | undefined) => void,
    disabled: boolean
    province?: string
}

const PatientRow = ({control, index, setValue, remove, disabled, province}: PatientRowProps) =>  {

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={10}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <PHNInputLookup 
                                    name={`patients.${index}.phn`}
                                    control={control}
                                    label="PHN"
                                    required
                                    handlePatientInfo={(patientInfo) => {
                                        setValue(`patients.${index}.email`, patientInfo.email, {shouldValidate: true} );
                                    }}
                                    skipServerWarmup={index !== 0}
                                    disabled={disabled}
                                    province={province}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <EmailInput  
                                    name={`patients.${index}.email`}
                                    control={control}
                                    label="Patient Email"
                                    rules={{required: {value: true, message: "Email is required"}}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Tooltip title="Delay sending the form until the next day at 7:00 AM MST"
                                placement="right">
                                    {/* //Wrap in fragment to stop error about refs from being thrown */}
                                    <div>
                                        <FormCheckbox
                                            name={`patients.${index}.delay`}
                                            control={control}
                                            label={"Delay Sending"}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <RemovePatientButton
                            index={index}
                            remove={remove}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    
                </Grid>
            </Grid>
        </>
    );
}

export default PatientRow