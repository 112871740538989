import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Help from "@material-ui/icons/Help";
import isNativeMobile from "src/models/isNativeMobile";

const getFractionalSize = (size: string, fraction: number) => {
  return parseFloat(size) * fraction + "em";
};

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: "relative",
  },
  icon: (props: { size?: string; mobileSize?: string }) => ({
    width: props.size || "2em",
    height: props.size || "2em",
    [theme.breakpoints.down("sm")]: {
      width: props.mobileSize || "2em",
      height: props.mobileSize || "2em",
    },
  }),
  questionMark: (props: {
    size?: string;
    mobileSize?: string;
    questionMarkFraction?: number;
    mobileQuestionMarkFraction?: number;
  }) => ({
    position: "absolute",
    width: getFractionalSize(
      props.size || "2em",
      props.questionMarkFraction || 0.3,
    ),
    height: getFractionalSize(
      props.size || "2em",
      props.questionMarkFraction || 0.3,
    ),
    top: 0,
    right: -10,
    zIndex: 1,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      width: getFractionalSize(
        props.mobileSize || "2em",
        props.mobileQuestionMarkFraction || 0.5,
      ),
      height: getFractionalSize(
        props.mobileSize || "2em",
        props.mobileQuestionMarkFraction || 0.5,
      ),
    },
  }),
  stethoscope: {
    right: -5,
  },
}));

interface AcceptanceFlagsProps {
  acceptanceFlags?: {
    acceptAll?: boolean;
    acceptExisting?: boolean;
    acceptReferral?: boolean;
  };
  size?: string;
  mobileSize?: string;
  questionMarkFraction?: number; // How much smaller the questionMark should be
  mobileQuestionMarkFraction?: number; // How much smaller the questionMark should be on mobile
}

const AcceptanceFlags = ({
  acceptanceFlags,
  size,
  mobileSize,
  questionMarkFraction,
  mobileQuestionMarkFraction,
}: AcceptanceFlagsProps) => {
  const classes = useStyles({
    size,
    mobileSize,
    questionMarkFraction,
    mobileQuestionMarkFraction,
  });

  return (
    <>
      {/* Hide accept All logo for now. May add back in the future. */}
      {/* {acceptanceFlags?.acceptAll && (
        <Grid item>
          <Tooltip title="Will accept eConsults for all patients">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-green-chat.svg"
                alt="Accept All"
                className={classes.icon}
              />
              <Help
                className={classes.questionMark}
                style={!isNativeMobile() ? { right: "-16px" } : {}}
              />
            </div>
          </Tooltip>
        </Grid>
      )} */}
      {acceptanceFlags?.acceptExisting && (
        <Grid item>
          <Tooltip title="Will only accept eConsults for existing patients">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-orange-chat.svg"
                alt="Accept Existing"
                className={classes.icon}
              />
              <Help
                className={classes.questionMark}
                style={!isNativeMobile() ? { right: "-16px" } : {}}
              />
            </div>
          </Tooltip>
        </Grid>
      )}
      {acceptanceFlags?.acceptReferral && (
        <Grid item>
          <Tooltip title="Will accept in-person referrals if necessary through Alethea eConsults">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-stethoscope.svg"
                alt="acceptReferral"
                className={classes.icon}
              />
              <Help
                className={`${classes.questionMark} ${classes.stethoscope}`}
              />
            </div>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

export default AcceptanceFlags;
