import { FieldValues } from "react-hook-form";
import  { TypedControlledInputProps } from "../types";
import FormDateTextField from "./FormDateTextField";

function TypedFormDateTextField<T extends FieldValues> ( props: TypedControlledInputProps<T>) {
    return (
        <FormDateTextField
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export default TypedFormDateTextField;

