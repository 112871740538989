import React from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import TextField from "@material-ui/core/TextField";
import sharedStyles from "../../shared/sharedStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const shared = sharedStyles(theme);
  return {
    ...shared,
  };
});

interface FormTextAreaProps extends ControlledInputProps {
  initRows: number;
}

const FormTextArea = ({
  name,
  control,
  initRows,
  label,
  defaultValue,
  rules,
  disabled,
  errorTextPadding,
  ...rest
}: FormTextAreaProps) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...rest}
          disabled={disabled}
          className={classes.canDisable}
          label={label}
          error={fieldState.error !== undefined}
          helperText={
            errorTextPadding
              ? fieldState.error?.message
                ? fieldState.error.message
                : " "
              : fieldState.error?.message
          }
          minRows={initRows}
          multiline
          maxRows={Infinity}
          margin="dense"
          fullWidth
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            disableAnimation: true,
          }}
          inputProps={{
            "aria-autocomplete": "none",
            list: "autocompleteOff",
          }}
        />
      )}
    />
  );
};

export default FormTextArea;
