import { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { Fcm } from "../../../../../../shared/types";
import * as notificationModel from "../NotificationModel";
import useQueryParamRouting from "../../../../../components/useQueryParamRouting/useQueryParamRouting";
import { AuthContext } from "../../../../../AuthProvider";
import { ErrorHandlerOptions } from "@alethea-medical/alethea-components";

interface NotificationViewControllerProps {
    updateNotification: (id: string, notificationUpdate: Fcm.UserNotification) => void,
    errorHandler: (options: ErrorHandlerOptions) => void
}

const NotificationViewController = ({ updateNotification, errorHandler }: NotificationViewControllerProps) => {
    
    const authContext = useContext(AuthContext)
    const [openNotification, setOpenNotification] = useState<Fcm.UserNotification | undefined>(undefined)

    const { addOrRemoveFromQueryParams, currentValue: notificationId } = useQueryParamRouting({ paramName: "campaignId" })

    useEffect(() => {
        if(authContext.uid !== "" && notificationId !== undefined) {
            notificationModel.loadNotification(authContext.uid, notificationId)
            .then((item) => {
                setOpenNotification(item.notification)
            })
            .catch((error: Error) => {
                setOpenNotification(undefined)
                addOrRemoveFromQueryParams(undefined)
                errorHandler({
                    error: error,
                    userMessage: "Unable to load notification"
                })
            })
        }
        else {
            setOpenNotification(undefined)
        }
    }, [authContext.uid, notificationId])

    useEffect(() => {
        if(notificationId !== undefined && openNotification !== undefined) {
            markAsRead(notificationId, openNotification)
        }
    }, [openNotification])

    const markAsRead = (notificationId: string, notification: Fcm.UserNotification) => {
        // Manually update the state
        if(notification.read === false) {
            updateNotification(notificationId, {...notification, read: true, readAt: firebase.firestore.Timestamp.now() })
            notificationModel.markAsRead(notificationId)
            .catch((error: Error) => {
                // Undo state modification on error
                updateNotification(notificationId, {...notification, read: false })
                // Fail silently for marking as read
                console.error(`Unable to mark notification as read: ${error}`)
            })
        }
    }

    return {
        openNotification
    }
}

export default NotificationViewController;