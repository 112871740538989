import { ServiceItem, isServiceOnVacation } from '../../../../db/Service';
import ServiceCard from './ServiceCard';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useConsultFormStyles from '../useConsultFormStyles';
import { FormHelperText } from '@material-ui/core';

interface selectedServiceProps {
    selectedService: ServiceItem;
    goBackToResults: () => void;
}

const selectedService = ({ selectedService, goBackToResults }: selectedServiceProps) => {

    const classes = useConsultFormStyles()
    const isOnVacation = isServiceOnVacation(selectedService.service)
    return (
        <div>
            <div className={classes.spacing}>
                <Button variant="outlined" color="primary" startIcon={<ChevronLeftIcon/>} onClick={goBackToResults}>
                    Back to Results
                </Button>
            </div>
            <div className={classes.spacing}>
                <ServiceCard serviceId={selectedService.id} service={selectedService.service}/>
            </div>
            <div className={classes.spacing}>
                <FormHelperText error>
                    {isOnVacation ? "Service is not available. Please save as draft and wait for the service to be available again, or select another service." : "" }
                </FormHelperText>
            </div>
        </div>
    );
}

export default selectedService;