import { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import DropdownMenuButton from "../../../../components/DropdownMenuButton";
import ConsultStatus, {  defaultConsultStatuses } from "./ConsultStatus";
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import palette from "../../../../palette";
import { Activity, Clinic } from "../../../../../shared/types";
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { fbFirestore, fbFunctions, logAnalyticsEvent } from "../../../../firebase";
import analyticsLogs from "../../../../analyticsLogs";
import usePermissions from "../../../../components/usePermissions";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { getDocumentData } from "@alethea-medical/utilities";
import { ClaimsContext } from "../../../../config/ClaimsProvider";

interface ConsultStatusDropdownButtonProps {
    viewerRole: Activity.ActivityRole.ActivityRole,
}


const ConsultStatusDropdownButton = ({ viewerRole, ...rest }: ConsultStatusDropdownButtonProps) => {
    
    const changeStatus = fbFunctions.httpsCallable("activity-changeStatus_v1")

    const { activityId, metadataActivity, sharedActivity } = useContext(ActivityContext)
    const { userClaims } = useContext(ClaimsContext);

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const [permissions, setPermissions] = useState<"none" | "viewer" | "editor">("none");
    const { granted: hasEditStatusPermissions } = usePermissions({ resourceKey: resourceKeys.editActivityStatus })
    const [isConsultantMoa, setIsConsultantMoa] = useState<boolean>(false)

    useEffect(() => {
        if(viewerRole === "moa" && userClaims?.clinicId !== undefined) {
            setProcessState(ProcessState.running)
            fbFirestore.collection(dbNames.clinics).doc(userClaims?.clinicId).get().then(getDocumentData).then((clinic: Clinic.Clinic) => {
                // Check that at least one consultant on the activity is a physician in the MOA's clinic
                return clinic.physicians.some((physicianId) => {
                    return sharedActivity.profiles[physicianId]?.activityRole === "consultant"
                })
            })
            .then((result) => {
                setProcessState(ProcessState.idle)
                setIsConsultantMoa(result)
            })
            .catch((error: Error) => {
                errorHandler({
                    error,
                    userMessage: "There was an error checking your edit status permissions. Please try again later",
                })
            })
        }
    }, [viewerRole])

    useEffect(() => {
        if(viewerRole === "consultant" && hasEditStatusPermissions) {
            setPermissions("editor")
        }
        else if(isConsultantMoa && hasEditStatusPermissions) {
            setPermissions("editor")
        }
        else if(metadataActivity.status !== undefined) {
            setPermissions("none")
        }
        else {
            setPermissions("none")
        }
    }, [viewerRole, isConsultantMoa, hasEditStatusPermissions])

    const onClickStatus = (status: string) => {

        setProcessState(ProcessState.running)
        changeStatus({ activityId, status })
        .then(() => {
            logAnalyticsEvent(analyticsLogs.secureMessaging.status.changeStatus)
            setProcessState(ProcessState.idle)

        })
        .catch((error) => {
            errorHandler({
                error,
                userMessage: "There was an error changing the status of this consult. Please try again later",
                analyticsLog: analyticsLogs.secureMessaging.status.changeStatusFail
            })
        })
    }

    switch(permissions) {
        case "none":
            return null;
        case "viewer":
            // Status shouldn't be undefined if viewer, but add in check anyway
            return (
                <>
                    <ConsultStatus viewerRole={viewerRole} status={metadataActivity.status ?? "no_status"}/>
                    <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar/>
                </>
            )
        case "editor":
            return (
                <>
                    <DropdownMenuButton 
                        style={{
                            minWidth: "180px", 
                        }}
                        autoClose={false}
                        customButton={
                            <ConsultStatus viewerRole={viewerRole} status={metadataActivity.status ?? "no_status"} endIcon={<ArrowDownIcon/>} />
                        }
                        {...rest}>
                        {defaultConsultStatuses.order.map((status, i) => 
                            <DropdownMenuItem 
                                key={`status_dropdown_${status}_${i}`}
                                style={{
                                    minWidth: "200px", 
                                    color: palette.darkGreen,
                                    backgroundColor: metadataActivity.status === status ? "#e0e0e0" : undefined,
                                }}
                                label={defaultConsultStatuses.statuses[status].label} 
                                onClick={(e) => { onClickStatus(status)}}
                                disabled={status === metadataActivity.status}
                            />
                        )}
                        {/* Only show loading bar */}
                        <ProcessStatus state={processState === ProcessState.running ? ProcessState.running : ProcessState.idle}/>
                        <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} loadingMessage="Changing status..." useSnackbar/>
                    </DropdownMenuButton>
                    {/* Show errors here */}
                </>  
            );
    }
    
}

export default ConsultStatusDropdownButton;