import React from "react";
import SpecialistTemplatesView from '../../SpecialistTemplates/Views/SpecialistTemplatesView';
import isMobileDevice from "../../../../models/isMobileDevice";


const SpecialistTemplatesPullout = () => {

    return (
        <SpecialistTemplatesView alwaysEnableEditing={false} disableDrag={isMobileDevice()}/>
    )
}

export default SpecialistTemplatesPullout;