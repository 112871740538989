
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import { IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { useState } from 'react';
import isNativeMobile from '../../../../models/isNativeMobile';
import isAndroid from '../../../../models/isAndroid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        copyButton: {
            position: "absolute", // make sure to set parent to relative position
            top: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 101 //Message z index is 100, so put this above message
        },
        smallIcon: {
            fontSize: "1rem"
        }
    }),
);

interface CopyToClipboardProps {
    formattedText: string
}

const CopyToClipboard = ( { formattedText }: CopyToClipboardProps) => {

    const classes = useStyles();

    const [ snackbarOpen, setSnackbarOpen ] = useState(false);

    const copyToClipHandler = () => {
        navigator.clipboard.writeText(formattedText)
        setSnackbarOpen(true)
    }

    if(isAndroid())
        return null;
    else
        return (
            <>
                <IconButton 
                    size="small"
                    className={classes.copyButton}
                    onClick={() => copyToClipHandler()}
                    color="primary"
                >
                    {
                        snackbarOpen ? 
                        <CheckIcon className={classes.smallIcon}/>
                        :
                        <FileCopyIcon className={classes.smallIcon}/>
                    }
                </IconButton>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={() => {setSnackbarOpen(false)}}
                    message="Copied to clipboard"
                />

            </>
        )
};

export default CopyToClipboard;
