import React, { useContext, useEffect, useState } from "react";
import { SpecialistTemplates } from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import * as model from "./SpecialistTemplatesModel";
import { DropResult } from "react-beautiful-dnd";
import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { logAnalyticsEvent } from "../../../firebase";
import analyticsLogs from "../../../analyticsLogs";
import { outcomes as econsultOutcomes } from "../SecureMessaging/SpecialistTools/Outcomes/EconsultOutcomes/EconsultOutcomesModal";
import { outcomes as dentistOutcomes } from "../SecureMessaging/SpecialistTools/Outcomes/DentistOutcomes/DentistOutcomesModal";
import usePermissions from "../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";

interface SpecialistTemplatesControllerProps {}
const SpecialistTemplatesController =
  ({}: SpecialistTemplatesControllerProps) => {
    const authContext = useContext(AuthContext);
    const [categories, setCategories] =
      useState<SpecialistTemplates.CategoryDict>({});
    const [categoryOrder, setCategoryOrder] = useState<string[]>([]);
    const [items, setItems] = useState<SpecialistTemplates.ItemDict>({});
    const [savedCategories, setSavedCategories] =
      useState<SpecialistTemplates.CategoryDict>({});
    const [savedCategoryOrder, setSavedCategoryOrder] = useState<string[]>([]);
    const [savedItems, setSavedItems] = useState<SpecialistTemplates.ItemDict>(
      {},
    );

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [outcomesList, setOutcomesList] = useState<string[]>([]);

    const { processState, setProcessState, processErrorMessage, errorHandler } =
      useProcessState({ logAnalyticsEvent });

    const initialLoad = (specialistUid: string) => {
      //add process state
      setProcessState(ProcessState.running);

      model
        .getSpecialistTemplates(specialistUid)
        .then((specialistTemplates) => {
          setProcessState(ProcessState.idle);

          setCategories(specialistTemplates.categories);
          setCategoryOrder(specialistTemplates.categoryOrder);
          setItems(specialistTemplates.items);

          // Set saved state to deep copy of initial state
          setSavedCategories(
            JSON.parse(JSON.stringify(specialistTemplates.categories)),
          );
          setSavedCategoryOrder(
            JSON.parse(JSON.stringify(specialistTemplates.categoryOrder)),
          );
          setSavedItems(JSON.parse(JSON.stringify(specialistTemplates.items)));

          //Wait for state to propagate, then set first load to false
          setTimeout(() => {
            setFirstLoad(false);
          }, 100);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error fetching your templates",
            analyticsLog: analyticsLogs.specialistTemplates.fetchFail,
          });
        });
    };

    const saveTemplates = () => {
      const templateToSave: SpecialistTemplates.Template = {
        items,
        categories,
        categoryOrder,
      };
      setProcessState(ProcessState.running);
      model
        .saveSpecialistTemplates(authContext.uid, templateToSave)
        .then(() => {
          setProcessState(ProcessState.success);
          logAnalyticsEvent(analyticsLogs.specialistTemplates.save);
          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 1000);

          // Update saved state to deep copy of current state
          setSavedCategories(JSON.parse(JSON.stringify(categories)));
          setSavedCategoryOrder(JSON.parse(JSON.stringify(categoryOrder)));
          setSavedItems(JSON.parse(JSON.stringify(items)));
          // Remove unsaved changes flag
          setUnsavedChanges(false);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error saving changes. Please try again.",
            analyticsLog: analyticsLogs.specialistTemplates.saveFail,
          });
        });
    };

    const discardChanges = () => {
      // Revert state back to deep copy of the saved state
      setCategories(JSON.parse(JSON.stringify(savedCategories)));
      setCategoryOrder(JSON.parse(JSON.stringify(savedCategoryOrder)));
      setItems(JSON.parse(JSON.stringify(savedItems)));

      // Remove unsaved changes flag, wait for propagation
      setTimeout(() => {
        setUnsavedChanges(false);
      }, 100);
    };

    // ID must be unique across items and categories
    // The actual ID doesn't matter, as long as it is unique. Using name as a starting point for a unique ID
    const createUniqueID = (name: string): string => {
      let id = `${name.toLowerCase()}`;
      let idx = 0;
      while (categories[id] !== undefined || items[id] !== undefined) {
        id = `${name.toLowerCase()}_${idx}`;
        idx += 1;
      }
      return id;
    };

    const updateCategoryHandler = (category: SpecialistTemplates.Category) => {
      const newCategories = { ...categories };
      newCategories[category.id] = category;
      setCategories(newCategories);
    };

    const addCategoryHandler = (name: string) => {
      // create id
      const categoryId = createUniqueID(name);
      const newCategory: SpecialistTemplates.Category = {
        id: categoryId,
        name: name,
        itemIds: [],
      };
      //Add to dictionary
      const newCategories = { ...categories };
      newCategories[categoryId] = newCategory;
      setCategories(newCategories);

      //Add to bottom of order list
      const newCategoryOrder = [...categoryOrder];
      newCategoryOrder.push(categoryId);
      setCategoryOrder(newCategoryOrder);
    };

    const deleteCategoryHandler = (categoryId: string, index: number) => {
      //Delete items that are contained within this category
      const newItems = { ...items };
      const itemIds = categories[categoryId].itemIds;
      itemIds.forEach((id) => {
        delete newItems[id];
      });
      setItems(newItems);

      //Remove from dictionary
      const newCategories = { ...categories };
      delete newCategories[categoryId];
      setCategories(newCategories);

      //Remove id from order list
      const newCategoryOrder = [...categoryOrder];
      newCategoryOrder.splice(index, 1);
      setCategoryOrder(newCategoryOrder);
    };

    const updateItemHandler = (item: SpecialistTemplates.Item) => {
      const newItems = { ...items };
      newItems[item.id] = item;
      setItems(newItems);
    };

    const addItemHandler = (name: string, categoryId: string) => {
      // create id
      const itemId = createUniqueID(name);
      const newItem: SpecialistTemplates.Item = {
        id: itemId,
        name: name,
        text: "",
      };
      //Add new item to item dictionary
      const newItems = { ...items };
      newItems[itemId] = newItem;
      setItems(newItems);

      //Add item to category it was created in
      const newCategories = { ...categories };
      newCategories[categoryId].itemIds.push(itemId);
      setCategories(newCategories);
    };

    const deleteItemHandler = (
      itemId: string,
      index: number,
      categoryId: string,
    ) => {
      //Delete from dictionary
      const newItems = { ...items };
      delete newItems[itemId];
      setItems(newItems);

      //Delete item from category list
      const newCategories = { ...categories };
      const newItemIds = [...newCategories[categoryId].itemIds];
      newItemIds.splice(index, 1);
      newCategories[categoryId].itemIds = newItemIds;
      setCategories(newCategories);
    };

    const reorderCategoryHandler = (
      sourceIdx: number,
      destIdx: number,
      categoryId: string,
    ) => {
      //Remove ID in order list, and move it to new index
      const newCategoryOrder = [...categoryOrder];
      newCategoryOrder.splice(sourceIdx, 1);
      newCategoryOrder.splice(destIdx, 0, categoryId);
      setCategoryOrder(newCategoryOrder);
    };

    const reorderItemHandler = (
      sourceIdx: number,
      destIdx: number,
      sourceCategoryId: string,
      destCategoryId: string,
      itemId: string,
    ) => {
      //Create copy of categories
      const newCategories = { ...categories };

      //Remove item from original list
      const newSourceItemOrder = [...newCategories[sourceCategoryId].itemIds];
      newSourceItemOrder.splice(sourceIdx, 1);
      //If we aren't moving categories, then add item to the source list in the right spot
      if (sourceCategoryId === destCategoryId) {
        newSourceItemOrder.splice(destIdx, 0, itemId);
      } else {
        //Add to new list
        const newDestItemOrder = [...newCategories[destCategoryId].itemIds];
        newDestItemOrder.splice(destIdx, 0, itemId);
        //Update destination category item order
        newCategories[destCategoryId].itemIds = newDestItemOrder;
      }
      //Update source destination category item order
      newCategories[sourceCategoryId].itemIds = newSourceItemOrder;

      //Update state to with new orders
      setCategories(newCategories);
    };

    const onDragEndHandler = (result: DropResult) => {
      const { source, destination, draggableId, type } = result;

      //Dragged out of a droppable zone, return
      if (destination === undefined || destination === null) return;

      //Didn't move at all, return
      if (
        source.index === destination.index &&
        source.droppableId === destination.droppableId
      )
        return;

      if (type === "category") {
        reorderCategoryHandler(source.index, destination.index, draggableId);
      } else if (type === "item") {
        reorderItemHandler(
          source.index,
          destination.index,
          source.droppableId,
          destination.droppableId,
          draggableId,
        );
      }
    };

    useEffect(() => {
      if (authContext.uid !== "") {
        initialLoad(authContext.uid);
      }
    }, [authContext.uid]);

    useEffect(() => {
      //Don't show unsaved changes if loading for the first time (nothing will have been changed)
      if (!firstLoad) {
        setUnsavedChanges(true);
      }
    }, [categories, categoryOrder, items]);

    // Set outcomes list to use when editing templates based on if specialist is MD or Dentist
    const { granted: hasEconsultOutcomePermissions } = usePermissions({
      resourceKey: resourceKeys.econsultOutcomes,
    });
    const { granted: hasDentistOutcomesPermissions } = usePermissions({
      resourceKey: resourceKeys.dentistOutcomes,
    });

    useEffect(() => {
      if (hasEconsultOutcomePermissions) {
        setOutcomesList(econsultOutcomes);
      }
    }, [hasEconsultOutcomePermissions]);

    useEffect(() => {
      if (hasDentistOutcomesPermissions) {
        setOutcomesList(dentistOutcomes);
      }
    }, [hasDentistOutcomesPermissions]);

    return {
      // state
      categories,
      categoryOrder,
      items,
      outcomesList,
      // handlers
      addCategoryHandler,
      deleteCategoryHandler,
      updateCategoryHandler,
      addItemHandler,
      deleteItemHandler,
      updateItemHandler,
      onDragEndHandler,
      // saving
      saveTemplates,
      discardChanges,
      unsavedChanges,
      processState,
      processErrorMessage,
      setProcessState,
    };
  };

export default SpecialistTemplatesController;
