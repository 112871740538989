import { useMobileSizes } from "@alethea-medical/alethea-components";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SaveIcon from "@material-ui/icons/Save";
import TocIcon from "@material-ui/icons/Toc";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import { Activity } from "../../../../../shared/types";
import ToggleButton from "../../../../components/ToggleButton";
import sortProfilesByActivityRole from "../../../../models/sortProfilesByActivityRole";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  }),
);

interface MessagesHeaderProps {
  sharedActivity: Activity.Activity;
  showTemplatesButton: boolean;
  showTemplates: boolean;
  setShowTemplates: (show: boolean) => void;
  showOutcomesButton: boolean;
  showOutcomesMenu: boolean;
  setShowOutcomesMenu: (show: boolean) => void;
  showSaveMessageDraftButton: boolean;
  saveMessageDraft: () => void;
  onGoBack: () => void;
  showResourcesMenu: boolean;
  setShowResourcesMenu: (show: boolean) => void;
  showResourcesButton: boolean;
}

const MessagesHeader = ({
  sharedActivity,
  showTemplatesButton,
  showTemplates,
  setShowTemplates,
  showOutcomesButton,
  showOutcomesMenu,
  setShowOutcomesMenu,
  showSaveMessageDraftButton,
  saveMessageDraft,
  onGoBack,
  showResourcesButton,
  showResourcesMenu,
  setShowResourcesMenu,
}: MessagesHeaderProps) => {
  const classes = useStyles();
  const isMobileSize = useMobileSizes();

  const toggleTemplates = () => {
    setShowTemplates(!showTemplates);
  };

  const toggleOutcomesMenu = () => {
    setShowOutcomesMenu(!showOutcomesMenu);
  };

  const toggleResourcesMenu = () => {
    setShowResourcesMenu(!showResourcesMenu);
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={showOutcomesButton && isMobileSize ? 12 : 4}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          {isMobileSize && (
            <Grid item>
              <Button onClick={onGoBack} startIcon={<ChevronLeftIcon />}>
                Back to Consult
              </Button>
            </Grid>
          )}

          <Grid item>
            <Typography>
              {Object.values(sharedActivity.profiles)
                .filter(
                  (activityUser): activityUser is Activity.ActivityUser =>
                    activityUser !== undefined,
                )
                .sort(sortProfilesByActivityRole)
                .map((profile) => `${profile.firstName} ${profile.lastName}`)
                .join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={showOutcomesButton && isMobileSize ? 12 : 8}>
        <Grid
          container
          justifyContent={
            showOutcomesButton && isMobileSize ? "flex-start" : "flex-end"
          }
          alignItems="center"
          spacing={1}
        >
          {showOutcomesButton && (
            <Grid item>
              <ToggleButton
                startIcon={<ViewAgendaIcon />}
                toggleState={showOutcomesMenu}
                onClick={toggleOutcomesMenu}
              >
                Edit Outcomes
              </ToggleButton>
            </Grid>
          )}
          {showTemplatesButton && (
            <Grid item>
              <ToggleButton
                startIcon={<TocIcon />}
                toggleState={showTemplates}
                onClick={toggleTemplates}
              >
                Templates
              </ToggleButton>
            </Grid>
          )}
          {showSaveMessageDraftButton && (
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={saveMessageDraft}
                startIcon={<SaveIcon />}
              >
                Save Message
              </Button>
            </Grid>
          )}
          {showResourcesButton && (
            <Grid item>
              <ToggleButton
                startIcon={<LibraryBooksIcon />}
                toggleState={showResourcesMenu}
                onClick={toggleResourcesMenu}
              >
                Resources
              </ToggleButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MessagesHeader;
