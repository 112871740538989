import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useConsultFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        /** Use no vertical padding on container, so that height layout in gallery view works properly */
        container: {
            padding: theme.spacing(0, 1)
        },
        /** Add vertical padding to form */
        form: {
            padding: theme.spacing(1, 0)
        },
        /** Generic spacing class */
        spacing: {
            margin: theme.spacing(1, 0)
        },
        /** Hide element, and don't keep in layout (used when hiding gallery or form depending on view) */
        displayNone: {
            display: "none"
        },
        /** Adds horizontal margin */
        leftMargin: {
            marginLeft: theme.spacing(1)
        },
    })
)

export default useConsultFormStyles