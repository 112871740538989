import { FieldValues } from 'react-hook-form';
import { TypedControlledInputProps } from '../types';
import FormSelectAutocomplete from './FormSelectAutocomplete';

interface TypedFormSelectAutocompleteProps<T extends FieldValues> extends TypedControlledInputProps<T> {
    options: any[],
    enabledOptions?: string[],
    sortByEnabled?: boolean
}

function TypedFormSelectAutocomplete<T extends FieldValues> ( props: TypedFormSelectAutocompleteProps<T>) {
    
    return (
        <FormSelectAutocomplete
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export default TypedFormSelectAutocomplete;