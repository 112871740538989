import React, { useEffect }  from 'react';
import Grid from '@material-ui/core/Grid';
import { Control, UseFormTrigger, useWatch } from 'react-hook-form';
import FormTextField from '../FormTextField';

interface FormNewPasswordProps {
    control: Control,
    trigger: UseFormTrigger<any>,
 
}

const FormNewPassword = ({ control, trigger }: FormNewPasswordProps) => {

    const passwordWatch = useWatch({control: control, name: "password", defaultValue: ""});
    const confirmPasswordWatch = useWatch({control: control, name: "confirmPassword", defaultValue: ""});

    useEffect(() => {
        if(confirmPasswordWatch !== "") 
            trigger("confirmPassword")
    }, [passwordWatch])


    const doPasswordsMatch = (confirmPassword: string) => {
        return confirmPassword === passwordWatch ? undefined : "Passwords do not match" 
    }

    const isPasswordValid = (password: string) => {
        return password.length >= 6 ? undefined : "Password must be 6 or more characters"   
    }


    return (
        <>
            <Grid item xs={12}>
                <FormTextField
                    name="password"
                    control={control}
                    label="Password"
                    rules={{required: {value: true, message: "Password is required"}, validate: {
                        isPasswordValid: isPasswordValid
                    }}}
                    {...{type: "password", inputProps: { autocomplete: 'new-password', form: {autocomplete: "new-password"}}}}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name="confirmPassword"
                    control={control}
                    label="Confirm Password"
                    rules={ {
                        required: { value: true, message: "Passwords do not match" }, validate: {
                            doPasswordsMatch: doPasswordsMatch
                        }
                    } }
                    {...{type: "password", inputProps: { autocomplete: 'new-password', form: {autocomplete: "new-password"}}}}
                />
            </Grid>
        </>
    );
}

export default FormNewPassword;