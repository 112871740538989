import Grid from '@material-ui/core/Grid';
import FormattedInputLabel from '../../../../../components/FormattedInputLabel';
import { TypedFormTextField } from '../../../../../components/FormInputFields/FormTextField';
import { Control, useFieldArray } from 'react-hook-form';
import { TypedEmailInput } from 'src/components/FormInputFields/EmailInput';
import { Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import RoundedButton from 'src/components/RoundedButton';
import { useOffsetEmailInputErrorTextStyles } from 'src/components/FormInputFields/EmailInput/EmailInput';
import SectionContainer from '../../Helpers/Container/SectionContainer';
import { SignupInfo } from '@alethea-medical/aletheamd-types';

interface PreceptorInformationProps {
    control: Control<SignupInfo.SignupInfo>,
    disabled?: boolean,
    number: number
}

const PreceptorInformation = ({ control, disabled, number }: PreceptorInformationProps) => {
    const classes = useOffsetEmailInputErrorTextStyles();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "preceptorInformation"
    });

    const addRowHandler = () => {
        append({ preceptorName: '', preceptorEmail: '' });
    }

    const removeRowHandler = (i: number) => {
        remove(i);
    }

    return (

        <SectionContainer title="Preceptor Information" number={number}>
            <Grid container spacing={4}>
                {fields.map((field, index) => (
                    <Grid item container spacing={2} alignItems="flex-end" key={field.id}>
                        <Grid item xs={12} md={6} lg={5} >
                            <FormattedInputLabel>Preceptor Name</FormattedInputLabel>
                            <TypedFormTextField
                                className={classes.flexAlignedEnd}
                                control={control}
                                name={`preceptorInformation.${index}.preceptorName`}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={5} >
                            <FormattedInputLabel>Preceptor E-Mail Address</FormattedInputLabel>
                            <TypedEmailInput
                                control={control}
                                name={`preceptorInformation.${index}.preceptorEmail`}
                                disabled={disabled}
                                errorTextPadding
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <RoundedButton
                                style={{ width: '100%' }}
                                className={classes.flexAlignedEnd}
                                startIcon={<RemoveIcon />}
                                onClick={() => removeRowHandler(index)}
                                size='medium'
                            >
                                Remove
                            </RoundedButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant='middle' />
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                    <RoundedButton style={{ width: '100%' }} startIcon={<AddIcon />} onClick={addRowHandler}>Add Preceptor</RoundedButton>
                </Grid>
            </Grid>
        </SectionContainer>
    )
}
export default PreceptorInformation;