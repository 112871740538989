import React, { useState, useEffect } from "react";
import { fbFirestore, fbStorage, logAnalyticsEvent } from "../../../firebase";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import firebase from "firebase";
import { AISpecialty, UnlabelledImage } from "../../../../shared/types";
import Grid from '@material-ui/core/Grid';
import ImageAnnotator from "../../../components/ImageAnnotator";
import { DisplayImage } from "../../../components/ImageAnnotator/ImageAnnotator";
import { useProcessState, PaperPage, ProcessState} from "@alethea-medical/alethea-components";
import useAITranslation from "../../../components/useAITranslation";

const ImageAnnotation = () => {
    const batchSize = 100;
    const [imagesToShow, setImagesToShow] = useState<DisplayImage[]>([]);
    const [pointer, setPointer] = useState<number>(0);
    const [labelFilter, setLabelFilter] = useState<string>('');
    const [userSpecialty, setUserSpecialty] = useState<AISpecialty>();


    const [lastSnapshot, setLastSnaphot] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>>();

    const { processState: loadImageState, setProcessState: setLoadImageState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const { spToAi, aiToSp, spLabelList } = useAITranslation({useSpToAi: true, useSpLabelList: true})


    const fetchNewImages = () => {
        if (userSpecialty !== undefined) {
            setLoadImageState(ProcessState.running);

            let query = fbFirestore.collection("unlabelled_images").doc(userSpecialty).collection("images").orderBy("created", "asc")
            if (labelFilter !== '') {
                query = query.where('aiPrediction', "array-contains", labelFilter)
            }
            if (lastSnapshot !== undefined) {//Paginate query
                query = query.startAfter(lastSnapshot);
            }

            return query.limit(batchSize).get()
                .then((querySnapshot) => {
                    setLastSnaphot(querySnapshot.docs[querySnapshot.size - 1]);

                    return Promise.all(querySnapshot.docs.map((doc) => {
                        const data: UnlabelledImage = doc.data() as UnlabelledImage;

                        // Remove duplicate labels (this happened on some images because of a bug that used the first label in the list as all the labels if an image had multiple labels)
                        const cleanedPrediction: string[] = []
                        data.aiPrediction.forEach((pred) => {
                            if(!cleanedPrediction.includes(pred))
                                cleanedPrediction.push(pred)
                        })
                        data.aiPrediction = cleanedPrediction

                        const fileUri = `images/ai_images/${data.filename}`;
                        const storageRef = fbStorage.ref(fileUri);
                        const displayImage: DisplayImage = {
                            ...data,
                            fileUri: fileUri,
                            src: "",
                            uid: doc.id,
                            doctorLabels: [],
                            specialty: userSpecialty
                        }

                        return storageRef.getDownloadURL()
                            .then((src: string) => {
                                displayImage.src = src;
                                return displayImage;
                            })
                            .catch((error: Error) => {
                                console.error(error);
                                return displayImage;
                            })
                    }));
                })
                .then((imageList) => {
                    setImagesToShow(imageList.filter((image) => image.src !== ""));
                    setLoadImageState(ProcessState.idle);
                    setPointer(0);
                })
                .catch((error: Error) => {
                    console.error(error);
                    errorHandler({
                        error: error,
                        userMessage: "Error loading images"
                    })
                })
        }
        else {
            return Promise.resolve();
        }
    }


    useEffect(() => {
        fetchNextBatch();
    }, [labelFilter, userSpecialty]);

    const fetchNextBatch = () => {
        setLastSnaphot(undefined)
        return fetchNewImages();
    }


    return (
        <PaperPage enablePadding>
            <Grid container> 
                <Grid item xs={6}>
                    <Autocomplete
                        autoComplete
                        autoSelect
                        options={userSpecialty ? spLabelList[userSpecialty] : []}
                        getOptionLabel={(option: string) => option}
                        onChange={(e: any, v: any) => { setLabelFilter(spToAi(v ? v : "")); }}                        
                        value={aiToSp(labelFilter, true)}
                        renderInput={(params: any) => <TextField {...params} label="Filter by Condition" margin="dense" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ImageAnnotator imagesToShow={imagesToShow} setImagesToShow={setImagesToShow} pointer={pointer} setPointer={setPointer}
                        userSpecialty={userSpecialty} setUserSpecialty={setUserSpecialty}
                        loadImageState={loadImageState} loadImageError={processErrorMessage}
                        fetchNextBatch={fetchNextBatch}
                        loadAIState={loadImageState} loadAIError={processErrorMessage}
                        showSpecialtyChange
                        />
                </Grid>
            </Grid>     
        </PaperPage>
    );
}

export default ImageAnnotation;