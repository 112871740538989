import React from 'react';
import Grid from '@material-ui/core/Grid';
import RoundedButton from '../../../../../components/RoundedButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sharedStyles from '../../../../../sharedStyles';
import Typography from '@material-ui/core/Typography';
import strings from '../../Common/strings';
import palette from '../../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
        title: {
            color: palette.darkGreen,
            fontSize: "2em"
        }
    })
)

interface NoPlanErrorPageProps {
    title: React.ReactNode
}


const NoPlanErrorPage = ({ title }: NoPlanErrorPageProps) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <a 
                    className={classes.unstyledLink}
                    href="https://aletheamedical.com/sign-up"
                    rel="noreferrer noopener"
                >
                    <RoundedButton>Pick a Plan</RoundedButton>
                </a>
            </Grid>
        </Grid>
    );
}

export default NoPlanErrorPage;