import firebase from 'firebase';
import { NotificationItem } from './NotificationCenterController';
import { fbFirestore, fbFunctions } from '../../../../firebase';
import { dbNames } from '@alethea-medical/aletheamd-db-keys';
import { Fcm } from "@alethea-medical/aletheamd-types";

const fcm_markAsRead = fbFunctions.httpsCallable("fcm-markNotificationAsRead_v1");

export function loadNotification(uid: string, notificationId: string): Promise<NotificationItem> {
    return fbFirestore.collection(dbNames.notifications).doc(uid).collection(dbNames.notifications_userNotifications).doc(notificationId).get().then((doc) => {
        if(!doc.exists) return Promise.reject(new Error("Notification does not exist"));
        const notification = doc.data() as Fcm.UserNotification | undefined;
        if(notification === undefined) return Promise.reject(new Error("Notification does not exist"));
        return {
            id: doc.id,
            notification
        }
    })
}

export function loadNotifications(uid: string, fetchEarlierThan: firebase.firestore.Timestamp, amountToLoad: number): Promise<{ results: NotificationItem[], didReturnResults: boolean }>
{
    return fbFirestore.collection(dbNames.notifications).doc(uid).collection(dbNames.notifications_userNotifications).where("sentAt", "<", fetchEarlierThan).orderBy("sentAt", "desc").limit(amountToLoad).get().then((querySnapshot) => {
        const results: NotificationItem[] = [];
        querySnapshot.forEach((doc) => {
            if(!doc.exists) return;
            const notification = doc.data() as Fcm.UserNotification | undefined;
            if(notification === undefined) return;
            results.push({
                id: doc.id,
                notification
            })
        });
        return { results: results, didReturnResults: results.length >= amountToLoad }
    })      
}

export function markAllAsRead(): Promise<void> {
    return fcm_markAsRead({ })
    .then(() => {
        return;
    })
}

export function markAsRead(campaignId: string): Promise<void> {
    return fcm_markAsRead({ campaignIds: [campaignId] })
    .then(() => {
        return;
    })
}


