import { formatTimeslot } from '../../Models/SpecialistScheduleModel';
import TimeRow, { isTimeRowMajor } from './TimeRow';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        column: {
            paddingLeft: theme.spacing(1)
        },
        timeText: {
            // Disable text select
            "-webkit-user-select": "none", /* Safari */
            "-ms-user-select": "none", /* IE 10 and IE 11 */
            "user-select": "none" /* Standard syntax */
        }
    })
)

interface CalendarTimeColumnProps {
    times: string[]
}

const CalendarTimeColumn = ({ times }: CalendarTimeColumnProps) => {
    const classes = useStyles();

    const scrollToRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if(scrollToRef.current !== null) {
            scrollToRef.current.scrollIntoView({behavior: "smooth"})
        }
    }, [scrollToRef.current])

    return (
        <div className={classes.column}>
            {times.map((time, index) => 
                <TimeRow key={`time_column_row_${time}`} time={time} index={index}>
                    <span ref={time === "0700" ? scrollToRef : undefined} className={classes.timeText}>{isTimeRowMajor(index) ? formatTimeslot(time) : ""}</span>
                </TimeRow>
            )}
        </div>
    );
}

export default CalendarTimeColumn;