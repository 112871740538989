import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MessagingGallery from '../../../components/Gallery/Views/MessagingGallery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(1)  
        },
        root: {
            padding: theme.spacing(1, 2)
        }
    }),
);


interface AttachmentViewerProps {
    messageMediaURIs: string[]
}

const AttachmentViewer = ({ messageMediaURIs }: AttachmentViewerProps) => {

    const classes = useStyles();

    return (
        <>
            <Paper className={classes.paper}>
                <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" color="primary">Attachments</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MessagingGallery referralMediaURIs={messageMediaURIs}/>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default AttachmentViewer;