import React, { useContext, useEffect } from "react";
import { Resources } from "../../../../shared/types";
import analyticsLogs from "../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../firebase";
import { ActivityContext } from "../SecureMessaging/Inbox/Contexts/ActivityProvider";
import { AuthContext } from "../../../AuthProvider";
import firebase from "firebase";
import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";

/**Summary
 * Handles interacting with the input context to fill in input text field values
 * and econsult outcome values
 */
interface ResourcesInputControllerProps {
  modifyProcessState: (processState: ProcessState) => void;
}

const ResourcesInputController = ({
  modifyProcessState,
}: ResourcesInputControllerProps) => {
  const activityContext = useContext(ActivityContext);
  const authContext = useContext(AuthContext);

  const resourceInsertHandler = async (resource: Resources.Resource) => {
    // Send message showing the shared resource
    await activityContext
      .sendMessage({
        sentBy: authContext.uid,
        sentAt: firebase.firestore.Timestamp.now(),
        message: `Shared resource: ${resource.resourceName}`,
        messageAction: true,
        messageActionData: {
          messageActionType: "shareResource",
          downloadUrl: resource.downloadUrl,
          resourceName: resource.resourceName,
        },
        billable: true,
      })
      .then(() => {
        modifyProcessState(ProcessState.success);
        logAnalyticsEvent(analyticsLogs.specialistResources.insertOption);
        setTimeout(() => {
          modifyProcessState(ProcessState.idle);
        }, 2500);
      });
  };

  return {
    resourceInsertHandler,
  };
};

export default ResourcesInputController;
