import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../../../AuthProvider";
import MessageListItem from "../../../../../components/MessageListItem";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { formatTimeShort } from "../../../../../models/formatTime";
import Typography from '@material-ui/core/Typography';
import { Activity } from "../../../../../../shared/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoContainer: {
            marginRight: theme.spacing(2)
        },
        timestamp: {
            whiteSpace: "nowrap"
        }
    })
)
interface ActivityListItemProps {
    draft: Activity.ConsultDraft,
    draftId: string,
    selected: boolean,
    setSelected: (id: string, checked: boolean) => void,
    openDraft: (id: string) => void
}

const ActivityListItem = ({ draft, draftId, openDraft, selected, setSelected }: ActivityListItemProps) => {
    const authContext = useContext(AuthContext);
    const classes = useStyles();
    const [read, setRead] = useState<boolean>(true);

    useEffect(() => {
        if(authContext.uid === "")
            return;

        setRead(draft.read ?? true)
    }, [authContext.uid, draft])

    return (
        <MessageListItem
            selected={selected}
            setSelected={setSelected}
            openMessage={openDraft}
            id={draftId}
            read={read}
            primary={draft.subject}
        >
            <div className={classes.infoContainer}>
                <Typography className={classes.timestamp}> {formatTimeShort(draft.created.toDate())}</Typography>
            </div>
        </MessageListItem>
    );
}

export default ActivityListItem;