import React, { FC, useEffect, useState } from 'react';
import * as serviceWorker from '../serviceWorkerRegistration';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
//From https://felixgerschau.com/create-a-pwa-update-notification-with-create-react-app/

/** Summary
 * Registers the service worker on component mount
 * Passes the service worker a callback to be called when a new service worker version is available
 * This call back will show the reload button
 * When the reload button is pressed, the page is refreshed and the new service worker is activated
 * 
 * The service worker is also installed in the background, so if the user reloads the page manually, it is also activated
 * See service-worker.ts for the events 'message' and 'install'
 */

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        console.log(`New update found. Registration available: ${registration === null}`);
        /* Without this check, null waiting will cause reload button to show every time the user visits the page
        since skip waiting will never be called */
        if(registration.waiting !== null) {
            setShowReload(true);
            setWaitingWorker(registration.waiting);
        }
        else {
            // registration.waiting is null on firefox
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1113522
            // service-worker.ts listens to 'install' to skip waiting, so service worker will be used on next refresh
            console.log("waiting is null, waiting until next reload")
        }
    };
  
    useEffect(() => {
        console.log("Registering service worker")
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);
  
    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (
        <Snackbar
            open={showReload}
            message="A new version is available!"
            onClick={reloadPage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={
            <Button
                color="secondary"
                size="small"
                onClick={reloadPage}
            >
                Reload
            </Button>
            }
        />
    );
}

export default ServiceWorkerWrapper;