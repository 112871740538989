import { GridSize } from "@material-ui/core";
import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../types";
import FormRoundedRadioButtons from "./FormRoundedRadioButtons";


interface TypedFormRoundedRadioButtonsProps<T extends FieldValues> extends TypedControlledInputProps<T> {
    options: string[],
    breakpoints?: {xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize},
    displayOption?: (option: string) => string,
    onClick?: (option: string) => void
}


function TypedFormRoundedRadioButtons<T extends FieldValues> (props: TypedFormRoundedRadioButtonsProps<T>) {

    return (
        <FormRoundedRadioButtons
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    );
}

export default TypedFormRoundedRadioButtons