import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { timeRowHeight } from './TimeRow';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeBlocked: {
            position: "absolute",
            backgroundColor: palette.darkGreen,
            width: "95%",
        },
    })
)
const marginBottom = 2
interface ScheduleBlockPaperProps {
    startIdx: number,
    endIdx: number,
    onClick?: () => void,
    className?: string,
}

const ScheduleBlockPaper: React.FC<ScheduleBlockPaperProps> = ({ startIdx, endIdx, onClick, className, children }) => {
    const classes = useStyles();

    const diff = endIdx - startIdx
    return (
        <Paper className={clsx(classes.timeBlocked, className)} 
            style={{
                top: timeRowHeight * startIdx,
                height: timeRowHeight * diff - marginBottom
            }}
            onClick={onClick}
        >
            {children}
        </Paper>
    )
}

export default ScheduleBlockPaper;