import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

interface SnackbarMessageProps {
    message?: string,
    useButton?: boolean,
    buttonText?: string,
    show: boolean,
    setShow: (show: boolean) => void,
    onClick?: () =>  void
}

const SnackbarMessage = ({ message, useButton, buttonText, show, setShow, onClick }: SnackbarMessageProps) => {


    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={show}
            autoHideDuration={6000}
            onClose={() => {setShow(false)}}
            message={message}
            action={
                <>
                    {useButton !== undefined && (
                        <Button color="secondary" size="small" onClick={onClick}>
                            {buttonText}
                        </Button>
                    )}
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => {setShow(false)}}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </>
            }
        />
    );
}

export default SnackbarMessage;