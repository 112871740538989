import { useState, useEffect, useContext } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import { PatientInfo } from "../../../../shared/types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fbFunctions } from "../../../firebase";
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import isPHN from "./isPHN";
import sharedStyles from "../../shared/sharedStyles";
import { makeStyles } from "@material-ui/core/styles";
import parseJSONTimestamp from "../../../models/parseJSONTimestamp";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import hasPermissions from "../../../models/hasPermissions";
import { AuthContext } from "../../../AuthProvider";

const useStyles = makeStyles((theme) => {
    return {
        ...sharedStyles(theme)
    }
});


interface PHNInputProps extends ControlledInputProps {
    required?: boolean,
    /** If provided, patient info from lookup will be passed through this function */
    handlePatientInfo?: (patientInfo: PatientInfo) => void,
    ignoreChecksum?: boolean,
    skipServerWarmup?: boolean,
    province?: string
}


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void; 
}


//We have to make individual components because this is super dumb and won't accept dynamic masks
//Sorry Patrick
const TextMaskAB = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}

const TextMaskBC = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/,' ', /\d/, /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}



const PHNInputLookup = ({ name, control, label, required, handlePatientInfo=()=>{}, ignoreChecksum, skipServerWarmup, disabled, province, errorTextPadding }: PHNInputProps) => {
    const classes = useStyles();

    const lookupPatientInfo = fbFunctions.httpsCallable("patientInfo-lookup");
    const [patientInfoList, setPatientInfoList] = useState<PatientInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const [permissionGranted, setPermissionGranted] = useState(false);
    
    const provinceFormatSelect = ( phn: any,province?: string) => {
       
        switch (province) {
            case "BC":
                //bc max length is 10 digit phn
                return `${phn.substr(0, 4)} ${phn.substr(5, 7)} ${phn.substr(8, 10)}`
            default:
                //alberta is default, the length is 9 digit phn
                return `${phn.substr(0, 5)}-${phn.substr(5, 9)}`
        }

    }

    useEffect(() => {
        hasPermissions(resourceKeys.phnLookup, authContext.profile)
        .then((allow) => {
            setPermissionGranted(allow);
            //Wakeup server
            if (allow && !skipServerWarmup) {
                lookupPatientInfo()
                    .then((res) => {
                        console.log(res.data.message);
                    })
                    .catch((error: Error) => {
                        console.log(error);
                    });
            }
        })
    }, [authContext.profile])

    //Search phns
    const handleSearchStringChange = (searchString: string) => {
        if (searchString.length == 3 || searchString.length == 9) {
            if(permissionGranted) {
                setLoading(true);
                lookupPatientInfo({ phn: searchString })
                    .then((res) => {
                        const parsedInfo: PatientInfo[] = (res.data as PatientInfo[]).map((p) => {
                            const parsedTimestamp = parseJSONTimestamp(p.dateOfBirth);
                            return {
                                ...p,
                                dateOfBirth: parsedTimestamp
                            }
                        })
                        setPatientInfoList(parsedInfo);
                    })
                    .catch((error: Error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
        }
        else if (searchString.length < 3) {
            setPatientInfoList([]);
        }
    }


    const filterOptions = createFilterOptions({
        stringify: (option: PatientInfo): string => option.phn,
    });

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={""}
            rules={{
                validate: {
                    required: (phn: string) => {
                        if (required)
                            return phn === "" ? "PHN is required" : undefined;
                        return undefined;
                    },
                    isPHN: (phn: string) => {
                        if (ignoreChecksum)
                            return undefined;
                        return isPHN(phn, province);
                    }
                }
            }}
            
            render={({ field, fieldState }) =>
                <Autocomplete
                    {...field}
                    className={classes.canDisable}
                    options={patientInfoList}
                    getOptionLabel={(option: PatientInfo) => {
                        if(option?.phn !== undefined && option?.phn !== null) {
                            let labelName = `${option.firstName} ${option.lastName}`;
                            if (labelName === ' ') {
                                labelName = option.email
                            }
                            
                            return `${provinceFormatSelect(option.phn,province)}: ${labelName}` 
                        }
                        return "";
                    }}
                    renderInput={(params) => {
                        const inputProps: any = params.InputProps;
                        if (!ignoreChecksum) {
                            switch(province) {
                                case "BC":
                                    inputProps.inputComponent = TextMaskBC;
                                    break;
                                default:
                                    inputProps.inputComponent = TextMaskAB;                                    
                            }
                        }

                        return (
                            <TextField
                                {...field}
                                {...Object.assign(params, { InputProps: inputProps })}
                                label={label}
                                onChange={(e) => {
                                    const phn = e.target.value.split("-").join("").split(" ").join("");
                                    handleSearchStringChange(phn);
                                    field.onChange(phn);
                                }}
                                error={fieldState.error !== undefined}
                                helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    disableAnimation: true,
                                }}
                            />
                        );
                    }}
                    inputValue={field?.value ?? ""}
                    onChange={(_, data: PatientInfo | null | undefined | string) => {
                        if (data === null || data === undefined ) {
                            field.onChange("");
                        }
                        else if(typeof data === "string") {
                            // Check if string (sometimes you just get PHN and not PatientInfo when you type in a PHN with no results and press enter) NO IDEA WHY THOUGH
                            const phn = data.split("-").join("").split(" ").join("");
                            field.onChange(phn);
                        }
                        else {
                            // Else type is patient info, update PHN value and call handle patient info callback
                            field.onChange(data.phn);
                            handlePatientInfo(data);
                        }
                    }}
                    freeSolo
                    loading={loading}
                    filterOptions={filterOptions}
                    disabled={disabled}
                />
            }
        />

    );
}

export default PHNInputLookup;