import React, { ReactNode, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import { PopoverOrigin } from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';

interface DropdownMenuButtonProps {
    /** Text to provide the button component. If undefined, the button will be an IconButton */
    text?: string,
    /** Start Icon to provide the button component. If text is undefined, this will be used as the IconButton icon */
    buttonIcon?: ReactNode,
    /** End icon to provide the button component */
    dropdownIcon?: ReactNode
    /** Optionally provide a custom button to render. text, buttonIcon, and dropdownIcon will be ignored */
    customButton?: ReactNode,
    /** Origin for the popup menu*/
    transformOrigin?: PopoverOrigin,
    /** Origin for where the popup menu will be anchored to the button */
    anchorOrigin?: PopoverOrigin,
    /** If true, popup will automatically close once an option is selected. Default true */
    autoClose?: boolean,
    style?: React.CSSProperties,
}


const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({ 
    text, 
    buttonIcon, 
    dropdownIcon=<ArrowDownIcon />,
    customButton,
    anchorOrigin={vertical: "bottom", horizontal: "center"}, 
    transformOrigin={vertical: "top", horizontal: "center"}, 
    autoClose=true,
    style,
    children, 
    ...rest }) => {
    

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


    return (
        <>
            {customButton &&
                React.cloneElement(customButton as React.ReactElement, { onClick: handleClick, style: style })
            }
            {!customButton && 
                <>
                    {/* Pass rest to button so it can render properly in buttonGroups */}
                    {text === undefined ?
                        <IconButton
                            {...rest}
                            style={style}
                            onClick={handleClick}
                        >
                            {buttonIcon}
                        </IconButton>
                        :
                        <Button
                            {...rest}
                            color="primary"
                            variant="outlined"
                            startIcon={buttonIcon}
                            onClick={handleClick}
                            endIcon={dropdownIcon}
                            style={style}
                        >
                            {text}
                        </Button>
                    }
                </>
            }
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                keepMounted
                anchorEl={anchorEl}
                open={(Boolean(anchorEl))}
                onClose={handleClose}
                onClick={autoClose ? handleClose : undefined}
            >
                {children}
            </Menu>
        </>
    );
}


interface DropdownMenuItemProps {
    label: string,
    icon?: ReactNode
    disabled?: boolean,
    onClick?: (e: React.MouseEvent<HTMLLIElement>) => void,
    color?: "primary" | "secondary" | "inherit" | "textPrimary" | "textSecondary" | "error",
    className?: string,
    style?: React.CSSProperties
}

export const DropdownMenuItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<DropdownMenuItemProps>>(({ label, icon, disabled, onClick, color, className, style, children }, ref) => {
    return (
        <MenuItem ref={ref} onClick={onClick} disabled={disabled} style={style} className={className}>
            {icon && 
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
            }
            <ListItemText primary={label} primaryTypographyProps={{ color: color}}/>
            {children}
        </MenuItem>
    )
})

export default DropdownMenuButton;