import React from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from "../../config/routes";
import useRoutePermissions from "./useRoutePermissions";
import NoPermissionsPage from "./NoPermissionspage";


interface PermissionRouteProps {
    route: AppRoute,
    path: string//Path is required for switch to work in DashboardRoutes
}

//Check whether or not to allow route based on the route's permissions (set in routes.ts)
//Path is required for this to work
//eslint-disable-next-line
const PermissionRoute: React.FC<PermissionRouteProps> = ({  route, path, children }) => {

    const { permissionGranted, loading } = useRoutePermissions(route);

    return (
        <Route path={route.path}>
            {permissionGranted && (
                children
            )}
            {!loading && !permissionGranted && (
                <NoPermissionsPage/>
            )}
            {loading &&
                <></>
            }
        </Route>
    );
}

export default PermissionRoute;