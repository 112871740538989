import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

interface LeavePagePromptProps {
  isDirty: boolean;
  message?: string;
}

const LeavePagePrompt = ({
  isDirty,
  message = "You have unsaved changes, are you sure you want to leave?",
}: LeavePagePromptProps) => {
  useEffect(() => {
    if (isDirty) {
      window.addEventListener("beforeunload", unloadListener);
      return () => {
        window.removeEventListener("beforeunload", unloadListener);
      };
    }
  }, [isDirty]);

  const unloadListener = (event: BeforeUnloadEvent) => {
    event.returnValue = message;
  };

  return <Prompt when={isDirty} message={message} />;
};

export default LeavePagePrompt;
