import { useContext, useEffect, useState } from "react";
import { ClaimsContext } from "../../../../config/ClaimsProvider";
import MOAConsultInboxView from "./MOAConsultInboxView";
import { ProcessState, ProcessStatus } from "@alethea-medical/alethea-components";
import LoadingPage from "../../../../components/LoadingPage";
import { AuthContext } from "../../../../AuthProvider";
import globalStrings from "../../../../globalStrings";
import usePermissions from "../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";



const MOAConsultInbox = () => {
    
    const error_noClinicId = `Unable to load your clinic ID. Please logout and log back in. If you continue to receive this error, you may not be a member of a clinic. Please contact us at ${globalStrings.supportEmail} for assistance setting up your clinic.`

    const { userClaims, fetchClaims, processState, processErrorMessage } = useContext(ClaimsContext)
    const authContext = useContext(AuthContext)

    // Only show the inbox page once permissions have been loaded.
    // This is necessary since the default statuses to be loaded will change depending on if this permission is granted or not.
    const [loadedPermissions, setLoadedPermissions] = useState<boolean>(false)
    const { granted: hasEditStatusPermissions } = usePermissions({ resourceKey: resourceKeys.editActivityStatus, onPermissionFetched: () => setLoadedPermissions(true) })

    useEffect(() => {
        if(authContext.user) {
            fetchClaims(authContext.user)
        }
    }, [authContext.user])

    // If claims are loading, then loading page will show
    return (
        <>
            {/* On Success, show inbox view */}
            {(processState === ProcessState.success && userClaims?.clinicId !== undefined && loadedPermissions) &&
                <MOAConsultInboxView hasEditStatusPermissions={hasEditStatusPermissions}/>
            }
            {/* On running, idle (initial load), or still loading permissions, show loading message */}
            {(processState === ProcessState.running || processState === ProcessState.idle || !loadedPermissions) &&
                <LoadingPage label="Loading Secure Messages"/>
            }
            {/* If finished loading, but user has no clinics show no clinic message */}
            {(processState === ProcessState.success && userClaims?.clinicId === undefined) &&
                <ProcessStatus state={ProcessState.error} errorMessage={error_noClinicId}/>
            }
            {/* If error occurs, show error message */}
            {processState === ProcessState.error &&
                <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
            }
        </>
    );
}

export default MOAConsultInbox;