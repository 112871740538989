import React, { useEffect, useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: "1.02em"
        },
        listItem: {
            paddingLeft: theme.spacing(2),
        },
        listItemIcon: {
            minWidth: "35px"
        },
        childrenContainer: {
            background: theme.palette.grey[200]
        },
    })
)

interface CollapseSidebarContextProps {
    atLeastOneAvailable: () => void
}

export const CollapseSidebarContext = React.createContext<CollapseSidebarContextProps>({
    atLeastOneAvailable: () => {}
});

interface SidebarCollapseItemProps {
    title: string,
    showCollapse: boolean,
}


const SidebarCollapseItem: React.FC<SidebarCollapseItemProps> = ({ title, showCollapse, children }) => {
    const classes = useStyles();

    const [showCategory, setShowCategory] = useState(false)

    const [open, setOpen] = useState(false)

    const clickItemHandler = () => {
        setOpen(!open)
    }

    /* Each sidebar item will call this function if they have permissions to display
    If no sidebar items call this function (meaning none are available), then this entire collapse menu is hidden
    
    For example, if the user it not a specialist, then none of the specialist sidebar items should be shown. 
    Therefore, this entire collapse menu should be hidden */
    const atLeastOneAvailableHandler = () => {
        setShowCategory(true)
    }

    return (
        <CollapseSidebarContext.Provider 
            value={{atLeastOneAvailable: atLeastOneAvailableHandler}}
        >
            <span style={{display: showCategory ? "unset" : "none"}}>
                {showCollapse ? 
                <>
                    <ListItem button onClick={clickItemHandler} className={classes.listItem} disableGutters divider>
                        <ListItemText primary={<Typography className={classes.title}>{title.toUpperCase()}</Typography>} />
                        <ListItemIcon className={classes.listItemIcon}>
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse in={open} className={classes.childrenContainer}>
                        {children}
                    </Collapse>
                </>

                    :
                    <>
                        {children}
                        <Divider/>
                    </>
                }
            </span>
        </CollapseSidebarContext.Provider>
    );
}

export default SidebarCollapseItem;