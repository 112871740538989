import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Activity } from "../../../../../shared/types";
import { formatTimeLong } from "../../../../models/formatTime";
import AddLinks from "../../../../components/AddLinks";
import { useMobileSizes } from "@alethea-medical/alethea-components";
import Typography from "@material-ui/core/Typography";
import CopyToClipboard from "../EconsultComponents/CopyToClipboard";
import Button from "@material-ui/core/Button";
import Description from "@material-ui/icons/Description";
import {
  aletheaOrangeLight,
  aletheaOrange,
} from "@alethea-medical/alethea-components";

function isMessageActionDataShareResource(
  data: Activity.MessageActionDataOptions,
): data is Activity.MessageActionDataShareResource {
  return "downloadUrl" in data;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: "15px",
      textAlign: "left",
      position: "relative",
    },
    myMessage: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
    },
    yourMessage: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.text.secondary,
    },
    systemMessage: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.secondary,
    },
    messageInfo: {
      fontSize: "small",
      color: theme.palette.primary.main,
    },
    messageText: {
      zIndex: 100,
      position: "relative",
      paddingRight: theme.spacing(1.1),
    },
    downloadResourceButton: {
      border: "0px",
      marginTop: "10px",
      marginBottom: "10px",
      "&:hover": {
        border: "0px",
      },
    },
    myDownloadResourceButton: {
      backgroundColor: theme.palette.secondary.light, // This is #e3f3ef
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#d0dfdb", // This should be (if 8.3% darker) from not hovered --> 208, 223, 219
        color: theme.palette.text.secondary,
      },
    },
    yourDownloadResourceButton: {
      backgroundColor: "#ccc",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#bbb", // 8.3% darker than #ccc
        color: theme.palette.text.secondary,
      },
    },
  }),
);
interface UserMessageProps {
  message: Activity.Message;
  mine: boolean;
  sentByUser: Activity.ActivityUser | undefined;
  nameColor: string;
  atLeastOneOtherUserRead: boolean;
  isLast: boolean;
  sharedResource: boolean;
}

const UserMessage = ({
  message,
  mine,
  sentByUser,
  nameColor,
  atLeastOneOtherUserRead,
  isLast,
  sharedResource,
}: UserMessageProps) => {
  const classes = useStyles();

  const isMobileSize = useMobileSizes();

  return (
    <Grid container justifyContent={mine ? "flex-end" : "flex-start"}>
      <Grid item xs={!isMobileSize ? 8 : 12}>
        <Paper
          className={clsx(classes.message, {
            [classes.myMessage]: mine && !message.systemMessage,
            [classes.yourMessage]: !mine && !message.systemMessage,
            [classes.systemMessage]: message.systemMessage,
          })}
          elevation={0}
        >
          <CopyToClipboard
            formattedText={
              `From Alethea\n` +
              `Sent by ${sentByUser?.firstName ?? ""} ${sentByUser?.lastName ?? ""}\n` +
              `${formatTimeLong(message.sentAt.toDate())}\n` +
              `\n${message.message}`
            }
          />

          <Grid container>
            {!mine && (
              <Grid item xs={12} className={classes.messageText}>
                <Typography style={{ color: nameColor }}>
                  {sentByUser?.firstName ?? ""} {sentByUser?.lastName ?? ""}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.messageText}>
              {!sharedResource ? (
                <Typography>
                  <AddLinks text={message.message} />
                </Typography>
              ) : (
                <Grid item xs={12}>
                  {message.messageActionData &&
                    isMessageActionDataShareResource(
                      message.messageActionData,
                    ) && (
                      <Button
                        variant="outlined"
                        startIcon={<Description />}
                        href={message.messageActionData?.downloadUrl}
                        target="_blank"
                        className={`${classes.downloadResourceButton} ${mine ? classes.myDownloadResourceButton : classes.yourDownloadResourceButton}`}
                      >
                        {message.messageActionData?.resourceName}
                      </Button>
                    )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={classes.messageInfo}>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item className={classes.messageInfo}>
                  {formatTimeLong(message.sentAt.toDate())}
                </Grid>
                {isLast && (
                  <Grid item className={classes.messageInfo}>
                    {!atLeastOneOtherUserRead && mine && "Unread"}
                    {atLeastOneOtherUserRead && mine && "Read"}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserMessage;
