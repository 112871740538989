import { styled } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

const FormattedInputLabel = styled(InputLabel)(({ theme }) => {
    
    return ({
        fontSize: "1.0em",
        fontWeight: 700
    })
});

export default FormattedInputLabel;