import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { Resources } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../../../firebase";

const emptyResources: Resources.Resources = {
  resources: {},
  categories: {},
  categoryOrder: [],
};

export function getResources(
  specialistUid: string,
): Promise<Resources.Resources> {
  return fbFirestore
    .collection(dbNames.specialistResources)
    .doc(specialistUid)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        const resources = snapshot.data();
        if (resources !== undefined) {
          return resources as Resources.Resources;
        }
      }

      //If user doesn't have resources, return empty resources object
      return emptyResources;
    });
}

export function saveResources(
  specialistUid: string,
  resources: Resources.Resources,
): Promise<void> {
  return fbFirestore
    .collection(dbNames.specialistResources)
    .doc(specialistUid)
    .set(resources)
    .then(() => Promise.resolve());
}
