import { PaperModal, ProcessState } from "@alethea-medical/alethea-components";
import { ProcessStatus } from "@alethea-medical/alethea-components";
import OutcomesInput from "../OutcomesInput";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputContextOutcomesSetter from "../InputContextOutcomesSetter";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import OutcomesController from "../OutcomesController";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontSize: "1.3em"
        },
        modal: {
            maxHeight: "90vh",
            overflowY: "auto"
        },
    })
)


export const outcomes = [
    "Patient OAT candidate", 
    "Patient NOT an OAT candidate",
    "Patient waiting for dental visit",
    "Dental work required (external)",
    "Dental work required (internal)",
    "OAT ordered",
    "OAT fitted",
    "Patient rejected treatment"
]

interface DentistOutcomesModalProps {
    showOutcomesMenu: boolean,
    setShowOutcomesMenu: (show: boolean) => void,
}

/**
 * Modal to show econsult outcomes
 */
const DentistOutcomesModal = ({ showOutcomesMenu, setShowOutcomesMenu }: DentistOutcomesModalProps) => {
    const classes = useStyles();

    const {
        selectedOutcome, setSelectedOutcome,
        selectedDiagnosis, setSelectedDiagnosis,
        handleSubmitOutcomes,
        processState, processErrorMessage
    } = OutcomesController({ })

    InputContextOutcomesSetter({ setSelectedOutcome, setSelectedDiagnosis, setShowOutcomesMenu })

    const onSubmit = () => {
        handleSubmitOutcomes()
        .then((success) => {
            if(success)
                setShowOutcomesMenu(false)
        })
    }

    const isDisabled = (): boolean => {
        return processState === ProcessState.running
    }


    return (
        <PaperModal className={classes.modal} show={showOutcomesMenu} setShow={setShowOutcomesMenu} flexWidth flexHeight enablePadding>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography className={classes.header}>Please confirm the eConsult outcome and diagnosis are correct</Typography>
                </Grid>
                <Grid item xs={12}>
                    <OutcomesInput 
                        outcomes={outcomes} selectedOutcome={selectedOutcome} setSelectedOutcome={setSelectedOutcome} selectedDiagnosis={selectedDiagnosis} setSelectedDiagnosis={setSelectedDiagnosis}
                        diagnosisLabel="Enter a Diagnosis, treatment AHI, and treatment SPO2"
                        diagnosisMinRows={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage="Outcomes saved."/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {setShowOutcomesMenu(false)}}
                        disabled={isDisabled()}
                    >Skip</Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmit}
                        disabled={isDisabled()}
                    >Save and Close</Button>
                </Grid>
            </Grid>
        </PaperModal>
    );
}


export default DentistOutcomesModal;