import firebase from "firebase";
import { Activity } from "../../../../../../shared/types";
import { fbFirestore } from "../../../../../firebase";
import { ActivityItem } from "../../types";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";


class ActivitiesListModel {
    metadataRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    sharedRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    uid: string

    constructor(uid: string) {
        this.uid = uid
        this.metadataRef = fbFirestore.collection(dbNames.userActivities).doc(uid).collection(dbNames.userActivities_activities)
        this.sharedRef = fbFirestore.collection(dbNames.activities)
    }

    /**
     * Update firestore to set overdue to false and adds user to readBy list in recent message
     * @param activity Activity to update
     * @returns 
     */
    updateDatabaseActivityRead(activity: ActivityItem<Activity.UserActivity>): Promise<ActivityItem<Activity.UserActivity>> {
        // Run transaction to get newest recent message data - otherwise this operation will overwrite any new data that the local state doesn't have yet    
        return fbFirestore.runTransaction((transaction) => {
            return transaction.get(this.sharedRef.doc(activity.id))
            .then((snapshot) => {
                const newSharedActivity = snapshot.data() as Activity.Activity
                if(!newSharedActivity.recentMessage.readBy.includes(this.uid)) {
                    // Add our uid to new readBy array
                    newSharedActivity.recentMessage.readBy.push(this.uid)
                    // Update with new array and most up to date recent message
                    const readUpdateObj: Partial<Activity.Activity> = {
                        recentMessage: newSharedActivity.recentMessage
                    }
                    transaction.update(this.sharedRef.doc(activity.id), readUpdateObj)
                }
                const newUserActivity = {...activity.metadataActivity}
                if(newUserActivity.overdue === true) {
                    newUserActivity.overdue = false
                    const overdueUpdateObj: Partial<Activity.UserActivity> = {
                        overdue: newUserActivity.overdue,
                    }
                    transaction.update(this.metadataRef.doc(activity.id), overdueUpdateObj)
                }

                return {
                    id: snapshot.id,
                    sharedActivity: newSharedActivity,
                    metadataActivity: newUserActivity
                }
            })
        })
    }

    /**
     * Mark provided activityIds as unread. Updates firestore user activity and activity and modifies dictionary passed
     * @param activities Activities dictionary to update. Modified in-place
     * @param activityIds Activity IDs to mark as unread
     * @returns Promise<void>
     */
    updateDatabaseActivityUnread(activities: ActivityItem<Activity.UserActivity>[]): Promise<ActivityItem<Activity.UserActivity>[]> {
        // Run transaction to get newest recent message data - otherwise this operation will overwrite any new data that the local state doesn't have yet
        return fbFirestore.runTransaction((transaction) => {
            const modifiedActivities: ActivityItem<Activity.UserActivity>[] = []
            return Promise.all(activities.map((activity) => {
                return transaction.get(this.sharedRef.doc(activity.id))
            }))
            .then((snapshots) => {
                snapshots.forEach((snapshot, index) => {
                    const newSharedActivity = snapshot.data() as Activity.Activity
                    const readByIndex = newSharedActivity.recentMessage.readBy.indexOf(this.uid)
                    if(readByIndex !== -1) {
                        newSharedActivity.recentMessage.readBy.splice(readByIndex, 1);
                        const readUpdateObj: Partial<Activity.Activity> = {
                            recentMessage: newSharedActivity.recentMessage
                        }
                        transaction.update(this.sharedRef.doc(snapshot.id), readUpdateObj)
                        modifiedActivities.push({
                            ...activities[index],
                            sharedActivity: newSharedActivity
                        })
                    }
                })
            })
            .then(() => {
                return modifiedActivities
            })
        })
    }

    /**
     * Updates the folder to the provided folder for each activity in the list. Returns a list of modified activities to be used to undo this action
     * @param uid UID of user to update archive status for
     * @param activities List of all activities to move
     * @param folder Folder to move to
     * @returns List of activities that were moved folders
     */
    updateDatabaseMoveFolder(activities: ActivityItem<Activity.UserActivity>[], folder: Activity.UserActivityFolder): Promise<ActivityItem<Activity.UserActivity>[]> {
        const undoActivities: ActivityItem<Activity.UserActivity>[] = []
        const batch = fbFirestore.batch()

        activities.forEach((activity) => {
            //Make deep copy
            undoActivities.push({...activity});
            batch.update(this.metadataRef.doc(activity.id),{ folder: folder })
        })

        return batch.commit()
        .then(() => {
            return undoActivities
        })
    }
    
    /**
     * Reset archive status to "undoActivities" in firestore.
     * @param undoActivities Activities to restore archive/inbox state of
     * @param activities Activities dictionary. Modified in-place with the undo activities
     * @returns void
     */
    updateDatabaseUndoMoveFolder(undoActivities: ActivityItem<Activity.UserActivity>[]): Promise<void> {
        const batch = fbFirestore.batch();

        undoActivities.forEach((activity) => {		
            batch.update(this.metadataRef.doc(activity.id), { folder: activity.metadataActivity.folder })
        })

        return batch.commit()
    }
}


export default ActivitiesListModel