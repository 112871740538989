import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import hasPermissions from "../../models/hasPermissions";

interface usePermissionsProps {
  resourceKey: string;
  onPermissionFetched?: (granted: boolean) => void;
}

const usePermissions = ({
  resourceKey,
  onPermissionFetched = () => {},
}: usePermissionsProps) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [granted, setGranted] = useState(false);

  useEffect(() => {
    setLoading(true);
    hasPermissions(resourceKey, authContext.profile).then((granted) => {
      setGranted(granted);
      onPermissionFetched(granted);
      setLoading(false);
    });
  }, [authContext.profile, setGranted, setLoading, granted, resourceKey]);

  return {
    granted,
    loading,
  };
};

export default usePermissions;
