import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { TimeBinnedMedia, UserMediaMetadataItem } from '../Models/GalleryModel';
import GalleryGrid from './GalleryGrid';

interface BinProps {
    timestamp: string,
    items: UserMediaMetadataItem[],
    galleryRenderer:  ({ index, key, photo }: any) => JSX.Element
}

const Bin = ({ items, timestamp, galleryRenderer }: BinProps) => {
    return (
        <Grid item xs={12} key={`gallery_${timestamp}`}>
            <Typography variant="subtitle1">
                {`${new Date(parseInt(timestamp)).toDateString()}`}
            </Typography>
            <GalleryGrid photos={items} galleryRenderer={galleryRenderer}/>
            <Divider/>
        </Grid>
    )
}


interface GalleryTimestampBinsProps {
    binnedMediaItems: TimeBinnedMedia | undefined,
    galleryRenderer:  ({ index, key, photo }: any) => JSX.Element
}

const GalleryTimestampBins = ({ binnedMediaItems, galleryRenderer }: GalleryTimestampBinsProps) => {
        return (
        <>
            {binnedMediaItems !== undefined ? 
                <Grid container spacing={2}>
                    {Object.keys(binnedMediaItems).map((timestamp) => 
                        <Grid item xs={12} key={`gallery_grid_${timestamp}`}>
                            <Bin items={binnedMediaItems[timestamp]} timestamp={timestamp} galleryRenderer={galleryRenderer}/>
                        </Grid>
                    )}
                </Grid>
                : <></>
            }
        </>
    );
}

export default GalleryTimestampBins;