import { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { PaperModal } from '@alethea-medical/alethea-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        helpButton: {
            cursor: "pointer"
        },
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
        },
    }),
);

    
interface HelpModalProps {
    /** Text to show in the button. If modalTitle is undefined, this will also be used as the modal header */
    buttonText?: string,
    /** Text to show in the modal, as well as the tooltip */
    helpText: string[] | JSX.Element[],
    /** If provided, this will be used as the modal header instead of buttonText */
    modalTitle?: string,
}

const HelpModal = ({ modalTitle, buttonText, helpText }: HelpModalProps) => {
    const classes = useStyles();

    const [showHelp, setShowHelp] = useState(false);

    return (
        <>
            <Tooltip title={
                <>
                    {helpText.length === 1 ? (
                        <>{helpText[0]}</>
                    ) : (
                        <ul>
                            {helpText.map((t, i) => 
                                <li key={`help_tooltip_${t}_${i}`}>{t}</li>
                            )}
                        </ul>
                    )}
                </>
            }>
                <Grid className={classes.helpButton} container spacing={1} onClick={() => {setShowHelp(true)}}>
                    <Grid item>
                        <HelpIcon color="primary"/>
                    </Grid>
                    {buttonText !== undefined && (
                        <Grid item>
                            <Typography color="primary">{buttonText}</Typography>
                        </Grid>                                    
                    )}
                </Grid>
            </Tooltip>
            <PaperModal className={classes.modal} show={showHelp} setShow={setShowHelp} flexHeight flexWidth enablePadding>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <HelpIcon color="primary"/>
                            </Grid>
                            {(modalTitle || buttonText) && (
                                <Grid item>
                                    <Typography color="primary">{modalTitle ?? buttonText}</Typography>
                                </Grid>                                    
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {helpText.length === 1 ? (
                            <Typography variant="subtitle1">{helpText[0]}</Typography>
                        ) : (
                            <ul>
                                {helpText.map((t, i) => 
                                    <li key={`help_modal_${t}_${i}`}><Typography variant="subtitle1">{t}</Typography></li>
                                )}
                            </ul>
                        )}
                    </Grid>
                </Grid>
            </PaperModal>
        </>
    );
}

export default HelpModal;