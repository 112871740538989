import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { PaperModal } from '@alethea-medical/alethea-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sharedStyles from '../../sharedStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
    })
)

interface useConfirmDestructiveModalProps {
    confirmText: React.ReactChild,
    confirmButtonText: string,
    cancelButtonText?: string,
    action: () => void   
}

const useConfirmDestructiveModal = ({ confirmText, confirmButtonText, cancelButtonText="Cancel", action }: useConfirmDestructiveModalProps) => {
    
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState(false)

    const showConfirmDestructiveModal = () => {
        setShowConfirm(true)
    }

    const confirmAction = () => {
        action()
        setShowConfirm(false)
    }

    const cancelAction = () => {
        setShowConfirm(false)
    }

    return {
        ConfirmDestructiveModal: <PaperModal show={showConfirm} setShow={setShowConfirm} enablePadding flexWidth flexHeight>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6">{confirmText}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="outlined" onClick={cancelAction} fullWidth>{cancelButtonText}</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" className={classes.dangerButton} onClick={confirmAction} fullWidth>{confirmButtonText}</Button>
                </Grid>
            </Grid>
        </PaperModal>,
        showConfirmDestructiveModal
    };
}

export default useConfirmDestructiveModal;