import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Typography, Theme, Grid, Paper } from "@material-ui/core";
import EarningTrends from "./EarningTrends";



export interface DatasetsChart {
	label: string,
	data: number[],
	fill?: boolean,
	borderColor?: string,
	backgroundColor: string[],
	type?: string,
	borderWidth?: number,
	order?: number,

}
export interface DataChart {
	labels: string[],
	datasets: DatasetsChart[]
}

interface ChartPanelProps {
	chartLabels: string[],
	chartEarningCommunications: number[],
	chartEarningEconsult: number[],
	chartEarningTotal: number[]

}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(1),
		},
		title: {
			textAlign: "center",

		},

	})
);

export const ChartPanel = ({ chartLabels, chartEarningCommunications, chartEarningEconsult, chartEarningTotal }: ChartPanelProps) => {
	const classes = useStyles();
	
	const [dataChart, setDataChart] = useState<DataChart>({ labels: [], datasets: [] });


	useEffect(() => {

		getChartData();

	}, [chartLabels, chartEarningCommunications, chartEarningEconsult, chartEarningTotal])

	const getChartData = () => {

		const chart: DataChart = {
			labels: chartLabels,
			datasets: [
				{
					label: "Consult Earnings",
					data: chartEarningEconsult,
					backgroundColor: [
						'#86d0c8',
					],
					borderWidth: 1,
					order: 1,
				},
				{
					label: "Patient Communications Earnings",
					data: chartEarningCommunications,

					backgroundColor: [
						'#05707d',
					],
					borderWidth: 1,
					order: 1,
				},
				{
					label: "Total Earning",
					data: chartEarningTotal,
					fill: false,
					borderColor: '#f36c24',
					backgroundColor: ['#f36c24',],
					type: 'line',
					order: 0,
				},
			]
		}
		setDataChart(chart)
	}


return (

	<Paper className={classes.container} variant="outlined">
		<Grid container direction="column" alignItems="center" spacing={1}>
			<Grid item  >
				<Typography variant="h6" color="primary">
					Earning Trends
				</Typography>
			</Grid>
			<Grid item >
				<EarningTrends data={dataChart} />
			</Grid>
		</Grid>
	</Paper>

);

}