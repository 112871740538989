import { fbFunctions } from "../../../../firebase";
import { downloadBlob } from "../../../../utils/download-utils";

type ConsultDraftsCollectionType = 'user_drafts' | 'drafts_pending_approval'

function exportPDF(activityId: string): Promise<void> {
	return fbFunctions.httpsCallable("secureMessaging-exportEconsultPdf")({ activityId }).then((result) => {
		downloadBlob(result.data, 'econsult_export.pdf', 'application/pdf')
	})
}

export function exportDraftPDF(consultDraftsCollectionType: ConsultDraftsCollectionType, draftId: string, subject: string): Promise<void> {
	return fbFunctions.httpsCallable("secureMessaging-exportEconsultDraftPdf")({ consultDraftsCollectionType, draftId }).then((result) => {
		downloadBlob(result.data, `draft_${subject}.pdf`, 'application/pdf')
	})
}


export default exportPDF;