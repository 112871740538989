import { Control, Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import sharedStyles from "../../../../../components/shared/sharedStyles";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import HelpModal from "../../../../../components/HelpModal";
import { SignupInfo } from "@alethea-medical/aletheamd-types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
    })
)


interface InviteCodeBoxProps {
    control: Control<SignupInfo.SignupInfo>,
    disabled?: boolean
}

const InviteCodeBox = ({ control, disabled }: InviteCodeBoxProps) => {
    const classes = useStyles();
    
    const formatInput = (value: string): string => {
        return value.toUpperCase();
    }

    return (
        <>
            <HelpModal 
                    buttonText="Referral Code" helpText={["If you received an invite from a colleague, enter their referral code here."]}/>
            <Controller
                name={"inviteCode"}
                control={control}
                render={({field}) => 
                    <TextField 
                        fullWidth
                       {...field}
                        onChange={(e) => {
                            e.target.value = formatInput(e.target.value)
                            field.onChange(e)
                        }}
                        disabled={disabled}
                        className={classes.canDisable}
                        variant="outlined"
                        InputLabelProps={{
                            disableAnimation: true
                        }}
                        onSubmit={(e) => {
                            // Remove leading & trailing empty spaces 
                            field.value = field.value ? field.value.trim() : "";
                        }}
                    />
                }
            />
        </>
    );
}

export default InviteCodeBox;