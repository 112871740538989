import React from "react";
import { Grid, Table, TableBody, TableContainer, Paper } from '@material-ui/core';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { getComparator, stableSort } from "../billingFunctions";

import { HeadCell } from "../types";
import BillingTableRow from "../Components/BillingTableRow";
import BillingTableHead from "../Components/BillingTableHead";
import ExportBillingReportPDF from "./ExportBillingReportPDF";
import getMonthName from "../../../../models/getMonthName";
import ExportBillingReportCSV from "./ExportBillingReportCSV";
import { BillingRecord } from "@alethea-medical/admin-types";
import useBillingTable from "../Components/useBillingTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        table: {
            overflow: 'visible',
            marginTop: theme.spacing(2)
        },
        pdfableTable: {
            width: "100%",
        },
        loadingStatus: {
            marginTop: theme.spacing(1)
        },
        container: {
            padding: theme.spacing(1)
        },
        title: {
			textAlign: "center",
		}
    })
);

const headCells: HeadCell[] = [
    { id: 'created', label: 'Service Date' },
    { id: 'hlinkResultReceivedAt', label: 'Date Accepted or Rejected '},
    { id: 'paidAt', label: 'Date Paid' },
    { id: 'type',  label: 'Activity' },
	{ id: 'subType', label: 'Sub-Activity'},
	{ id: 'patientId', label: 'PHN' },
    { id: 'status', label: 'Status' },
    { id: 'dxCode', label: 'Diagnosis Code' },
	{ id: 'billingCode', label: 'Billing Code' },
	{ id: 'payToDoctor',  label: 'Amount Paid' },	
    { id: 'errorCode', label: 'Error Code' },
];


const MonthlyBillingTable = () => {
    const classes = useStyles();



    const { createBillingTable, createMonthPicker, billingData, order, orderBy, fromDate } = useBillingTable({headCells: headCells, fetchBy: "hlinkResultReceivedAt", tableKey: "billing", defaultSort: "hlinkResultReceivedAt" })


    const createPDFPages = () => {
        const sorted = stableSort([...billingData], getComparator(order, orderBy));
        
        const chunked: BillingRecord[][] = [];
        const rowsPerChunk = 65;
        let chunkIndex = 0;

        //Split array into smaller arrays of rowsPerChunk length
        sorted.forEach((row, index) => {
            if(index !== 0 && index % rowsPerChunk === 0)
                chunkIndex++;
            if(chunked[chunkIndex] === undefined )
                chunked[chunkIndex] = []
            chunked[chunkIndex].push(row);
        })
        
        return chunked.map((pageRows, index) => {
            return (
                <Grid container key={`billing_report_pdf_page_${index}`} spacing={2} style={{position: "relative"}}>
                    {index === 0 && (
                        <>
                            <img src="/Alethea LogoPDFWriter.png" width="10%" style={{position: "absolute", top: 15, left: 15}}/>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{ textAlign: "center"}}>Billing Report {getMonthName(fromDate.getMonth())} {fromDate.getFullYear()}</Typography>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                className={classes.pdfableTable}
                                size={'small'}
                                stickyHeader
                            >
                                <BillingTableHead
                                    headCells={headCells}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={() => {}}
                                />
                                <TableBody>
                                    {pageRows.map((data, index) => <BillingTableRow key={`table_row_${index}`} index={index} headCells={headCells} data={data}/>)
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>                    
                </Grid>
            );
        })
    }

    return (
        <>
            <Paper className={classes.container} variant="outlined">

                <Grid container justifyContent="space-between">

                    <Grid item xs={12}>
                        <span className={classes.title}>
                            <Typography variant="h6" color="primary">
                                Processed Billing Records
                            </Typography>
                        </span>

                    </Grid>
                    <Grid item>
                        {createMonthPicker()}
                    </Grid>
                    <Grid item>
                        {/* Keep buttons together */}
                        <Grid container justifyContent="flex-start" spacing={1}>
                            <Grid item>
                                <ExportBillingReportPDF pages={createPDFPages()}/>
                            </Grid>
                            <Grid item>
                                <ExportBillingReportCSV billingData={stableSort(billingData, getComparator(order, orderBy))}/>
                            </Grid>
                        </Grid>
                    </Grid>
     
                </Grid>
                {createBillingTable()}
            </Paper>
        </>

    );
}

export default MonthlyBillingTable;

