import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { UserMediaMetadata } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../../../firebase";
import { hexEncoding } from "@alethea-medical/utilities";

/** Updates the media metadata item in firestore. Returns the update object used */
export function updateTags (uid: string, id: string, tags: string[])
{
    const updateObj: Partial<UserMediaMetadata> = {
        tags: tags.map((t) => hexEncoding.encodeStringToHex(t))//Encode as base64 string to obscure
    }
    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).doc(id).update(updateObj)
}

/** Updates the media metadata item in firestore. Returns the update object used */
export function updateNotes (uid: string, id: string, notes: string)
{
    const updateObj: Partial<UserMediaMetadata> = {
        notes: hexEncoding.encodeStringToHex(notes)//Encode as base64 string to obscure
    }
    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).doc(id).update(updateObj)
}