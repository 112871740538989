import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { fbFirestore } from "../../../../firebase";
import { Fcm } from "../../../../../shared/types";
import Badge, { BadgeProps } from '@material-ui/core/Badge';
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import DraftsListController from "./Controllers/DraftsListController";

const UnreadApprovalsCounter = (props: BadgeProps) => {
    const { unreadApprovals } = DraftsListController("drafts_pending_approval");

    return (
        <Badge badgeContent={unreadApprovals} color="error" max={99} overlap="rectangular" {...props}>
            {props.children}
        </Badge>
    );
}

export default UnreadApprovalsCounter;