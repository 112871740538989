import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Divider from '@material-ui/core/Divider';


interface NotificationCollapseProps {
    title: string   
}

const NotificationCollapse: React.FC<NotificationCollapseProps> = ({ title, children }) => {
 
    const [showNotifSettings, setShowNotifSettings] = useState<boolean>(false);
    

    return (
        <>
            <Grid item xs={12}>
                <Button onClick={() => setShowNotifSettings(!showNotifSettings)} endIcon={showNotifSettings ? <ExpandLessIcon/> : <ExpandMoreIcon/>}>{title}</Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={showNotifSettings}>
                    <>
                        {children}
                        <Divider style={{marginTop: "8px"}}/>
                    </>
                </Collapse>
            </Grid>
        </>
    );
}

export default NotificationCollapse;