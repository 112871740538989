import { useState } from "react"
import { useHistory } from "react-router-dom"

const useDraftId = () => {
    
    const [draftId, setDraftId] = useState<string | undefined>(undefined)
    const history = useHistory()

    const addDraftIdToSearch = (newDraftId: string | undefined) => {
        const queryParams = location.search !== "" ? location.search.split('&') : []
        const idx = queryParams.findIndex((str) => str.includes("consultDraftId"))

        if(newDraftId !== undefined && idx === -1) {
            queryParams.push(`consultDraftId=${newDraftId}`)
            history.push({
                search: `${queryParams.join('&')}`
            })
        }
        else if(newDraftId === undefined && idx !== -1){
            queryParams.splice(idx, 1)

            // Remove draft ID from query params so when page is refreshed, the draft is not loaded
            history.push({
                search: `${queryParams.join('&')}`
            })
        }
    }

    const updateDraftId = (newDraftId: string | undefined) => {
        setDraftId(newDraftId)
        addDraftIdToSearch(newDraftId)
    }

    return {
        draftId, setDraftId: updateDraftId
    };
}

export default useDraftId;