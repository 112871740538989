import React from "react";
import { FieldArrayMethodProps } from "react-hook-form";
import Button  from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


interface AddPatientButtonProps {
    append: (value: Partial<unknown> | Partial<unknown>[], options?: FieldArrayMethodProps | undefined) => void,
    disabled?: boolean
}

const AddPatientButton = ({ append, disabled}: AddPatientButtonProps) => {

    const handleClick = () => {
        append({
            phn: '',
            email: ''
        });
    }

    return (
        <Button 
            onClick={handleClick}
            color="primary"
            disabled={disabled}
            startIcon={<AddCircleOutlineIcon color="primary"/>}
        >            
            Add another recipient
        </Button >
    );
}

export default AddPatientButton;