import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { SpecialistTemplates } from '../../../../shared/types';

interface InsertTemplateButtonProps {
    item: SpecialistTemplates.Item,
    templateInsertHandler: (item: SpecialistTemplates.Item) => void
}

const InsertTemplateButton = ({ item, templateInsertHandler }: InsertTemplateButtonProps) => {
    
    const handleTemplateInsert = () => {
        templateInsertHandler(item)
    }

    return (
        <Button variant="outlined" startIcon={<AddIcon/>} onClick={handleTemplateInsert}>
            Insert Text
        </Button>
    );
}

export default InsertTemplateButton;