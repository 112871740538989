import React from "react";
import { Control, useWatch } from "react-hook-form";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';

interface FormSendButtonProps {
    control: Control,
    disabled: boolean
}


const FormSendButton = ({control, disabled}: FormSendButtonProps) => {
    const patients = useWatch({control, name: "patients", defaultValue: []});

    return (
        <Button
            type="submit" 
            color="primary" 
            startIcon={<SendIcon color="primary"/>}
            disabled={disabled || patients === undefined || patients.length === 0}
        >
            Send
        </Button>
    );
}

export default FormSendButton;
