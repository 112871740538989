import React, {} from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GridOnIcon from '@material-ui/icons/GridOn';
import { ProcessState, ProcessStatus } from '@alethea-medical/alethea-components';
import { useProcessState } from "@alethea-medical/alethea-components";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import { logAnalyticsEvent } from '../../../../firebase';
import { BillingRecord } from '@alethea-medical/admin-types';
import firebase from "firebase";


interface ExportBillingReportCSVProps {
    billingData: BillingRecord[]
}
const ExportBillingReportCSV = ({ billingData }: ExportBillingReportCSVProps) => {

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const valueOrBlank = (value: string | undefined) => {
        if(value === undefined) {
            return ""
        }
        return value;
    }
    const dateOrBlank = (date: firebase.firestore.Timestamp | undefined) => {
        if(date === undefined) {
            return ""
        }
        return date.toDate().toLocaleString();
    }

    const saveCSV = () => {
        setProcessState(ProcessState.running);
        logAnalyticsEvent("billing_report_export_csv_failed_start");
        
        try {

            const csvData = billingData.map((data: BillingRecord) => {

                return {
                    "Service Date": dateOrBlank(data.created),
                    "Data Accepted or Rejected": dateOrBlank(data.hlinkResultReceivedAt),
                    "Date Paid": dateOrBlank(data.paidAt),
                    "Activity": valueOrBlank(data.type),
                    "Sub-Activity": valueOrBlank(data.subType),
                    "PHN": valueOrBlank(data.patientId),
                    "Status": valueOrBlank(data.status),
                    "Diagnosis Code": valueOrBlank(data.dxCode),
                    "Billing Code": valueOrBlank(data.billingCode),
                    "Amount Paid": valueOrBlank(`${data.payToDoctor}`),
                    "Error Code": valueOrBlank(data.errorCode)
                }
            });
            const csv = parse(csvData, {
                fields: ["Service Date", "Data Accepted or Rejected", "Date Paid",
                "Activity", "Sub-Activity", "PHN", "Status", "Diagnosis Code", "Billing Code", "Amount Paid", "Error Code"]
            });
            const fileName = `billing_report.csv`;
    
            
            // Create a blob of the data
            const fileToSave = new Blob([csv], {type: "text/plain;charset=utf-8"});
            saveAs(fileToSave, fileName);

            logAnalyticsEvent("billing_report_export_csv_failed_success");
            setProcessState(ProcessState.idle);
        }
        catch(e) {
            let error: Error = new Error("Error");
            if (typeof e === "string") {
                error = new Error(e.toUpperCase()) // works, `e` narrowed to string
            } else if (e instanceof Error) {
                error = e // works, `e` narrowed to Error
            }
            errorHandler({
                error: error,
                userMessage: "Error exporting CSV. Please try again.",
                analyticsLog: "billing_report_export_csv_failed"
            })
        }

    }

    return (
        <div>
            <Tooltip title="Export billing records as a CSV">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GridOnIcon/>}
                    onClick={saveCSV}
                >
                    Export as CSV
                </Button>
            </Tooltip>            
            <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
        </div>
    );
}

export default ExportBillingReportCSV;