import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperModal } from "@alethea-medical/alethea-components";
import AveiroPaymentPage from './AveiroPaymentPage';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import PaymentIcon from '@material-ui/icons/Payment';
import { useState } from 'react';
import { DropdownMenuItem } from '../../../components/DropdownMenuButton/DropdownMenuButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
        },
        
    })
)

interface AveiroPaymentModalProps {
    activityId: string,
    variant: "button" | "dropdown",
}

const AveiroPaymentButton = ({ activityId, variant }: AveiroPaymentModalProps) => {
    const classes = useStyles();

    const [show, setShow] = useState(false)

    const openModalHandler = () => {
        setShow(true)
    }

    return (
        <>
            {variant === "button" &&
                <Tooltip
                    title="Make a Payment"
                    children={
                        <Button 
                            onClick={openModalHandler}
                            color="primary"
                            variant="outlined"
                            startIcon={<PaymentIcon />}
                        >
                            Payment
                        </Button>
                    }
                />
            }
            {variant === "dropdown" &&
                <DropdownMenuItem label="Payment" icon={<PaymentIcon color="primary" />} onClick={openModalHandler} color='primary' />            
            }

            <PaperModal className={classes.modal} show={show} setShow={setShow} enablePadding flexHeight>
                {show && 
                    <AveiroPaymentPage activityId={activityId}/>
                }
            </PaperModal>
        </>

    );
}

export default AveiroPaymentButton;