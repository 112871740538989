import { useContext, useState } from "react"
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { AuthContext } from "../../../../AuthProvider";
import { logAnalyticsEvent } from "../../../../firebase";
import serverRequest from "../../../../models/serverRequest";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import FormTextField from "../../../../components/FormInputFields/FormTextField";
import FormTextArea from "../../../../components/FormInputFields/FormTextArea";
import EmailInput from "../../../../components/FormInputFields/EmailInput";
import { ProcessState, ProcessStatus, useProcessState, PaperModal, HeightLayout, HeightLayoutChild, thinScrollbar } from "@alethea-medical/alethea-components";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import EmailIcon from '@material-ui/icons/Email'



interface MOAMessageProps {
    activityId: string,
    defaultSubject: string,
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		
		...thinScrollbar,
       
		messagesBox: {
			padding: theme.spacing(1, 1, 3, 1),
			overflowY: "auto",
			overflowX: "hidden",

		},
	}),
);

const MOAMessageButton = ({ activityId, defaultSubject }: MOAMessageProps) => {

    const authContext = useContext(AuthContext);
    const { handleSubmit, control, reset } = useForm();
    const classes = useStyles();
	const [show, setShow] = useState<boolean>(false);

    const { processState, setProcessState, processErrorMessage, setProcessErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

   
    const getSubjectPrepend = (): string => {
        let subjectPrepend = "Secure Message from Dr. ";
        subjectPrepend += `${authContext?.profile?.lastName}: `;
        return subjectPrepend;
    }

    const onSubmit = (data: any) => {

        setProcessState(ProcessState.running);
       
        const form = new FormData();
        form.append("physicianUid", authContext.uid);
        form.append("activityId", activityId)
        form.append("patientEmail", data.email);
        form.append("subject", getSubjectPrepend() + data.subject);
        form.append("body", data.body);
        

        logAnalyticsEvent("secure_messaging_forward_econsult_start");
        
        serverRequest(authContext.user, {}, form, 'secure-messaging-moa-message')
        .then(() => {

            logAnalyticsEvent("secure_messaging_forward_econsult_success");

            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
                setShow(false);
            }, 1000);
            resetForm();
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error forwarding econsult", 
                analyticsLog: "secure_messaging_forward_econsult_failed"
            })
        });

    }

    const resetForm = () => {
        reset({
            email: '',
            subject: defaultSubject, 
            body: ''
        });
    }

    const onError = () => {
        setProcessErrorMessage("Check form for errors.");
        setProcessState(ProcessState.error);
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <>
            <DropdownMenuItem label="Forward to MOA" icon={<EmailIcon color="primary" />} onClick={() => { setShow(true) }} color='primary'/>
            <PaperModal show={show} setShow={setShow} flexWidth enablePadding>
            <HeightLayout>
                <HeightLayoutChild flexDriven allowOverflowY className={clsx(classes.thinScrollbar, classes.messagesBox)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Forward eConsult as PDF</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Securely forward this eConsult, message thread, and attachments as a PDF.</Typography>    
                        <Typography variant="subtitle1">Your MOA can view the eConsult by creating an <a href="https://alethea.care" target="_blank" rel="noopener noreferrer">Alethea Care</a> account.</Typography>
                        <Typography variant="subtitle1">You can track the eConsults you have sent in the <a href="/dashboard/patient-messages" target="_blank" rel="noopener noreferrer">Patient Messages</a> tab.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off' aria-autocomplete='none'>
                            <fieldset disabled={isDisabled()} aria-autocomplete="none">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <EmailInput  
                                            name="email"
                                            label="To"
                                            control={control}
                                            rules={{required: {value: true, message: "Email is required"}}}
                                        />             
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextField  
                                            name="subject"
                                            control={control}
                                            label="Subject"
                                            defaultValue={defaultSubject}
                                            disabled={isDisabled()}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormTextArea 
                                            name="body"
                                            label="Message"
                                            control={control} 
                                            initRows={3}
                                            disabled={isDisabled()}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} successMessage={"Message sent."}/>    
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit" color="primary" disabled={isDisabled()} startIcon={<SendIcon color="primary"/>}>
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </form>
                    </Grid>
                </Grid>
                </HeightLayoutChild>
                </HeightLayout>
            </PaperModal>       
        </>
    );
}

export default MOAMessageButton;