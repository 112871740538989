import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { PaperModal } from '@alethea-medical/alethea-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sharedStyles from '../../../sharedStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
    })
)
interface ConfirmDeleteButtonProps {
    numToDelete: number
    deleteHandler: () => void
}

const ConfirmDeleteButton = ({ numToDelete, deleteHandler }: ConfirmDeleteButtonProps) => {
    const classes = useStyles();

    const [show, setShow] = useState(false);

    const showConfirmationHandler = () => {
        setShow(true)
    }

    const handleDelete = () => {
        deleteHandler();
        setShow(false)
    }

    return (
        <>
            <PaperModal show={show} setShow={setShow} enablePadding flexHeight flexWidth>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Delete {numToDelete} file{numToDelete > 1 ? "s" : ""}?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" className={classes.dangerButton} onClick={handleDelete}>Delete</Button>
                    </Grid>
                </Grid>
            </PaperModal>
            <Button variant="contained" className={classes.dangerButton} onClick={showConfirmationHandler}>Delete Selected</Button>
        </>
    );
}

export default ConfirmDeleteButton;