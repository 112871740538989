import ScheduleBlockPaper from './ScheduleBlockPaper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TimeslotRangeDisplay from './TimeslotRangeDisplay';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        previewPaper: {
            padding: theme.spacing(1),
            pointerEvents: "none"
        },
        whiteText: {
            color: "white"
        }
    })
)


interface BlockPreviewProps {
    startIndex: number,
    endIndex: number,
    times: string[],
    show: boolean
}

const BlockPreview = ({ startIndex, endIndex, times, show }: BlockPreviewProps) => {
    const classes = useStyles();

    // If going bottom to top, swap indexes
    // Also add one to compensate for end time being at the bottom of the block
    if(startIndex > endIndex) {
        const temp = endIndex
        endIndex = startIndex + 1
        startIndex = temp
    }

    return (
        <>
            {(show && times[startIndex] !== undefined && times[endIndex] !== undefined) &&
                <ScheduleBlockPaper startIdx={startIndex} endIdx={endIndex} className={classes.previewPaper}>
                    {startIndex !== endIndex && <TimeslotRangeDisplay className={classes.whiteText} start={times[startIndex]} end={times[endIndex]}/>}
                </ScheduleBlockPaper>
            }
        </>
    );
}

export default BlockPreview;