import React, { useEffect, useRef, useState } from 'react';

import useUploadMediaFile from './useUploadMediaFile';
import IconButton from '@material-ui/core/IconButton';
import { ProcessStatus, ProcessState } from "@alethea-medical/alethea-components";
import { useHistory } from 'react-router-dom';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

import { AletheaMedicalCapacitorCamera } from "@alethea-medical/capacitor-camera";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PluginListenerHandle } from '@capacitor/core';
import isIOS from '../../../models/isIOS';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
       button: {
           color: "white"
       }
    }),
);
const MobileCameraButton = () => {
    const classes = useStyles();
    const history = useHistory();
    const [uploadsRemaining, setUploadsRemaining] = useState(0)
    const [uploadErrorMessage, setUploadErrorMessage] = useState("")

	const {
        uploadFileFromMobileURL,
        processState,
    } = useUploadMediaFile({ updateRemainingUploads: setUploadsRemaining, mobileUploadErrorOccured: setUploadErrorMessage });




    const openCameraHandler = () =>
    {
        if ( isIOS())
        {
            AletheaMedicalCapacitorCamera.launchCamera()
            .catch((error: Error) => {
                alert(error.message);
            })
        } 
        else
        {
            AletheaMedicalCapacitorCamera.launchUsbCamera()
            .then((usbResult) => {
                if ( usbResult.status_code === 0 )
                {
                    if ( usbResult.exit_code === "exit_no_device" )
                    {
                        return AletheaMedicalCapacitorCamera.launchCamera()
                    }
                }
            })
            .catch((error: Error) => {
                alert(error.message);
            })
        }
    }


    const imageListenerRef = useRef<PluginListenerHandle>();
    const videoListenerRef = useRef<PluginListenerHandle>();
    const galleryListenerRef = useRef<PluginListenerHandle>();



    useEffect(() => {
        //Remove pre-existing listeners on mount (if they exist)
        removeListeners();

        //Add listeners for image and video captuer
        addListeners();
        
        //Remove listeners on component unmount
        return () => {
            removeListeners();
        }
    }, [])
    

    //Send upload finished event. Any components using the useMobileCameraListeners event will be notified (Gallery listens to refresh)
    useEffect(() => {
        if(uploadsRemaining === 0) {
            document.body.dispatchEvent(new Event("onMobileCameraUploadsFinished"))
        }
    }, [uploadsRemaining])

    
    const addListeners = async () => {
        imageListenerRef.current = await AletheaMedicalCapacitorCamera.addListener('onImageCaptured', (info) => {
            console.log("Received image capture event")
            return uploadFileFromMobileURL(info.url, "image")
        })

        videoListenerRef.current = await AletheaMedicalCapacitorCamera.addListener('onVideoCaptured', (info) => {
            return uploadFileFromMobileURL(info.url, "video")
        })

        galleryListenerRef.current = await AletheaMedicalCapacitorCamera.addListener('onGalleryPressed', () => {
            history.push("/dashboard/gallery")
        })
    }

    const removeListeners = () => {
        if(imageListenerRef.current !== undefined)
            imageListenerRef.current.remove()
        if(videoListenerRef.current !== undefined)
            videoListenerRef.current.remove()
    }

    const isUploading = () => {
        return processState === ProcessState.running
    }

    const getUploadingMessage = () => {
        return `${uploadsRemaining} upload${uploadsRemaining > 1 ? "s" : ""} remaining. Do not close the app.`;
    }

    const getProcessState = () => {
        if(uploadErrorMessage !== "" && !isUploading()) {
            return ProcessState.error
        }
        else {
            return processState
        }
    }

    return (
        <>
            <IconButton onClick={openCameraHandler}>
                <PhotoCameraIcon className={classes.button}/>
            </IconButton>
            {/* Show modal if you want to stop user from navigating during upload */}
            {/* <PaperModal show={isUploading()} flexHeight flexWidth>
                <ProcessStatus state={ProcessState.running} loadingMessage={getUploadingMessage()}/>
            </PaperModal> */}
            {/* Use Process state to display error messages. Allow user to close */}
            <ProcessStatus state={getProcessState()} setState={() => {
                setUploadErrorMessage("")
            }} errorMessage={uploadErrorMessage} useSnackbar loadingMessage={getUploadingMessage()}/>
        </>
    );
}

export default MobileCameraButton;