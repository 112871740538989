import { useState, useEffect, useCallback } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Utilities } from "../../../../shared/types";
import useAITranslation from "../../useAITranslation";
import { UserMediaMetadataItem } from "../Models/GalleryModel";
import GalleryRenderer from "./GalleryRenderer";
import MediaViewerController from "../Controllers/MediaViewerController";
import GalleryGrid from "./GalleryGrid";
import MultiMediaViewer from "./MediaViewer/MultiMediaViewer";
import {
  getCleanedFileNameFromActivityPath,
  getMockMetadataForReferralMedia,
} from "../Models/MessagingGalleryModel";
import {
  PaperModal,
  useSizeManager,
} from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkMedia: {
      marginBottom: theme.spacing(2),
    },
    mediaHeader: {
      fontWeight: 500,
    },
    mediaViewerContainer: {
      height: "100%",
    },
  }),
);

interface MessagingGalleryProps {
  referralMediaURIs: string[];
  econsultImageLabels?: Utilities.StringDict<string[]>;
}

export const MessagingGallery = ({
  referralMediaURIs,
  econsultImageLabels,
}: MessagingGalleryProps) => {
  const classes = useStyles();

  const [linkMedia, setLinkMedia] = useState<UserMediaMetadataItem[]>([]);
  const [mediaItems, setMediaItems] = useState<UserMediaMetadataItem[]>([]);

  const { aiToSp } = useAITranslation({ useSpToAi: true });

  const getDoctorLabels = (
    item: UserMediaMetadataItem,
  ): string[] | undefined => {
    return econsultImageLabels !== undefined &&
      econsultImageLabels[item.data.filePath] !== undefined
      ? econsultImageLabels[item.data.filePath].map((label) =>
          aiToSp(label, true),
        )
      : undefined;
  };

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        enableSelect={false}
        editing={false}
        handleOpenMedia={openMediaItemHandler}
        labels={getDoctorLabels(photo)}
      />
    ),
    //Refresh on aiToSp, since it takes a sec to load
    [aiToSp],
  );

  useEffect(() => {
    getMockMetadataForReferralMedia(referralMediaURIs).then(
      ({ otherMedia, imageVideoMedia }) => {
        // Sort media by their IDs
        otherMedia.sort((a, b) => a.id.localeCompare(b.id));
        imageVideoMedia.sort((a, b) => a.id.localeCompare(b.id));

        setLinkMedia(otherMedia);
        setMediaItems(imageVideoMedia);
      },
    );
  }, [referralMediaURIs]);

  const { sizeRef, height: mediaViewerHeight, updateSize } = useSizeManager();

  return (
    <>
      <Box className={classes.linkMedia}>
        <Typography className={classes.mediaHeader}>Files</Typography>
        <ul>
          {linkMedia.map((file) => (
            <li key={`econsult_file_${file.id}`}>
              <div>
                <a
                  href={file.data.fileDownloadUrl ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getCleanedFileNameFromActivityPath(file.data.filePath)}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </Box>
      <Typography className={classes.mediaHeader}>Images and Videos</Typography>
      <GalleryGrid photos={mediaItems} galleryRenderer={galleryRenderer} />
      {openMediaItem !== undefined && (
        <PaperModal
          show={openMediaItem !== undefined}
          setShow={(show) => {
            if (!show) closeMediaItemHandler();
          }}
          updateSize={updateSize}
          enablePadding
        >
          <div className={classes.mediaViewerContainer} ref={sizeRef}>
            <MultiMediaViewer
              height={mediaViewerHeight}
              currentMediaItemId={openMediaItem.id}
              mediaItems={mediaItems}
              closeMediaItem={closeMediaItemHandler}
              doctorLabels={getDoctorLabels(openMediaItem)}
              allowAIDatabaseCaching={false}
            />
          </div>
        </PaperModal>
      )}
    </>
  );
};

export default MessagingGallery;
