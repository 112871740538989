
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import palette from '../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        draftSubjectTextbox: {
            padding: theme.spacing(1),
            backgroundColor: palette.darkGreen,
            color: "white"
        }
    })
)
interface ConsultDraftSubjectProps {
    draftSubject: string,
}

const ConsultDraftSubject = ({ draftSubject }: ConsultDraftSubjectProps) => {
    const classes = useStyles();
    return (
        <Paper className={classes.draftSubjectTextbox}>
            <Typography>Draft: {draftSubject !== "" ? draftSubject : "No Subject"}</Typography>
        </Paper>
    );
}

export default ConsultDraftSubject;