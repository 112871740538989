
import firebase from "firebase";

interface Timestamp1 {
    _seconds: number, _nanoseconds: number
}
interface Timestamp2 {
    seconds: number, nanoseconds: number
}

// When a timestamp is passed from the backend to the frontend, it loses its functions since its turned into JSON
// Use this to convert it back into a normal timestamp
function parseJSONTimestamp(timestamp: Timestamp1 | Timestamp2 | undefined) {
    
    if((timestamp as Timestamp1)?._seconds !== undefined && (timestamp as Timestamp1)?._nanoseconds !== undefined) {
        return new firebase.firestore.Timestamp((timestamp as Timestamp1)._seconds, (timestamp as Timestamp1)._nanoseconds);
    }
    else if((timestamp as Timestamp2)?.seconds !== undefined && (timestamp as Timestamp2)?.nanoseconds !== undefined) {
        return new firebase.firestore.Timestamp((timestamp as Timestamp2).seconds, (timestamp as Timestamp2).nanoseconds);

    }
    else {
        return firebase.firestore.Timestamp.fromMillis(0)
    }
}

export default parseJSONTimestamp