import Typography from '@material-ui/core/Typography';
import EconsultCard from "./EconsultCard";
import { formatTimeDateThenTime } from "../../../../models/formatTime";
import palette from "../../../../palette";
import Alert from "@material-ui/lab/Alert";
import { GetPaymentsResponse } from "./ActivityPaymentList";

function statusToColor(status: string) {
    switch (status) {
        case "approved":
            return "green"
        case "declined":
            return "red"
        case "pending":
            return palette.darkGreen
        default:
            return undefined
    }
}


interface ActivityPaymentProps {
    payment: GetPaymentsResponse,
}

const ActivityPayment = ({ payment }: ActivityPaymentProps) => {
    
    return (
        <EconsultCard title={payment.payment.name}>
            <Typography style={{ color: statusToColor(payment.payment.status) }}>{payment.payment.status.toUpperCase()}</Typography>
            {payment.payment.status === "pending" && 
                <Alert severity="warning">Retry this payment by pressing the Payment button above</Alert>
            }
            <Typography>{formatTimeDateThenTime(payment.payment.statusUpdatedAt.toDate())}</Typography>
            <Typography>{`Total amount paid: $${(payment.payment.amount/100).toFixed(2)}`}</Typography>
            <Typography>Recorded by: {payment.recordedBy}</Typography>
            {(payment.payment.paymentType === "clover" && payment.payment.receiptLink !== undefined) && 
                <Typography><a href={payment.payment.receiptLink} target="_blank" rel="noopener noreferrer">View Receipt</a></Typography>
            }
            {payment.payment.paymentType === "external" && 
                <Typography>External Payment</Typography>
            }
        </EconsultCard>
    );
}

export default ActivityPayment;