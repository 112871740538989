import React from 'react';
import clsx from "clsx";
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(1),
        },
        header: {
            paddingLeft: theme.spacing(2),
            backgroundColor: theme.palette.primary.light
        },
        item: {
            paddingLeft: theme.spacing(7),
            backgroundColor: theme.palette.secondary.light
        }
    }),
);

interface LinkPreviewerContainerProps {
    header?: boolean
    item?: boolean
}

const LinkPreviewerContainer: React.FC<LinkPreviewerContainerProps> = ({ header, item, children  }) => {
    const classes = useStyles();
    return (
        <ListItem className={clsx(classes.container, {
            [classes.header]: header,
            [classes.item]: item
        })}>
            {children}
        </ListItem>
    );
}

export default LinkPreviewerContainer;