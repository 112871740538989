import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';



interface SearchBarProps {
    enableSearch: boolean,    
    runSearch: (params: string[]) => void,
    clearSearch: () => void,
    placeholderText: string
}



const DraftSearchBar = ({ enableSearch, runSearch, clearSearch, placeholderText }: SearchBarProps) => {

    const [searchSubject, setSearchSubject] = useState<string>("")
    
    const handleSearch = () => {
        runSearch([searchSubject]);
    }

    const handleClearSearch = () => {
        setSearchSubject("")
        clearSearch()
    }

    return (
        <>                
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={12} md={8}>    
                    <TextField
                        value={searchSubject}
                        onChange={(e) => {
                            setSearchSubject(e.target.value);
                        }}
                        placeholder={placeholderText}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onKeyPress={(e) => {
                            if(e.key === "Enter") {
                                handleSearch();
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSearch}>Search</Button>
                        </Grid>
                        {enableSearch && 
                            <Grid item>
                                <Button variant="outlined" color="primary" onClick={handleClearSearch}>Clear</Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default DraftSearchBar