import { Activity } from "@alethea-medical/aletheamd-types";

const roleOrder: Activity.ActivityRole.ActivityRole[] = ["referrer", "consultant", "moa", "forwarder"]

function getRoleAsNumber(role: Activity.ActivityRole.ActivityRole) {
    let idx = roleOrder.indexOf(role)
    if(idx === -1) //If not in list, then default to last
        idx = roleOrder.length
    return idx
}

/** Used to sort activity user profiles by activity role. Order is determined by role order array 
 * In the case of a tie, alphabetical order is used
*/
function sortProfilesByActivityRole(a: Activity.ActivityUser, b: Activity.ActivityUser) {
    const roleResult = getRoleAsNumber(a.activityRole) - getRoleAsNumber(b.activityRole)
    if(roleResult === 0)
        return `${a.firstName} ${a.lastName}` >= `${b.firstName} ${b.lastName}` ? 1  : -1
    return roleResult
}

export default sortProfilesByActivityRole