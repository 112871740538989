import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import isWeb from "../../models/isWeb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
    },
    searchField: {
      marginRight: theme.spacing(1),
    },
    helperText: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1.5),
    },
    noMarginBottom: {
      "& .MuiTextField-root": {
        marginBottom: 0,
      },
    },
    autocomplete: {
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px 4px !important",
        minHeight: "44px",
      },
      "& .MuiAutocomplete-input": {
        padding: "6px 4px !important",
      },
      "& .MuiChip-root": {
        height: 28,
        margin: "3px 2px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[400],
      },
    },
    searchBarContainer: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
      gap: theme.spacing(1),
    },
    searchFieldContainer: {
      flexGrow: 1,
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: theme.spacing(1),
        width: "100%",
      },
    },
    buttonsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    button: {
      whiteSpace: "nowrap",
      height: "44px",
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
  }),
);

interface SearchBarProps {
  enableSearch: boolean;
  runSearch: (searchTerms: string[]) => void;
  clearSearch: () => void;
  maxSearchTerms?: number;
  placeholderText: string;
}

export const SearchBar = ({
  enableSearch,
  runSearch,
  clearSearch,
  maxSearchTerms = 10,
  placeholderText,
}: SearchBarProps) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleSearch = () => {
    if (searchParams.length > 0) {
      runSearch(searchParams);
    }
  };

  const handleClearSearch = () => {
    setSearchParams([]);
    clearSearch();
  };

  useEffect(() => {
    if (searchParams.length === 0 && enableSearch) {
      clearSearch();
    }
  }, [searchParams]);

  const getHelperText = () => {
    if (inputValue.length > 0 || searchParams.length > 0) {
      return "Press enter to add multiple search terms";
    }
    return undefined;
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <div className={classes.searchBarContainer}>
          <div className={classes.searchFieldContainer}>
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={searchParams}
              filterSelectedOptions
              autoSelect
              limitTags={maxSearchTerms}
              onChange={(e, value) => {
                setSearchParams(
                  (value as string[]).map((str) => {
                    try {
                      return str.trim();
                    } catch (e) {
                      return str;
                    }
                  }),
                );
              }}
              onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholderText}
                  variant="outlined"
                  margin="none"
                  size="medium"
                  InputProps={{
                    ...params.InputProps,
                    style: { paddingTop: 0, paddingBottom: 0 },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: "10px 4px" },
                    enterkeyhint: "done",
                  }}
                />
              )}
              className={classes.autocomplete}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              size="medium"
              className={classes.button}
            >
              Search
            </Button>
            {enableSearch && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClearSearch}
                size="medium"
                className={classes.button}
              >
                Clear
              </Button>
            )}
          </div>
        </div>
      </Grid>
      {isWeb() && getHelperText() && (
        <Grid item>
          <Typography variant="caption" className={classes.helperText}>
            {getHelperText()}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
