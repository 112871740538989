import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { UserMediaMetadataSettings } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../../../firebase";

/**
 * Subscribe to user media metadata settings
 * @param uid User's UID
 * @param setTags Callback to set tags
 * @returns Unsubscribe
 */
export function getUserMediaMetadataSettings(uid: string, setTags: (tags: string[]) => void, errorHandler: (error: Error) => void): () => void
{   
    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).onSnapshot((doc) => {
        if(doc.exists) {
            const data = doc.data() as UserMediaMetadataSettings | undefined;
            if(data?.userTags !== undefined) {
                setTags(data.userTags)
                return
            }
        }
        setTags([])
    }, errorHandler)
}

export function updateUserTags(uid: string, tags: string[]): Promise<void> 
{
    const updateObj: Partial<UserMediaMetadataSettings> = {
        userTags: tags
    }
    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).set(updateObj, {merge: true})
    .then(() => {
        return;
    })
}
