import { isMobile } from "react-device-detect";

/**
 * Returns true if device is mobile or tablet
 * Does not care if on web browser or native app, 
 * will return true as long as its a mobile device
 */
function isMobileDevice() {
    return isMobile;
}

export default isMobileDevice;