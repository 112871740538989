import React, { useEffect, useState } from 'react';
import { SearchProps } from '../Controllers/GalleryController';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button, { ButtonProps } from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';
import MediaEditTags from './MediaViewer/MediaEditTags';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';

type DateChoices = "week" | "month" | "6month" | "year" | "custom"

interface GallerySearchBarProps {
    searchProps: SearchProps
}

const GallerySearchBar = ({ searchProps }: GallerySearchBarProps) => {
    const { searchDateFrom, setSearchDateFrom,
        searchDateTo, setSearchDateTo,
        searchNotes, setSearchNotes,
        searchTags, setSearchTags,
        enableSearch,
        performSearch, cancelSearch } = searchProps;

    const [show, setShow] = useState(false)
    const [dateRangeChoice, setDateRangeChoice] = useState<DateChoices>("week")

    const handleShow = () => {
        setShow(!show)
    }

    const handleChangeDateFrom = (date: MaterialUiPickersDate, keepChoice?: boolean) => {
        //Don't change choice if called from date range picker
        //Otherwise, if date changed by user, change choice to custom
        if(!keepChoice)
            setDateRangeChoice("custom")

        if(date !== null) {
            const startOfDay = date.clone().startOf('day');
            setSearchDateFrom(startOfDay.toDate());
        }
    }

    const handleChangeDateTo = (date: MaterialUiPickersDate, keepChoice?: boolean) => {
        if(!keepChoice)
            setDateRangeChoice("custom")

        if(date !== null) {
            const endOfDay = date.clone().endOf('day');
            setSearchDateTo(endOfDay.toDate());
        }
    }

    const handleClearDateFrom = () => {
        setSearchDateFrom(null)
        clearNotesIfDisabled(null, searchDateTo, searchTags)
        setDateRangeChoice("custom")
    }


    const handleClearDateTo = () => {
        setSearchDateTo(null)
        clearNotesIfDisabled(searchDateFrom, null, searchTags)
        setDateRangeChoice("custom")
    }

    const handleTagsChanged = (newTags: string[]) => {
        setSearchTags(newTags)
        clearNotesIfDisabled(searchDateFrom, searchDateTo, newTags)
    }

    const clearNotesIfDisabled = (searchDateFrom: Date | null, searchDateTo: Date | null, searchTags: string[]) => {
        if(searchDateFrom === null && searchDateTo === null && searchTags.length === 0)
            setSearchNotes("")
    }

    const isSearchButtonDisabled = () => {
        return searchDateFrom === null && searchDateTo === null && searchTags.length === 0
    }

    const dateRangeChangedHandler = (choice: DateChoices) => {
        const from = moment()
        const to = moment()
        setDateRangeChoice(choice)

        switch(choice) {
            case "week":
                from.subtract(7, 'd')
                handleChangeDateFrom(from, true)
                handleChangeDateTo(to, true)
                break;
            case "month":
                from.subtract(1, 'M')
                handleChangeDateFrom(from, true)
                handleChangeDateTo(to, true)
                break;
            case "6month":
                from.subtract(6, 'M')
                handleChangeDateFrom(from, true)
                handleChangeDateTo(to, true)
                break;
            case "year":
                from.subtract(1, 'y')
                handleChangeDateFrom(from, true)
                handleChangeDateTo(to, true)
                break;
            case "custom":
                break;   
            default:
                break;
        }

    }

    useEffect(() => {
        //Set initial date range on first load
        dateRangeChangedHandler(dateRangeChoice)
    }, [])


    return (
        <Grid container spacing={1} alignItems="center"> 
            <Grid item>
                <Button onClick={handleShow} variant="outlined" color="primary" startIcon={<SearchIcon/>}>{show ? "Hide" : "Show"} Search</Button>
            </Grid>
            {!show && enableSearch && 
                <Grid>
                    <Button color="primary" variant="outlined" onClick={cancelSearch}>Clear Search</Button>
                </Grid>
            }
            <Grid item xs={12}>
                <Collapse in={show}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} md={4}>
                            <Select
                                defaultValue="week"
                                value={dateRangeChoice}
                                onChange={(e) => {dateRangeChangedHandler(e.target.value as DateChoices)}}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            >
                                <MenuItem value="week">Last Week</MenuItem>
                                <MenuItem value="month">Last Month</MenuItem>
                                <MenuItem value="6month">Last 6 Months</MenuItem>
                                <MenuItem value="year">Last Year</MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                        </Grid>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid item xs={6} md={4}>
                                <DatePicker
                                    views={["year", "month", "date"]}
                                    format="DD/MM/yyyy"
                                    variant="inline" 
                                    inputVariant="outlined"
                                    margin="dense"
                                    autoOk 
                                    label={"From"} 
                                    value={searchDateFrom} 
                                    fullWidth
                                    onChange={handleChangeDateFrom}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={(e) =>  {e.stopPropagation(); handleClearDateFrom()}}>
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <DatePicker
                                    views={["year", "month", "date"]}
                                    format="DD/MM/yyyy"
                                    variant="inline" 
                                    inputVariant="outlined"
                                    margin="dense"
                                    autoOk 
                                    label={"To"} 
                                    value={searchDateTo} 
                                    fullWidth
                                    onChange={handleChangeDateTo}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={(e) =>  {e.stopPropagation(); handleClearDateTo()}}>
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={4}>
                            <MediaEditTags tags={searchTags} tagsChanged={handleTagsChanged} placeholder="Tags" saveNewTags={false}/>
                        </Grid>
                        {/* Hide grid item if collapsed */}
                        <Grid item xs={12} md={4} style={{ display: searchDateFrom !== null || searchDateTo !== null || searchTags.length > 0 ? "initial" : "none" }}>
                            <Grow in={searchDateFrom !== null || searchDateTo !== null || searchTags.length > 0} >
                                <TextField
                                    label="Filter Notes"
                                    value={searchNotes}
                                    onChange={(e) => { setSearchNotes(e.target.value)}}
                                    margin="dense"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grow>
                        </Grid>
                        <Grid item>
                            <SearchButton onClick={performSearch} disabled={isSearchButtonDisabled()}>Search</SearchButton>
                        </Grid>       
                        <Grid item>
                            {enableSearch && 
                                <Button color="primary" variant="outlined" onClick={cancelSearch}>Clear Search</Button>
                            }
                        </Grid>              
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
}

interface SearchButtonProps extends ButtonProps {

}

const SearchButton = (props: SearchButtonProps) => (
    <Button {...props} variant={props.disabled ? "outlined" : "contained"} color="primary" startIcon={<SearchIcon/>} fullWidth>{props.children}</Button>
)



export default GallerySearchBar;