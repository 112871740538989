import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../AuthProvider';
import { getUserMediaMetadataSettings, updateUserTags } from '../Models/MediaEditTagsModel';

const MediaEditTagsController = () => {

    const authContext = useContext(AuthContext);
    const [userTags, setUserTags] = useState<string[]>([])

    const updateTagsHandler = (tags: string[]) => {
        //Filter out tags that are already in userTags
        const newTags = tags.filter((t) => !userTags.includes(t))
        if(newTags.length > 0) {
            const updatedTags = userTags.concat(newTags);
            updateUserTags(authContext.uid, updatedTags)
            .then(() => {
                setUserTags(updatedTags)
            })   
            .catch((error: Error) => {
                console.error(error);
            })
        }
    }

    const deleteTagHandler = (tag: string) => {
        //Remove tag from user tags list
        const updatedTags = [...userTags]
        const idx = updatedTags.indexOf(tag)
        if(idx !== -1) {
            updatedTags.splice(idx, 1)
            updateUserTags(authContext.uid, updatedTags)
            .then(() => {
                setUserTags(updatedTags)
            })   
            .catch((error: Error) => {
                console.error(error);
            })
        }
    }

    useEffect(() => {
        if(authContext.uid !== "") {
            //Subscribe to changes in user settings
            const unsubscribe = getUserMediaMetadataSettings(authContext.uid, setUserTags, (error: Error) => {
                console.error(error);
            })
            //Unsubscribe when component unmounts
            return unsubscribe;
        }
    }, [authContext])

    return {
        userTags,
        updateTagsHandler,
        deleteTagHandler
    }
}

export default MediaEditTagsController;