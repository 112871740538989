import React from "react";
import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BillingRecord } from "@alethea-medical/admin-types";
import { HeadCell } from "../types";
import { formatStatus, isTimestampField } from "../billingFunctions";
import firebase from "firebase"

interface BillingRowProps {
    headCells: HeadCell[],
    data: BillingRecord,
    index: number
}


const useStyles = makeStyles((theme) => ({
    statusCell: {
        textAlign: "center"
    },
    tableCell: {
        overflow: 'auto',
    },
    subTable: {
        overflow: 'hidden',
    },
}));

const BillingTableRow = ({ headCells, data, index }: BillingRowProps) => {
    const classes = useStyles();

    const getFormattedRow = (headCell: HeadCell, data: BillingRecord) => {
        if(isTimestampField(headCell.id)) {
            // Need to force type otherwise typescript yells at me
            return data[headCell.id] !== undefined ? (data[headCell.id] as firebase.firestore.Timestamp).toDate().toLocaleString() : "N/A"

        }
        else if(headCell.id === 'status') {
            return formatStatus(data[headCell.id]);
        }
        else {
            return data[headCell.id] !== undefined ? data[headCell.id] : "N/A"
        }

    }

    return (
        <>
            <TableRow
                tabIndex={-1}
            >
                {headCells.map((headCell) => 
                    <TableCell className={classes.tableCell} align="left" key={`billing_row_${index}_${headCell.id}`}>
                        {getFormattedRow(headCell, data)}
                    </TableCell>
                 )}
            </TableRow>
        </>
    );
}

export default BillingTableRow;