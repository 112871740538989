import { dbNames } from "@alethea-medical/aletheamd-db-keys"
import { SpecialistAvailability } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../../../../firebase"
import firebase from "firebase"

export interface VacationPeriodItem  {
    data: SpecialistAvailability.VacationPeriod,
    id: string | undefined
}

export function sortVacation (a: VacationPeriodItem, b: VacationPeriodItem): number {
    if(a.data.start < b.data.start) return -1
    else if(a.data.start > b.data.start) return 1
    else return 0
}

export function fetchVacation(uid: string): Promise<VacationPeriodItem[]> {
    return fbFirestore.collection(dbNames.specialistAvailabilitySettings).doc(uid)
    .collection(dbNames.specialistAvailabilitySettings_vacation).where("end", ">=", firebase.firestore.Timestamp.now()).get()
    .then((snapshot) => {
        return snapshot.docs.map((doc) => {
            return {
                data: (doc.data() as SpecialistAvailability.VacationPeriod),
                id: doc.id
            }
        }).sort(sortVacation)
    })
}

export function validateVacation(vacation: VacationPeriodItem[]): boolean {
    return vacation.every((v) => v.data.start < v.data.end)
}

export function saveVacation(uid: string, vacation: VacationPeriodItem[], toDelete: string[]): Promise<VacationPeriodItem[]> {
    const batch = fbFirestore.batch()
    const collectionRef = fbFirestore.collection(dbNames.specialistAvailabilitySettings).doc(uid).collection(dbNames.specialistAvailabilitySettings_vacation)
    vacation.forEach((v) => {
        // If ID is undefined, a new ID will be created
        const ref = collectionRef.doc(v.id)
        if(v.id === undefined)
            v.id = ref.id // Update ID with new ID if was previously undefined (ie a new item)

        ref.set(v.data)
    })

    // Delete records marked for deletion
    toDelete.forEach((deleteId) => {
        const ref = collectionRef.doc(deleteId)
        ref.delete()
    })

    // Update update flag document to trigger events based on vacation settings (such as emailing sales)
    batch.set(collectionRef.doc("0_update_flag"), { numUpdates: firebase.firestore.FieldValue.increment(1) }, { merge: true })

    return batch.commit()
    .then(() => vacation )
}