import React, { useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import {
  useForm,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";
import { AuthContext } from "../../../AuthProvider";
import serverRequest from "../../../models/serverRequest";
import { logAnalyticsEvent } from "../../../firebase";
import {
  PaperPage,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { FormsSelect } from "./FormsSelect";
import LocationDropdown from "../../../components/LocationDropdown";
import PatientRow from "./PatientRow";
import AddPatientButton from "./AddPatientButton";
import DelayAllCheckbox from "./DelayAllCheckbox";
import FormSendButton from "./FormSendButton";
import SuccessModal from "../../../components/SuccessModal";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import HelpModal from "../../../components/HelpModal";
import SelectSendOnBehalfOf from "../../../components/SelectSendOnBehalfOf";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import usePermissions from "../../../components/usePermissions";

export interface FormPatient {
  email: string;
  phn: string;
  delay: boolean;
}

const FormSender = () => {
  const { handleSubmit, control, setValue, reset, watch, getValues } = useForm({
    mode: "onTouched",
  });
  const { isDirty } = useFormState({ control: control });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "patients",
  });
  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });
  const sendingOnBehalfOfOption = useWatch({
    control,
    name: "sendingOnBehalfOfOption",
  });
  const [optionMustBeChosen, setOptionMustBeChosen] = React.useState(true);

  const authContext = useContext(AuthContext);
  const locationIdx = watch("locationIdx");

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    reset({
      locationIdx: getValues("locationIdx"),
      patients: [
        {
          phn: "",
          email: "",
        },
      ],
    });
  }, []);

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [authContext.user, sendMsgOnBehalfOfPhysicianOrClinic]);

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [locationIdx, sendingOnBehalfOfOption]);

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);

    logAnalyticsEvent("form_sender_start", {
      numPatients: data.patients.length,
      form: data.selectedForm.name,
    });

    //Build body for https request
    const body: any = {
      physicianUid: authContext.uid,
      locationIdx: data.locationIdx,
      formId: data.selectedForm.uid,
      patients: data.patients.map((patient: FormPatient) => {
        return {
          phn: patient.phn,
          email: patient.email,
          delay: patient.delay,
        };
      }),
    };

    if (data.sendingOnBehalfOfOption) {
      body.sendingOnBehalfOfOptionKind = data.sendingOnBehalfOfOption.isClinic
        ? "clinic"
        : "physician";
      body.sendingOnBehalfOfOptionUid = data.sendingOnBehalfOfOption.id;
    }

    //Send request to server to send forms
    serverRequest(
      authContext.user,
      { "Content-Type": "application/json" },
      JSON.stringify(body),
      "send-forms",
    )
      .then(() => {
        logAnalyticsEvent("form_sender_success");

        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);
        reset({
          locationIdx: data.locationIdx,
          selectedForm: data.selectedForm,
          patients: [],
          sendingOnBehalfOfOption: getValues("sendingOnBehalfOfOption"),
        });
        append({
          phn: "",
          email: "",
        });
      }) //Catch any errors on the server side. Will also catch error if all emails fail to send
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error sending forms",
          analyticsLog: "form_sender_failed",
        });
      });
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  return (
    <>
      <PaperPage enablePadding>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          autoComplete="off"
          aria-autocomplete="none"
        >
          <fieldset disabled={isDisabled()} aria-autocomplete="none">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <HelpModal
                  buttonText="About Forms"
                  helpText={[
                    "The selected form will be individually emailed to each patient.",
                    "If delay is checked, the form is sent the next day at 7:00 AM MST.",
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <LocationDropdown
                  name="locationIdx"
                  control={control}
                  label="Clinic"
                  disabled={isDisabled()}
                  setValue={setValue}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <SelectSendOnBehalfOf control={control} />
              </Grid>

              <Grid item xs={12}>
                <FormsSelect
                  control={control}
                  setValue={setValue}
                  disabled={isDisabled()}
                />
              </Grid>

              <Grid item xs={12}>
                <AddPatientButton append={append} disabled={isDisabled()} />
              </Grid>

              {/* Patient rows */}
              {fields.map((field, index) => (
                <PatientRow
                  key={field.id}
                  control={control}
                  index={index}
                  setValue={setValue}
                  remove={remove}
                  disabled={isDisabled()}
                  province={
                    authContext?.profile?.locations[locationIdx]?.province
                  }
                />
              ))}

              {/* Add button */}
              <Grid item xs={12}>
                <AddPatientButton append={append} disabled={isDisabled()} />
              </Grid>
              <Grid item xs={12}>
                {/* Delay email checkbox */}
                <DelayAllCheckbox control={control} setValue={setValue} />
              </Grid>
              <Grid item xs={12}>
                <ProcessStatus
                  state={processState}
                  errorMessage={processErrorMessage}
                  successMessage="Forms Sent Successfully"
                />
              </Grid>
              <Grid item xs={12}>
                <FormSendButton
                  control={control}
                  disabled={optionMustBeChosen || isDisabled()}
                />
              </Grid>
            </Grid>
          </fieldset>
        </form>
      </PaperPage>
      <SuccessModal
        text={"Forms sent successfully."}
        show={processState === ProcessState.success}
      />
      <LeavePagePrompt isDirty={isDirty} />
    </>
  );
};

export default FormSender;
