import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import { useContext, useEffect, useState } from "react";
import { Activity } from "../../../../../../shared/types";
import analyticsLogs from "../../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../../firebase";
import { ActivityContext } from "../../Inbox/Contexts/ActivityProvider";

interface OutcomesControllerProps {
    setShowOutcomesMenu?: (showOutcomesMenu: boolean) => void
}


const OutcomesController = ({ setShowOutcomesMenu=()=>{} }: OutcomesControllerProps) => {
    
    const [selectedOutcome, setSelectedOutcome] = useState<string>("");
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<string>("");

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });
    const activityContext = useContext(ActivityContext);

    useEffect(() => {
        if(activityContext.econsult.specialistResponse !== undefined) {
            setSelectedOutcome(activityContext.econsult.specialistResponse.outcome);
            setSelectedDiagnosis(activityContext.econsult.specialistResponse.diagnosis);
        } else if(activityContext.econsult.aiPredictions?.outcome !== undefined && selectedOutcome === "") {
            setSelectedOutcome(activityContext.econsult.aiPredictions.outcome.humanReadableLabel);
        }
    }, [activityContext.econsult])

    /** Save outcomes to activity and econsult. Always resolves, since errors are handled by this function. 
     * Returns true if no error, false if there was an error */
    const handleSubmitOutcomes = (): Promise<boolean> => {
        setProcessState(ProcessState.running);

        //Add to econsult
        const econsultUpdate: Partial<Activity.Econsult> = {
            specialistResponse: {
                outcome: selectedOutcome,
                diagnosis: selectedDiagnosis
            }
        }
        const activityUpdate: Partial<Activity.Activity> = {
            exposedEconsultData: econsultUpdate
        }

        //Duplicate data to make it easier to read from activity list for display purposes
        //It should belong in the eConsult though, so we put it there too.
        return activityContext.updateSharedActivity(activityUpdate)
        .then(() => {
            return activityContext.updateEconsult(econsultUpdate)
        })
        .then(() => {
            logAnalyticsEvent(analyticsLogs.econsultOutcomes.success, activityContext.econsult.specialty);
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle)
            }, 1000)
            setShowOutcomesMenu(false);
            return true
        })
        .catch((error: Error) => {
            errorHandler({
                userMessage: "Unable to log outcome in eConsult. Please try again or skip this step.",
                error: error,
                hideErrorMessage: true,
                analyticsLog: analyticsLogs.econsultOutcomes.fail,
                analyticsLogObj: activityContext.econsult.specialty
            });
            return false
        })
 
    }

    return {
        selectedOutcome, setSelectedOutcome,
        selectedDiagnosis, setSelectedDiagnosis,
        handleSubmitOutcomes,
        processState, processErrorMessage
    }
}

export default OutcomesController;