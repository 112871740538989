import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { parse } from "query-string";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { logAnalyticsEvent } from '../../../../firebase';
import { useProcessState, ProcessState } from '@alethea-medical/alethea-components';
import { exportDraftPDF } from '../../SecureMessaging/Utilities/exportPDF';
import analyticsLogs from '../../../../analyticsLogs';

interface useExportDraftPdfProps {
    draftId: string | undefined,
    subject: string,
    isDirty: boolean
}

const useExportDraftPdf = ({ draftId, subject, isDirty }: useExportDraftPdfProps) => {

	const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const params = parse(location.search);
    const draftCollection = params.collection ?? "user_drafts"

	const exportPDFHandler = () => {
        if(draftId === undefined)
            return;
        if(isDirty) {
            errorHandler({
                userMessage: "Save your draft before exporting."
            })
            return;
        }
            
		setProcessState(ProcessState.running);
		logAnalyticsEvent(analyticsLogs.consultDraft.export);
		return exportDraftPDF(draftCollection, draftId, subject)
			.then(() => {
				setProcessState(ProcessState.idle);
			})
			.catch((error: Error) => {
				errorHandler({
					error: error,
					userMessage: "Error exporting PDF",
					analyticsLog: analyticsLogs.consultDraft.exportFail
				});
			})
	}

    return {
        exportPdfState: processState, exportPdfErrorMessage: processErrorMessage, setExportPdfState: setProcessState,
        renderExportPdfButton: () => (
            <Tooltip
                title="Export Draft as PDF"
                children={
                    <Button
                        color="primary"
                        startIcon={<PictureAsPdfIcon />}
                        fullWidth
                        onClick={exportPDFHandler}
                    >
                        Export
                    </Button>
                }
            />
        )
    }
}

export default useExportDraftPdf;