import { ErrorHandlerOptions, ProcessState } from "@alethea-medical/alethea-components"
import analyticsLogs from "../../../analyticsLogs"
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { fbFunctions, logAnalyticsEvent } from "../../../firebase"
import Typography from '@material-ui/core/Typography';

interface AveiroExternalPaymentFormProps {
    visible: boolean,
    activityId: string
    itemCode: string | undefined,
    refetchPayments: () => void,
    setProcessState: (state: ProcessState) => void,
    processState: ProcessState,
    errorHandler: (options: ErrorHandlerOptions) => void
}

// Payment form that will record a external payment. Used for payments that are to be manually recorded by Aveiro
const AveiroExternalPaymentForm = ({ visible, activityId, itemCode, refetchPayments, setProcessState, processState, errorHandler}: AveiroExternalPaymentFormProps) => {
    const submitOatPayment = fbFunctions.httpsCallable("aveiro-submitOatPayment")

    const handleRetry = () => {
        if(itemCode === undefined)
            return;

        setProcessState(ProcessState.running)
        return submitOatPayment({ itemCode: itemCode, activityId: activityId, externalPayment: true })
        .then((result) => {
            if(result.data.ok) {
                // Show success page
                logAnalyticsEvent(analyticsLogs.aveiro.paymentSubmit.success)
                setProcessState(ProcessState.success)
            }
            else {
                errorHandler({
                    error: new Error(result.data.error),
                    userMessage: "Could not record payment. Reason",
                    analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail
                })
            }
        })
        .catch((error: Error) => {
            errorHandler({
                error,
                userMessage: "Error recording payment",
                analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail
            })
        })
        .finally(() => {
            // Refetch payments after recording
            refetchPayments()
        })
    }

    return (
        <Collapse in={visible}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Typography>Record this payment that was made outside of Alethea</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={handleRetry} variant="contained" color="secondary" disabled={processState === ProcessState.running} fullWidth>
                        Record Payment
                    </Button>
                </Grid>
            </Grid>
        </Collapse>
    );
}

export default AveiroExternalPaymentForm