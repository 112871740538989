import { resourceKeys } from "@alethea-medical/aletheamd-db-keys"
import { useContext, useEffect } from "react";
import { Activity } from "../../../../../../shared/types";
import { AuthContext } from "../../../../../AuthProvider";
import hasPermissions from "../../../../../models/hasPermissions";
import { SpecialistContext } from "../../Utilities/SpecialistProvider";

interface useEnableOutcomesProps {
    setEnable: (enable: boolean) => void,

    activity: Activity.Activity,
    /** Optional resource key to check permissions for. Defaults to econsultOutcomes */
    resourceKey?: string
}

/**
 * Sets the enable state provided in props depending on if the current user has
 * 1. User role permissions
 * 2. Is the specialist on the econsult
 * 3. Has a specialist profile
 * 4. Has enableEconsultDataCollection set to true on their specialist profile
 * 
 */
const useEnableOutcomes = ({ setEnable, activity, resourceKey=resourceKeys.econsultOutcomes }: useEnableOutcomesProps) => {

    const { specialist } = useContext(SpecialistContext);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        //Need to have specialist document, be a specialist in their profile, be the specialist on th e econsult, and have econsult data collection enabled        
        hasPermissions(resourceKey, authContext.profile)
        .then((allow) => {
            const newEnable = allow && activity.profiles[authContext.uid]?.activityRole === "consultant" && specialist?.enableEconsultDataCollection === true
            setEnable(newEnable)
        })
    }, [authContext.profile, specialist])
}

export default useEnableOutcomes;