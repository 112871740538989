import firebase from "firebase";
import { Service } from "@alethea-medical/aletheamd-types";

export interface ServiceItem {
    id: string,
    service: Service.Service;
}

function join(str1: string | undefined, str2: string | undefined, separator: string) {
    const builder: string[] = []
    if(str1) builder.push(str1)
    if(str2) builder.push(str2)
    return builder.join(separator)
}

export const getFormattedClinicCityProvinceFromService = (service: Service.Service): string => {
    const l = service?.clinic?.location;
    if (!l) return '';

    return join(join(l.city, l.division, ' '), l.province, ', ')
}

export const getFormattedClinicAddressFromService = (service: Service.Service): string => {
    const l = service?.clinic?.location
    if(!l) return '';

    return join(join(join(l.street, l.city, ', '), l.province, ', '), l.postalCode, ', ')
}

export const getFormattedUserNameWithCredsFromServiceUser = (user?: Service.ServiceUser): string => {
    if(!user) return '';

    return join(join(user.salutation, user.userName, ' '), getTitleAndCredsFromServiceUser(user), ', ')
}

export const getTitleAndCredsFromServiceUser = (user?: Service.ServiceUser): string => {
    if(!user) return '';

    return join(user.title, user.credentials, ' | ')
}

export function isServiceOnVacation(service: Service.Service) {
    const now = firebase.firestore.Timestamp.now();

    const onVacation = service.vacations?.some((vacation) => {

        // If start is before "now", then it overlaps (since "now" must also be less than end)
        // start <= now <= end
        if (vacation.start <= now && vacation.end >= now)
            return true;

        return false;
    });
    return onVacation;
}

/**
 * Finds the end date of the latest vacation that the service is currently on
 * @param service Service to check vacation for
 * @returns Timestamp of end date for vacation
 */
export function getLatestCurrentVacationEndDate(service: Service.Service): firebase.firestore.Timestamp | undefined {
    const now = firebase.firestore.Timestamp.now();
    let latestVacationEndDate: firebase.firestore.Timestamp | undefined = undefined;

    service.vacations?.forEach((vacation) => {
        if (vacation.start <= now && vacation.end >= now) {
            if (!latestVacationEndDate || vacation.end > latestVacationEndDate) {
                latestVacationEndDate = vacation.end;
            }
        }
    });
    return latestVacationEndDate;
}