import React from "react";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { makeStyles, Theme } from "@material-ui/core/styles";

export interface SidebarProps {
  className?: string;
  paperClassName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  isMobileSize: boolean;
  drawerWidthOpen: number;
  drawerWidthClosed: number;
}

const AletheaSidebar: React.FC<SidebarProps> = ({
  className,
  paperClassName,
  open,
  setOpen,
  isMobileSize,
  drawerWidthOpen,
  drawerWidthClosed,
  children,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      overflowX: "hidden",
    },
    drawerOpen: {
      transition: theme.transitions.create("width", {
        // should match transitions in AppBar
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: drawerWidthOpen,
      overflowX: "hidden",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidthClosed,
      overflowX: "hidden",
    },
  }));
  const classes = useStyles();

  return (
    <SwipeableDrawer
      variant={isMobileSize ? "temporary" : "permanent"}
      className={clsx(className, classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(paperClassName, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default AletheaSidebar;
