import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import { useState } from "react";
import analyticsLogs from "../../../../../analyticsLogs";
import { fbFunctions, logAnalyticsEvent } from "../../../../../firebase";

interface InterpretationsControllerProps {
    activityId: string,
}

const InterpretationsController = ({ activityId }: InterpretationsControllerProps) => {
    
    const [billAudiogram, setBillAudiogram] = useState(false)
    const [billSinusEndoscopy, setBillSinusEndoscopy] = useState(false)

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });
    const billInterpretation = fbFunctions.httpsCallable("interpretations-billInterpretation")

    /**
     * 
     * @returns True if successful, false if error
     */
    const bill = (): Promise<boolean> => {
        setProcessState(ProcessState.running)
        return billInterpretation({activityId, options: { billAudiogram, billSinusEndoscopy }})
        .then(() => {
            if(billAudiogram)
                logAnalyticsEvent(analyticsLogs.interpretation.audiogram.bill)
            if(billSinusEndoscopy)
                logAnalyticsEvent(analyticsLogs.interpretation.sinusEndoscopy.bill)

            setProcessState(ProcessState.success)
            return new Promise<boolean>((resolve) => {
                setBillAudiogram(false)
                setBillSinusEndoscopy(false)

                setTimeout(() => {
                    setProcessState(ProcessState.idle)
                    resolve(true)
                }, 1000)
            })
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error occurred while billing interpretations. Please try again",
                analyticsLog: analyticsLogs.interpretation.billFail
            })
            setBillAudiogram(false)
            setBillSinusEndoscopy(false)
            
            return Promise.resolve(false)

        })   
    }

    return {
        bill,
        billAudiogram, setBillAudiogram,
        billSinusEndoscopy, setBillSinusEndoscopy,
        processState, processErrorMessage
    }
}

export default InterpretationsController;