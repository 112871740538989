import React, { useContext, useState } from "react";
import { useWatch, Control } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Favorite from '@material-ui/icons/Favorite';
import Unfavorite from '@material-ui/icons/FavoriteBorder';
import { AuthContext } from "../../../AuthProvider";
import { FormListItem } from "./FormsSelect";
import { useEffect } from "react";
import { fbFirestore } from "../../../firebase";
import { sortForms } from "./sortForms";
import { useFavorites } from "./useFavorites";
import { getDocumentData } from "@alethea-medical/utilities";


interface AddFavoriteProps {
    control: Control,

    disabled?: boolean
    isLoading: boolean
    formList: FormListItem[]
    setFormList: React.Dispatch<React.SetStateAction<FormListItem[]>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}


const AddFavoriteButton = ({ control, disabled, formList, setFormList, isLoading, setLoading }: AddFavoriteProps) => {
    const selectedForm = useWatch({ control, name: "selectedForm", defaultValue: undefined });
    const [favorites, setFavorites] = useState<any[]>([]);
    const authContext = useContext(AuthContext);



    //pull from firestore the doc containing list of favorites
    useEffect(() => {
        fbFirestore.collection("form_users").doc(authContext.uid).get().then(getDocumentData)
            .then((prefs: { privateForms: string[], favoritedForms: string[] }) => {
                if (prefs.favoritedForms !== undefined)
                    setFavorites(prefs.favoritedForms);
            })
            .catch((error) => { console.log("Error getting document: ", error); });

    }, [authContext.uid])


    //ensure that formList has been populated so that initial load of favorites works
    useEffect(() => {
        formList.length > 0 ? setLoading(false) : setLoading(true);
    }, [formList.length])

    //apply any changes made to the favorites
    useEffect(() => {
        if (!isLoading && favorites !== undefined) {
            const tempFormList = [...formList]
            favorites.map((favoritedItem: string) => {
                if (formList !== undefined) {
                    const favoriteIndex = formList.findIndex(x => x.uid === favoritedItem);
                    if (favoriteIndex !== -1) {
                        const tempFormItem = formList[favoriteIndex];
                        tempFormItem.favoritedStatus = "Favorites";
                        tempFormList[favoriteIndex] = tempFormItem;
                    }
                }
            });
            setFormList(tempFormList)
            sortForms(formList)
        }
    }, [favorites, isLoading])


    //sort the forms based on whether they are favorited or not and then alphabetically
    useEffect(() => { sortForms(formList) }, [formList])

    //update db
    useFavorites(favorites, selectedForm)



    //favorite button press 
    const handleClick = () => {
        if (selectedForm !== undefined) {
            if (favorites.includes(selectedForm.uid)) {
                //remove the favorited item from the users favorites preferences 
                const index = formList.findIndex(item => item.uid === selectedForm.uid)
                const tempFormList = [...formList]
                const tempFormItem = formList[index];
                tempFormItem.favoritedStatus = "Forms";
                tempFormList[index] = tempFormItem;
                setFormList(tempFormList)
                setFavorites(favorites => favorites = favorites.filter(item => item !== selectedForm.uid));
            } else {
                //add favorited item to preferences 
                setFavorites(favorites => favorites = [...favorites, selectedForm.uid])
            }

            sortForms(formList);
        }
    }

    return (
        <Button
            startIcon={
                selectedForm?.uid !== undefined && (favorites.includes(selectedForm.uid) ?
                    <Favorite color="primary" />
                    :
                    <Unfavorite color="primary" />
                )}
            onClick={handleClick}
            color="primary"
            disabled={disabled}
        >
            {selectedForm?.uid !== undefined && (favorites.includes(selectedForm.uid) ?
                <span>Remove from Favourites</span> :
                <span>Add to Favourites</span>)}
        </Button >
    );
}

export default AddFavoriteButton;