import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { getDocumentData } from "@alethea-medical/utilities";
import firebase from "firebase";
import { UserMediaMetadata } from "@alethea-medical/aletheamd-types";
import { fbFirestore, fbStorage } from "../../../firebase";
import { hexEncoding } from "@alethea-medical/utilities";
import { removeHashFromActivityMedia, getTimestampFromName, tryGetTimestampFromName } from "./MessagingGalleryModel";

/**
 * This file handles all reading, writing, and managing of data for the gallery
 */

export interface UserMediaMetadataItem {
    id: string,
    data: UserMediaMetadata
}

export interface TimeBinnedMedia {
    [timestamp: string]: UserMediaMetadataItem[]
}

export interface SearchOptions {
    dateRange: {
        from?: Date, to?: Date
    },
    notes?: string,
    tags?: string[]
}


// Process the document snapshot from firestore, and fetch the download URLs for the media (so they can be displayed)
// Thumbnails prioritized, but fallback to original file if no thumbnail exists yet
function processSnapshot(snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): Promise<UserMediaMetadataItem[]> {
    return Promise.all(snapshot.docs.map((doc) => {
        return getDocumentData(doc)
        .then((item: UserMediaMetadata) => {
            const result: UserMediaMetadataItem = {
                id: doc.id,
                data: item,
            }

            //Decode base64 encoded strings
            result.data.notes = hexEncoding.decodeStringFromHex(result.data.notes)
            result.data.tags = result.data.tags.map((t) => hexEncoding.decodeStringFromHex(t))

            const updateDownloadUrls: Partial<UserMediaMetadata> = {}
            
            let promise: Promise<any> = Promise.resolve()
            // Fetch download url if we don't have it yet or if video
            // If video, if url is created before converting, the url will not work for the converted video, so just fetch every time
            if(item.fileDownloadUrl === undefined || item.fileType === "video") {
                promise = promise.then(() => {
                    return fbStorage.ref(item.filePath).getDownloadURL()
                    .then((fileSrc) => {
                        updateDownloadUrls.fileDownloadUrl = fileSrc
                        result.data.fileDownloadUrl = fileSrc
                        return result;
                    })
                })
            }
            if(item.thumbnailDownloadUrl === undefined && item.thumbnailPath !== undefined) {
                // Fetch download url for thumbnail if we don't have it yet
                promise = promise.then(() => {
                    return fbStorage.ref(item.thumbnailPath).getDownloadURL()
                    .then((thumbnailSrc) => {
                        updateDownloadUrls.thumbnailDownloadUrl = thumbnailSrc
                        result.data.thumbnailDownloadUrl = thumbnailSrc
                        return result;
                    })
                    .catch((error: Error) => {
                        //Don't completely error out if thumbnail fails
                        console.error(error);
                    })
                })
            }
            return promise.then(() => {
                //Add download URLs to firestore object if we had to fetch them
                if(updateDownloadUrls.fileDownloadUrl !== undefined || updateDownloadUrls.thumbnailDownloadUrl !== undefined) {
                    return doc.ref.update(updateDownloadUrls)
                    .then(() => result)
                }
                else {
                    return result;
                }
            });
        })
        .catch((error: Error) => {
            console.error(error)
            return undefined;//Don't crash the whole snapshot processing if one error occurs
        })
    })).then((userMediaMetadataItems) => {
        //Filter out failures
        return userMediaMetadataItems.filter((item) => item !== undefined) as UserMediaMetadataItem[]
    })
}


// Fetch all media starting from a media item until the start of the day that media item was created
// This guarantees that the getMediaByOrderedDate query will contain all media for each day it fetches
function getMediaFromLastDay(uid: string, item: UserMediaMetadataItem, startAfter: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    // Get end of start day we have fetched (in local time)
    const startOfLastDay = item.data.created.toDate();
    startOfLastDay.setHours(0);
    startOfLastDay.setMinutes(0);
    startOfLastDay.setSeconds(0);
    startOfLastDay.setMilliseconds(0);

    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).where("created", ">=", startOfLastDay).orderBy("created", "desc").startAfter(startAfter).get()
}

//Default view - fetch in order created by with limit
export function getMediaByOrderedDate(uid: string, limit: number, 
    startAfter?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): Promise<{
        data: UserMediaMetadataItem[],
        paginationCursor: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined//To keep track of pagination
    }> {

    let query = fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).orderBy("created", "desc")

    if(startAfter !== undefined)
        query = query.startAfter(startAfter)

    return query.limit(limit).get()
    .then((snapshot) => {
        return processSnapshot(snapshot)
        .then((items) => {
            if(items.length > 0) {
                //Fill out the final day of what we fetched, since query.limit can cut off media that should be shown in a day
                return getMediaFromLastDay(uid, items[items.length - 1], snapshot.docs[snapshot.size - 1])
                .then((lastDaySnapshot) => {
                    return processSnapshot(lastDaySnapshot)
                    .then((itemsFromLastDay) => {
                        return {
                            data: items.concat(itemsFromLastDay),
                            //Set pagination cursor to last item in lastDaySnapshot if it has size more than one, otherwise use the first snapshot we fetched
                            paginationCursor: lastDaySnapshot.size > 0 ? lastDaySnapshot.docs[lastDaySnapshot.size - 1] : snapshot.docs[snapshot.size - 1]
                        }
                    })
                })
            }
            else {
                return {
                    data: items,
                    paginationCursor: undefined
                }
            }
        })
    })
}

// Fetch all media newer than the provided date
export function getNewestMedia(uid: string, mostRecentCreated: Date): Promise<UserMediaMetadataItem[]> {
    return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).where("created", ">", mostRecentCreated).orderBy("created", "desc").get()
    .then(processSnapshot)  
}

export function searchMedia(uid: string, searchOptions: SearchOptions): Promise<UserMediaMetadataItem[] | undefined> {

    const ref = fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media)
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> | undefined = undefined;
    
    const queried = {
        date: false,
        notes: false,
        tags: false
    }

    if(searchOptions.dateRange?.from !== undefined) {
        queried.date = true
        query = ref.where("created", ">=", searchOptions.dateRange.from)
    }
    if(searchOptions.dateRange?.to !== undefined) {
        queried.date = true
        query = (query === undefined ? ref : query).where("created", "<=", searchOptions.dateRange.to)
    }
    //Don't apply notes query if already querying by date
    //Can't query by notes, unless the string exactly matches the notes from beginning onwards
    //i.e. To find the file with notes "Search this string", the user can't search "string" or "earch this". They have to search "Search th..."
    // if(searchOptions.notes !== undefined && !queried.date) {
    //     queried.notes = true
    //     query = (query === undefined ? ref : query).where("notes", '>=', searchOptions.notes).where("notes", '<=', searchOptions.notes + '\uf8ff')
    // }
    //Don't apply tags query if already querying by date
    if(searchOptions.tags !== undefined && !queried.date && !queried.notes) {
        queried.tags = true
        if(searchOptions.tags.length > 10) {
            return Promise.reject(new Error("Cannot search for more than 10 tags at once."))
        }
        //Encode tags before
        query = (query === undefined ? ref : query).where("tags", "array-contains-any", searchOptions.tags.map((t) => hexEncoding.encodeStringToHex(t)))
    }
    if(query !== undefined) {
        return query.get().then(processSnapshot)
        .then((mediaItems) => {
            //Apply notes and tags filters if not already queried
            //If searchOptions.notes/tags is undefined, it won't be used
            if(searchOptions.notes !== undefined || searchOptions.tags !== undefined)
                return filterMedia(mediaItems, {
                    notes: queried.notes ? undefined : searchOptions.notes,
                    tags: queried.tags ? undefined : searchOptions.tags
                })
            else
                return mediaItems
        })
        .then((mediaItems) => {
            //Sort in-place so newest items are at the top
            //Sort here instead of using query.orderBy so we don't have to create an index
            sortByNewest(mediaItems)
            return mediaItems;
        })
    }
    else {
        return Promise.resolve(undefined);
    }
}

// Add items to timestamp binned dictionary
// Each element in dictionary has the day millisecond timestamp as the key, and a list of media items as the value
export function binMediaByTime(items: UserMediaMetadataItem[]): TimeBinnedMedia {
    const timeBinnedMedia: TimeBinnedMedia = {};
    items.forEach((item) => {
        const timestamp = item.data.created.toDate();
        timestamp.setHours(0);
        timestamp.setMinutes(0);
        timestamp.setSeconds(0);
        timestamp.setMilliseconds(0);
        const timeKey = timestamp.getTime();//Get milliseconds as key
        
        //If we have a bin for that day, add the item to that bin, otherwise create a new bin.
        if(timeBinnedMedia[timeKey]) {
            timeBinnedMedia[timeKey].push(item);
        }
        else {
            timeBinnedMedia[timeKey] = [item];
        }
    })

    return timeBinnedMedia;
}

// Delete specified items from firestore, delete file in storage and thumbnail in storage if it exists
// Ignores errors when deleting the file (so if the file already doesn't exist, it won't error out) 
// Does not ignore errors when deleting metadata document
export function deleteMedia(uid: string, itemsToDelete: UserMediaMetadataItem[]): Promise<{success: string[], fail: string[]}> {
    return Promise.all(itemsToDelete.map((item) => {
        //Delete firestore document
        return fbFirestore.collection(dbNames.userMediaMetadata).doc(uid).collection(dbNames.userMediaMetadata_media).doc(item.id).delete()
        .then(() => {
            // Delete file in storage (If error occurs, ignore the error)
            return fbStorage.ref(item.data.filePath).delete().catch((error: Error) => { console.error(error); return; })
        })
        .then(() => {
            // Delete thumbnail in storage if defined (If error occurs, ignore the error)
            if(item.data.thumbnailPath !== undefined) {
                return fbStorage.ref(item.data.thumbnailPath).delete().catch((error: Error) => { console.error(error); return; })
            }
        })
        .then(() => {
            return {
                id: item.id, success: true
            };
        })
        .catch((error: Error) => {
            console.error(error)
            return {
                id: item.id, success: false
            }
        })
    }))
    .then((results) => {
        const success: string[] = []
        const fail: string[] = []
        results.forEach((r) => {
            if(r.success)
                success.push(r.id)
            else
                fail.push(r.id)
        })
        return { success, fail }
    })
}


/**
 * Used to filter data after it has been fetched from firestore
 * @param listToFilter UserMediaMetadata list before filtering. Acquired by using one of the "get" functions
 * @param filters Filters to apply
 * @returns Filtered data
 */
export function filterMedia(listToFilter: UserMediaMetadataItem[], filters: {
    notes?: string
    tags?: string[],
}): UserMediaMetadataItem[] {
    return listToFilter.filter((item) => {
        let keep = true;

        //Only check if keep is true, otherwise it will always be false so there is no point in checking
        if(keep && filters.notes !== undefined)
            keep = keep && item.data.notes.toLowerCase().includes(filters.notes.toLowerCase())//Notes string in item must contain filter notes string

        if(keep && filters.tags !== undefined)
            keep = keep && filters.tags.some((fTag) => item.data.tags.some((tag) => tag === fTag))//At least one tag in filter must be in the item at least once (matches how array-contains-any works)

        return keep
    })
}

function sortByNewest(listToSort: UserMediaMetadataItem[]) {
    listToSort.sort((a, b) => {
        if(a.data.created.toMillis() === b.data.created.toMillis())
            return 0
        return a.data.created.toMillis() > b.data.created.toMillis() ? -1 : 1
    })
}

export function getMediaDisplayName(media: UserMediaMetadata): string {
    const fullFilename = media.filePath.split('/')[media.filePath.split('/').length - 1]
    const filename = removeHashFromActivityMedia(fullFilename)
    const timestamp = tryGetTimestampFromName(filename.split('.')[0]);
    if(timestamp === undefined) {
        return filename;
    }
    return `${filename} - ${timestamp.toDate().toLocaleString()}`
}