import React from 'react';
import Grid from '@material-ui/core/Grid';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { HeightLayout, HeightLayoutChild, useProcessState } from "@alethea-medical/alethea-components";
import { ProcessState, ProcessStatus } from '@alethea-medical/alethea-components';
import { logAnalyticsEvent } from '../../../../../firebase';
// import downloadBlob from '../../../../models/downloadBlob';
import RoundedButton from '../../../../../components/RoundedButton';
import Alert from '@material-ui/lab/Alert';
import globalStrings from '../../../../../globalStrings';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pdf: {
            padding: theme.spacing(0, 3),
        },
        agreementContainer: {
            height: "100%",
            overflowY: "auto"
        },
        submit: {
            marginTop: theme.spacing(3)
        }
    }),
);

interface AgreementContainerProps {
    setBlob: (blob: Blob) => void,
    onSavedAnalyticsLog: string,
    isSigned?: boolean,
    isMissingInfo?: boolean,
    onSave?: () => void
}

const AgreementContainer: React.FC<AgreementContainerProps> = ({ setBlob, onSavedAnalyticsLog, isSigned, isMissingInfo, onSave=()=>{}, children }) => {    
    const classes = useStyles();

    const { processState, processErrorMessage, setProcessState, errorHandler } = useProcessState({ logAnalyticsEvent })

    const savePDF = () => {
        setProcessState(ProcessState.running);
        return createPDF()
        .then((pdf) => {
            const blob = pdf.output('blob');
            setBlob(blob);

            // Download when click next for debugging
            // downloadBlob(blob, "download.pdf");

            setProcessState(ProcessState.idle);
            logAnalyticsEvent(onSavedAnalyticsLog);
            onSave();
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: `An error occurred. Please try again or contact us at ${globalStrings.salesEmail}`,
                hideErrorMessage: true,
                analyticsLog: "signup_create_signed_pdf_failed"
            })
        })
    }
    

    const createPDF = (): Promise<jsPDF> => {
        const input = document.getElementById('agreement_page');
        if (input !== null) {
            return html2canvas(input,  {
                scale: 1.5,//Increase scale to 2, since 1 gives a blurry image
                useCORS: true,
                onclone: function(doc){
                    const inputClone = doc.getElementById('agreement_page');
                    //Set width to 210mm (A4 size)
                    if(inputClone !== null) {
                        inputClone.style.paddingTop = "20mm";
                        inputClone.style.paddingBottom = "40mm";
                        inputClone.style.paddingLeft = "20mm";
                        inputClone.style.paddingRight = "20mm";
                        inputClone.style.width = "210mm";
                    }

                    const ws1 = doc.getElementById("ws1");
    
                    if(ws1 !== null)
                        ws1.style.visibility = "visible"
        
                    const ws2 = doc.getElementById("ws2");
                    if(ws2 !== null)
                        ws2.style.visibility = "visible"
                    
                    const clearSigButton = doc.getElementById("clear-sig-button");
                    if(clearSigButton !== null)
                        clearSigButton.style.visibility = "hidden"
                }
            })
            .then((canvas) => {         
                const pdf = new jsPDF({unit: "pt", format: [canvas.width, canvas.height]});
                const imgData = canvas.toDataURL('image/jpeg', 0.8);
                pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
                return pdf;
            })
        }
        return Promise.reject(new Error("Error creating PDF. Could not find PDF content."))
    }

    return (
        <HeightLayout>
            <HeightLayoutChild flexDriven allowOverflowY>
                <div id={`agreement_page`} className={classes.pdf}>
                    {children}
                </div>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriver>
                <Grid container justifyContent="flex-end" spacing={2} className={classes.submit}>
                    <Grid item>
                        <RoundedButton onClick={savePDF} disabled={!isSigned || isMissingInfo || processState === ProcessState.running}>
                            Sign
                        </RoundedButton>
                    </Grid>
                    {isMissingInfo && (
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Alert severity="error">Make sure you have filled out the required information in the previous sections before signing.</Alert>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ProcessStatus
                            state={processState}
                            errorMessage={processErrorMessage}
                            setState={setProcessState}
                        />
                    </Grid>
                </Grid>
            </HeightLayoutChild>
        </HeightLayout>
    );
}

export default AgreementContainer;


