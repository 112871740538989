import { useContext, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { AuthContext } from "../../../../../AuthProvider";
import { ActivityItem } from "../../types";
import MessageListItem from "../../../../../components/MessageListItem";
import Grid, { GridSize } from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import palette from "../../../../../palette";
import { formatTimeDateThenTime } from "../../../../../models/formatTime";
import Typography from "@material-ui/core/Typography";
import { Activity } from "../../../../../../shared/types";
import EconsultOutcomesLabel from "../../EconsultComponents/EconsultOutcomesLabel";
import PrimarySection from "./PrimarySection";
import LabelContainer from "./LabelContainer";
import { ProcessState } from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldText: {
      fontWeight: 700,
    },
    overdueThread: {
      background: palette.orangeLightLight,
    },
    phoneText: {
      color: palette.orange,
      fontWeight: 600,
      fontSize: "1.0em",
    },
    iconRead: {
      color: palette.darkGreen,
    },
    iconUnread: {
      color: palette.orange,
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
    },
    labelContainerDesktop: {
      padding: theme.spacing(1),
    },
    labelComponentItem: {
      margin: theme.spacing(0, 1),
    },
  }),
);
interface ActivityListItemProps {
  activity: ActivityItem<Activity.UserActivity>;
  econsult: Activity.Econsult | null;
  selected: boolean;
  setSelected: (id: string, checked: boolean) => void;
  openActivity: (id: string) => void;
  filters: string[];
  calculateGridSize: (filters: string[]) => Record<string, GridSize>;
  permissions: {
    hasEditStatusPermissions: boolean;
  };
  processState: ProcessState;
}

const ActivityListItem = ({
  activity,
  econsult,
  openActivity,
  selected,
  setSelected,
  filters,
  calculateGridSize,
  permissions,
  processState,
}: ActivityListItemProps) => {
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const [read, setRead] = useState<boolean>(true);
  const [overdue, setOverdue] = useState<boolean>(false);
  const gridSizes = calculateGridSize(filters);

  const activityRole =
    activity.sharedActivity.profiles[authContext.uid]?.activityRole;

  useEffect(() => {
    if (authContext.uid === "") return;

    setRead(
      activity.sharedActivity.recentMessage.readBy.includes(authContext.uid),
    );
    setOverdue(activity.metadataActivity.overdue === true);
  }, [authContext.uid, activity]);

  return (
    <MessageListItem
      className={clsx({
        [classes.overdueThread]: overdue,
      })}
      selected={selected}
      setSelected={setSelected}
      openMessage={openActivity}
      id={activity.id}
      read={read}
      labelComponent={<LabelContainer activity={activity} read={read} />}
      primary={
        <PrimarySection
          activity={activity}
          econsult={econsult}
          filters={filters}
          gridSizes={gridSizes}
          read={read}
          overdue={overdue}
          permissions={permissions}
          activityRole={activityRole}
          processState={processState}
        />
      }
      secondary={
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <EconsultOutcomesLabel
              specialistResponse={
                activity.sharedActivity.exposedEconsultData?.specialistResponse
              }
              fontSize="small"
            />
          </Grid>
          <Grid item>
            {activity.sharedActivity.activityType === "phoneConsult" &&
              activity.sharedActivity.phoneConsultData !== undefined &&
              !activity.sharedActivity.phoneConsultData.callComplete && (
                <Typography className={classes.phoneText}>
                  Call scheduled for{" "}
                  {formatTimeDateThenTime(
                    activity.sharedActivity.phoneConsultData?.startTime.toDate(),
                  )}
                </Typography>
              )}
          </Grid>
        </Grid>
      }
    ></MessageListItem>
  );
};

export default ActivityListItem;
