import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Paper, Grid, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { SpecialistTemplates } from "@alethea-medical/aletheamd-types";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Item from "./Item";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import AddItemButton from "../AddItemButton";
import DeleteButton from "../DeleteButton";
import InternalStateTextField from "../../../../components/InternalStateTextField";
import palette from "../../../../palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    category: {
      backgroundColor: palette.lightGreenLight,
    },
    categoryEditing: {
      padding: theme.spacing(1),
    },
    categoryNotEditing: {
      padding: theme.spacing(1),
    },
    dragging: {
      backgroundColor: palette.lightGreen,
    },
  }),
);

interface CategoryProps {
  index: number;
  category: SpecialistTemplates.Category;
  outcomesList: string[];
  deleteCategoryHandler: (categoryId: string, index: number) => void;
  updateCategoryHandler: (newCategory: SpecialistTemplates.Category) => void;
  items: SpecialistTemplates.ItemDict;
  addItemHandler: (name: string, categoryId: string) => void;
  deleteItemHandler: (
    itemId: string,
    index: number,
    categoryId: string,
  ) => void;
  updateItemHandler: (newItem: SpecialistTemplates.Item) => void;
  isEditing: boolean;
  templateInsertHandler: (item: SpecialistTemplates.Item) => void;
  disableDrag?: boolean;
}

const Category = ({
  index,
  category,
  outcomesList,
  deleteCategoryHandler,
  updateCategoryHandler,
  items,
  addItemHandler,
  deleteItemHandler,
  updateItemHandler,
  isEditing,
  templateInsertHandler,
  disableDrag,
}: CategoryProps) => {
  const classes = useStyles();

  const [expandNewItem, setExpandNewItem] = useState(false);

  // When new item is added, automatically expand it
  const addItem = (name: string) => {
    setExpandNewItem(true);
    addItemHandler(name, category.id);
  };

  const deleteItem = (id: string, index: number) => {
    deleteItemHandler(id, index, category.id);
  };

  const categoryNameChangeHandler = (name: string) => {
    const newCategory = { ...category };
    newCategory.name = name;
    updateCategoryHandler(newCategory);
  };

  // When items update, reset expand new item
  useEffect(() => {
    setExpandNewItem(false);
  }, [category.itemIds]);

  return (
    <Draggable
      draggableId={category.id}
      index={index}
      isDragDisabled={!isEditing}
    >
      {(provided, snapshot) => (
        <Paper
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          className={clsx(classes.category, {
            [classes.categoryEditing]: isEditing,
            [classes.categoryNotEditing]: !isEditing,
            [classes.dragging]: snapshot.isDragging,
          })}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid {...provided.dragHandleProps} item>
              {/* Only disable icon. If dragHandleProps are not found, then react-beautiful-dnd throws an error. Dragging is disabled when not editing */}
              {isEditing && !disableDrag && <MenuIcon />}
            </Grid>
            <Grid item xs={6}>
              {isEditing ? (
                <InternalStateTextField
                  value={category.name}
                  setValue={categoryNameChangeHandler}
                  multiline
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rules={(value: string) =>
                    value === "" ? "Category name cannot be empty." : undefined
                  }
                />
              ) : (
                <Typography>{category.name}</Typography>
              )}
            </Grid>
            <Grid item xs={true}>
              <Grid container justifyContent="flex-end">
                {isEditing && (
                  <Grid item>
                    <DeleteButton
                      id={category.id}
                      index={index}
                      name={category.name}
                      deleteHandler={deleteCategoryHandler}
                      type="category"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Droppable droppableId={category.id} type="item">
                {(provided) => (
                  <Grid
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    container
                    spacing={2}
                  >
                    {category.itemIds.map((itemId, index) => {
                      if (items[itemId] === undefined) return null;

                      const id = `item_${itemId}`;
                      return (
                        <Grid item xs={12} key={id}>
                          <Item
                            index={index}
                            item={items[itemId]}
                            outcomesList={outcomesList}
                            deleteItemHandler={deleteItem}
                            updateItemHandler={updateItemHandler}
                            isEditing={isEditing}
                            templateInsertHandler={templateInsertHandler}
                            // Default to expanded, if last item in list, and an item has been added
                            defaultExpand={
                              expandNewItem &&
                              index === category.itemIds.length - 1
                            }
                            disableDrag={disableDrag}
                          />
                        </Grid>
                      );
                    })}
                    {provided.placeholder}
                    {isEditing && (
                      <Grid item xs={12}>
                        <AddItemButton addItemHandler={addItem} />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

export default Category;
