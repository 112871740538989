import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      justifyContent: "flex-start",
    },
    newResourcePaper: {
      padding: theme.spacing(1),
    },
  }),
);

interface AddResourceButtonProps {
  addResourceHandler: (name: string) => void;
}

const AddResourceButton = ({ addResourceHandler }: AddResourceButtonProps) => {
  const classes = useStyles();
  const [resourceName, setResourceName] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleAddClicked = () => {
    setShowTextField(true);
  };

  // When enter is pressed or "create" is clicked
  const handleSubmit = () => {
    if (resourceName === "") return;

    addResourceHandler(resourceName);
    closeTextField();
  };

  // Listen for enter key press to submit
  const onKeyPress = (e: { keyCode: number }) => {
    if (e.keyCode == 13) {
      handleSubmit();
    }
  };

  const closeTextField = () => {
    setShowTextField(false);
    setResourceName("");
  };

  // Automatically focus textfield when textfield shows
  useEffect(() => {
    if (showTextField && textFieldRef.current !== null) {
      textFieldRef.current.focus();
    }
  }, [showTextField]);

  return (
    <>
      {!showTextField && (
        <Button
          className={classes.addButton}
          variant="text"
          color="primary"
          onClick={handleAddClicked}
          startIcon={<AddIcon />}
          fullWidth
        >
          New Resource
        </Button>
      )}
      {showTextField && (
        <Paper className={classes.newResourcePaper}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={true}>
              <TextField
                label="Resource Name"
                variant="outlined"
                fullWidth
                margin="dense"
                inputRef={(input) => {
                  textFieldRef.current = input;
                }}
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value as string)}
                onKeyDown={onKeyPress}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={resourceName === ""}
              >
                Create
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={closeTextField}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default AddResourceButton;
