import React, { useState } from 'react';
import clsx from "clsx";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { thinScrollbar } from "@alethea-medical/alethea-components";
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { logAnalyticsEvent } from '../../firebase';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import isMobileDevice from '../../models/isMobileDevice';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        container: {
            height: "100%",
            position: "relative"
        },
        viewer: {
            overflowY: "scroll",
        },
        border: {
            borderColor: theme.palette.grey[300],
        },
        buttonFloat: {
            top: 0,
            left: 0,
        },
        controls: {
            zIndex: 10,
            position: "absolute",
        },
        loadingFloat: {
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 10
        },
        alert: {
            margin: theme.spacing(1)
        },
        noData: {
            margin: theme.spacing(3)
        },
        grabbable: {
            cursor: "grab"
        },
        pdfPage: {
            height: "100%",
            '& canvas': {//Center canvas on page
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
            }
        },
    }),
);

interface PDFViewerProps {
    pdfURL?: string,
    noDataText?: string | React.ReactChild,
    height?: number,
    width?: number
    disableAnalytics?: boolean
}

const PDFViewer = ({ pdfURL, noDataText, height, width, disableAnalytics = false}: PDFViewerProps) => {
    const classes = useStyles();

    const [numPages, setNumPages] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);//Mult by width


    const loadSuccessHandler = (pdf: any) => {
        setNumPages(pdf.numPages);
    }

    const pdfOpened = () => {

        if (!disableAnalytics){
            logAnalyticsEvent("message_pdf_open");}
    }

    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.25)
    }
    const handleZoomOut = () => {
        setZoomLevel(Math.max(zoomLevel - 0.25, 0.25))
    }


    return (
        <>
            <div className={classes.container}>
                    <>
                        {(pdfURL !== undefined) &&  (
                            <div className={clsx(classes.buttonFloat, classes.controls)}>
                                <Grid container direction="column">    
                                    <Grid item>
                                        <a href={pdfURL} target="_blank" rel="noopener noreferrer">
                                            <IconButton onClick={pdfOpened}>{<OpenInNewIcon color="primary"/>}</IconButton>
                                        </a>
                                    </Grid>
                                    {/* Only show on non-mobile devices */}
                                    {!isMobileDevice() && (
                                        <>
                                            <Grid item>
                                                <IconButton onClick={handleZoomIn}><ZoomInIcon color="primary"/></IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={handleZoomOut}><ZoomOutIcon color="primary"/></IconButton>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </div>
                        )}
                        <Box
                            className={clsx({
                                [classes.viewer]: !isMobileDevice(),
                                [classes.thinScrollbar]: !isMobileDevice(), 
                                [classes.border]: !isMobileDevice(),
                                [classes.grabbable]: isMobileDevice()
                            })}
                            border={1}
                            width={width}
                            height={height}
                        >
                            {/* Show draggable PDF on mobile devices (native or web) */}
                            {isMobileDevice() && 
                                <TransformWrapper options={{minScale: 0.5, limitToBounds: false, limitToWrapper: false}} wheel={{step: 1}}>
                                    <TransformComponent>
                                        <Document
                                            file={pdfURL}
                                            noData={<div className={classes.noData}>
                                                {noDataText ? noDataText : ""}
                                            </div>}
                                            onLoadSuccess={loadSuccessHandler}
                                            loading={<LinearProgress/>}
                                            error={<MuiAlert className={classes.alert} severity="error">Error loading PDF</MuiAlert>}
                                        >
                                            {
                                                [...Array(numPages)].map((_, index) => 
                                                    <Page
                                                        key={`page_${index}`}
                                                        pageIndex={index}
                                                        width={width}
                                                    />
                                                )
                                            }
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            }
                            {/* Show default viewer on desktop */}
                            {!isMobileDevice() && 
                                <Document
                                    file={pdfURL}
                                    noData={<div className={classes.noData}>
                                        {noDataText ? noDataText : ""}
                                    </div>}
                                    onLoadSuccess={loadSuccessHandler}
                                    loading={<LinearProgress/>}
                                    error={<MuiAlert className={classes.alert} severity="error">Error loading PDF</MuiAlert>}
                                >
                                    {
                                        [...Array(numPages)].map((_, index) => 
                                            <Page
                                                className={classes.pdfPage}
                                                key={`page_${index}`}
                                                pageIndex={index}
                                                scale={zoomLevel}
                                                width={width}
                                            />
                                        )
                                    }
                                </Document>
                            }
                        </Box>
                    </>
            </div>
        </>
    );
}


export default PDFViewer;