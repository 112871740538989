import { ProcessStatus } from '@alethea-medical/alethea-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { useEffect, useRef, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Activity } from '../../../../../shared/types';
import useConfirmDestructiveModal from '../../../../components/useConfirmDestructiveModal';
import isIOS from '../../../../models/isIOS';
import isNativeMobile from '../../../../models/isNativeMobile';
import useExportDraftPdf from './useExportDraftPdf';

interface ConsultDraftButtonsProps {
    control: Control<Activity.ConsultFormFields>
    draftId: string | undefined,
    draftSubject: string,
    setDraftSubject: (subject: string) => void,
    saveDraftHandler: (subject: string) => void,
    discardDraftHandler: () => void,
    disabled: boolean
    isDirty: boolean,
}

const ConsultDraftButtons = ({ control, draftId, draftSubject, setDraftSubject, saveDraftHandler, discardDraftHandler, disabled, isDirty }: ConsultDraftButtonsProps) => {
    const { showConfirmDestructiveModal, ConfirmDestructiveModal } = useConfirmDestructiveModal({ 
        action: discardDraftHandler, confirmText: "Discard Draft?", confirmButtonText: "Discard"
    })
    
    const specialty = useWatch({ control, name: "specialty"})
    const subsite = useWatch({ control, name: "subsite"})
    const phn = useWatch({ control, name: "patientInfo.phn"})

    const [showSubjectEntry, setShowSubjectEntry] = useState(false)
    const textFieldRef = useRef<HTMLInputElement | null>(null)

    const { exportPdfState, exportPdfErrorMessage, setExportPdfState, renderExportPdfButton} = useExportDraftPdf({ draftId, subject: draftSubject, isDirty })


    const startSavingHandler = () => {
        setShowSubjectEntry(true)
        if(draftSubject === "" && specialty !== "" && subsite !== "" && phn !== "")
            setDraftSubject(`${specialty} - ${subsite}, ${phn}`)
    }

    const closeTextField = () => {
        setShowSubjectEntry(false)
    }

    const onKeyPress = (e: { keyCode: number; }) => {
        if(e.keyCode == 13) {
            handleSubmit()
        }
    }


    const handleSubmit = () => {
        saveDraftHandler(draftSubject)
        closeTextField()
    }

    // Automatically focus textfield when textfield shows
    useEffect(() => {
        if(showSubjectEntry && textFieldRef.current !== null && !isIOS()) {
            textFieldRef.current.focus()
            textFieldRef.current.setSelectionRange(0, draftSubject.length, "forward")            
        }
    }, [showSubjectEntry])


    return (
        <Grid container alignItems='center' spacing={1}>
            {!showSubjectEntry && 
                <>
                    <Grid item xs={true} md={"auto"}>
                        <Button 
                            variant="text" 
                            color="primary" 
                            onClick={startSavingHandler}
                            startIcon={<SaveIcon/>}
                            disabled={disabled}
                            fullWidth
                        >
                            Save Draft
                        </Button>
                    </Grid>
                    {draftId !== undefined &&
                        <Grid item xs={6} md={"auto"}>
                            <Button
                                variant="text" 
                                color="primary"     
                                onClick={showConfirmDestructiveModal}                            
                                startIcon={<DeleteIcon/>}
                                disabled={disabled}
                                fullWidth
                            >
                                Discard
                            </Button>
                        </Grid>
                    }
                    {/* Don't show export PDF on native mobile, since download 
                    goes to a random folder and can't be found*/}
                    {(draftId !== undefined && !isNativeMobile()) &&
                        <Grid item xs={6} md={"auto"}>
                            {renderExportPdfButton()}
                        </Grid>
                    }
                </>
            }
            {showSubjectEntry && 
                <>
                    <Grid item xs={true}>
                        <TextField
                            placeholder='No Subject'
                            label="Subject"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            inputRef={(input) => {
                                textFieldRef.current = input;
                            }}
                            value={draftSubject}
                            onChange={(e) => setDraftSubject(e.target.value as string)}
                            onKeyDown={onKeyPress}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="text" color="primary"     
                            onClick={handleSubmit}                            
                            disabled={disabled}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton     
                            onClick={closeTextField}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </>
            }
            {ConfirmDestructiveModal}
            <ProcessStatus state={exportPdfState} setState={setExportPdfState} errorMessage={exportPdfErrorMessage} useSnackbar={true} loadingMessage={"Exporting Draft..."}/>
        </Grid>
    );
}

export default ConsultDraftButtons;