import { useEffect } from "react";
import OneWayMessaging from "../Pages/OneWayMessaging";
import FormSender from "../Pages/FormSender";
import Dashboard from "./Dashboard";
import Profile from "../Pages/Profile";
import Consult from "../Pages/Consult";
import ImageAnnotation from "../Pages/ImageAnnotation";
import SecureMessaging from "../Pages/SecureMessaging";
import PatientMessages from "../Pages/PatientMessages";
import Camera from "../Pages/Camera";
import BillingReport from "../Pages/BillingReport";
import References from "../Pages/References";
import routes, { routeAliases } from "../../config/routes";
import PermissionRoute from "../../components/PermissionRoute";
import { Switch, useHistory, withRouter } from "react-router-dom";
import ValidationRequired from "../Pages/ValidationRequired";
import Gallery from "../Pages/Gallery/Gallery";
import EditSpecialistTemplatesPage from "../Pages/SpecialistTemplates/Views/EditSpecialistTemplatesPage";
import EditResourcesPage from "../Pages/Resources/Views/EditResourcesPage";
import EditSchedulePage from "../Pages/SpecialistSchedule/Views/EditSchedulePage";
import MOAConsultInbox from "../Pages/SecureMessaging/MOAConsultInbox";
import { PermissionsProvider } from "../Pages/Consult/SearchAndResults/PermissionsContext";

export const DashboardRoutes = withRouter(({ location }) => {
  const history = useHistory();

  useEffect(() => {
    // If the current pathname is an alias for another path
    if (routeAliases[location.pathname] !== undefined) {
      // Redirect to other path
      history.push(`${routeAliases[location.pathname].path}${location.search}`);
    }
  }, [location.pathname]);

  return (
    <Dashboard>
      <Switch>
        {/* Path required for switch to work https://stackoverflow.com/questions/63066533/using-custom-router-switch-in-react-router */}
        <PermissionRoute path={routes.profile.path} route={routes.profile}>
          <Profile />
        </PermissionRoute>
        <PermissionRoute path={routes.camera.path} route={routes.camera}>
          <Camera />
        </PermissionRoute>
        <PermissionRoute path={routes.gallery.path} route={routes.gallery}>
          <Gallery />
        </PermissionRoute>
        <PermissionRoute
          path={routes.imageAnnotation.path}
          route={routes.imageAnnotation}
        >
          <ImageAnnotation />
        </PermissionRoute>
        <PermissionRoute
          path={routes.billingReport.path}
          route={routes.billingReport}
        >
          <BillingReport />
        </PermissionRoute>
        <PermissionRoute path={routes.forms.path} route={routes.forms}>
          <FormSender />
        </PermissionRoute>
        <PermissionRoute
          path={routes.oneWayMessaging.path}
          route={routes.oneWayMessaging}
        >
          <OneWayMessaging />
        </PermissionRoute>
        <PermissionRoute path={routes.consult.path} route={routes.consult}>
          <PermissionsProvider>
            <Consult />
          </PermissionsProvider>
        </PermissionRoute>
        <PermissionRoute
          path={routes.secureMessaging.path}
          route={routes.secureMessaging}
        >
          <SecureMessaging />
        </PermissionRoute>
        <PermissionRoute
          path={routes.moaConsultInbox.path}
          route={routes.moaConsultInbox}
        >
          <MOAConsultInbox />
        </PermissionRoute>
        <PermissionRoute
          path={routes.patientMessages.path}
          route={routes.patientMessages}
        >
          <PatientMessages />
        </PermissionRoute>
        <PermissionRoute path={routes.resources.path} route={routes.resources}>
          <References />
        </PermissionRoute>
        <PermissionRoute
          path={routes.specialistTemplates.path}
          route={routes.specialistTemplates}
        >
          <EditSpecialistTemplatesPage />
        </PermissionRoute>
        <PermissionRoute
          path={routes.editResources.path}
          route={routes.editResources}
        >
          <EditResourcesPage />
        </PermissionRoute>
        <PermissionRoute
          path={routes.specialistSchedule.path}
          route={routes.specialistSchedule}
        >
          <EditSchedulePage />
        </PermissionRoute>
        <PermissionRoute
          path={routes.validationRequired.path}
          route={routes.validationRequired}
        >
          <ValidationRequired />
        </PermissionRoute>
      </Switch>
    </Dashboard>
  );
});
