import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

/** Summary
 * Renders buttons for controlling selections, and editing
 */

interface GalleryControlsProps {
    refresh: () => void,
    editing?: boolean, setEditing?: (value: boolean) => void,
    setShowUploadModal?: (value: boolean) => void,
}

const GalleryControls: React.FC<GalleryControlsProps> = ({ refresh, editing, setEditing, setShowUploadModal, children }) => {
        
    const selectHandler = () => {
        if(editing !== undefined && setEditing !== undefined)
            setEditing(!editing)
    }

    const uploadHandler = () => {
        if(setShowUploadModal !== undefined)
            setShowUploadModal(true)
    }

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <IconButton onClick={refresh}><RefreshIcon /></IconButton>
            </Grid>
            {editing !== undefined && setEditing !== undefined && (
                <Grid item>
                    <Button variant={editing ? "outlined" : "contained"} color="primary" onClick={selectHandler}>{editing ? "Cancel" : "Select"}</Button>
                </Grid>
            )}
            {setShowUploadModal !== undefined && (
                <Grid item>
                    <Button variant={"contained"} color="primary" onClick={uploadHandler}>Upload Files</Button>
                </Grid>
            )}
            {children}
        </Grid>  
    );
}

export default GalleryControls;