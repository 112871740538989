import { FieldValues } from "react-hook-form";
import { PatientInfo } from "../../../../shared/types";
import { TypedControlledInputProps } from "../types";
import PHNInputLookup from "./PHNInputLookup";

//Remember to copy these values from PHNInputLookup if they every change
interface PHNInputProps<T extends FieldValues> extends TypedControlledInputProps<T> {
    required?: boolean,
    handlePatientInfo?: (patientInfo: PatientInfo) => void,
    ignoreChecksum?: boolean,
    skipServerWarmup?: boolean,
    province?: string
}


function TypedPHNInputLookup<T extends FieldValues> ( props: PHNInputProps<T>) {
    return (
        <PHNInputLookup
            {...props}
            control={props.control as any}
            name={props.name as string}
        />
    )
    
}

export default TypedPHNInputLookup;