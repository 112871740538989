import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../types";
import FormTextArea from "./FormTextArea";

interface TypedFormTextAreaProps<T extends FieldValues>
  extends TypedControlledInputProps<T> {
  initRows: number;
}

function TypedFormTextArea<T extends FieldValues>(
  props: TypedFormTextAreaProps<T>,
) {
  return (
    <FormTextArea
      {...props}
      control={props.control as any}
      name={props.name as string}
    />
  );
}

export default TypedFormTextArea;
