import { useContext, useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Collapse from "@material-ui/core/Collapse";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import SecureMessagingToolbar from "./SecureMessagingToolbar";
import sharedStyles from "../sharedStyles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ProcessStatus } from "@alethea-medical/alethea-components";
import MOAMessageButton from "./MOAMessageButton";
import { AuthContext } from "../../../../AuthProvider";
import isNativeMobile from "../../../../models/isNativeMobile";
import ForwardSecureMessageButton from "./ForwardSecureMessageButton";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import CallCompleteButton from "../PhoneConsultComponents/CallCompleteButton";
import usePermissions from "../../../../components/usePermissions";
import AddConsultantButton from "./AddConsultantButton";
import ViewSecureMessageNavigation from "../ViewSecureMessageNavigation";
import useExportPDF from "../Utilities/useExportPDF";
import DropdownMenuButton from "../../../../components/DropdownMenuButton";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import { UserActivityContext } from "../Inbox/Contexts/ActivityProvider";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ConsultStatusDropdownButton from "./ConsultStatusDropdownButton";
import AveiroPaymentButton from "../../Aveiro/AveiroPaymentButton";
import AveiroInvoiceButton from "../../Aveiro/AveiroInvoiceButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
  }),
);

interface ViewSecureMessageToolbarProps {
  goToMessages: () => void;
}

const ViewSecureMessageToolbar = ({
  goToMessages,
}: ViewSecureMessageToolbarProps) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const activityContext = useContext(UserActivityContext);

  const [showToolbar, setShowToolbar] = useState(true);

  const {
    exportPdfState,
    exportPdfErrorMessage,
    setExportPdfState,
    exportPDFHandler,
  } = useExportPDF({ activityId: activityContext.activityId });

  const { granted: hasAddSpecialistPermission } = usePermissions({
    resourceKey: resourceKeys.addUserToActivity,
  });
  const { granted: hasMOAMessagingPermission } = usePermissions({
    resourceKey: resourceKeys.moaMessaging,
  });
  const { granted: hasAveiroPaymentPermission } = usePermissions({
    resourceKey: resourceKeys.createAveiroCharge,
  });

  const showForwardToSpecialistButton =
    activityContext.sharedActivity.profiles[authContext.uid]?.activityRole ===
      "referrer" &&
    activityContext.sharedActivity.activityType !== "phoneConsult";

  const toggleMuteNotifications = () => {
    activityContext.updateMetadataActivity({
      muted: !activityContext.metadataActivity.muted,
    });
  };

  const activityRole =
    activityContext.sharedActivity.profiles[authContext.uid]?.activityRole;

  return (
    <>
      <Box
        className={clsx(classes.boxPadding, classes.boxBorderColor)}
        borderBottom={1}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <ViewSecureMessageNavigation
            showToolbar={showToolbar}
            setShowToolbar={setShowToolbar}
            goBack={activityContext.closeActivity}
            goToMessages={goToMessages}
          />
          <Grid item>
            <Collapse in={showToolbar}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <SecureMessagingToolbar
                    folder={activityContext.metadataActivity.folder}
                    atLeastOneSelected={true}
                    markAsUnread={activityContext.markAsUnread}
                    moveFolder={activityContext.moveFolder}
                  />
                </Grid>
                <Grid item>
                  <ButtonGroup>
                    <Tooltip
                      title={
                        activityContext.metadataActivity.muted
                          ? "Click to Turn Notifications ON"
                          : "Click to Turn Notifications OFF"
                      }
                      children={
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={toggleMuteNotifications}
                          startIcon={
                            activityContext.metadataActivity.muted ? (
                              <NotificationsOffIcon />
                            ) : (
                              <NotificationsIcon />
                            )
                          }
                        >
                          {activityContext.metadataActivity.muted
                            ? "Muted"
                            : "Unmuted"}
                        </Button>
                      }
                    />
                    <DropdownMenuButton
                      text="Actions"
                      buttonIcon={<MoreHorizIcon />}
                    >
                      {!isNativeMobile() && (
                        <DropdownMenuItem
                          label="Export PDF"
                          icon={<PictureAsPdfIcon color="primary" />}
                          onClick={exportPDFHandler}
                          color="primary"
                        />
                      )}
                      {hasMOAMessagingPermission && (
                        <MOAMessageButton
                          activityId={activityContext.activityId}
                          defaultSubject={
                            activityContext.sharedActivity.subject
                          }
                        />
                      )}
                      {showForwardToSpecialistButton && (
                        <ForwardSecureMessageButton />
                      )}
                      {hasAddSpecialistPermission && (
                        <AddConsultantButton
                          viewType="md"
                          variant="dropdown"
                          activityId={activityContext.activityId}
                          sharedActivity={activityContext.sharedActivity}
                        />
                      )}
                      {hasAveiroPaymentPermission && (
                        <AveiroPaymentButton
                          variant="dropdown"
                          activityId={activityContext.activityId}
                        />
                      )}
                      {hasAveiroPaymentPermission && (
                        <AveiroInvoiceButton
                          variant="dropdown"
                          activityId={activityContext.activityId}
                          econsult={activityContext.econsult}
                        />
                      )}
                    </DropdownMenuButton>
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  {activityRole && (
                    <ConsultStatusDropdownButton viewerRole={activityRole} />
                  )}
                </Grid>
                <Grid item>
                  {activityContext.sharedActivity.activityType ===
                    "phoneConsult" &&
                    activityContext.sharedActivity.phoneConsultData !==
                      undefined &&
                    activityRole && (
                      <CallCompleteButton
                        activityId={activityContext.activityId}
                        callComplete={
                          activityContext.sharedActivity.phoneConsultData
                            .callComplete
                        }
                        userActivityRole={activityRole}
                      />
                    )}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
      <ProcessStatus
        state={exportPdfState}
        setState={setExportPdfState}
        errorMessage={exportPdfErrorMessage}
        useSnackbar={true}
      />
    </>
  );
};

export default ViewSecureMessageToolbar;
