import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { MouseEventHandler, useState, useEffect, useContext } from "react";
import { Service } from "../../../../../shared/types";
import {
  getFormattedClinicAddressFromService,
  getFormattedUserNameWithCredsFromServiceUser,
  getLatestCurrentVacationEndDate,
  isServiceOnVacation,
} from "../../../../db/Service";
import palette from "../../../../palette";
import ResponseTime from "../SpecialtyDropdown/ResponseTime";
import { logAnalyticsEvent } from "src/firebase";
import analyticsLogs from "src/analyticsLogs";
import AcceptanceFlags from "./AcceptanceFlags/AcceptanceFlags";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBorder: {
      borderColor: palette.darkGreen,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "5px",
      padding: theme.spacing(1),
    },
    cardVacation: {
      backgroundColor: "#eee",
    },
    serviceTitle: {
      fontSize: "1.1rem",
      fontWeight: "bold",
      color: palette.darkGreen,
    },
    vacationText: {
      textAlign: "right",
      color: palette.orange,
      fontWeight: "bold",
    },
    actionContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between",
      },
    },
    acceptanceFlags: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  }),
);

interface ServiceCardProps {
  serviceId: string;
  service: Service.Service;
  showStartConsultButton?: boolean;
  selectService?: (serviceId: string) => void;
}

const ServiceCard = ({
  serviceId,
  service,
  showStartConsultButton,
  selectService = () => {},
}: ServiceCardProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleStartConsult: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    logAnalyticsEvent(analyticsLogs.services.selectSearchBarService);

    // Select the service
    selectService(serviceId);
  };

  const isOnVacation = isServiceOnVacation(service);
  const vacationEndDate = getLatestCurrentVacationEndDate(service);

  return (
    <div
      className={clsx(classes.cardBorder, {
        [classes.cardVacation]: isOnVacation,
      })}
      onClick={() => toggleExpanded()}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
            <Grid item>
              <div className={classes.serviceTitle}>{service.serviceName}</div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {getFormattedClinicAddressFromService(service)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            className={classes.actionContainer}
          >
            <div className={classes.acceptanceFlags}>
              <AcceptanceFlags
                acceptanceFlags={service.acceptanceFlags}
                size="2.5em"
                mobileQuestionMarkFraction={0.3}
              />
            </div>
            <Grid item>
              <ResponseTime responseTimeMillis={service.econsultResponseTime} />
            </Grid>
            {showStartConsultButton && (
              <Grid item>
                <Tooltip title="Start Consult">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleStartConsult}
                  >
                    <ChevronRightIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {isOnVacation && (
              <Grid item xs={12}>
                <Typography className={classes.vacationText}>
                  On Vacation{" "}
                  {vacationEndDate &&
                    " - Return date: " +
                      vacationEndDate?.toDate()?.toDateString()}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  {service.users.map((user) => (
                    <li key={user.uid}>
                      <Typography variant="subtitle1">
                        {getFormattedUserNameWithCredsFromServiceUser(user)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {service.shortDescription}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceCard;
