import React, {} from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import usePDFCreator from '../../../../components/usePDFCreator';
import { ProcessStatus } from '@alethea-medical/alethea-components';


interface ExportBillingReportPDFProps {
    pages: React.ReactChild[]
}

const ExportBillingReportPDF = ({ pages }: ExportBillingReportPDFProps) => {

    const { savePDF, createPDFContainer, pdfProcessState, pdfErrorMessage } = usePDFCreator({ pdfKey: "billing_report_pdf", pages: pages, 
    analyticsLog: "billing_report_export_pdf", download: true, hide: true });

    return (
        <div>
            <Tooltip title="Export billing records as a PDF">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PictureAsPdfIcon/>}
                    onClick={savePDF}
                >
                    Export as PDF
                </Button>
            </Tooltip>            
            {/* hidden */}
            {createPDFContainer()}
            <ProcessStatus state={pdfProcessState} errorMessage={pdfErrorMessage}/>
        </div>
    );
}

export default ExportBillingReportPDF;