import React from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Resources } from "../../../../shared/types";

interface InsertResourceButtonProps {
  resource: Resources.Resource;
  resourceInsertHandler: (resource: Resources.Resource) => void;
  disabled?: boolean;
}

const InsertResourceButton = ({
  resource,
  resourceInsertHandler,
  disabled = false,
}: InsertResourceButtonProps) => {
  const handleResourceInsert = () => {
    resourceInsertHandler(resource);
  };

  return (
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={handleResourceInsert}
      disabled={disabled}
    >
      Insert Resource
    </Button>
  );
};

export default InsertResourceButton;
