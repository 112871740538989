
/**
 * Returns error message for phone number input field if invalid
 * @param phone Phone number to validate
 * @returns Undefined if valid or empty, error message if invalid
 */
const isValidPhoneInput = (phone: string): string | undefined => {
    return isPhoneNumber(phone) || phone === "" ? undefined : "Phone number is invalid";
}

/**
 * Validates a phone number
 * @param phoneNumber Phone number to validate
 * @returns true if phone number is of format (xxx) xxx-xxxx
 */
export function isPhoneNumber(phoneNumber: string): boolean {
    return /^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber)
}

export default isValidPhoneInput;