import React from "react";
import { logAnalyticsEvent } from "../../../firebase";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            position: "relative",
            width: "100%",
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:hover": {
                color: theme.palette.primary.main,
                textDecoration: "none"
            },
            "&:visited": {
                color: theme.palette.primary.main,
                textDecoration: "none"
            },
            "&:active": {
                color: theme.palette.primary.dark,
                textDecoration: "none"
            },
        },
        title: {
            fontSize: "1.3em"
        },
        linkText: {
            fontSize: "1.0em",
            color: theme.palette.secondary.dark,
            textDecoration: "underline"
        }
    }),
);

interface linkPreviewProps {
    href: string,
    title: string,
    analyticsEventTag: string
}

const LinkPreviewer = ({ href, title, analyticsEventTag }: linkPreviewProps) => {
    const classes = useStyles();

    const logEvent = () => {
        logAnalyticsEvent(`${analyticsEventTag}`)
    }

    return (
        <a
            href={href}
            className={classes.link}            
            rel="noreferrer noopener"
            target="_blank"
            onClick={logEvent}
        >
            <Typography className={classes.title}>
                {title}
            </Typography>
            <Typography className={classes.linkText}>
                {href.replace("https://www.", "")}
            </Typography>
        </a>
    );
};

export default LinkPreviewer