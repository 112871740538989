import { useContext, useMemo, useState } from "react";

import EconsultCard from "./EconsultCard";
import EconsultInfo from "./EconsultInfo";
import EconsultMessaging from "./EconsultMessaging";
import EconsultPatient from "./EconsultPatient";
import EconsultProfile from "./EconsultProfile";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  formatTimeDateThenTime,
  formatTimeLong,
} from "../../../../models/formatTime";
import EconsultOutcomesLabel from "./EconsultOutcomesLabel";

import { ProcessStatus } from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Collapse from "@material-ui/core/Collapse";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../../../AuthProvider";
import MessagingGallery from "../../../../components/Gallery/Views/MessagingGallery";
import usePermissions from "../../../../components/usePermissions";
import sortProfilesByActivityRole from "../../../../models/sortProfilesByActivityRole";
import palette from "../../../../palette";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import EconsultImageAnnotationView from "../SpecialistTools/EconsultImageAnnotation/EconsultImageAnnotationView";
import ActivityPaymentList from "./ActivityPaymentList";
import ConsultAuditLog from "./ConsultAuditLog";
import EconsultCardExpandHeader from "./EconsultCardExpandHeader";
import { ActivityUser } from "shared/types/dist/namespaces/Activity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    econsultContainer: {
      padding: theme.spacing(1),
      width: "100%",
    },
    phoneText: {
      color: palette.orange,
      fontWeight: 600,
      fontSize: "1.0em",
    },
    expandButton: {
      padding: theme.spacing(1),
    },
  }),
);

const EconsultView = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const activityContext = useContext(ActivityContext);

  const [expandProfile, setExpandProfile] = useState(true);
  const [expandAuditLog, setExpandAuditLog] = useState(false);

  const { granted: hasPaymentPermission } = usePermissions({
    resourceKey: resourceKeys.readActivityPayments,
  });

  const { granted: hasMOAMessagingPermission } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  const isConsultant =
    activityContext.sharedActivity.profiles[authContext.uid]?.activityRole ===
    "consultant";

  const canMessageMOA = useMemo(() => {
    return hasMOAMessagingPermission && isConsultant;
  }, [hasMOAMessagingPermission, isConsultant]);

  return (
    <Grid
      container
      spacing={1}
      alignItems="stretch"
      className={classes.econsultContainer}
    >
      <Grid item xs={12}>
        <EconsultImageAnnotationView />
      </Grid>
      <Grid item xs={12}>
        <Typography>{activityContext.sharedActivity.subject}</Typography>
        <EconsultOutcomesLabel
          specialistResponse={activityContext.econsult.specialistResponse}
        />
        <Typography variant="subtitle1">
          {activityContext.econsult.created
            ? `Sent at ${formatTimeLong(activityContext.econsult.created.toDate())}`
            : ""}
        </Typography>
        {activityContext.sharedActivity.activityType === "phoneConsult" &&
          activityContext.sharedActivity.phoneConsultData !== undefined &&
          activityContext.sharedActivity.phoneConsultData.callComplete ===
            false && (
            <>
              <Typography className={classes.phoneText}>
                Call scheduled for{" "}
                {formatTimeDateThenTime(
                  activityContext.sharedActivity.phoneConsultData.startTime.toDate(),
                )}
              </Typography>
              {activityContext.sharedActivity.profiles[authContext.uid]
                ?.activityRole === "consultant" && (
                <Typography className={classes.phoneText}>
                  Call the referring physician at:{" "}
                  {
                    activityContext.sharedActivity.phoneConsultData
                      .physicianPhoneNumber
                  }
                </Typography>
              )}
            </>
          )}
      </Grid>
      <Grid item xs={12}>
        <ProcessStatus
          state={activityContext.processState}
          errorMessage={activityContext.processErrorMessage}
          loadingMessage="Loading Consult..."
        />
      </Grid>
      {hasPaymentPermission && (
        <Grid item xs={12}>
          <ActivityPaymentList activityId={activityContext.activityId} />
        </Grid>
      )}
      {canMessageMOA && (
        <Grid item xs={12}>
          <EconsultMessaging />
        </Grid>
      )}
      <Grid item xs={12}>
        <EconsultPatient econsult={activityContext.econsult} />
      </Grid>
      <Grid item xs={12}>
        <EconsultCard
          titleComponent={
            <EconsultCardExpandHeader
              title="Members"
              expand={expandProfile}
              setExpand={setExpandProfile}
            />
          }
        >
          <Collapse in={expandProfile}>
            <Grid container spacing={1}>
              {Object.values(activityContext.sharedActivity.profiles)
                .filter(
                  (activityUser): activityUser is ActivityUser =>
                    activityUser !== undefined,
                )
                .sort(sortProfilesByActivityRole)
                .map((activityUser, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`econsult_profile_${activityUser.firstName}_${activityUser.lastName}_${index}`}
                  >
                    <EconsultProfile user={activityUser} />
                  </Grid>
                ))}
            </Grid>
          </Collapse>
        </EconsultCard>
      </Grid>
      <EconsultInfo econsult={activityContext.econsult} />
      {activityContext.econsult.referralMediaURIs.length > 0 && (
        <Grid item xs={12}>
          <EconsultCard title="Attachments">
            <MessagingGallery
              referralMediaURIs={activityContext.econsult.referralMediaURIs}
              econsultImageLabels={activityContext.econsult.imageLabels}
            />
          </EconsultCard>
        </Grid>
      )}
      <Grid item xs={12}>
        <EconsultCard
          titleComponent={
            <EconsultCardExpandHeader
              title="Audit Log"
              expand={expandAuditLog}
              setExpand={setExpandAuditLog}
            />
          }
        >
          <Collapse in={expandAuditLog}>
            <ConsultAuditLog />
          </Collapse>
        </EconsultCard>
      </Grid>
    </Grid>
  );
};

export default EconsultView;
