import { useCallback, useState } from "react";
import clsx from "clsx";
import {
  ProcessStatus,
  HeightLayout,
  HeightLayoutChild,
  useSizeManager,
  ProcessState,
  SwipeableOrGrid,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import GalleryTimestampBins from "../../../../components/Gallery/Views/GalleryTimestampBins";
import MediaViewer from "../../../../components/Gallery/Views/MediaViewer/MediaViewer";
import SelectedMediaViewer from "../../../../components/Gallery/Views/SelectedMediaViewer";
import GalleryController from "../../../../components/Gallery/Controllers/GalleryController";
import MediaViewerController from "../../../../components/Gallery/Controllers/MediaViewerController";
import SelectGalleryController, {
  UserMediaMetadataSelectedDict,
} from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../../components/Gallery/Models/GalleryModel";
import useFileDrop from "../../../../components/useFileDrop";
import {
  FileDict,
  FileDropReturnTypes,
} from "../../../../components/useFileDrop/useFileDrop";
import GalleryRenderer from "../../../../components/Gallery/Views/GalleryRenderer";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RefreshIcon from "@material-ui/icons/Refresh";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import isMobileDevice from "../../../../models/isMobileDevice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      height: "100%",
    },
    fullHeight: {
      height: "100%",
    },
    column: {
      padding: theme.spacing(0, 1),
    },
    galleryItem: {
      marginBottom: theme.spacing(1),
    },
    hidden: {
      display: "none",
    },
  }),
);

interface UploadSecureMessageFilesProps {
  processState: ProcessState;
  processErrorMessage: string;
  uploadHandler: (
    files: FileDict,
    selectedMedia: UserMediaMetadataSelectedDict,
  ) => Promise<
    | { successFileUploadKeys: string[]; successGalleryUploadKeys: string[] }
    | undefined
  >;
}

const UploadSecureMessageFiles = ({
  processState,
  processErrorMessage,
  uploadHandler,
}: UploadSecureMessageFilesProps) => {
  const classes = useStyles();

  const {
    files,
    removeFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
  }: FileDropReturnTypes = useFileDrop({
    disabled: processState === ProcessState.running,
  });

  const {
    selectedMedia,
    selectHandler,
    unselectHandler,
    unselectMultipleHandler,
    unselectAllHandler,
  } = SelectGalleryController({});

  const {
    // data
    binnedMediaItems,
    // handlers
    loadMoreHandler,
    refreshHandler,
    modifyItemHandler,
    // states
    galleryLoadState,
    galleryLoadError,
  } = GalleryController();

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        selected={selectedMedia[photo.id] !== undefined}
        enableSelect={true}
        editing={false}
        select={selectHandler}
        unselect={unselectHandler}
        handleOpenMedia={openMediaItemHandler}
      />
    ),
    [selectedMedia],
  );

  const uploadFiles = () => {
    uploadHandler(files, selectedMedia).then((result) => {
      if (result !== undefined) {
        removeFiles(result.successFileUploadKeys);
        unselectMultipleHandler(result.successGalleryUploadKeys);
      }
    });
  };

  const { height, sizeRef } = useSizeManager();

  const isMobileSize = useMobileSizes();
  const [swipeIndex, setSwipeIndex] = useState(0);

  return (
    <div className={classes.container}>
      <div ref={sizeRef} className={classes.fullHeight}>
        <SwipeableOrGrid
          showSwipe={isMobileSize}
          enableStepper={true}
          showInitialStepper={true}
          gridSize={[6, 6]}
          index={swipeIndex}
          onChangeIndex={(index) => setSwipeIndex(index)}
          containerClassName={classes.fullHeight}
          swipeClassName={classes.fullHeight}
          gridItemClassName={classes.fullHeight}
          gridContainerClassName={classes.fullHeight}
        >
          <HeightLayout height={height} className={classes.column}>
            <HeightLayoutChild flexDriven allowOverflowY>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Select Files From your Gallery
                  </Typography>
                </Grid>
                {isMobileSize && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PhotoLibraryIcon />}
                      onClick={() => {
                        setSwipeIndex(1);
                      }}
                    >
                      Open Gallery
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SelectedMediaViewer
                    selectedMedia={selectedMedia}
                    select={selectHandler}
                    unselect={unselectHandler}
                    unselectAll={unselectAllHandler}
                    gridSizeMultiplier={isMobileSize ? 1 : 2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Upload Files from your Computer
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {createDropzone(
                    isMobileDevice()
                      ? "Tap to Upload"
                      : "Click Here or Drag and Drop to Upload",
                    isMobileSize ? "100px" : undefined,
                  )}
                </Grid>
                {Object.keys(files).length && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        From your Computer
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {createFileList()}
                    </Grid>
                    <Grid item xs={12}>
                      {createFileThumbs()}
                    </Grid>
                  </>
                )}
              </Grid>
            </HeightLayoutChild>
            <HeightLayoutChild flexDriver>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProcessStatus
                    state={processState}
                    errorMessage={processErrorMessage}
                    successMessage="Files Uploaded Successfully."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      Object.keys(files).length +
                        Object.keys(selectedMedia).length ===
                        0 || processState === ProcessState.running
                    }
                    onClick={uploadFiles}
                    startIcon={<AttachFileIcon />}
                  >
                    Upload Files
                  </Button>
                </Grid>
              </Grid>
            </HeightLayoutChild>
          </HeightLayout>
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Gallery</Typography>
            </Grid>
            <HeightLayout
              height={height}
              className={clsx(classes.column, {
                [classes.hidden]: openMediaItem !== undefined,
              })}
            >
              <HeightLayoutChild flexDriver>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      {isMobileSize && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<ChevronLeftIcon />}
                            onClick={() => setSwipeIndex(0)}
                          >
                            Done
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <IconButton onClick={refreshHandler}>
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ProcessStatus
                          state={galleryLoadState}
                          errorMessage={galleryLoadError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </HeightLayoutChild>
              <HeightLayoutChild
                flexDriven
                allowOverflowY
                className={classes.galleryItem}
              >
                <GalleryTimestampBins
                  binnedMediaItems={binnedMediaItems}
                  galleryRenderer={galleryRenderer}
                />
              </HeightLayoutChild>
              <HeightLayoutChild flexDriver>
                <Button
                  onClick={loadMoreHandler}
                  variant="outlined"
                  color="primary"
                >
                  Load More
                </Button>
              </HeightLayoutChild>
            </HeightLayout>
            {openMediaItem !== undefined && (
              <MediaViewer
                height={height}
                mediaItem={openMediaItem}
                closeMediaItem={closeMediaItemHandler}
                modifyItemHandler={modifyItemHandler}
                fullWidth
              />
            )}
          </>
        </SwipeableOrGrid>
      </div>
    </div>
  );
};

export default UploadSecureMessageFiles;
