import { useContext, useEffect, useState } from 'react';
import { AppRoute } from "../../config/routes"
import { AuthContext } from '../../AuthProvider';
import isMobileDevice from '../../models/isMobileDevice';
import hasPermissions from '../../models/hasPermissions';

const useRoutePermissions = (route?: AppRoute) => {

    const authContext = useContext(AuthContext);

    const [ permissionGranted, setPermissionGranted ] = useState(false)
    const [loading, setLoading] = useState(false);

    const checkPermission = (route: AppRoute): Promise<boolean> => {
        if(isMobileDevice() && !route.availableOnMobile) {
            return Promise.resolve(false);
        }
        else if(authContext.profile === undefined)  {
            return Promise.resolve(false);
        }
        else {
            return Promise.all(route.requiredResourcePermissions.map((resources) => {
                return Promise.all(resources.map((r) => {
                    return hasPermissions(r, authContext.profile);
                }))
                .then((results) => {
                    return results.every((bool) => bool);
                })
            }))
            .then((results) => {
                return results.some((bool) => bool);
            })
            .then((allow) => {
                return allow
            })
        }
    }

    useEffect(() => {
        if(route !== undefined ) {
            setLoading(true);
            if(!authContext.loading) {
                checkPermission(route)
                .then((allow) => {
                    setPermissionGranted(allow);
                    setLoading(false);
                })
            }
        }
    }, [route, authContext.profile, authContext.loading])


    return {
        permissionGranted,
        loading
    };
}

export default useRoutePermissions;