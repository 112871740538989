import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import sharedStyles from "../../../sharedStyles";
import useConfirmDestructiveModal from "../../../components/useConfirmDestructiveModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    iconButton: {
      padding: theme.spacing(1),
    },
  }),
);

interface DeleteButtonProps {
  id: string;
  index: number;
  name: string;
  type: "category" | "resource";
  deleteHandler: (id: string, index: number) => void;
}

const DeleteButton = ({
  id,
  index,
  name,
  type,
  deleteHandler,
}: DeleteButtonProps) => {
  const classes = useStyles();

  const { ConfirmDestructiveModal, showConfirmDestructiveModal } =
    useConfirmDestructiveModal({
      confirmText: `Delete ${type === "category" ? "category" : "resource"} "${name}"${type === "category" ? " and all resources within it" : ""}?`,
      confirmButtonText: "Delete",
      action: () => {
        deleteHandler(id, index);
      },
    });

  return (
    <>
      <IconButton
        className={classes.iconButton}
        onClick={showConfirmDestructiveModal}
      >
        {type === "category" ? <DeleteSweepIcon /> : <DeleteIcon />}
      </IconButton>
      {ConfirmDestructiveModal}
    </>
  );
};

export default DeleteButton;
