import { MutableRefObject } from 'react';
import { PhoneConsult } from '../../../../../../shared/types';
import ScheduleDayController from '../../Controllers/ScheduleDayController';
import TimeRow from './TimeRow';
import TimeRowAdd from './TimeRowAdd';

import BlockPreview from './BlockPreview';
import ScheduleBlock from './ScheduleBlock';

interface DayProps {
    day: string,
    dayIndex: number,
    scheduleDay: PhoneConsult.ScheduleDay,
    blocksRef: MutableRefObject<PhoneConsult.Timeslot[][]>,
    times: string[],
    dragContainerRef: MutableRefObject<HTMLDivElement | null>,
    setDirty: () => void
}

const ScheduleDay = ({ day, dayIndex, scheduleDay, blocksRef, times, dragContainerRef, setDirty }: DayProps) => {

    const { blocks, startDragIndex, endDragIndex, isCreatingBlock, mouseDownHandler, removeBlockHandler, updateBlockHandler } = ScheduleDayController({ times, dayIndex, scheduleDay, blocksRef, dragContainerRef, setDirty })


    return (
        <div style={{ position: "relative"}}>
            {times.map((time, index) => 
                <TimeRow key={`time_day_row_${time}`} time={time} index={index}>
                    {/* Don't show button for last time slot (since its invalid and wouldn't do anything) */}
                    {time !== "2350" && <TimeRowAdd index={index} mouseDownHandler={mouseDownHandler} hideButton={isCreatingBlock}/>}
                </TimeRow>
            )}
            {blocks.map((block, index) => 
                <ScheduleBlock key={`schedule_day_block_${day}_${block.start}_${index}`}
                    block={block} 
                    blockIndex={index} 
                    times={times}
                    dragContainerRef={dragContainerRef} 
                    removeBlockHandler={removeBlockHandler} updateBlockHandler={updateBlockHandler} />
            )}
            <BlockPreview startIndex={startDragIndex} endIndex={endDragIndex} times={times} show={isCreatingBlock}/>
        </div>
    );
}

export default ScheduleDay;