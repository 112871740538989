import { Control } from "react-hook-form";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";
import { TypedFormProvinceSelect } from "../../../../../components/FormInputFields/FormProvinceSelect";
import useCms from "../../../../../utils/useCms";
import { SignupInfo, Plan } from "@alethea-medical/aletheamd-types";

interface SignupProvinceSelectProps {
    control: Control<SignupInfo.SignupInfo>,
    selectedPlan: Plan.Plan
}

const SignupProvinceSelect = ({ control, selectedPlan }: SignupProvinceSelectProps) => {
    const content = useCms({ cmsId: "signup" })

    return (
        <>
            <FormattedInputLabel>Select your Province</FormattedInputLabel>
            <TypedFormProvinceSelect
                control={control}
                name={"province"}
                defaultValue=""
                enabledOptions={content.getArray<string>(Plan.getCMSKey(selectedPlan))}
                sortByEnabled
                {...{fullWidth: false}}
            />
        </>
    );

}
export default SignupProvinceSelect