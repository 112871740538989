import { PaperModal, ProcessState } from "@alethea-medical/alethea-components";
import { ProcessStatus } from "@alethea-medical/alethea-components";
import OutcomesInput from "../OutcomesInput";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OutcomesController from "../OutcomesController";
import InputContextOutcomesSetter from "../InputContextOutcomesSetter";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InterpretationsView from "../../Interpretations/InterpretationsView";
import usePermissions from "../../../../../../components/usePermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import InterpretationsController from "../../Interpretations/InterpretationsController";
import PrevInterpretationsView from "../../Interpretations/PrevInterpretationsView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontSize: "1.3em"
        },
        modal: {
            maxHeight: "90vh",
            overflowY: "auto"
        },
    })
)

export const defaultOutcome = "Not applicable"

export const outcomes = [
    "No treatment required",
    "Patient to see Specialist",
    "Management or treatment suggested",
    "Treatment suggested prior to Specialist visit",
    "Further investigations suggested",
    "Refer to different specialty",
    "Unknown",
    defaultOutcome
]

interface EconsultOutcomesModalProps {
    activityId: string,
    showOutcomesMenu: boolean,
    setShowOutcomesMenu: (show: boolean) => void,
}

/**
 * Modal to show econsult outcomes
 */
const EconsultOutcomesModal = ({ activityId, showOutcomesMenu, setShowOutcomesMenu }: EconsultOutcomesModalProps) => {
    const classes = useStyles();

    const {
        selectedOutcome, setSelectedOutcome,
        selectedDiagnosis, setSelectedDiagnosis,
        handleSubmitOutcomes,
        processState, processErrorMessage
    } = OutcomesController({ })

    InputContextOutcomesSetter({ setSelectedOutcome, setSelectedDiagnosis, setShowOutcomesMenu })

    const { granted: billAudiogramGranted } = usePermissions({ resourceKey: resourceKeys.billAudiogram })
    const { granted: billSinusEndoscopyGranted } = usePermissions({ resourceKey: resourceKeys.billSinusEndoscopy })


    const interpController = InterpretationsController({ activityId })

    const onSubmit = () => {
        let closeModal = true;
        let waitToClose = false;
        Promise.resolve()
        .then(() => {
            if(interpController.billAudiogram || interpController.billSinusEndoscopy) {
                return interpController.bill()
                .then((success) => {
                    if(success) {
                        // On success, wait 1 second before closing
                        closeModal = closeModal && true
                        waitToClose = true
                    }
                    else {
                        // On error, don't close
                        closeModal = false
                    }
                })
            }
            return Promise.resolve()
        })
        .then(() => {
            return handleSubmitOutcomes()
            .then((success) => {
                if(success) {
                    // On success, close modal
                    closeModal = closeModal && true
                }
                else {
                    // On error, don't close
                    closeModal = false
                }
            })
        })
        .then(() => {
            if(closeModal) {
                if(waitToClose) {
                    setTimeout(() => {
                        setShowOutcomesMenu(false)
                    }, 1000)
                }
                else {
                    setShowOutcomesMenu(false)
                }
            }
        })
    }

    const isDisabled = (): boolean => {
        // interpretations are running/success or outcomes are running = disable
        return interpController.processState === ProcessState.running || interpController.processState === ProcessState.success || processState === ProcessState.running
    }


    return (
        <PaperModal className={classes.modal} show={showOutcomesMenu} setShow={setShowOutcomesMenu} flexWidth flexHeight enablePadding>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography className={classes.header}>Please confirm the eConsult outcome and diagnosis are correct</Typography>
                </Grid>
                <Grid item xs={12}>
                    <OutcomesInput outcomes={outcomes} selectedOutcome={selectedOutcome} setSelectedOutcome={setSelectedOutcome} selectedDiagnosis={selectedDiagnosis} setSelectedDiagnosis={setSelectedDiagnosis} handleSubmitOutcomes={handleSubmitOutcomes}/>
                </Grid>
                {(billAudiogramGranted || billSinusEndoscopyGranted) && 
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>Did you complete any Interpretations with this Consult?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InterpretationsView {...interpController} billAudiogramGranted={billAudiogramGranted} billSinusEndoscopyGranted={billSinusEndoscopyGranted}/>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <PrevInterpretationsView/>
                </Grid>
                <Grid item xs={12}>
                    <ProcessStatus state={interpController.processState} errorMessage={interpController.processErrorMessage} successMessage="Interpretations billed." loadingMessage="Billing Interpretations..."/>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage="Outcomes saved."/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {setShowOutcomesMenu(false)}}
                        disabled={isDisabled()}
                    >Skip</Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmit}
                        disabled={isDisabled()}
                    >Save and Close</Button>
                </Grid>
            </Grid>
        </PaperModal>
    );
}


export default EconsultOutcomesModal;