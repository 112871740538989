import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outcomeText: {
            color: theme.palette.primary.main,
            whiteSpace: 'pre-wrap',//Show newlines, and don't collapse sequential spaces
            wordWrap: 'break-word',//break lines on word to fit
            fontWeight: 600
        },
        outcomeTextSmall: {
            fontSize: "1.0em"
        }
    })
);


interface EconsultOutcomesLabelProps {
    specialistResponse?: {
        outcome: string,
        diagnosis: string
    },
    fontSize?: "small" | "large"
}

const EconsultOutcomesLabel = ({ specialistResponse, fontSize="large"}: EconsultOutcomesLabelProps) => {
    const classes = useStyles();

    return (
        <>
            {specialistResponse !== undefined && (
                <>
                    <Typography className={clsx(classes.outcomeText, {
                        [classes.outcomeTextSmall]: fontSize === "small"
                    })}>
                        Outcome: {specialistResponse.outcome}
                    </Typography>
                    <Typography className={clsx(classes.outcomeText, {
                        [classes.outcomeTextSmall]: fontSize === "small"
                    })}>                        
                        Diagnosis: {specialistResponse.diagnosis}
                    </Typography>
                </>
            )}
        </>
    );
}

export default EconsultOutcomesLabel;