import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { PaperModal } from '@alethea-medical/alethea-components';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sharedStyles from '../../../sharedStyles';
import useConfirmDestructiveModal from '../../../components/useConfirmDestructiveModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
        iconButton: {
            padding: theme.spacing(1)
        }
    })
)

interface DeleteButtonProps {
    id: string,
    index: number,
    name: string,
    type: "category" | "item",
    deleteHandler: (id: string, index: number) => void
}

const DeleteButton = ({ id, index, name, type, deleteHandler }: DeleteButtonProps) => {
    
    const classes = useStyles();

    const { ConfirmDestructiveModal, showConfirmDestructiveModal } = useConfirmDestructiveModal({
        confirmText: `Delete ${type === "category" ? "category" : "template"} "${name}"${type === "category" ? " and all templates within it" : ""}?`,
        confirmButtonText: "Delete",
        action: () => { deleteHandler(id, index) }
    })

    return (
        <>
            <IconButton className={classes.iconButton} onClick={showConfirmDestructiveModal}>
                {type === "category" ? <DeleteSweepIcon/> : <DeleteIcon/>}
            </IconButton>
            {ConfirmDestructiveModal}
        </>
    );
}

export default DeleteButton;