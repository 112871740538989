import { useEffect, useRef } from "react";
import isNativeMobile from "../../../models/isNativeMobile";


interface UseMobileCameraClosedListenerProps {
    /** Called when the onMobileCameraClosed event is emitted by the mobile camera plugin */
    onUploadsFinished: () => void
}

function useMobileCameraListeners ({ onUploadsFinished }: UseMobileCameraClosedListenerProps) {
    const mobileCameraClosedListener = useRef<() => void>();

    useEffect(() => {
        //Only available on mobile
        if(isNativeMobile()) {    
            //Remove any pre-existing listeners (if any)
            removeListener()
            
            addListener(onUploadsFinished)
    
            return () => {
                removeListener()
            }
        }
    }, [onUploadsFinished])

    const addListener = (listener: () => void) => {
        //Store the listener so we can unsubscribe later
        mobileCameraClosedListener.current = () => {
            listener()
        }
        document.body.addEventListener('onMobileCameraUploadsFinished', mobileCameraClosedListener.current)
    }

    const removeListener = () => {
        if(mobileCameraClosedListener.current !== undefined) {
            document.body.removeEventListener('onMobileCameraUploadsFinished', mobileCameraClosedListener.current)
        }
    }
}

export default useMobileCameraListeners