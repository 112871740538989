import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from "firebase";
import { AuthContext } from '../../../AuthProvider';
import { binMediaByTime, getMediaByOrderedDate, getNewestMedia, searchMedia, SearchOptions, TimeBinnedMedia, UserMediaMetadataItem } from '../Models/GalleryModel';
import { ProcessState, useProcessState } from '@alethea-medical/alethea-components';
import { logAnalyticsEvent } from '../../../firebase';
import analyticsLogs from '../../../analyticsLogs';
import useMobileCameraListeners from '../../../views/Pages/Camera/useMobileCameraListeners';

/** Summary
 * Handles all states related to the basic gallery
 * Handles fetches, pagination, timestamp binning, and searching
 */

//Interface for easier passing of variables
export interface SearchProps {
    searchDateFrom: Date | null, setSearchDateFrom: (date: Date | null) => void,
    searchDateTo: Date | null, setSearchDateTo: (date: Date | null) => void,
    searchNotes: string, setSearchNotes: (notes: string) => void,
    searchTags: string[], setSearchTags: (tags: string[]) => void,
    enableSearch: boolean, setEnableSearch: (value: boolean) => void,
    performSearch: () => void,
    cancelSearch: () => void
}

const GalleryController = () => {
    const authContext = useContext(AuthContext);
    const defaultFetchAmount = 20;

    //Media metadata from firestore queries
    const [mediaItems, setMediaItems] = useState<UserMediaMetadataItem[]>([]);
    //Media metadata after running through filters
    const [paginationCursor, setPaginationCursor] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>>();
    //Timestamp binned media (after filters)
    const [binnedMediaItems, setBinnedMediaItems] = useState<TimeBinnedMedia>();

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
    const [searchDateTo, setSearchDateTo] = useState<Date | null>(null);
    const [searchNotes, setSearchNotes] = useState<string>("");
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [enableSearch, setEnableSearch] = useState(false)


    const galleryErrorHandler = (error: Error) => {
        errorHandler({
            error: error,
            userMessage: "Error fetching gallery files",
            analyticsLog: analyticsLogs.gallery.fetchFail
        })
    }

    const initialLoad = () => {
        setProcessState(ProcessState.running);
        getMediaByOrderedDate(authContext.uid, defaultFetchAmount, undefined)
        .then((result) => {
            setProcessState(ProcessState.idle);
            setMediaItems(result.data);
            setPaginationCursor(result.paginationCursor)
        })
        .catch(galleryErrorHandler)
    }

    //Only use when using getMediaByOrderedDate
    const loadMoreHandler = () => {
        setProcessState(ProcessState.running);
        getMediaByOrderedDate(authContext.uid, defaultFetchAmount, paginationCursor)
        .then((result) => {
            setProcessState(ProcessState.idle);
            setMediaItems(mediaItems.concat(result.data));
            //Only set pagination cursor if not undefined
            //Otherwise, it will reset pagination and cause duplicate images to be fetched
            if(result.paginationCursor !== undefined)
                setPaginationCursor(result.paginationCursor)
        })
        .catch(galleryErrorHandler)
    }
 
    // Fetch all media newer than mediaItems[0] (newest item in list)
    const fetchNewHandler = () => {
        setProcessState(ProcessState.running);

        const mostRecentCreated = mediaItems.length > 0 ? mediaItems[0].data.created.toDate() : new Date()

        getNewestMedia(authContext.uid, mostRecentCreated)
        .then((result) => {
            setProcessState(ProcessState.idle);
            setMediaItems(result.concat(mediaItems));
        })
        .catch(galleryErrorHandler)
    }

    const refreshHandler = () => {
        if(enableSearch)
            cancelSearchHandler()
        initialLoad();
    }

    const searchHandler = () => {
        setProcessState(ProcessState.running);
        setEnableSearch(true)

        const searchOptions: SearchOptions = {dateRange: {}}
        if(searchDateFrom !== null)
            searchOptions.dateRange.from = searchDateFrom
        if(searchDateTo !== null)
            searchOptions.dateRange.to = searchDateTo
        if(searchNotes !== "") 
            searchOptions.notes = searchNotes
        if(searchTags.length > 0)
            searchOptions.tags = searchTags
        
        searchMedia(authContext.uid, searchOptions)
        .then((result) => {
            setProcessState(ProcessState.idle);
            if(result !== undefined) {
                setMediaItems(result);
                //Reset pagination
                setPaginationCursor(undefined)
            }
        })
        .catch((error) => {
            setEnableSearch(false)
            galleryErrorHandler(error)
        })
    }

    const cancelSearchHandler = () => {
        setEnableSearch(false)
        //Don't clear dates
        setSearchNotes("")
        setSearchTags([])
        initialLoad();
    }

    const removeMediaItemsHandler = (ids: string[]) => {
        const newMediaItems = [...mediaItems];
        ids.forEach((id) => {
            const idx = newMediaItems.findIndex((i) => i.id === id)
            if(idx !== -1)
                newMediaItems.splice(idx, 1)
        })
        setMediaItems(newMediaItems);
    }

    const modifyItemHandler = (id: string, newMediaItem: UserMediaMetadataItem) => {
        const newMediaItems = [...mediaItems];
        const idx = newMediaItems.findIndex((i) => i.id === id)
        if(idx !== -1)
            newMediaItems[idx] = newMediaItem
        
        setMediaItems(newMediaItems);
    }

    //Initial state, show all media from most recent
    useEffect(() => {
        if(authContext.uid !== "") {
            initialLoad();
        }
    }, [authContext.uid])


    //When media items list update, bin it by day
    useEffect(() => {
        setBinnedMediaItems(binMediaByTime(mediaItems));
    }, [mediaItems])

    //Fetch new images when mobile camera upload finishes
    const fetchNewCallback = useCallback(() => fetchNewHandler, [mediaItems])
    useMobileCameraListeners({ onUploadsFinished: fetchNewCallback() })

    return {
        // data
        mediaItems,
        binnedMediaItems,
        // handlers
        loadMoreHandler,
        refreshHandler,
        fetchNewHandler,
        removeMediaItemsHandler,
        modifyItemHandler,
        // states
        searchProps: {
            searchDateFrom, setSearchDateFrom,
            searchDateTo, setSearchDateTo,
            searchNotes, setSearchNotes,
            searchTags, setSearchTags,
            enableSearch, setEnableSearch,
            performSearch: searchHandler,
            cancelSearch: cancelSearchHandler
        },
        galleryLoadState: processState, galleryLoadError: processErrorMessage
    }
}

export default GalleryController;