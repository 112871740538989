import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { SpecialistTemplates } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../../../firebase";

const emptyTemplate: SpecialistTemplates.Template = {
    items: {},
    categories: {},
    categoryOrder: []
}

export function getSpecialistTemplates(specialistUid: string): Promise<SpecialistTemplates.Template> {
    return fbFirestore.collection(dbNames.specialistTemplates).doc(specialistUid).get()
    .then((snapshot) => {
        if(snapshot.exists) {
            const templates = snapshot.data();
            if(templates !== undefined) {
                return templates as SpecialistTemplates.Template;
            }
        }
        //If user doesn't have templates, return empty template object
        return emptyTemplate
    })
}

export function saveSpecialistTemplates(specialistUid: string, template: SpecialistTemplates.Template): Promise<void> {
    return fbFirestore.collection(dbNames.specialistTemplates).doc(specialistUid).set(template)
    .then(() => Promise.resolve())
}