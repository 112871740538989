import { useCallback, useState } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import UploadFilesModal from "./UploadFilesModal";
import GalleryTimestampBins from "../../../components/Gallery/Views/GalleryTimestampBins";
import GalleryControls from "../../../components/Gallery/Views/GalleryControls";
import GalleryController from "../../../components/Gallery/Controllers/GalleryController";
import { UserMediaMetadataItem } from "../../../components/Gallery/Models/GalleryModel";
import SelectGalleryController from "../../../components/Gallery/Controllers/SelectGalleryController";
import SelectedMediaViewer from "../../../components/Gallery/Views/SelectedMediaViewer";
import GalleryRenderer from "../../../components/Gallery/Views/GalleryRenderer";
import MediaViewerController from "../../../components/Gallery/Controllers/MediaViewerController";
import MediaViewer from "../../../components/Gallery/Views/MediaViewer/MediaViewer";
import GallerySearchBar from "../../../components/Gallery/Views/GallerySearchBar";
import AVAGalleryButton from "./AVAGalleryIntegration/AVAGalleryButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginBottom: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(2),
    },
    hidden: {
      display: "none",
    },
  }),
);

const GalleryView = () => {
  const classes = useStyles();

  const {
    // data
    binnedMediaItems,
    // handlers
    loadMoreHandler,
    refreshHandler,
    fetchNewHandler,
    removeMediaItemsHandler,
    modifyItemHandler,
    // states
    searchProps,
    galleryLoadState,
    galleryLoadError,
  } = GalleryController();

  const {
    // data
    selectedMedia,
    // handlers
    selectHandler,
    unselectHandler,
    unselectAllHandler,
    deleteSelectedMediaHandler,
    // states
    editing,
    setEditing,
    selectState,
    selectError,
  } = SelectGalleryController({ removeMediaItems: removeMediaItemsHandler });

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        selected={selectedMedia[photo.id] !== undefined}
        enableSelect={editing}
        editing={editing}
        select={selectHandler}
        unselect={unselectHandler}
        handleOpenMedia={openMediaItemHandler}
      />
    ),
    [selectedMedia, editing],
  );

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <HeightLayout
        height={heightMinusAppBar}
        className={clsx(classes.container, {
          [classes.hidden]: openMediaItem !== undefined,
        })}
      >
        <HeightLayoutChild flexDriver className={classes.item}>
          <GalleryControls
            refresh={refreshHandler}
            editing={editing}
            setEditing={setEditing}
            setShowUploadModal={setShowUploadModal}
          >
            <AVAGalleryButton
              selectedMedia={selectedMedia}
              unselectAll={unselectAllHandler}
            />
          </GalleryControls>
          <ProcessStatus
            state={galleryLoadState}
            errorMessage={galleryLoadError}
          />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.item}>
          <GallerySearchBar searchProps={searchProps} />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.item}>
          <SelectedMediaViewer
            selectedMedia={selectedMedia}
            select={selectHandler}
            unselect={unselectHandler}
            unselectAll={unselectAllHandler}
            deleteSelectedMedia={deleteSelectedMediaHandler}
          />
          <ProcessStatus
            state={selectState}
            errorMessage={selectError}
            successMessage="Files deleted successfully."
          />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven allowOverflowY className={classes.item}>
          <GalleryTimestampBins
            binnedMediaItems={binnedMediaItems}
            galleryRenderer={galleryRenderer}
          />
        </HeightLayoutChild>
        {!searchProps.enableSearch && (
          <HeightLayoutChild flexDriver className={classes.item}>
            <Button
              onClick={loadMoreHandler}
              variant="outlined"
              color="primary"
            >
              Load More
            </Button>
          </HeightLayoutChild>
        )}
      </HeightLayout>
      {openMediaItem !== undefined && (
        <MediaViewer
          height={heightMinusAppBar}
          mediaItem={openMediaItem}
          closeMediaItem={closeMediaItemHandler}
          modifyItemHandler={modifyItemHandler}
          removeMediaItems={removeMediaItemsHandler}
        />
      )}
      <UploadFilesModal
        show={showUploadModal}
        setShow={setShowUploadModal}
        uploadFinishedHandler={fetchNewHandler}
      />
    </>
  );
};

export default GalleryView;
