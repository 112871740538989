import React from 'react';
import List from '@material-ui/core/List';
import LinkPreviewer from './LinkPreviewer';
import LinkPreviewerContainer from './LinkPreviewerContainer';
import LinkPreviewerHeader from './LinkPreviewerHeader';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 0
        }
    }),
);
const References = () => {
    const classes = useStyles();

    return ( 
        <Box className={classes.container}>
            <List className={classes.container}>
                <LinkPreviewerContainer header>
                    <LinkPreviewerHeader title="Support"/>
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/support"
                        title="User Support"
                        analyticsEventTag='Resources_link_press_support'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/resources"
                        title="Diagnostics Technology Resources"
                        analyticsEventTag='Resources_link_press_diagnostic_technology_resources'
                    />                    
                </LinkPreviewerContainer>

                <LinkPreviewerContainer header>
                    <LinkPreviewerHeader title="Specialist Handouts"/>
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/specialists/david-sheps"
                        title="Dr. David Sheps: Orthopaedics patient handouts and reference material"
                        analyticsEventTag='Resources_link_press_david_sheps'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/specialists/devon-livingstone"
                        title="Dr. Devon Livingstone: Otolaryngology patient handouts and reference material"
                        analyticsEventTag='Resources_link_press_dr_devon_livingstone'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/specialists/michael-braganza"
                        title="Dr. Michael Braganza: Pulmonary Function Test Requisition forms"
                        analyticsEventTag='Resources_link_press_dr_michael_braganza'
                    />                    
                </LinkPreviewerContainer>

                <LinkPreviewerContainer header>
                    <LinkPreviewerHeader title="Diagnostic References"/>
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/reference/nose-sinus"
                        title="Nose and Sinus Reference"
                        analyticsEventTag='Resources_link_press_nose_and_sinus'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/reference/ear"
                        title="Ear Reference"
                        analyticsEventTag='Resources_link_press_ear'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/reference/oral-cavity"
                        title="Oral Cavity Reference"
                        analyticsEventTag='Resources_link_press_cavity'
                    />                    
                </LinkPreviewerContainer>
                <LinkPreviewerContainer item>
                    <LinkPreviewer 
                        href="https://www.aletheamedical.com/reference/dermatology"
                        title="Dermatology Reference"
                        analyticsEventTag='Resources_link_press_dermatology'
                    />                    
                </LinkPreviewerContainer>
            </List>
        </Box>
    )
}


export default References
