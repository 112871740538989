import { AISpecialty, Activity, Specialist, UnlabelledImage, UserProfile } from "@alethea-medical/aletheamd-types";
import { UserMediaMetadataItem } from "../../../../../components/Gallery/Models/GalleryModel";
import { getMockMetadataForReferralMedia, getCleanedFileNameFromActivityPath } from "../../../../../components/Gallery/Models/MessagingGalleryModel";
import isOfTypeAIExcludeSubsite from "../../../../../models/isOfTypeAIExcludeSubsite";
import isOfTypeAISpecialty from "../../../../../models/isOfTypeAISpecialty";
import { fbFirestore, fbFunctions } from "../../../../../firebase";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import hasPermissions from "../../../../../models/hasPermissions";

export interface ImageAnnotationItem extends UserMediaMetadataItem {
    aiPrediction: string[],
    aiVersion: string,
    doctorLabels?: string[]
}

export interface ImageAnnotationSelectedDict {
    [uid: string]: ImageAnnotationItem
}

export function shouldShowImageAnnotation (activity: Activity.Activity, econsult: Activity.Econsult, specialist: Specialist.Profile, profile: UserProfile, uid: string): Promise<boolean> {
    return hasPermissions(resourceKeys.imageAnnotation, profile)
    .then((allow) => {
        return allow //Need to have imageAnnotation permissions
         && specialist !== undefined //Need to have a specialist document in firestore 
         && activity.profiles[uid]?.activityRole === "consultant" //Need to be the specialist on the econsult
         && specialist.enableEconsultDataCollection === true //Need to have eConsult data collection enabled
         && isOfTypeAISpecialty(econsult.specialty) //Need to be an AI specialty
         && !isOfTypeAIExcludeSubsite(econsult.subsite) //Need to not be a subsite that doesn't have AI
    })
}

/**
 * Get images from referral media URIs, and fetch their AI Predictions from unlabelled_images collection
 * If specialty or subsite is not for AI labelling, this will return an empty list
 * Ignores videos and other files included in the referralMediaURIs of the eConsult
 * @param econsult eConsult to fetch images for. Fetches images from referralMediaURIs
 * @returns Image annotation item list
 */
export function getImagesToLabel(econsult: Activity.Econsult): Promise<ImageAnnotationItem[]> {
    const specialty = econsult.specialty as AISpecialty
    //Initialize images, ignore video and other
    return getMockMetadataForReferralMedia(econsult.referralMediaURIs, ["video", "other"])
        .then(({ imageVideoMedia: imageMedia }) => imageMedia)
        .then((imageMedia) => {
            return Promise.all(imageMedia.map((image) => {
                return fetchAIPredictionFromUnlabelledImage(specialty, getCleanedFileNameFromActivityPath(image.data.filePath))
                .then((result) => {
                    //If doctor label already exists in eConsult, add it to the image
                    const doctorLabels = (econsult?.imageLabels ?? {})[image.data.filePath];
                    return {
                        ...image,
                        aiPrediction: result.aiPrediction,
                        aiVersion: result.aiVersion,
                        doctorLabels
                    }
                })
            }))
        })
        .then((imagesToLabel) => {
            //Sort explicitly, so images are always in the same order. Doesn't matter what we sort on, as long as its consistent between reloads
            //Using filePath, as it should be in order of newest to oldest (data.created is mocked out to always be the same, so that doesn't work)
            imagesToLabel.sort((a, b) => {
                if(a.data.filePath < b.data.filePath) return -1;
                else if(a.data.filePath > b.data.filePath) return 1;
                else return 0;
            })
            return imagesToLabel
        })

}

/**
 * Try and fetch AI Prediction from unlabelled_images collection
 * @param specialty Specialty to look in
 * @param filename Name of the file (without activity hash, or path ex. 123456789.jpg)
 * @returns Promise string list of AI predictions 
 */
function fetchAIPredictionFromUnlabelledImage(specialty: AISpecialty, filename: string): Promise<{aiPrediction: string[], aiVersion: string}> {
    return fbFirestore.collection(dbNames.unlabelledImages).doc(specialty).collection(dbNames.unlabelledImages_images).where("filename", "==", filename).get()
        .then((snapshot) => {
            if (snapshot.size > 0) {
                console.log(`Using preexisting unlabelled image data for ${filename}`)

                const data = snapshot.docs[0].data() as UnlabelledImage;
                if (data !== undefined) {
                    return {
                        aiPrediction: data.aiPrediction,
                        aiVersion: data.aiVersion
                    }
                }
            }
            //If data doesn't exist, return none
            return {
                aiPrediction: [],
                aiVersion: "N/A"
            }
        })

}


// Same as specialist-label-image data in backend
interface SpecialistLabelImageData {
    labels: {
        filePath: string,
        aiPrediction: string[],
        doctorLabels: string[],
        customLabels: string[],
        aiVersion: string
        specialty: AISpecialty,
        created: { seconds: number, nanoseconds: number}
    }[]
}
const specialistLabelImages = fbFunctions.httpsCallable("ai-specialistLabelImages");

export function submitLabels(econsult: Activity.Econsult, labelledImages: ImageAnnotationItem[], checkAILabelExists: (aiLabel: string) => boolean
    ): Promise<{[key: string]: string[]}> {
        
    const data: SpecialistLabelImageData = {
        labels: []
    }

    // Get preexisting econsult labels (or initialize if empty)
    const econsultLabels = econsult.imageLabels !== undefined ? {...econsult.imageLabels} : {};
    labelledImages.forEach((image) => {
        // Add doctor labels if not undefined
        if(image.doctorLabels !== undefined){
            // Add to econsult

            //Check if arrays same length
            const sameLength = (econsultLabels[image.data.filePath] ?? []).length === image.doctorLabels.length;
            //Check that arrays contain same labels
            const containsSame = (econsultLabels[image.data.filePath] ?? []).every((label) => (image.doctorLabels as string[]).includes(label))
            //If same array, then don't update
            if(sameLength && containsSame) {
                return;
            }
            //Otherwise update labels
            else {
                econsultLabels[image.data.filePath] = image.doctorLabels


                // Separate pre-existing labels from custom labels that the specialist created
                const customLabels: string[] = [];
                const doctorLabels: string[] = [];
                image.doctorLabels.forEach((label) => {
                    if(checkAILabelExists(label))
                        doctorLabels.push(label)
                    else
                        customLabels.push(label)
                })
    
                //Add to data for backend
                data.labels.push({
                    filePath: image.data.filePath,
                    aiPrediction: image.aiPrediction,
                    doctorLabels: doctorLabels,
                    customLabels: customLabels,
                    aiVersion: image.aiVersion,
                    specialty: econsult.specialty as AISpecialty,
                    created: image.data.created
                })
            }
            
            
        }
    })
    //Only update if labels changed
    if(data.labels.length > 0) {
        console.log(`Updating ${data.labels.length} labels`)
        return specialistLabelImages(data)
        .then(() => {
            return econsultLabels
        })
    }
    return Promise.resolve(econsultLabels);
}