import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { fbFunctions, logAnalyticsEvent } from "../'../../../../firebase";
import * as QueryString from "query-string";
import { ProcessStatus, ProcessState } from '@alethea-medical/alethea-components';
import { useProcessState } from "@alethea-medical/alethea-components";
import ColumnViewContainer from '../../../components/ColumnViewContainer';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header:{
            textAlign: 'center',
            margin: theme.spacing(2)
        },
    }),
);



const LinkAccount = withRouter(({ location }) => {

    const linkAccount = fbFunctions.httpsCallable("ava-linkAccount");
    const classes = useStyles();
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    useEffect(() => {
        const params = QueryString.parse(location.search);
        setProcessState(ProcessState.running);
        if(params.jwt) {
            linkAccount({jwt: params.jwt})
            .then(() => {
                setProcessState(ProcessState.success);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error, 
                    userMessage: "Error linking your AVA account to your Alethea account"
                });  
            })
        }
        else {
            errorHandler({
                userMessage: "Invalid information provided to link your AVA account to your Alethea account. Please try again."
            });  
        }
    }, [])

    return (
        <>
            <ColumnViewContainer>
                <Grid container justifyContent="center" spacing={1} alignItems="center">
                    <Grid item xs={2}>
                        <img src="/Ava_logo.png" alt="Ava Logo" width="100%"/>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container justifyContent="center" alignItems="center">
                            <ArrowForwardIcon color="primary"/>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <img src="/logo_big.png" alt="Alethea Logo" width="100%"/>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" spacing={4} alignItems="center">
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" className={classes.header}>
                            Account Link
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus 
                            state={processState} 
                            setState={setProcessState} 
                            errorMessage={processErrorMessage} 
                            successMessage="Your AVA and Alethea accounts have been successfully linked. You may now close this page." 
                            loadingMessage="Linking your AVA account..."/>
                    </Grid>
                </Grid>

            </ColumnViewContainer>
            
        </>
    );
})

export default LinkAccount;