import { useHistory } from "react-router-dom"
import * as QueryString from "query-string";
import { useEffect, useState } from "react";

interface useQueryParamRoutingProps {
    paramName: string
}


/** Utility hook to add or remove a parameter from query parameters without affecting other parameters
 * Ex: https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345
 * useQueryParamRouting({ paramName: "draftId" })
 * Call addOrRemoveFromQueryParams(09876) results in https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345&draftId=09876
 * Call addOrRemoveFromQueryParams(undefined) results in https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345
 */
const useQueryParamRouting = ({ paramName }: useQueryParamRoutingProps) => {
    
    const history = useHistory()
    const [currentValue, setCurrentValue] = useState<string | undefined>(undefined)
    
    /**
     * Adds or removes the provided parameter as paramName to the query parameters (https://<route>>/?<other params>&<paramName>=<param>)
     * @param param Parameter to add. If undefined the parameter will be removed
     */
    const addOrRemoveFromQueryParams = (param: string | undefined) => {
        const queryParams = history.location.search !== "" ? history.location.search.split('&') : []
        const idx = queryParams.findIndex((str) => str.includes(paramName))

        // Add to query params while keeping old params
        if(param !== undefined && idx === -1) {
            queryParams.push(`${paramName}=${param}`)
            history.push({
                search: `${queryParams.join('&')}`
            })
        }
        // Remove from query params while keeping old params
        else if(param === undefined && idx !== -1){
            queryParams.splice(idx, 1)

            history.push({
                search: `${queryParams.join('&')}`
            })
        }
    }

    useEffect(() => {
        const params = QueryString.parse(location.search)
        setCurrentValue(params[paramName])
    }, [history.location.search])

    return {
        addOrRemoveFromQueryParams,
        currentValue
    }
}

export default useQueryParamRouting;