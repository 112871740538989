import Button from "@material-ui/core/Button";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import {
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import AVAGalleryController from "./AVAGalleryController";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface AVAGalleryButtonProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  unselectAll: () => void;
}

const AVAGalleryButton = ({
  selectedMedia,
  unselectAll,
}: AVAGalleryButtonProps) => {
  const {
    enableAVAUpload,
    avaGalleryState,
    avaGalleryError,
    isDisabled,
    setAVAGalleryState,
    uploadFilesToAVAHandler,
    avaGalleryLoading,
    showSelectError,
  } = AVAGalleryController({ selectedMedia, unselectAll });

  const isMobileSize = useMobileSizes();

  return (
    <>
      {enableAVAUpload && (
        <>
          <Grid item>
            <Button
              onClick={uploadFilesToAVAHandler}
              variant="outlined"
              color="primary"
              disabled={isDisabled}
            >
              <span style={{ display: "inline-flex", alignItems: "baseline" }}>
                {isMobileSize ? "" : "Upload to"}
                <img
                  src="/Ava_logo.svg"
                  alt="Icon"
                  style={
                    isMobileSize
                      ? {
                          height: "1.2em",
                          fontSize: "1em",
                          marginTop: "5.25px",
                          marginBottom: "5.25px",
                        }
                      : {
                          height: "1em",
                          fontSize: "1em",
                          marginLeft: "0.35em",
                        }
                  }
                />
              </span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ProcessStatus
              state={avaGalleryState}
              setState={setAVAGalleryState}
              errorMessage={avaGalleryError}
              successMessage="Files uploaded successfully."
              loadingMessage={avaGalleryLoading}
              useSnackbar
            />

            {showSelectError && (
              <MuiAlert severity="warning">
                Select at least one image to upload to Ava.
              </MuiAlert>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default AVAGalleryButton;
