import { useMobileSizes } from "@alethea-medical/alethea-components";
import Button from "@material-ui/core/Button";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Alert from "@material-ui/lab/Alert";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../../components/Gallery/Models/GalleryModel";
import SelectedMediaViewer from "../../../../components/Gallery/Views/SelectedMediaViewer";
import useConsultFormStyles from "../useConsultFormStyles";

interface ConsultGalleryPreviewerProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  selectHandler: (item: UserMediaMetadataItem) => void;
  unselectHandler: (id: string) => void;
  unselectAllHandler: () => void;
  onOpenGallery: () => void;
  imageWarningLimit: number;
}

const ConsultGalleryPreviewer = ({
  selectedMedia,
  selectHandler,
  unselectHandler,
  unselectAllHandler,
  onOpenGallery,
  imageWarningLimit,
}: ConsultGalleryPreviewerProps) => {
  const classes = useConsultFormStyles();

  const isMobileSize = useMobileSizes();

  return (
    <div>
      <div className={classes.spacing}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PhotoLibraryIcon />}
          onClick={onOpenGallery}
        >
          Open Gallery
        </Button>
      </div>

      <div className={classes.spacing}>
        <SelectedMediaViewer
          selectedMedia={selectedMedia}
          select={selectHandler}
          unselect={unselectHandler}
          unselectAll={unselectAllHandler}
          gridSizeMultiplier={isMobileSize ? 1 : 2} //Make grid size 2x as big if on web (since the width is only half the screen on web)
        />
      </div>
      <div className={classes.spacing}>
        {Object.keys(selectedMedia).length > imageWarningLimit && (
          <Alert severity="warning">
            Please do not attach more than {imageWarningLimit} images unless
            necessary.
          </Alert>
        )}
      </div>
    </div>
  );
};

export default ConsultGalleryPreviewer;
