import React from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';

interface GalleryGridProps<T> {
    photos: T[],
    breakpoints?: {xs: GridSize, sm: GridSize, md: GridSize, lg: GridSize}
    galleryRenderer:  (photo: T) => JSX.Element
}

function GalleryGrid<T> ({ photos, breakpoints={xs: 6, sm: 4, md: 3, lg: 2 }, galleryRenderer }: GalleryGridProps<T>) {
    return (
        <Grid container spacing={1}>
            {photos.map((photo, index) => 
                <Grid item xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} key={`photo_${index}_${JSON.stringify(photo)}`}>
                    {galleryRenderer(photo)}
                </Grid>
            )}
        </Grid>
    );
}


export default GalleryGrid;