import { useContext, useEffect } from "react";
import { ActivityContext } from "../../Inbox/Contexts/ActivityProvider";
import { InputContext } from "../../Utilities/InputProvider";

interface InputContextOutcomesSetterProps {
    setSelectedOutcome: (selectedOutcome: string) => void,
    setSelectedDiagnosis: (selectedDiagnosis: string) => void,
    setShowOutcomesMenu: (show: boolean) => void
}

const InputContextOutcomesSetter = ({ setSelectedOutcome, setSelectedDiagnosis, setShowOutcomesMenu }: InputContextOutcomesSetterProps) => {
    const inputContext = useContext(InputContext);
    const activityContext = useContext(ActivityContext);
    
    useEffect(() => {
        if(activityContext.econsult.specialistResponse?.outcome === undefined && inputContext.outcome !== "") {
            setSelectedOutcome(inputContext.outcome)
        }
    }, [inputContext.outcome])

    useEffect(() => {
        if(activityContext.econsult.specialistResponse?.diagnosis === undefined && inputContext.diagnosis !== "") {
            setSelectedDiagnosis(inputContext.diagnosis)
        }
    }, [inputContext.diagnosis])


    useEffect(() => {
        //Turned on and off when message is sent
        if(inputContext.messageSent === true){
            //Only show if we haven't recorded a specialist response yet
            if(activityContext.econsult.specialistResponse === undefined) {
                setShowOutcomesMenu(true);
            }
        }
    }, [inputContext.messageSent])
}

export default InputContextOutcomesSetter;