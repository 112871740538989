import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { fbFirestore } from "../../../../firebase";
import { Fcm } from "../../../../../shared/types";
import Badge, { BadgeProps } from '@material-ui/core/Badge';
import { dbNames } from "@alethea-medical/aletheamd-db-keys";

const UnreadNotificationsCounter = (props: BadgeProps) => {
    const [unreadNotifications, setUnreadNotifications] = useState<number>(0)
    const authContext = useContext(AuthContext)

    useEffect(() => {
        if(authContext.uid !== "") {
            return fbFirestore.collection(dbNames.notifications).doc(authContext.uid).onSnapshot((snapshot) => {
                if(!snapshot.exists) return;
                const notificationsProfile = snapshot.data() as Fcm.UserNotificationProfile | undefined;
                if(!notificationsProfile) return;
                if(typeof notificationsProfile.numUnread !== "number") return;
                setUnreadNotifications(Math.max(notificationsProfile.numUnread, 0))
            })
        }
    }, [authContext.uid])

    return (
        <Badge badgeContent={unreadNotifications} color="error" max={99} overlap="rectangular" {...props}>
            {props.children}
        </Badge>
    );
}

export default UnreadNotificationsCounter;  