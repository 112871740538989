import { Capacitor } from "@capacitor/core";

/**
 * Returns true if running in a native app
 *
 * Will NOT return true if on mobile, but in web browser
 * */
function isNativeMobile() {
  return Capacitor.isNativePlatform();
}

export default isNativeMobile;
