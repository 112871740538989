import { ProcessState } from "@alethea-medical/alethea-components";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { thinScrollbar } from "@alethea-medical/alethea-components";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { ProcessStatus, HeightLayout, HeightLayoutChild } from "@alethea-medical/alethea-components";
import DraftsListController from './Controllers/DraftsListController';
import Typography from '@material-ui/core/Typography';
import DraftListItem from './ListItem/DraftListItem';
import DraftsToolbar from './DraftsToolbar';
import DraftSearchBar from './DraftSearchBar';
import { Activity } from '../../../../../shared/types';

const sortDraftEntriesByCreated = (a: [string, Activity.ConsultDraft], b: [string, Activity.ConsultDraft]) => {
    if(a[1].created < b[1].created)
        return 1;
    else if(a[1].created > b[1].created)
        return -1;
    else
        return 0;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		...thinScrollbar,
		box: {
			borderColor: theme.palette.grey[300],
		},
		threadSidebar:  {
			padding: 0,
		},
		threadListItem: {
			padding: 0,
		},
		threadList: {
			padding: 0
		},
		hidden: {
			display: "none"
		},
		toolbar: {
            paddingLeft: theme.spacing(1),
        },
		inboxControlsBar: {
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0)
		},
		tabSwitcher: {
			paddingLeft: theme.spacing(1)
		},
		centerListItem: { 
			justifyContent: "center"
		},
		noSavedDrafts: {
			textAlign: "center",
		}
	}),
);


interface DraftsListViewProps {
    height: number,
	approvalsList?: boolean
}

const DraftsListView = ({ height, approvalsList }: DraftsListViewProps) => {
    const classes = useStyles();

	const { drafts, searchDrafts,
		selectedItems, allSelected, selectAllHandler, listItemSelectHandler,
		openDraftHandler, openApprovalHandler, discardHandler,
		enableSearch, clearSearch, runSearch,
		processState, processErrorMessage, setProcessState
	} = DraftsListController(approvalsList ? "drafts_pending_approval" : "user_drafts");

    return (
		<List className={classes.threadSidebar}>
			<HeightLayout height={height}>
				<HeightLayoutChild flexDriver>
					<ListItem 
						divider
					>
						<DraftSearchBar enableSearch={enableSearch} 
							runSearch={runSearch} clearSearch={clearSearch}
							placeholderText={'Search by Subject'}
						/>
					</ListItem>
					<ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>
					<ListItem className={classes.toolbar} divider>
						<Grid container spacing={1} justifyContent="flex-start" alignItems="center">
							<Grid item>
								<Checkbox checked={allSelected} onChange={(e) => { selectAllHandler(e.target.checked) }}/>
							</Grid>
							<Grid item>
								<DraftsToolbar
									atLeastOneSelected={selectedItems.length > 0}
									moreThanOneSelected={selectedItems.length > 1}
									discard={discardHandler}
								/>
							</Grid>
						</Grid>
					</ListItem>
				</HeightLayoutChild>
				<HeightLayoutChild flexDriven allowOverflowY className={classes.thinScrollbar}>
					{Object.keys(drafts).length == 0 && processState != ProcessState.running && 
						<ListItem className={classes.centerListItem}>
							<Typography className={classes.noSavedDrafts}>You have no {approvalsList ?  "pending approvals" : "saved drafts"}</Typography>
						</ListItem>
					}
					{Object.entries(enableSearch ? searchDrafts : drafts).sort(sortDraftEntriesByCreated).map(([draftId, draft]) => 
						<DraftListItem 
							key={`draft_list_item_${draftId}`} 
							draft={draft} 
							draftId={draftId}
							openDraft={approvalsList ?  openApprovalHandler : openDraftHandler}
							selected={selectedItems.includes(draftId)}
							setSelected={listItemSelectHandler}
						/>
					)}
				</HeightLayoutChild>
			</HeightLayout>
		</List>		
    );
}

export default DraftsListView;