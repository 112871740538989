import React, { useContext, useEffect, useState } from "react";
import { Specialist } from "../../../../../shared/types";
import { fbFirestore } from "../../../../firebase";
import { getDocumentData } from "@alethea-medical/utilities";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { AuthContext } from "../../../../AuthProvider";
import hasPermissions from "../../../../models/hasPermissions";

interface ContextProps {
  specialist?: Specialist.Profile;
  templatesAvailable: boolean;
}

export const SpecialistContext = React.createContext<ContextProps>({
  specialist: undefined,
  templatesAvailable: false,
});

export const SpecialistProvider: React.FunctionComponent = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [specialist, setSpecialist] = useState<Specialist.Profile>();
  const [templatesAvailable, setTemplatesAvailable] = useState(false);

  useEffect(() => {
    if (
      authContext.uid !== "" &&
      authContext.profile?.userRoles !== undefined
    ) {
      fbFirestore
        .collection(dbNames.specialists)
        .doc(authContext.uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            return getDocumentData(snapshot).then(
              (specialist: Specialist.Profile) => {
                setSpecialist(specialist);
              },
            );
          }
        })
        .catch((error: Error) => {
          console.error(error);
        });

      //Check that user has permissions to view specialist templates
      //If they have none, they can create new ones from the menu
      hasPermissions(
        resourceKeys.specialistTemplates,
        authContext.profile,
      ).then((allow) => {
        setTemplatesAvailable(allow);
      });
    }
  }, [authContext.uid, authContext.profile?.userRoles]);

  return (
    <SpecialistContext.Provider
      value={{
        specialist,
        templatesAvailable,
      }}
    >
      {children}
    </SpecialistContext.Provider>
  );
};
