import clsx from "clsx";
import { AppRoute } from "../../../config/routes";
import { orangeButton } from "../../../sharedStyles";
import { useRoutePermissions } from "../../../components/PermissionRoute";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import AletheaSidebarItem, {
  AletheaSidebarItemProps,
} from "./components/AletheaSidebarItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: "white",
      minWidth: "45px",
    },
    text: {
      color: "white",
    },
    consultButtonClosed: {
      paddingLeft: theme.spacing(1),
      height: "40px",
      width: "40px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    consultButtonOpen: {
      width: "auto",
      margin: theme.spacing(1),
    },
    consultButton: {
      ...orangeButton,
      borderRadius: "10px",

      padding: theme.spacing(1),
    },
  }),
);

interface ConsultButtonProps extends AletheaSidebarItemProps {
  route: AppRoute;
}

const ConsultButton = (props: ConsultButtonProps) => {
  const classes = useStyles();
  const { permissionGranted } = useRoutePermissions(props.route);
  return (
    <AletheaSidebarItem
      {...props}
      label="New Consult"
      enabled={permissionGranted}
      textClass={classes.text}
      classes={{
        drawerButton: clsx(classes.consultButton, {
          [classes.consultButtonClosed]: !props.showText,
          [classes.consultButtonOpen]: props.showText,
        }),
        icon: classes.icon,
      }}
      icon={props.icon}
    />
  );
};

export default ConsultButton;
