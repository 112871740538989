import { useScreenSize } from '@alethea-medical/alethea-components';
import SpecialistTemplatesView from './SpecialistTemplatesView';

const EditSpecialistTemplatesPage = () => {
    const { heightMinusAppBar } = useScreenSize({})
    return (
        <SpecialistTemplatesView height={heightMinusAppBar} alwaysEnableEditing={true}/>
    );
}

export default EditSpecialistTemplatesPage;