import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from "../../palette";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        statusPaper: {
            color: palette.darkGreen,
            borderStyle: "solid",
            borderColor: palette.darkGreenLight,
            borderWidth: "1px",
            backgroundColor: "transparent",
            borderRadius: "4px",
            height: "36.02px",
            padding: theme.spacing(0, 1)
        },
        // Icon screws up the spacing, so we need a separate case for it
        itemWithIcon: {
            height: "100%",
            marginTop: theme.spacing(0.4)
        },
        clickable: {
            cursor: "pointer"
        }
    })
)

interface StatusPaperProps {
    icon?: React.ReactChild,
    endIcon?: React.ReactChild,
    style?: React.CSSProperties,
    className?: string,
    onClick?: () => void
}

const StatusPaper: React.FC<StatusPaperProps> = ({ icon, endIcon, style, className, onClick, children }) => {
    const classes = useStyles();
    return (
        <Grid container className={clsx(classes.statusPaper, className, {
                [classes.clickable]: onClick
            })} 
            onClick={onClick} 
            style={style} 
            justifyContent="space-between" alignItems="center" spacing={1}
        >
            {icon && 
                <Grid item>
                    {icon}
                </Grid>
            }
            <Grid item className={clsx({
                [classes.itemWithIcon]: icon || endIcon
            })}>
                {children}
            </Grid>
            {endIcon && 
                <Grid item>
                    {endIcon}
                </Grid>
            }
        </Grid>
    )
}

export default StatusPaper;