import { Route, Redirect, Switch } from "react-router-dom";
import ResetPassword from "../../../components/ResetPassword";
import Signup from "../../Pages/Signup";
import { Login } from "../components";

export const AuthRoutes = () => {
    return (
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />

            <Route path="/auth/reset-password">
                <ResetPassword />
            </Route>
            {/* Redirect from old links */}
            <Redirect to="/login" from="/auth/login" />
            <Redirect to="/signup" from="/auth/signup" />
           
            {/* Redirect to login for any other invalid link */}
            <Redirect to="/login" from="/" />
        </Switch>
    );
};