import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: "2.0em"
        }
    })
)
interface SectionHeaderProps {
    title: string,
}

const SectionHeader = ({ title }: SectionHeaderProps) => {
    const classes = useStyles();

    return (                       
        <Typography className={classes.title}>
            {title}
        </Typography>
    );
}

export default SectionHeader;