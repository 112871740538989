import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import ActivityListItem from "./ListItem/ActivityListItem";

import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TuneIcon from "@material-ui/icons/Tune";

import { thinScrollbar } from "@alethea-medical/alethea-components";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import SnackbarMessage from "../../../../components/SnackbarMessage";
import SecureMessagingToolbar from "../Toolbar/SecureMessagingToolbar";
import ViewSecureMessage from "../ViewSecureMessage";

import { useEffect, useState } from "react";

import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { useContext } from "react";
import { Activity } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { SpecialistProvider } from "../Utilities/SpecialistProvider";
import ConsultSearchBar from "./ConsultSearchBar";
import ActivityProvider from "./Contexts/ActivityProvider";
import ActivitiesListController from "./Controllers/ActivitiesListController";
import InboxController from "./Controllers/InboxController";
import LabelContainer from "./ListItem/LabelContainer";
import ActivitiesListModel from "./Models/ActivitiesListModel";
import InboxModel from "./Models/InboxModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...thinScrollbar,
    box: {
      borderColor: theme.palette.grey[300],
    },
    columnHeader: {
      fontWeight: "bold",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    threadSidebar: {
      padding: 0,
    },
    threadListItem: {
      padding: 0,
    },
    threadList: {
      padding: 0,
    },
    hidden: {
      display: "none",
    },
    hiddenIcon: {
      visibility: "hidden",
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
    },
    inboxControlsBar: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    tabSwitcher: {
      paddingLeft: theme.spacing(1),
    },
  }),
);

interface InboxViewProps {
  height: number;
  folder: Activity.UserActivityFolder;
  setShowTabs: (show: boolean) => void;
}

const InboxView = ({ height, folder, setShowTabs }: InboxViewProps) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  const {
    sortedActivities,
    sortedEconsults,
    activities,
    updateActivitiesInState,
    removeActivitiesFromState,
    loadMoreHandler,
    disableLoadMoreLoading,
    disableLoadMoreEndOfResults,
    calculateGridSize,

    isActivityOpen,
    openActivityHandler,

    // Permissions
    permissions,

    // Select
    selectedActivities,
    allSelected,
    selectAllHandler,
    activitySelectHandler,
    unselectAllHandler,

    // Search
    enableSearch,
    runSearch,
    clearSearch,
    saveSearchFilters,
    fetchSearchFilters,

    processState,
    setProcessState,
    processErrorMessage,
    errorHandler,
  } = InboxController<Activity.UserActivity>({
    inboxModel: new InboxModel(
      authContext.uid,
      dbNames.activities,
      dbNames.userActivities,
      dbNames.userActivities_activities,
      "lastMessageReceivedAt",
    ),
    folder,
    metadataFieldToSortBy: "lastMessageReceivedAt",
    setShowTabs,
  });

  const activitiesListController = ActivitiesListController({
    activitiesListModel: new ActivitiesListModel(authContext.uid),
    activities,
    updateActivitiesInState,
    removeActivitiesFromState,
    unselectAllHandler,
    errorHandler,
  });

  // To show the column headers
  const columnHeaders = [
    "Specialty",
    "Subsite",
    "PHN",
    "Patient Name",
    "Referrer Name",
    "Consultant Name",
  ];

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showColumnHeaders, setShowColumnHeaders] = useState(true);
  const [toolbarAnimationComplete, setToolbarAnimationComplete] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isMobileSize = useMobileSizes();
  const gridSizes = calculateGridSize(selectedOptions);

  useEffect(() => {
    if (toolbarAnimationComplete) {
      setShowColumnHeaders(true);
    }
  }, [toolbarAnimationComplete]);

  const handleSelectAll = (checked: boolean) => {
    selectAllHandler(checked);
    setShowColumnHeaders(false);
    setToolbarAnimationComplete(false);
  };

  const handleActivitySelect = (id: string, checked: boolean) => {
    activitySelectHandler(id, checked);
    setShowColumnHeaders(selectedActivities.length === 0 && !checked);
    setToolbarAnimationComplete(false);
  };

  return (
    <>
      <SpecialistProvider>
        <div className={isActivityOpen ? classes.hidden : ""}>
          <List className={classes.threadSidebar}>
            <HeightLayout height={height}>
              <HeightLayoutChild flexDriver>
                <ListItem divider>
                  <ConsultSearchBar
                    enableSearch={enableSearch}
                    runSearch={runSearch}
                    clearSearch={clearSearch}
                    saveSearchFilters={saveSearchFilters}
                    fetchSearchFilters={fetchSearchFilters}
                    placeholderText={
                      "PHN, Patient Name, Specialty, Subsite, Referring Provider/Consultant name"
                    }
                    maxSearchTerms={10}
                    onSelectedOptionsChange={setSelectedOptions}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                  />
                </ListItem>
                <ProcessStatus
                  state={processState}
                  setState={setProcessState}
                  errorMessage={processErrorMessage}
                  useSnackbar={true}
                />
                <ListItem className={classes.toolbar} divider>
                  <Grid
                    container
                    justifyContent={
                      isMobileSize ? "space-between" : "flex-start"
                    }
                    alignItems="center"
                  >
                    <Grid item>
                      <Checkbox
                        checked={allSelected}
                        onChange={(e) => {
                          handleSelectAll(e.target.checked);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.hiddenIcon}
                      style={{ padding: "8px" }}
                    >
                      {sortedActivities.length > 0 && (
                        <LabelContainer
                          activity={sortedActivities[0]}
                          read={true}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <SecureMessagingToolbar
                        folder={folder}
                        atLeastOneSelected={selectedActivities.length > 0}
                        markAsUnread={() => {
                          activitiesListController.markAsUnreadHandler(
                            selectedActivities,
                          );
                        }}
                        moveFolder={(folder) => {
                          activitiesListController.moveFolderHandler(
                            selectedActivities,
                            folder,
                          );
                        }}
                        onAnimationEnd={() => {
                          setToolbarAnimationComplete(true);
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      xs
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {!isMobileSize && showColumnHeaders && (
                        <>
                          <Grid
                            item
                            className={clsx(classes.columnHeader, {
                              [classes.hiddenIcon]:
                                !permissions.hasEditStatusPermissions,
                            })}
                            xs={gridSizes["Status"]}
                          />
                          {columnHeaders.map(
                            (header) =>
                              selectedOptions.includes(header) && (
                                <Grid
                                  key={header}
                                  item
                                  className={classes.columnHeader}
                                  xs={gridSizes[header]}
                                >
                                  {header}
                                </Grid>
                              ),
                          )}
                        </>
                      )}
                      <Grid item xs container justifyContent="flex-end">
                        <Tooltip title="Edit Subject Line">
                          <IconButton
                            onClick={() => setShowOptions(!showOptions)}
                          >
                            <TuneIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              </HeightLayoutChild>
              <HeightLayoutChild
                flexDriven
                allowOverflowY
                className={classes.thinScrollbar}
              >
                {sortedActivities.map((a, index) => (
                  <ActivityListItem
                    key={`activity_list_item_${a.id}`}
                    activity={a}
                    econsult={sortedEconsults[index]}
                    openActivity={openActivityHandler}
                    selected={selectedActivities.includes(a.id)}
                    setSelected={handleActivitySelect}
                    filters={selectedOptions}
                    calculateGridSize={calculateGridSize}
                    permissions={permissions}
                    processState={processState}
                  />
                ))}
                <ListItem
                  button
                  onClick={loadMoreHandler}
                  disabled={
                    disableLoadMoreLoading || disableLoadMoreEndOfResults
                  }
                  alignItems="center"
                  divider
                >
                  <ListItemIcon>
                    <ArrowDownwardIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      disableLoadMoreLoading
                        ? "Loading"
                        : disableLoadMoreEndOfResults
                          ? "End of Consults"
                          : enableSearch
                            ? "Load More Search Results"
                            : "Load More"
                    }
                  />
                </ListItem>
              </HeightLayoutChild>
            </HeightLayout>
          </List>
        </div>
        <ActivityProvider
          updateActivitiesInListState={updateActivitiesInState}
          activitiesListController={activitiesListController}
          viewType="md"
          metadataRefId={authContext.uid}
          metadataCollection={dbNames.userActivities}
          metadataSubCollection={dbNames.userActivities_activities}
        >
          <ViewSecureMessage />
        </ActivityProvider>
        <SnackbarMessage
          message={`Moved to ${activitiesListController.moveSnackbarFolderAction}`}
          show={activitiesListController.showUndoSnackbar}
          setShow={activitiesListController.setShowUndoSnackbar}
          onClick={activitiesListController.undoMoveFolderHandler}
          useButton
          buttonText="UNDO"
        />
        <SnackbarMessage
          message={"Marked as unread"}
          show={activitiesListController.showMarkedAsUnreadSnackbar}
          setShow={activitiesListController.setShowMarkAsUnreadSnackbar}
        />
      </SpecialistProvider>
    </>
  );
};

export default InboxView;
