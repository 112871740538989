import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { fbFunctions } from '../../../firebase';
import { downloadBlob } from '../../../utils/download-utils';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { TypedFormTextField } from '../../../components/FormInputFields/FormTextField';
import Button from '@material-ui/core/Button';
import { Activity } from '../../../../shared/types';
import { useEffect, useMemo, useState } from 'react';
import { TypedFormSelect } from '../../../components/FormInputFields/FormSelect';
import useCms from '../../../utils/useCms';


interface BusinessInfo {name: string, phone: string, website: string, address: {line1: string, line2: string, line3: string}}

interface CreateInvoiceData {
    patientAddress: {
        line1: string,
        line2: string,
        line3: string
    },
    businessIndex?: number
}


interface AveiroInvoicePageProps {
    activityId: string,
    econsult: Activity.Econsult
}

const AveiroInvoicePage = ({ activityId, econsult }: AveiroInvoicePageProps) => {
    const createInvoice = fbFunctions.httpsCallable("aveiro-createInvoice")

    const content = useCms({ cmsId: "aveiro" })
    
    const [businessOptions, setBusinessOptions] = useState<BusinessInfo[]>([])

    const getDefaultValues = (econsult: Activity.Econsult): CreateInvoiceData => {
        return {
            patientAddress: {
                line1: econsult.symptoms?.["Patient Address Line 1"] ?? "",
                line2: econsult.symptoms?.["Patient Address Line 2"] ?? "",
                line3: econsult.symptoms?.["Patient Address Line 3"] ?? ""
            },
            businessIndex: businessOptions.length > 0 ? 0 : undefined
        }
            
    }
    const { handleSubmit, control, setValue } = useForm<CreateInvoiceData>({ mode: "onTouched", defaultValues: useMemo(() => getDefaultValues(econsult), [econsult]) });

    const { processState, setProcessState, setProcessErrorMessage, processErrorMessage, errorHandler } = useProcessState({ });


    useEffect(() => {
        const newInfo = content.getArray<BusinessInfo>("businessInfo")
        if(newInfo.length > 0) {
            setBusinessOptions(newInfo)
        }
    }, [content])

    useEffect(() => {
        if(businessOptions.length > 0) {
            setValue("businessIndex", 0)
        }
    }, [businessOptions])


	const onSubmit = (data: CreateInvoiceData) => {
        if(data.businessIndex === undefined)
            return;
        setProcessState(ProcessState.running)
        createInvoice({ activityId, patientAddress: data.patientAddress, businessInfo: businessOptions[data.businessIndex] })
        .then((result) => {
            downloadBlob(result.data.pdf, `invoice_${result.data.invoiceNumber}.pdf`, 'application/pdf')
            setProcessState(ProcessState.success)
            setTimeout(() => {
                setProcessState(ProcessState.idle)
            }, 1000)
        })
        .catch((error) => {
            errorHandler({
                error,
                userMessage: "There was an error creating the invoice. Please try again."
            })
        })
	}

	const onError = () => {
		setProcessErrorMessage("Check form for errors.");
		setProcessState(ProcessState.error);
	}

	const isDisabled = () => {
		return processState === ProcessState.running;
	}

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off' aria-autocomplete='none'>
            <fieldset disabled={isDisabled()} aria-autocomplete='none'>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Create an Invoice</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TypedFormTextField
                            name="patientAddress.line1"
                            control={control}
                            label="Patient Address Line 1"
                            disabled={isDisabled()}
                            rules={{required: {value: true, message: "Address line 1 is required."}}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TypedFormTextField
                            name="patientAddress.line2"
                            control={control}
                            label="Patient Address Line 2 (Optional)"
                            disabled={isDisabled()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TypedFormTextField
                            name="patientAddress.line3"
                            control={control}
                            label="Patient Address Line 3"
                            disabled={isDisabled()}
                            rules={{required: {value: true, message: "Address line 3 is required."}}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TypedFormSelect
                            name="businessIndex"
                            control={control}
                            label="Business"
                            disabled={isDisabled()}
                            rules={{ required: { value: true, message: "Business is required." } }} 
                            getOptionLabel={(index) => businessOptions[index].name}
                            options={businessOptions.map((_: any, index: number) => index)}                        
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} successMessage={"Invoice downloaded"} loadingMessage='Creating invoice...'/>
                    </Grid>
                    <Grid item>
                        <Button type="submit" color="primary" disabled={isDisabled()}>
                            Create Invoice
                        </Button>
                    </Grid>
                </Grid>
            </fieldset>
        </form>
    );
}

export default AveiroInvoicePage;