import React, { useState, useEffect } from 'react';


import { PatientMessage } from "./types";
import { fbStorage } from "../../../firebase";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Divider from '@material-ui/core/Divider';


import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PDFViewer from '../../../components/PDFViewer';
import AttachmentViewer from './AttachmentViewer';
import MessageSubheader from './MessageSubheader';
import Collapse from '@material-ui/core/Collapse';
import PatientMessageToolbar from "./PatientMessageToolbar";
import { PatientMessageFolder } from '../../../../shared/types';
import { useScreenSize, useSizeManager, HeightLayout, HeightLayoutChild, SwipeableOrGrid, useMobileSizes } from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subject: {
            fontWeight: 500
        },
        subjectContainer: {
            padding: theme.spacing(0, 2),
        },
        errorReason: {
            maxWidth: "50%"
        },
        fullHeight: {
            height: "100%"
        },
        toolbarContainer: {
            paddingLeft: theme.spacing(1)
        }
    }),
);

interface ViewMessageProps {
    message: PatientMessage,
    goBackHandler: () => void,
    markAsUnread: () => void,
    moveFolder: (folder: PatientMessageFolder) => void,
}


const ViewMessage = ({ message, goBackHandler, markAsUnread, moveFolder }: ViewMessageProps) => {

    const classes = useStyles();
    const [pdfURL, setPDFURL] = useState<string | undefined>(undefined);
    const [showMsgInfo, setShowMsgInfo] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);

    

    useEffect(() => {
        if(message.messagePDFURI !== undefined) {
            fbStorage.ref(message.messagePDFURI).getDownloadURL()
            .then((url) =>{
                setPDFURL(url);
            })
            .catch((error) => {
                console.error(error);
            })  
        }
  
    }, [message])

    const getNoDataText = () => {
        if(message.subType === "Forms" && message.messagePDFURI === undefined) {
            if(message.status === "sending") {
                return (
                    <>
                        <Typography variant="h5">
                            This form has not yet been sent.
                        </Typography>
                    </>
                );
            }
            else {
                return (
                    <>
                        <Typography variant="h5">
                            This form has no response yet.
                        </Typography>
                    </>
                );
            }

        }
        return undefined;
    }

    const isMobileSize = useMobileSizes();
	const { sizeRef: bodySizeRef, height: bodyHeight, width: bodyWidth} = useSizeManager();
    const { heightMinusAppBar } = useScreenSize({})

    return (
        <>
            <HeightLayout height={heightMinusAppBar}>
                <HeightLayoutChild flexDriver>
                    <>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <IconButton onClick={goBackHandler}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <div className={classes.toolbarContainer}>
                                    <PatientMessageToolbar 
                                        folder={message.folder}
                                        atLeastOneSelected={true}
                                        markAsUnread={markAsUnread} 
                                        moveFolder={moveFolder} 
                                        showAttachments={showAttachments}
                                        onShowAttachments={() => setShowAttachments(!showAttachments)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className={classes.subjectContainer} container justifyContent="flex-start" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.subject}>
                                            {message.subject}
                                            <IconButton onClick={() => {setShowMsgInfo(!showMsgInfo)}}>
                                                {showMsgInfo ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Collapse in={showMsgInfo}>
                                            <Typography variant="subtitle1"><MessageSubheader message={message}/></Typography>
                                        </Collapse>
                                    </Grid>
                                    {message.error && (
                                        <Grid item xs={12} className={classes.errorReason} justifyContent="flex-start" alignItems="center">
                                            <Typography variant="subtitle1">
                                                {message.error}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </>
                </HeightLayoutChild>
                <HeightLayoutChild flexDriven ref={bodySizeRef}>
                    <SwipeableOrGrid 
                        showSwipe={isMobileSize} 
                        index={showAttachments ? 1 : 0} 
                        onChangeIndex={(index) => {
                            setShowAttachments(index === 1)
                        }}
                        enableStepper={false}
                        showInitialStepper={false}
                        ignoreTouchInput
                        gridSize={[8, 4]} 
                        containerClassName={classes.fullHeight}
                        swipeClassName={classes.fullHeight}
                        gridItemClassName={classes.fullHeight} 
                        gridContainerClassName={classes.fullHeight}>
                        <PDFViewer pdfURL={pdfURL} noDataText={getNoDataText()} height={bodyHeight} width={isMobileSize ? bodyWidth : bodyWidth * 8/12}/>
                        <AttachmentViewer messageMediaURIs={message.messageMediaURIs}/>
                    </SwipeableOrGrid>
                </HeightLayoutChild>
            </HeightLayout>
        </>
    );
}


export default ViewMessage;