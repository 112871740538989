import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  thinScrollbar,
  useMobileSizes,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import Grid from "@material-ui/core/Grid";
import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import ConsultButton from "../../../Dashboard/Sidebar/ConsultButton";
import routes from "../../../../config/routes";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useEffect, useState } from "react";
import InboxView from "./InboxView";
import DraftsListView from "./DraftsListView";
import { parse } from "query-string";
import { Activity } from "../../../../../shared/types";
import UnreadNotificationsCounter from "../NotificationCenter/UnreadNotificationsCounter";
import UnreadApprovalsCounter from "./UnreadApprovalsCounter";
import NotificationCenter from "../NotificationCenter";
import useMultiPermissions from "../../../../components/usePermissions/useMultiPermissions";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import isNativeMobile from "./../../../../models/isNativeMobile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...thinScrollbar,
    box: {
      borderColor: theme.palette.grey[300],
    },
    threadSidebar: {
      padding: 0,
    },
    threadListItem: {
      padding: 0,
    },
    threadList: {
      padding: 0,
    },
    hidden: {
      display: "none",
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
    },
    inboxControlsBar: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    unreadNotificationsCounter: {
      top: 15,
      right: 10,
    },
    unreadNotificationsCounterApprovals: {
      top: 15,
      right: isNativeMobile() ? 10 : 25,
    },
    mobileMaxWidth: {
      width: isNativeMobile() ? "100vw" : "auto",
    },
  }),
);

function isUserActivityFolder(tab: string): tab is Activity.UserActivityFolder {
  return tab === "inbox" || tab === "archive";
}

export type MessagingTab =
  | "inbox"
  | "archive"
  | "drafts"
  | "notifications"
  | "approvals";

const SecureMessaging = withRouter(({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissionDict } = useMultiPermissions({
    resourceKeys: [
      resourceKeys.econsultAndSecureMessaging,
      resourceKeys.forwardEconsult,
      resourceKeys.approveEconsult,
    ],
  });
  const granted = Object.values(permissionDict).some((value) => value === true);
  const isApprover = permissionDict[resourceKeys.approveEconsult] === true;
  const [tab, setTab] = useState<MessagingTab>("inbox");

  const [showTabs, setShowTabs] = useState(true);
  const isMobileSize = useMobileSizes();

  useEffect(() => {
    const params = parse(location.search);
    if (params.tab !== undefined) {
      if (
        params.tab === "inbox" ||
        params.tab === "archive" ||
        params.tab === "drafts" ||
        params.tab === "notifications" ||
        params.tab === "approvals"
      ) {
        setTab(params.tab);
      }
    }
  }, [location.search]);

  const handleTab = (_: any, newTab: MessagingTab) => {
    // Set tab in search params if user presses back to go to this page the tab is correct
    history.push({
      search: `?tab=${newTab}`,
    });
  };

  const { heightMinusAppBar } = useScreenSize({});
  const { sizeRef, height, updateSize } = useSizeManager();

  useEffect(() => {
    updateSize();
  }, [showTabs, tab]);

  useEffect(() => {
    const params = parse(location.search);
    if (params.tab === undefined) {
      setTab(granted ? "inbox" : "notifications");
    }
    updateSize();
  }, [granted]);

  return (
    <HeightLayout height={heightMinusAppBar}>
      {showTabs && (
        <HeightLayoutChild flexDriver>
          {isMobileSize && (
            <ConsultButton
              route={routes.consult}
              showText={true}
              icon={<AddCircleIcon />}
              linkComponent={Link}
              {...{ disableGutters: true }}
            />
          )}

          <Grid container justifyContent="space-between">
            <Grid item>
              <Tabs
                value={tab}
                onChange={handleTab}
                orientation={"horizontal"}
                variant="scrollable"
                className={classes.mobileMaxWidth}
              >
                {granted && <Tab value="inbox" label="Inbox" />}
                {granted && <Tab value="archive" label="Archive" />}
                <Tab value="notifications" label="Alethea News" />
                <UnreadNotificationsCounter
                  classes={{
                    anchorOriginTopRightRectangular:
                      classes.unreadNotificationsCounter,
                  }}
                />
                {granted && <Tab value="drafts" label="Drafts" />}
                {isApprover && <Tab value="approvals" label="Approvals" />}
                {isApprover && (
                  <UnreadApprovalsCounter
                    classes={{
                      anchorOriginTopRightRectangular:
                        classes.unreadNotificationsCounterApprovals,
                    }}
                  />
                )}
              </Tabs>
            </Grid>
            {!isMobileSize && (
              <Grid item>
                <ConsultButton
                  route={routes.consult}
                  showText={true}
                  icon={<AddCircleIcon />}
                  linkComponent={Link}
                  {...{ disableGutters: true }}
                />
              </Grid>
            )}
          </Grid>
        </HeightLayoutChild>
      )}
      {isUserActivityFolder(tab) && granted && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <InboxView height={height} folder={tab} setShowTabs={setShowTabs} />
        </HeightLayoutChild>
      )}
      {tab === "drafts" && granted && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <DraftsListView height={height} />
        </HeightLayoutChild>
      )}
      {tab === "notifications" && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <NotificationCenter height={height} setShowTabs={setShowTabs} />
        </HeightLayoutChild>
      )}
      {tab === "approvals" && isApprover && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <DraftsListView height={height} approvalsList />
        </HeightLayoutChild>
      )}
    </HeightLayout>
  );
});

export default SecureMessaging;
