import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { logAnalyticsEvent } from '../../../../firebase';
import { useProcessState, ProcessState } from '@alethea-medical/alethea-components';
import exportPDF from './exportPDF';
import analyticsLogs from '../../../../analyticsLogs';

interface useExportPDFProps {
    activityId: string,

}

const useExportPDF = ({ activityId }: useExportPDFProps) => {
    

	const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

	const exportPDFHandler = () => {
		setProcessState(ProcessState.running);
		logAnalyticsEvent(analyticsLogs.secureMessaging.exportPdf.start);
		return exportPDF(activityId)
			.then(() => {
				logAnalyticsEvent(analyticsLogs.secureMessaging.exportPdf.success);
				setProcessState(ProcessState.idle);
			})
			.catch((error: Error) => {
				errorHandler({
					error: error,
					userMessage: "Error exporting PDF",
					analyticsLog: analyticsLogs.secureMessaging.exportPdf.fail
				});
			})
	}

    return {
        exportPdfState: processState, exportPdfErrorMessage: processErrorMessage, setExportPdfState: setProcessState,
        exportPDFHandler,
        renderExportPdfButton: () => (
            <Tooltip
                title="Export eConsult as PDF"
                children={
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<PictureAsPdfIcon />}
                        onClick={exportPDFHandler}
                    >
                        Export
                    </Button>
                }
            />
        )
    }
}

export default useExportPDF;