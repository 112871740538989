import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MessageListItem from "../../../../components/MessageListItem";
import MarkunreadIcon from '@material-ui/icons/Markunread';
import DraftsIcon from '@material-ui/icons/Drafts';
import palette from '../../../../palette';
import Typography from '@material-ui/core/Typography';
import { formatTimeLong } from '../../../../models/formatTime';
import { Fcm } from '../../../../../shared/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelContainer: {
            padding: theme.spacing(1, 1, 0, 1)
        },
        iconRead: {
            color: palette.darkGreen
        },
        iconUnread: {
            color: palette.orange
        },
        timestamp: {
            whiteSpace: "nowrap"
        },
    }),
);


interface NotificationListItemProps {
    openNotification: (id: string) => void,
    id: string,
    notification: Fcm.UserNotification
}

const NotificationListItem = ({ openNotification, id, notification }: NotificationListItemProps) => {
    const classes = useStyles();


    return (
        <MessageListItem
            openMessage={openNotification}
            id={id}
            read={notification.read}
            labelComponent={
                <div className={classes.labelContainer}>
                    {notification.read &&
                        <DraftsIcon className={clsx({
                            [classes.iconRead]: notification.read,
                            [classes.iconUnread]: !notification.read
                        })} />
                    }
                    {!notification.read &&
                        <MarkunreadIcon className={clsx({
                            [classes.iconRead]: notification.read,
                            [classes.iconUnread]: !notification.read
                        })} />
                    }
                </div>
            }
            primary={notification.title}
        >
            <Typography className={classes.timestamp}> {formatTimeLong(notification.sentAt.toDate())}</Typography>
        </MessageListItem>
    );
}

export default NotificationListItem;