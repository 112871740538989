import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ImageAnnotationSelectedDict } from './EconsultImageAnnotationModel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useAITranslation from '../../../../../components/useAITranslation';
import { AISpecialty } from '../../../../../../shared/types';
import isNativeMobile from '../../../../../models/isNativeMobile';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import palette from '../../../../../palette';
import isWeb from '../../../../../models/isWeb';
import useCustomAILabels from './useCustomAILabels';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autoCompleteOption: {
            // Hover
            '&[data-focus="true"]': {
                backgroundColor: theme.palette.grey[200],
                borderColor: 'transparent',
                },
            // Selected
            '&[aria-selected="true"]': {
                backgroundColor: palette.lightGreenLight,
                borderColor: 'transparent',
            },
        },
    })
)
interface AddLabelsDropdownProps {
    specialty: string,
    labelHandler: (items: ImageAnnotationSelectedDict, aiLabels: string[]) => void,
    unselectAllHandler: () => void,
    selectedMedia: ImageAnnotationSelectedDict
}

const AddLabelsDropdown = ({ specialty, labelHandler, unselectAllHandler, selectedMedia }: AddLabelsDropdownProps) => {
    const classes = useStyles();
    const [labels, setLabels] = useState<string[]>([])
    const { spToAi, spLabelList, checkAILabelExists } = useAITranslation({useSpToAi: true, useSpLabelList: true})
    const { customAILabels, addNewLabelsToState }= useCustomAILabels();

    const handleAddLabels = () => {
        //Pass true to spToAi so it returns input label if not found (for custom labels)
        const aiLabels = labels.map((sp) => spToAi(sp, true))

        updateCustomLabels(aiLabels);

        labelHandler(selectedMedia, aiLabels)
        setLabels([])
        unselectAllHandler()
    }

    const updateCustomLabels = (labels: string[]) => {
        // Update custom labels in state. When user presses save, they will be saved to their custom label list in firestore
        //If label doesn't' exist in translation table, and doesn't exist in custom labels, then its a new custom label
        const newLabels: string[] = []
        labels.forEach((label) => {
            if(!checkAILabelExists(label)) {
                if(!customAILabels.includes(label)) {
                    newLabels.push(label)
                }
            }
        })
        addNewLabelsToState(newLabels)
    }

    const getHelperText = (inputValue: string, hasFocus: boolean) => {
        //If the textfield value could exist in the list, don't show helper text
        //If not focused, don't show helper text
        if(!hasFocus || inputValue.length === 0 || spLabelList[specialty as AISpecialty].some((t) => t.toLowerCase().includes(inputValue.toLowerCase())))
            return undefined

        //If no results showing, show helper text to add a new tag
        return "Press enter to create a new label"
    }

    const getLabelList = () => {
        const defaultLabels = spLabelList[specialty as AISpecialty] ?? []
        return defaultLabels.concat(customAILabels).sort((a, b) => {
            if(a.toLowerCase() < b.toLowerCase()) return -1;
            else if(a.toLowerCase() > b.toLowerCase()) return 1;
            else return 0;
        })
    }

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
                <Autocomplete
                    classes={{
                        option: classes.autoCompleteOption
                    }}
                    value={labels}
                    options={getLabelList()}
                    getOptionLabel={(option: string) => option}
                    onChange={(e, value) => {
                        setLabels(value as string[]);
                    }}
                    renderInput={(params: any) => 
                        <TextField 
                            {...params} 
                            placeholder="Add labels"
                            margin="dense" 
                            helperText={isWeb() && getHelperText((params?.inputProps as any)?.value, document.activeElement === (params?.inputProps as any)?.ref?.current)}
                            inputProps={{
                                ...params.inputProps,
                                enterkeyhint: "done"//Allow enter to add tag on mobile
                            }}
                        />
                    }

                    autoSelect={isNativeMobile()}
                    multiple
                    freeSolo
                    clearOnBlur
                    autoHighlight   
                />
            </Grid>
            <Grid item xs={6}>
                <Button fullWidth variant='contained' color="secondary" onClick={handleAddLabels} disabled={Object.keys(selectedMedia).length === 0 || labels.length === 0}>Label Images</Button>
            </Grid>
        </Grid>
    );
}

export default AddLabelsDropdown;