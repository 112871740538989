import { Reducer, useEffect, useReducer } from "react";
import { useForm, useFormState } from "react-hook-form";
import { ProcessState, useProcessState } from '@alethea-medical/alethea-components';
import { fbAuth, logAnalyticsEvent, projectEnv } from "../../../firebase";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PageHeaderFooterBars from "./Helpers/Styling/page/PageHeaderFooterBars";
import PageHeader from "./Helpers/Styling/page/PageHeader";
import * as QueryString from "query-string";
import { useHistory, withRouter } from "react-router-dom";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import serverRequest from "../../../models/serverRequest";
import SignupSuccessPage from "./Helpers/Info/SignupSuccessPage";
import globalStrings from "../../../globalStrings";
import { Location } from "history";
import SignupForm from "./Content/SignupForm";
import { SignupInfo, Plan, Role } from "@alethea-medical/aletheamd-types";

export const PROVINCE_WATCH_KEY = "province" as const;
export const PLAN_WATCH_KEY = "selectedPlan" as const;
export const ROLE_WATCH_KEY = "selectedRole" as const;

// #region Reducer Helpers
// ========================================================================================================

export const SignupActionNames = {
    SET_FORWARDING_TO_ONBOARDING: "setForwardingToOnboarding",
    SET_PROCEED_WITHOUT_PRAC_ID: "setProceedWithoutPracId",
    SET_IS_PRAC_ID_VALID: "setIsPracIdValid",
    SET_SAVED_SIGNATURE: "setSavedSignature",
    SET_AGREEMENT_BLOB: "setAgreementBlob"
} as const;

type AvailableSignupActions = {
    [SignupActionNames.SET_FORWARDING_TO_ONBOARDING]: boolean,
    [SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID]: boolean,
    [SignupActionNames.SET_IS_PRAC_ID_VALID]: boolean,
    [SignupActionNames.SET_SAVED_SIGNATURE]: string | undefined,
    [SignupActionNames.SET_AGREEMENT_BLOB]: Blob | undefined
};

type Action<T, U> = {
    type: T,
    payload: U
}

// Creates a union type of all possible actions
export type SignupAction = {
    [K in keyof AvailableSignupActions]: Action<K, AvailableSignupActions[K]> // Creates a map from available actions to actions with the correct type
}[keyof AvailableSignupActions]; // Turns the map into a union type

export const createSignupReducerAction = <T extends keyof AvailableSignupActions>(type: T, payload: AvailableSignupActions[T]) => ({
    type,
    payload
})
// #endregion =============================================================================================

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(10, 8, 10, 8)
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(8, 2, 8, 2)
            }
        },
        signupIcon: {//Center the icon vertically
            display: "block",
            margin: "auto"
        },
        pageContainer: {
            position: "relative",
            height: "100%",
            minHeight: "100vh",
        }
    })
)

export type SignupState = {
    isPracIdValid: boolean,
    proceedWithoutPracId: boolean,
    agreementBlob?: Blob,
    savedSignature?: string,
    forwardingToOnboarding: boolean
}

const Signup = withRouter(({ location }: { location: Location }) => {
    const classes = useStyles();
    const history = useHistory();

    const form = useForm({ mode: "onTouched", defaultValues: SignupInfo.defaultSignupInfo });
    const { control, trigger, setValue, reset, watch, handleSubmit } = form;
    const { isDirty } = useFormState({ control })
    const processStateObj = useProcessState({ logAnalyticsEvent });
    const { processState, setProcessState, errorHandler } = processStateObj;

    const selectedPlan = watch(PLAN_WATCH_KEY);

    const [signupState, reduceSignupState] = useReducer<Reducer<SignupState, SignupAction>>((prevState, action) => {
        switch (action.type) {
            case SignupActionNames.SET_FORWARDING_TO_ONBOARDING:
                return { ...prevState, forwardingToOnboarding: action.payload }
            case SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID:
                return { ...prevState, proceedWithoutPracId: action.payload }
            case SignupActionNames.SET_IS_PRAC_ID_VALID:
                return { ...prevState, isPracIdValid: action.payload }
            case SignupActionNames.SET_SAVED_SIGNATURE:
                return { ...prevState, savedSignature: action.payload }
            case SignupActionNames.SET_AGREEMENT_BLOB:
                return { ...prevState, agreementBlob: action.payload }
            default:
                return { ...prevState }
        }
    }, {
        isPracIdValid: false,
        proceedWithoutPracId: false,
        agreementBlob: undefined,
        savedSignature: undefined,
        forwardingToOnboarding: false
    })

    const { isPracIdValid, forwardingToOnboarding, agreementBlob } = signupState;
    const setForwardingToOnboarding = (newValue: boolean) =>
        reduceSignupState(createSignupReducerAction("setForwardingToOnboarding", newValue));

    const forwardToOnboarding = (firstName?: string, lastName?: string, email?: string) => {
        let hubspotLink = projectEnv === "prod" ? "https://meetings.hubspot.com/alana41/onboarding-session" : "https://meetings.hubspot.com/alana41/testing"
        if (firstName !== undefined)
            hubspotLink += `?firstName=${encodeURIComponent(firstName)}`
        if (lastName !== undefined)
            hubspotLink += `&lastName=${encodeURIComponent(lastName)}`
        if (email !== undefined)
            hubspotLink += `&email=${encodeURIComponent(email)}`

        window.open(hubspotLink, '_self')
    }

    const signIn = (data: SignupInfo.SignupInfo, selectedOnboarding: boolean) => {
        setForwardingToOnboarding(selectedOnboarding)
        fbAuth.signInWithEmailAndPassword(data.email, data.password)
            .then(() => {
                if (selectedOnboarding) {
                    forwardToOnboarding(data.firstName, data.lastName, data.email)
                }
                else if (isPracIdValid)
                    history.push('/dashboard/consult');
                else
                    history.push('/dashboard/validation-required');
            })
    }


    const onSubmit = (data: SignupInfo.SignupInfo) => {
        if (agreementBlob === undefined) {
            errorHandler({
                error: new Error("Agreement is undefined."),
                userMessage: "There was an error loading your Agreement forms. Please try again.",
                hideErrorMessage: true
            });
            return;
        }

        setProcessState(ProcessState.running);

        const formData = new FormData();
        formData.append("formData", JSON.stringify(data));

        formData.append("pracIdValid", JSON.stringify(isPracIdValid))

        formData.append("attachment", agreementBlob, "Family Physician Platform Use Agreement Signed.pdf");

        formData.append("plan", Plan.getDatabaseCode(data.selectedPlan)); // For compatibility with old backend
        formData.append("role", data.selectedRole); // For compatibility with old backend

        serverRequest(undefined, {}, formData, 'signup/complete-signup-v2')
            .then(() => {
                logAnalyticsEvent("signup_complete_success");
                setProcessState(ProcessState.success);

                reset(data)// Reset form to mark as not dirty
                signIn(data, data.scheduleOnboarding === "Yes");
            })
            .catch((error: Error) => {
                errorHandler({
                    error,
                    userMessage: `There was an error completing the signup process. Contact us at ${globalStrings.salesEmail} or ${globalStrings.salesPhone} if this issue continues to occur.`,
                    analyticsLog: "signup_complete_failed"
                });
            });
    }

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors.",
        })
    }

    //Parse query parameters
    useEffect(() => {
        const params = QueryString.parse(location.search);
        const plan = Plan.getFromQueryString(params.selectedPlan)
        setValue(PLAN_WATCH_KEY, Plan.isValid(plan) ? plan : Plan.EmptyPlan);

        const role = params.selectedRole
        if (Role.isValid(role)) {
            setValue(ROLE_WATCH_KEY, role)
            trigger(ROLE_WATCH_KEY)
        }
    }, [])

    // User successfully signed up so only show signup success page
    if (processState === ProcessState.success)
        return <SignupSuccessPage forwardingToOnboarding={forwardingToOnboarding} forwardToOnboardingManually={forwardToOnboarding} />

    return (
        <Grid container className={classes.pageContainer} direction="column">
            <PageHeaderFooterBars position="header" />
            <Grid item xs={12}>
                <PageHeader title={Plan.isValid(selectedPlan) ? "Set up your account" : "Pick a Plan"} />
            </Grid>
            <Grid item xs={12}>
                <SignupForm
                    form={form}
                    reduceSignupState={reduceSignupState}
                    signupState={signupState}
                    processStateObj={processStateObj}
                    onFormSubmission={handleSubmit(onSubmit, onError)}
                />
            </Grid>
            <PageHeaderFooterBars position="footer" />

            {/* Prompts user if they try to leave page, not displayed */}
            <LeavePagePrompt isDirty={isDirty} />
        </Grid>
    );
})

export default Signup;
