import { Controller } from "react-hook-form";
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { ControlledInputProps } from "../types";
import sharedStyles from "../../shared/sharedStyles";
import NumberFormat from 'react-number-format';
import { makeStyles } from "@material-ui/core/styles";
import isDateString from "./isDateString";
import { useState } from "react";
import { formatDateAsYYYYMMDD } from "../../../models/formatTime";

const useStyles = makeStyles((theme) => {
    const shared = sharedStyles(theme);
    return {
        ...shared
    }
});

// Create custom textfield to refromat date when it changes
const CustomTextField = (props: TextFieldProps) => {

    /**
     * Reformats date only if it is provided as a full date (10 characters long)
     * If date is not a full date, it is returned as is
     * Formats as yyyy/mm/dd
     * Can take in dates in the following formats:
     * yyyy-mm-dd
     * dd-mm-yyyy
     * dd/mm/yyyy
     * @param date Date to format
     * @returns Formatted date
     */
    const [lastDateSubmittedIncorrect, setLastDateSubmittedIncorrect] = useState(false);

    const displayError = (timeInMs: number) => {
        setLastDateSubmittedIncorrect(true);
        setTimeout(() => {
            setLastDateSubmittedIncorrect(false);
        }, timeInMs)
    }

    const reformatFullDate = (inputDate: string): string => {
        const date = formatDateAsYYYYMMDD(inputDate)
        
        if(date === '') {
            displayError(4000);
        }

        return date
    }
    return (
        //Reformat date, then call onchange with reformatted value
        <TextField
            {...props}
            onPaste={(e) => {
                const pastedText = e.clipboardData.getData('text/plain');
                e.preventDefault(); // Prevent the default paste behavior
                const reformattedText = reformatFullDate(pastedText); // Format the pasted text and set it as the value

                // Note: no real alternative to execCommand and every browser will keep supporting it ?
                // https://stackoverflow.com/questions/60581285/execcommand-is-now-obsolete-whats-the-alternative
                if(document.execCommand) document.execCommand('insertText', false, reformattedText);
            }}
            error={lastDateSubmittedIncorrect || props.error}
            helperText={lastDateSubmittedIncorrect ? <b>Date with an incorrect format pasted</b> : (props.error ? props.helperText : null)}
            autoComplete="off"
            inputProps={{
                "aria-autocomplete": "none",
                "list": "autocompleteOff"                        
            }}
        />
    )
}

const FormDateTextField = ({ name, control, defaultValue, rules, label, disabled, errorTextPadding, ...rest }: ControlledInputProps) => {
    const classes = useStyles();


    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={Object.assign(rules ? rules : {}, {
                validate: {
                    isDateString: isDateString
                }
            })}
            render={({ field, fieldState }) =>
                <NumberFormat
                    className={classes.canDisable}
                    {...field}
                    disabled={disabled}
                    format="####/##/##"
                    mask={["Y", "Y", "Y", "Y", "M", "M", "D", "D"]}
                    placeholder="YYYY/MM/DD"
                    customInput={CustomTextField}
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    InputLabelProps={{
                        disableAnimation: true,                 
                    }}
                    inputProps={{
                        "aria-autocomplete": "none",
                        "list": "autocompleteOff"                        
                    }}
                    {...rest}
                />
            }
        />
    );
}

export default FormDateTextField