import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import TextField from "@material-ui/core/TextField";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import isValidPracIDInput from "./isPracID";
import sharedStyles from "../../shared/sharedStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const shared = sharedStyles(theme);
  return {
    ...shared,
  };
});

// Formats input as prac-id with format 1234-56789
const PracIDInput = ({
  name,
  control,
  defaultValue,
  rules,
  disabled,
  label,
  errorTextPadding,
  ...rest
}: ControlledInputProps) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={{
        ...rules,
        validate: {
          isPracID: (ID: string) =>
            isValidPracIDInput(ID, control._formValues.province),
        },
      }}
      render={({ field, fieldState }) => {
        const handleChange = (values: NumberFormatValues) => {
          field.onChange(values.value);
        };

        const getPracIDFormat = (province: string): string | undefined => {
          switch (province) {
            case "AB":
              return "####-#####";
            case "BC":
              return "#####";
            default:
              return undefined;
          }
        };

        return (
          <NumberFormat
            {...field}
            {...rest}
            format={getPracIDFormat(control._formValues.province)}
            mask="_"
            customInput={TextField}
            label={label}
            disabled={disabled}
            className={classes.canDisable}
            error={Boolean(fieldState.error)}
            helperText={
              errorTextPadding
                ? fieldState.error?.message || " "
                : fieldState.error?.message
            }
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="off"
            InputLabelProps={{
              disableAnimation: true,
            }}
            inputProps={{
              "aria-autocomplete": "none",
              list: "autocompleteOff",
            }}
            onValueChange={handleChange}
            // Override default onChange, because it saves the formatted value, not the raw value
            onChange={() => {}}
          />
        );
      }}
    />
  );
};

export default PracIDInput;
