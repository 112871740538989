import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import palette from '../../../../../../palette';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        numberCircle: {
            fontWeight: "bold",
            fontSize: "2.0em",
            color: "white",
            backgroundColor: palette.orange,
            padding: theme.spacing(3)
        },
    })
)
interface SectionNumberProps {
    number: number
}

const SectionNumber = ({ number }: SectionNumberProps) => {
    const classes = useStyles();

    return (
        <Avatar className={classes.numberCircle}>
            {number}
        </Avatar>
    );
}

export default SectionNumber;