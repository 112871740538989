import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

interface InterpretationsViewProps {
    billAudiogram: boolean,
    setBillAudiogram: (doBill: boolean) => void,
    billAudiogramGranted: boolean,
    billSinusEndoscopy: boolean,
    setBillSinusEndoscopy: (doBill: boolean) => void,
    billSinusEndoscopyGranted: boolean
}

const InterpretationsView = ({ billAudiogram, setBillAudiogram, billAudiogramGranted, billSinusEndoscopy, setBillSinusEndoscopy, billSinusEndoscopyGranted }: InterpretationsViewProps) => {


    return (
        <Grid container spacing={1}>
            {billAudiogramGranted && 
                <Grid item xs={12}>
                    <FormControlLabel
                        label={"Audiogram"}
                        checked={billAudiogram}
                        onChange={(_, checked) => {setBillAudiogram(checked)}}
                        control={
                            <Checkbox 
                                color="primary"
                                disableRipple  
                            />
                        }
                    />
                </Grid>
            }
            {billSinusEndoscopyGranted &&
                <Grid item xs={12}>
                    <FormControlLabel
                        label={"Sinus Endoscopy"}
                        checked={billSinusEndoscopy}
                        onChange={(_, checked) => {setBillSinusEndoscopy(checked)}}
                        control={
                            <Checkbox 
                                color="primary"
                                disableRipple  
                            />
                        }
                    />  
                </Grid>
            }
        </Grid>

    );
}

export default InterpretationsView;