import { Controller } from "react-hook-form";

import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import isPHN from "./isPHN";
import { ControlledInputProps } from "../types";



interface PHNInputProps extends ControlledInputProps {
    province?: string
}


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}
  
const TextMaskAB = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}

const TextMaskBC = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/,' ', /\d/, /\d/, /\d/ ,' ',/\d/ ,/\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}



const PHNInput = ({  name, control, defaultValue, rules, label, disabled, province, errorTextPadding, ...rest  }: PHNInputProps) => {

    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={Object.assign({ validate: {
                isPHN: (phn: string) => {
                    return isPHN(phn, province);
                }
            }}, {...rules})}
            render={({field, fieldState}) => {
        
                const inputProps: any = {}

                switch(province) {
                    case "BC":
                        inputProps.inputComponent = TextMaskBC;
                        break;
                    case "AB":
                        inputProps.inputComponent = TextMaskAB;      
                        break;                            
                }
                
                return (
                    <TextField   
                        {...field}
                        {...rest}
                        {...{InputProps: inputProps}}
                        label={label}
                        error={fieldState.error !== undefined}
                        helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                        onChange={(e) => {
                            const phn = e.target.value.split('-').join('').split(' ').join('');
                            field.onChange(phn);
                        }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        disabled={disabled}
                        autoComplete="off"
                        InputLabelProps={{
                            disableAnimation: true,
                        }}
                        inputProps={{
                            "aria-autocomplete": "none",
                            "list": "autocompleteOff"                        
                        }}
                    />
                );
            }}
        />

    );
}

export default PHNInput;