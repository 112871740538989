// #region Helper Components
// ========================================================================================================

import { UseFormReturn } from "react-hook-form"
import { Role, Plan, SignupInfo } from "@alethea-medical/aletheamd-types"
import { Collapse, Grid } from "@material-ui/core"
import { PLAN_WATCH_KEY, PROVINCE_WATCH_KEY, ROLE_WATCH_KEY, SignupAction, SignupActionNames, SignupState, createSignupReducerAction, useStyles } from "../Signup"
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components"
import { FormEventHandler, useMemo } from "react"
import NoPlanErrorPage from "../Helpers/Info/NoPlanErrorPage"
import strings from "../Common/strings"
import SignupProvinceSelect from "../Helpers/Input/SignupProvinceSelect"
import InformationBox from "../Helpers/Container/InformationBox"
import DoctorInformation from "./Sections/DoctorInformation"
import PreceptorInformation from "./Sections/PreceptorInformation"
import SectionContainer from "../Helpers/Container/SectionContainer"
import ClinicInformation from "./Sections/ClinicInformation"
import UserAgreement from "./Sections/UserAgreement"
import Onboarding from "./Sections/Onboarding"
import Spacer from "../Helpers/Styling/Spacer"
import InviteCodeBox from "../Helpers/Container/InviteCodeBox"
import RoundedButton from "src/components/RoundedButton"
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SelectRoleDropdown from "../Helpers/Input/SelectRoleDropdown"
import { OptionalRole } from "shared/types/dist/namespaces/Role"

type SignupFormContentProps = {
    plan: Plan.Plan,
    maybeRole: OptionalRole,
    form: UseFormReturn<SignupInfo.SignupInfo>,
    reduceSignupState: (action: SignupAction) => void,
    signupState: SignupState,
    processStateObj: ReturnType<typeof useProcessState>,
    onFormSubmission: FormEventHandler<HTMLFormElement>
}
// Shows remainder of the form or error page if the role isn't eligible for the plan (i.e nurse for plus)
const SignupFormFields = ({
    plan,
    maybeRole: role,
    form,
    reduceSignupState,
    signupState,
    processStateObj,
    onFormSubmission
}: SignupFormContentProps) => {
    if (!Role.isValid(role)) {
        return null
    }

    if (!Role.isEligibleForPlan(role, plan)) {
        return <NoPlanErrorPage title={strings.invalidRoleAndPlan(Role.getLongDisplayName(role))} />;
    }

    const classes = useStyles();

    const { processState, processErrorMessage } = processStateObj;
    const { proceedWithoutPracId, isPracIdValid, savedSignature, agreementBlob } = signupState;
    const selectedRole = form.watch(ROLE_WATCH_KEY);
    const province = form.watch(PROVINCE_WATCH_KEY);

    const setIsPracIdValid = (newValue: boolean) =>
        reduceSignupState(createSignupReducerAction(SignupActionNames.SET_IS_PRAC_ID_VALID, newValue));
    const setProceedWithoutPracId = (newValue: boolean) =>
        reduceSignupState(createSignupReducerAction(SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID, newValue));
    const setAgreementBlob = (newValue?: Blob) =>
        reduceSignupState(createSignupReducerAction(SignupActionNames.SET_AGREEMENT_BLOB, newValue));
    const setSavedSignature = (newValue?: string) =>
        reduceSignupState(createSignupReducerAction(SignupActionNames.SET_SAVED_SIGNATURE, newValue));

    const isDisabledNeedPracId = useMemo(() => {
        return !(proceedWithoutPracId || isPracIdValid || (Role.isValid(selectedRole) && !Role.acceptsPracId(selectedRole)))
    }, [proceedWithoutPracId, isPracIdValid, selectedRole, Role.isValid, Role.acceptsPracId])

    const isAgreementSigned = useMemo(() => {
        return agreementBlob !== undefined && savedSignature !== undefined
    }, [agreementBlob, savedSignature])


    const isDisabled = useMemo(() => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }, [processState])

    const { control, trigger, setValue } = form;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <SignupProvinceSelect control={control} selectedPlan={plan} />
            </Grid>
            <Grid item xs={12}>
                <InformationBox>{strings.provinceInfo(plan)}</InformationBox>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={province !== ""}>
                    <form onSubmit={onFormSubmission}>
                        <fieldset disabled={isDisabled}>
                            {/* Numbers and header */}
                            <Grid container>
                                <Grid item xs={12}>
                                    <DoctorInformation
                                        control={control}
                                        trigger={trigger}
                                        isPracIdValid={isPracIdValid}
                                        setIsPracIdValid={setIsPracIdValid}
                                        proceedWithoutPracId={proceedWithoutPracId}
                                        setProceedWithoutPracId={setProceedWithoutPracId}
                                        disabled={isDisabled}
                                        disabledNeedPracId={isDisabledNeedPracId}
                                        disablePracIdEntry={!Role.acceptsPracId(role)}
                                        role={role}
                                        number={1}
                                    />
                                    {
                                        role === Role.Roles.Resident ?
                                            <PreceptorInformation
                                                control={control}
                                                number={2}
                                            /> :
                                            <SectionContainer title="Clinic Information" number={2}>
                                                <ClinicInformation
                                                    control={control}
                                                    disabled={isDisabled || isDisabledNeedPracId}
                                                />
                                            </SectionContainer>
                                    }
                                    <UserAgreement
                                        control={control}
                                        selectedRole={role}
                                        isAgreementSigned={isAgreementSigned}
                                        savedSignature={savedSignature}
                                        setSavedSignature={setSavedSignature}
                                        setAgreementBlob={setAgreementBlob}
                                        number={3}
                                    />
                                    <Onboarding
                                        setValue={setValue}
                                        trigger={trigger}
                                        control={control}
                                        disabled={isDisabled || isDisabledNeedPracId || !isAgreementSigned}
                                        number={4}
                                    />
                                    <SectionContainer>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Spacer height="50px" />
                                            </Grid>
                                            <Grid item>
                                                <InviteCodeBox control={control} disabled={isDisabled} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Spacer height="50px" />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <RoundedButton
                                                    fullWidth
                                                    type="submit"
                                                    disabled={isDisabled || isDisabledNeedPracId || !isAgreementSigned}
                                                >
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            Complete Sign Up
                                                        </Grid>
                                                        <Grid item>
                                                            <ChevronRightIcon className={classes.signupIcon} />
                                                        </Grid>
                                                    </Grid>
                                                </RoundedButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ProcessStatus
                                                    state={processState}
                                                    errorMessage={processErrorMessage}
                                                />
                                            </Grid>
                                        </Grid>
                                    </SectionContainer>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </form>
                </Collapse>
            </Grid>
        </Grid>
    );
}

type SignupFormProps = {
    form: UseFormReturn<SignupInfo.SignupInfo>,
    reduceSignupState: (action: SignupAction) => void,
    signupState: SignupState,
    processStateObj: ReturnType<typeof useProcessState>,
    onFormSubmission: FormEventHandler<HTMLFormElement>
}

// Shows form or error page if the plan is invalid (i.e. not plus or econsult)
const SignupFormWrapped = ({
    form,
    reduceSignupState,
    signupState,
    processStateObj,
    onFormSubmission
}: SignupFormProps) => {
    const { control, watch } = form;
    const plan = watch(PLAN_WATCH_KEY);

    if (!Plan.isValid(plan)) {
        return <NoPlanErrorPage title={strings.pickAPlan} />
    }

    const role = watch(ROLE_WATCH_KEY);

    return <>
        <SelectRoleDropdown control={control} plan={plan} />
        <SignupFormFields
            plan={plan}
            maybeRole={role}
            form={form}
            reduceSignupState={reduceSignupState}
            signupState={signupState}
            processStateObj={processStateObj}
            onFormSubmission={onFormSubmission}
        />
    </>
}

// Wraps the form in a container
const SignupForm = ({
    form,
    reduceSignupState,
    signupState,
    processStateObj,
    onFormSubmission
}: SignupFormProps) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <SignupFormWrapped
            form={form}
            reduceSignupState={reduceSignupState}
            signupState={signupState}
            processStateObj={processStateObj}
            onFormSubmission={onFormSubmission}
        />
    </div>
}

export default SignupForm;
