import { useCallback, useState } from 'react';
import GalleryGrid from '../../../../../components/Gallery/Views/GalleryGrid';
import GalleryRenderer from '../../../../../components/Gallery/Views/GalleryRenderer';
import useAITranslation from '../../../../../components/useAITranslation';
import EconsultImageAnnotationController from './EconsultImageAnnotationController';
import { ImageAnnotationItem, ImageAnnotationSelectedDict } from './EconsultImageAnnotationModel';
import Grid from '@material-ui/core/Grid';
import ImageToAnnotateModal from './ImageToAnnotateModal';
import SelectGalleryController from '../../../../../components/Gallery/Controllers/SelectGalleryController';
import ConfirmAIPredictionButton from './ConfirmAIPredictionButton';
import IgnoreImagesButton from './IgnoreImagesButton';
import AddLabelsDropdown from './AddLabelsDropdown';
import Collapse from '@material-ui/core/Collapse';
import StartStopLabellingButton from './StartStopLabellingButton';
import { ProcessState, ProcessStatus } from '@alethea-medical/alethea-components';

const EconsultImageAnnotationView = () => {
    
    const controller = EconsultImageAnnotationController()
    const { aiToSp } = useAITranslation({ useSpToAi: true})
    const [modalImage, setModalImage] = useState<ImageAnnotationItem>()
    const {
        // data
        selectedMedia,
        // handlers
        selectHandler, unselectHandler, unselectAllHandler,
    } = SelectGalleryController({});


    const galleryRenderer = useCallback(
        (photo: ImageAnnotationItem) => (
            <GalleryRenderer
                item={photo}
                selected={selectedMedia[photo.id] !== undefined}
                handleOpenMedia={(item) => {setModalImage(item as ImageAnnotationItem)}}
                select={selectHandler}
                unselect={unselectHandler}
                labels={(photo.doctorLabels ?? photo.aiPrediction).map((ai) => aiToSp(ai, true))} 
                labelType={photo.doctorLabels ? "Doctor" : "AI"}
                enableSelect 
                useOriginalAspectRatio
            />
        ), 
    [aiToSp])

    return (
        <>
            {controller.showAnnotation && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StartStopLabellingButton 
                                show={controller.expandAnnotation} 
                                startLabellingHandler={controller.startLabellingHandler}
                                stopLabellingHandler={controller.stopLabellingHandler}
                                disabled={controller.annotationProcess === ProcessState.running}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={controller.annotationProcess} errorMessage={controller.annotationError} loadingMessage="Saving labels..."/>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={controller.expandAnnotation}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <AddLabelsDropdown specialty={controller.specialty} selectedMedia={selectedMedia as ImageAnnotationSelectedDict} labelHandler={controller.labelImagesHandler} unselectAllHandler={unselectAllHandler}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ConfirmAIPredictionButton selectedMedia={selectedMedia as ImageAnnotationSelectedDict} confirmHandler={controller.confirmAIPredictionsHandler} unselectAllHandler={unselectAllHandler}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IgnoreImagesButton selectedMedia={selectedMedia as ImageAnnotationSelectedDict} ignoreHandler={controller.ignoreImagesHandler} unselectAllHandler={unselectAllHandler}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GalleryGrid 
                                            photos={controller.imagesToLabel}
                                            breakpoints={{xs: 6, sm: 4, md: 4, lg: 4}}
                                            galleryRenderer={galleryRenderer}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <ImageToAnnotateModal modalImage={modalImage} setModalImage={setModalImage}/>
                </>
            )}
        </>
    );
}

export default EconsultImageAnnotationView;