import React from "react";
import useTextDrop from "./useTextDrop";

interface TextDropWrapperProps {
  onDropText: (text: string) => void;
  children: React.ReactNode;
}

const TextDropWrapper: React.FC<TextDropWrapperProps> = ({
  onDropText,
  children,
}) => {
  const { isDragging, handleDragOver, handleDragLeave, handleDrop } =
    useTextDrop(onDropText);

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

export default TextDropWrapper;
