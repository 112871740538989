import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import sharedStyles from "../../../sharedStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    gridItem: {
      textAlign: "center",
    },
  }),
);

const LoginLoadingPage = ({ message }: { message: string }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={6} md={4} className={classes.gridItem}>
        <img
          src="/Alethea Logo.png"
          className={classes.imgFluid}
          alt="Alethea Logo"
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h5">{message}</Typography>
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        <CircularProgress variant="indeterminate" />
      </Grid>
    </Grid>
  );
};

export default LoginLoadingPage;
