import React, { useState } from 'react';
import { ProcessState } from '@alethea-medical/alethea-components';
import { logAnalyticsEvent } from '../../firebase';
import downloadBlob from '../../models/downloadBlob';
import { useProcessState } from "@alethea-medical/alethea-components";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pdf: {
            width: '210mm',//A4 paper
        }  
    }),
);

interface usePDFCreatorProps {
    pdfKey: string,//Must be unique among all pdf creators. Used as base ID
    pages: React.ReactChild[],
    hide?: boolean,
    analyticsLog?: string,
    download?: boolean,
    onclone?: (doc: Document) => void
}

const usePDFCreator = ({ pdfKey, pages, hide, analyticsLog, download, onclone }: usePDFCreatorProps) => {
    const classes = useStyles();

    const [ blob, setBlob ] = useState<Blob>()
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const savePDF = () => {
        if(analyticsLog)
            logAnalyticsEvent(`${analyticsLog}_start`);
        
        setProcessState(ProcessState.running);
        return createPDF()
        .then((pdf) => {
            const newBlob = pdf.output('blob');
            setBlob(newBlob);
            
            if(download)
                downloadBlob(newBlob, "billing_report.pdf");
            
            if(analyticsLog)
                logAnalyticsEvent(`${analyticsLog}_success`);
                
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error exporting PDF. Please try again.",
                analyticsLog: analyticsLog ? `${analyticsLog}_failed` : undefined
            })
        })
    }


    const createPDF = (): Promise<jsPDF> => {
        return Promise.all(pages.map((_, index) => {
            const input = document.getElementById(`${pdfKey}_${index}`);
            if (input !== null) {
                return html2canvas(input,  {
                    scale: 1.0,
                    useCORS: true,
                    onclone: (doc) => {
                        if(onclone)
                            onclone(doc)
                        
                        //Set visibility back to visible if hidden
                        const inputClone = doc.getElementById(`${pdfKey}_${index}`);
                        if(inputClone !== null)
                            inputClone.style.display = "initial";
                    }
                })
            }
        }))
        .then((canvases) => {                   
            const pdf = new jsPDF({unit: "mm"});
            canvases.forEach((canvas, index) => {
                if(canvas !== undefined) {
                    if(index !== 0)
                        pdf.addPage();
                    const imgData = canvas.toDataURL('image/jpeg', 0.8);
                    
                    //Set width to 210mm to fit page
                    pdf.addImage(imgData, 'JPEG', 0, 0, 210, 0);
                }
            })
            return pdf;
        })
    }

    const createPDFContainer = () => {
        return (
            <>
                {pages.map((page, index) => 
                    /* A4 size paper */
                    <div key={`${pdfKey}_${index}`} id={`${pdfKey}_${index}`} className={classes.pdf} style={{ 
                        display: hide ? "none" : undefined
                    }}>
                        {page}
                    </div>
                )}
            </>

        )
    }

    return {
        blob: blob,
        savePDF: savePDF,
        createPDFContainer: createPDFContainer,
        pdfProcessState: processState,
        pdfErrorMessage: processErrorMessage,
    };
}

export default usePDFCreator;