import Grid from '@material-ui/core/Grid';
import VacationDatePicker from './VacationDatePicker';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import firebase from 'firebase';
import { SpecialistAvailability } from '../../../../../../shared/types';
import Paper from '@material-ui/core/Paper';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
        },
    })
)


interface VacationRowProps {
    index: number,
    vacationPeriod: SpecialistAvailability.VacationPeriod,
    removeRowHandler: (index: number) => void,
    updateRowHandler: (index: number, newValue: SpecialistAvailability.VacationPeriod) => void,
    hasPhoneConsultPermission: boolean,
}

const VacationRow = ({ index, vacationPeriod, removeRowHandler, updateRowHandler, hasPhoneConsultPermission }: VacationRowProps) => {
    const classes = useStyles();


    const onStartChange = (newStart: MaterialUiPickersDate) => {
        if(!newStart)
            return;

        newStart.set("hours", 0)
        newStart.set("minutes", 0)
        newStart.set("seconds", 0)
        newStart.set("milliseconds", 0)

        if(newStart.valueOf() > vacationPeriod.end.toMillis()) {
            // If start ends after end, then set end to start
            const newEnd = newStart.clone()
            newEnd.set("hours", 23)
            newEnd.set("minutes", 59)
            newEnd.set("seconds", 59)
            newEnd.set("milliseconds", 999)

            updateRowHandler(index, {
                ...vacationPeriod,
                start: firebase.firestore.Timestamp.fromMillis(newStart.valueOf()),
                end: firebase.firestore.Timestamp.fromMillis(newEnd.valueOf())
            })
        }
        else {
            updateRowHandler(index, {
                ...vacationPeriod,
                start: firebase.firestore.Timestamp.fromMillis(newStart.valueOf())
            })
        }

    }

    const onEndChange = (newEnd: MaterialUiPickersDate) => {
        if(!newEnd)
            return;
            
        // Set to end of day
        newEnd.set("hours", 23)
        newEnd.set("minutes", 59)
        newEnd.set("seconds", 59)
        newEnd.set("milliseconds", 999)

        updateRowHandler(index, {
            ...vacationPeriod,
            end: firebase.firestore.Timestamp.fromMillis(newEnd.valueOf())
        })
    }

    const onDescriptionChanged = (e: any) => {
        updateRowHandler(index, {
            ...vacationPeriod,
            description: e.target.value
        })
    }

    const onDisableEconsultChecked = (_: any, checked: boolean) => {
        updateRowHandler(index, {
            ...vacationPeriod,
            disableEconsult: !checked // Invert value, since checked === true means enable econsult
        })
    }

    const onDisablePhoneConsultChecked = (_: any, checked: boolean) => {
        updateRowHandler(index, {
            ...vacationPeriod,
            disablePhoneConsult: !checked // Invert value, since checked === true means enable phone consult
        })
    }

    const onDelete = () => {
        removeRowHandler(index)
    }


    return (
        <Paper className={classes.paper} elevation={3}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs={true}>
                    <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
         
                        <Grid item xs={12} md={6}>
                            <VacationDatePicker value={vacationPeriod.start.toDate()} label={"From"} dateChangeHandler={onStartChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VacationDatePicker value={vacationPeriod.end.toDate()} label={"To"} dateChangeHandler={onEndChange}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                label="Name (Optional)"
                                value={vacationPeriod.description}
                                onChange={onDescriptionChanged}
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                        {hasPhoneConsultPermission && 
                            // Don't show either option if don't have permissions for phone consults (since it only makes sense when you can see both)
                            // By default the values are both set to true
                            <>
                                <Grid item>
                                    <FormControlLabel
                                        label={"Available for eConsults"}
                                        checked={!vacationPeriod.disableEconsult}// Invert value, since it makes more sense for the user the other way
                                        onChange={onDisableEconsultChecked}
                                        control={
                                            <Checkbox 
                                                color="primary"
                                                disableRipple  
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        label={"Available for Phone Consults"}
                                        checked={!vacationPeriod.disablePhoneConsult}// Invert value, since it makes more sense for the user the other way
                                        onChange={onDisablePhoneConsultChecked}
                                        control={
                                            <Checkbox 
                                                color="primary"
                                                disableRipple  
                                            />
                                        }
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton onClick={onDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            </Grid>
            {vacationPeriod.start > vacationPeriod.end && <FormHelperText error>{'"From" date must be before "To" date'}</FormHelperText>}
        </Paper>
    );
}

export default VacationRow;