import React from 'react';
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ArchiveIcon from '@material-ui/icons/Archive';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import AttachmentIcon from '@material-ui/icons/Attachment';

import SendIcon from '@material-ui/icons/Send';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { PatientMessageFolder } from '../../../../shared/types';
import Grid from '@material-ui/core/Grid';
import { useMobileSizes } from '@alethea-medical/alethea-components';
import ToggleButton from '../../../components/ToggleButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            marginRight: theme.spacing(1)
        }
    }),
);

interface PatientMessageToolbarProps {
    folder: PatientMessageFolder,
    atLeastOneSelected: boolean,
    markAsUnread: () => void,
    moveFolder: (folder: PatientMessageFolder) => void,
    showAttachments?: boolean,
    onShowAttachments?: () => void
}

const PatientMessageToolbar = ({ folder, atLeastOneSelected, markAsUnread, moveFolder, showAttachments, onShowAttachments }: PatientMessageToolbarProps) => {
    const classes = useStyles();
    const isMobileSize = useMobileSizes();

    return (
        <>
            <Collapse in={atLeastOneSelected} unmountOnExit>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <ButtonGroup>
                            <Tooltip
                                title="Mark as unread"
                                children={
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={markAsUnread}
                                        disabled={!atLeastOneSelected}
                                    >
                                        <MarkunreadMailboxIcon className={clsx({[classes.iconButton]: !isMobileSize})}/>
                                        {!isMobileSize && "Unread"}
                                    </Button>      
                                }
                            />      
                        </ButtonGroup>     
                    </Grid>
                    <Grid item>
                        <ButtonGroup>  
                            <Tooltip
                                title={"Move to Inbox"}
                                children={
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {moveFolder("inbox")}}
                                        disabled={folder === "inbox" || !atLeastOneSelected}
                                    >
                                        {<MoveToInboxIcon className={clsx({[classes.iconButton]: !isMobileSize})}/>}
                                        {!isMobileSize && "Inbox"}
                                    </Button>      
                                }
                            />   
                            <Tooltip
                                title={"Move to Sent"}
                                children={
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {moveFolder("sent")}}
                                        disabled={folder === "sent" || !atLeastOneSelected}
                                    >
                                        {<SendIcon className={clsx({[classes.iconButton]: !isMobileSize})}/>}
                                        {!isMobileSize && "Sent"}
                                    </Button>      
                                }
                            />   
                            <Tooltip
                                title={"Move to Archive"}
                                children={
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {moveFolder("archive")}}
                                        disabled={folder === "archive" || !atLeastOneSelected}
                                    >
                                        {<ArchiveIcon className={clsx({[classes.iconButton]: !isMobileSize})}/>}
                                        {!isMobileSize && "Archive"}
                                    </Button>      
                                }
                            />
                        </ButtonGroup>     
                    </Grid>
                    {onShowAttachments && showAttachments !== undefined && isMobileSize && (
                        <Grid item>
                            <ToggleButton
                                toggleState={showAttachments}
                                onClick={onShowAttachments}
                            >
                                <AttachmentIcon className={classes.iconButton}/>
                            </ToggleButton>
                        </Grid>
                    )}
                </Grid>
            </Collapse>
        </>
    );
}

export default PatientMessageToolbar;