import React from "react";
import { createStyles, makeStyles, Typography, Theme, Paper, Grid, Divider } from "@material-ui/core";
import moment from "moment";
import { formatDollars } from "../billingFunctions";
import palette from "../../../../palette";



type sectionProps = {
	name: string;
	label: string;
	communications: number,
	econsults: number,
	commEarnings: number,
	econsultsEarnings: number;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({

		total: {
			color: palette.orange,
			textAlign: 'center'
		},
		monthHeader: {
			color: palette.darkGreen,
			textAlign: 'center'
		},
		subtitle: {
			padding: theme.spacing(1)
		},

	})
);

const TotalPanel = (props: sectionProps) => {
	const classes = useStyles();


	const renderTitle = (inputName: string) => {

		const currMonthName = moment().format('MMMM');
		const prevMonthName = moment().subtract(1, "month").format('MMMM');
		if (inputName == 'current') {

			return <Typography variant="h5" className={classes.monthHeader}>{currMonthName}</Typography>
		}
		if (inputName == 'past') {
			return <Typography variant="h5" className={classes.monthHeader}>{prevMonthName}</Typography>
		}
		if (inputName == 'ytd') {
			return <Typography variant="h5" className={classes.monthHeader}>YTD</Typography>
		}
		return null

	}

	return (

		<Paper variant="outlined">

			<Grid container justifyContent="space-evenly">

				<Grid item xs={12}>
					{renderTitle(props.name)}

				</Grid>
				<Grid item xs={12} >
					<Divider />
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h4" className={classes.total}>
						Total <br /> ${formatDollars(props.econsultsEarnings + props.commEarnings)}
					</Typography>

				</Grid>
				<Grid item xs={8}>
					<Typography variant="subtitle1" color="primary">
						{props.econsults} <b>Consults</b> earning ${formatDollars(props.econsultsEarnings)}  <br />
					</Typography>
					<Typography variant="subtitle1" color="primary">
						{props.communications} <b>Comunications</b> earning ${formatDollars(props.commEarnings)}<br />
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="subtitle2" color="primary" className={classes.subtitle}>
						{props.label}
					</Typography>
				</Grid>
			</Grid>

		</Paper >

	);


}
export default TotalPanel;

