import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { UserMetadata, UserMetadataLoginData } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../firebase";
import versionStrings from "../versionStrings";
import firebase from "firebase";
import isIOS from "./isIOS";
import isAndroid from "./isAndroid";

function updateUserMetaData(uid: string): Promise<void> {
    const loginData: UserMetadataLoginData = {
        version: versionStrings.version,
        lastLogin: firebase.firestore.Timestamp.now()
    }

    const updateObj: Partial<UserMetadata> = {}
    if(isAndroid()) {
        updateObj.android = loginData;
    }
    else if(isIOS()) {
        updateObj.ios = loginData;
    }
    else {
        updateObj.web = loginData;
    }

    //Update or create if doesn't exist
    return fbFirestore.collection(dbNames.userMetadata).doc(uid).set(updateObj, { merge: true })
}

export default updateUserMetaData;