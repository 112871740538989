import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import ColumnViewContainer from '../ColumnViewContainer';
import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as QueryString from "query-string"
import { withRouter } from 'react-router-dom';
import FormNewPassword from '../FormInputFields/FormNewPassword';
import {fbAuth, logAnalyticsEvent} from '../../firebase';



const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {
        form: {
            width: "100%"
        },
        header: {
            textAlign: 'center',
            marginBottom: theme.spacing( 1 )
        },
        submit: {
            marginTop: theme.spacing( 2 )
        },
        typography: {
            textAlign: "center"
        },
        status: {
            marginTop: theme.spacing( 2 )
        }
    } ),
);



const ResetPassword = withRouter( ( { location } ) =>
{
    const classes = useStyles();
    const history = useHistory();

    const { handleSubmit, control, trigger } = useForm( { mode: "onTouched" } );

    const [ oobCode, setOobCode ] = useState<string>( "" );
    const [ validated, setValidated ] = useState<boolean>();

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState( { logAnalyticsEvent } );


    useEffect( () =>
    {
        const params = QueryString.parse( location.search );
        if ( params.oobCode !== undefined )
        {
            setOobCode( params.oobCode );
            fbAuth.verifyPasswordResetCode( params.oobCode )
                .then( () =>
                {
                    setValidated( true );
                } )
                .catch( ( error: Error ) =>
                {
                    console.error( error );
                    setValidated( false );
                } )
        }

    }, [ location ] );

    const onSubmit = ( data: any ) =>
    {
        setProcessState( ProcessState.running );
        logAnalyticsEvent( "reset_password_start" );
        fbAuth.confirmPasswordReset( oobCode, data.password )
            .then( () =>
            {
                logAnalyticsEvent( "reset_password_success" );
                setProcessState( ProcessState.success );
            } )
            .catch( ( error: Error ) =>
            {
                errorHandler( {
                    error: error,
                    userMessage: "An error occurred while resetting your password",
                    analyticsLog: "reset_password_failed"
                } );
            } )
    }


    const isDisabled = () =>
    {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <ColumnViewContainer>
            { validated === true && (
                <>
                    <Typography component="h1" variant="h5" className={ classes.header }>
                        Reset Password
                    </Typography>
                    <Typography variant="subtitle1" className={ classes.typography }>
                        Enter your new password below.
                    </Typography>
                    <form className={ classes.form } onSubmit={ handleSubmit( onSubmit ) }>
                        <FormNewPassword control={ control } trigger={ trigger } />
                        <Grid item xs={ 12 }>
                            <Button
                                className={ classes.submit }
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                disabled={ isDisabled() }
                            >
                                Reset Password
                            </Button>
                        </Grid>
                        <Grid item xs={ 12 } className={ classes.status }>
                            <ProcessStatus state={ processState } errorMessage={ processErrorMessage } successMessage={ "Password changed" } />
                        </Grid>
                    </form>
                    { processState === ProcessState.success && (
                        <>
                            <Button
                                className={ classes.submit }
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={ () => history.push( "/auth/login" ) }
                            >
                                Continue to Login
                            </Button>
                        </>
                    ) }
                </>
            ) }
            { validated === false && (
                <>
                    <Typography variant="subtitle1" className={ classes.typography }>
                        The link to reset your password is invalid or has expired.
                    </Typography>
                </>
            ) }
        </ColumnViewContainer>
    );
} )

export default ResetPassword;