import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { withRouter, useHistory } from "react-router-dom";
import ForgotPassword from "../../../components/ForgotPassword";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { AuthContext } from "../../../AuthProvider";
import sharedStyles from "../../../sharedStyles";
import LoginLoadingPage from "./LoginLoadingPage";
import { defaultRoles, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import versionStrings from "../../../versionStrings";
import { projectEnv } from "../../../firebase";
import palette from "../../../palette";
import routes, {
  getDefaultHomepageRoute,
  isValidHomepagePreferenceForUser,
  validHomepageRoutes,
  validHomepageRoutesResourceKeys,
} from "../../../config/routes";
import { useMultiPermissions } from "../../../components/usePermissions";
import { multiPermissionsSatisfies } from "src/components/usePermissions/useMultiPermissions";
import { UserProfile } from "@alethea-medical/aletheamd-types";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { fbFirestore } from "../../../firebase";
import isNativeMobile from "src/models/isNativeMobile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing(5),
      overflow: "hidden",
    },
    signupText: {
      marginTop: theme.spacing(3),
    },
    gridItem: {
      textAlign: "center",
    },
    forgotPassword: {
      marginTop: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    versionString: {
      marginTop: theme.spacing(2),
      color: palette.darkGreen,
      fontSize: "0.75em",
    },
  }),
);

const Login = withRouter(({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profileToBeLoggedIn, setProfileToBeLoggedIn] =
    useState<UserProfile | null>();

  const handleLoginSavedUser = () => {
    if (!authContext.savedUser || !authContext.loginSavedUser) return;

    authContext.loginSavedUser(authContext.savedUser);
  };

  const handleLogoutSavedUser = () => {
    if (!authContext.logoutSavedUser) return;

    authContext.logoutSavedUser();

    // Clear profile
    setProfileToBeLoggedIn(null);

    // Clear the form inputs as well
    setEmail("");
    setPassword("");
  };

  const handleSubmit = () => {
    authContext.signIn(email, password).catch((error: any) => {
      console.log(error.message);
      alert(error.message);
    });
  };

  // Go to consult first, then moaConsultInbox, then camera and gallery
  const { permissionDict } = useMultiPermissions({
    resourceKeys: validHomepageRoutesResourceKeys,
  });

  const redirectToPage = (route: string) => {
    console.log(`Redirect to page: ${route}`);
    history.push(route);
  };

  const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (authContext.savedUser) {
      // User ready to be logged in
      fbFirestore
        .collection(dbNames.users)
        .doc(authContext.savedUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const profile = doc.data() as UserProfile;
            setProfileToBeLoggedIn(profile);
          }
        });
    }
  }, [authContext.savedUser]);

  useEffect(() => {
    if (authContext.authenticated && authContext.profile) {
      const state = location.state as any;
      if (state?.prevPath?.location?.pathname) {
        const targetPage = `${state.prevPath.location.pathname}${state.prevPath.location.search}`;
        redirectToPage(targetPage);
      } else if (
        authContext.profile.userRoles.includes(
          defaultRoles.pracIdValidationRequired,
        ) &&
        multiPermissionsSatisfies(
          permissionDict,
          routes.validationRequired.requiredResourcePermissions,
        )
      ) {
        redirectToPage(routes.validationRequired.path);
      } else if (Object.keys(permissionDict).length > 0) {
        // Start by checking if the user has a homepage preference, else default to previous method
        const homepageSetting = authContext.profile.preferences?.homepage;
        // Default to state prev path first, can 't use this callback
        if (
          homepageSetting &&
          isValidHomepagePreferenceForUser(permissionDict, homepageSetting)
        ) {
          redirectToPage(validHomepageRoutes[homepageSetting].path);
        } else {
          redirectToPage(
            getDefaultHomepageRoute(
              permissionDict,
              authContext.profile.userRoles,
            ).path,
          );
        }
      }
    }
  }, [authContext.profile, authContext.authenticated, permissionDict]);

  if (
    authContext.savedUser === undefined ||
    (authContext.savedUser && !profileToBeLoggedIn)
  ) {
    // First case: Not sure if there is a saved user (it'll either be set, or null) OR there is a saved user but their profile isn't loaded in yet...
    return (
      <div className={classes.root}>
        <LoginLoadingPage message="Loading..." />
      </div>
    );
  } else if (authContext.authenticated && authContext.profile) {
    // Profile is logged in, will redirect (don't show the login input page again)
    return (
      <div className={classes.root}>
        <LoginLoadingPage message="Logging in..." />
      </div>
    );
  }

  // There is no saved user (eg. show login page) -OR- there is a saved user & their profile is loaded in so show the "Continue..." screen
  return (
    <div className={classes.root}>
      {authContext.loading && <LoginLoadingPage message="Loading..." />}
      {!authContext.loading && (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={6} md={4} className={classes.gridItem}>
            <img
              src="/Alethea Logo.png"
              className={classes.imgFluid}
              alt="Alethea Logo"
            />
          </Grid>
          <Grid item xs={12} className={clsx(classes.gridItem, classes.header)}>
            <Typography variant="h5">P O R T A L</Typography>
          </Grid>
          {!isNativeMobile() && authContext.savedUser && profileToBeLoggedIn ? (
            <>
              <Grid
                item
                xs={12}
                className={classes.gridItem}
                style={{ marginBottom: "10px" }}
              >
                <Grid>
                  <Typography variant="h6">Last signed in as </Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontSize: "1.3em" }}>
                    {profileToBeLoggedIn.firstName}{" "}
                    {profileToBeLoggedIn.lastName}
                  </Typography>
                </Grid>

                <Grid>
                  <Typography variant="subtitle1" style={{ marginTop: "-5px" }}>
                    {profileToBeLoggedIn.credentials}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.gridItem}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoginSavedUser}
                >
                  Continue
                </Button>
              </Grid>

              <Grid item xs={12} className={classes.gridItem}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogoutSavedUser}
                >
                  Logout
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      value={email}
                      type="email"
                      placeholder="Enter your Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onKeyDown={keydownHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      value={password}
                      placeholder="Enter your Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onKeyDown={keydownHandler}
                      type="password"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.gridItem}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            className={`${classes.gridItem} ${classes.forgotPassword}`}
          >
            <ForgotPassword />
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.gridItem} ${classes.signupText}`}
          >
            <Typography variant="subtitle1">Don't have an account?</Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <a
              className={classes.unstyledLink}
              href="https://aletheamedical.com/sign-up"
              rel="noreferrer noopener"
            >
              <Button variant="contained" color="primary">
                Sign Up
              </Button>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(classes.versionString, classes.gridItem)}
            >
              Version {versionStrings.version}
              {projectEnv !== "prod"
                ? `, Build ${versionStrings.devBuild}`
                : ""}
            </Typography>
          </Grid>
          {projectEnv !== "prod" && (
            <Grid item xs={12}>
              <Typography
                className={clsx(classes.versionString, classes.gridItem)}
              >
                Environment: {projectEnv.toUpperCase()}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
});

export default Login;
