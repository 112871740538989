import { PhoneConsult, SpecialistAvailability } from "@alethea-medical/aletheamd-types";
import moment from "moment";
import { fbFirestore } from "../../../../firebase";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";


export const daysOfTheWeek = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
]

export const initialSchedule = daysOfTheWeek.map(() => {
    return {
        timeslots: [],
        timezone: "America/Edmonton"
    }
})

/**
 * Convert a moment date object into a timeslot string (ex 1300)
 * @param date Date to convert
 * @returns timeslot string
 */
function dateToTimeslot(date: moment.Moment): string {
    return `${date.hours().toString().padStart(2, '0')}${date.minutes().toString().padStart(2, '0')}`
}

/**
 * Convert timeslot to a date. Uses current day and sets hours and minutes to the timeslot value
 * @param timeslot Timeslot to convert
 * @returns moment date object
 */
function timeslotToDate(timeslot: string): moment.Moment {
    const hours = parseInt(timeslot.substring(0, 2))
    const minutes = parseInt(timeslot.substring(2, 4))
    const date = moment()
    date.hours(hours)
    date.minutes(minutes)
    date.second(0)
    date.milliseconds(0)
    return date
}

/**
 * Generate list of timeslots for displaying in block dropdown
 * @param timeslotDuration Duration of timeslot
 * @returns 
 */
 function generateTimeslotOptions(timeslotDuration: number): string[] {
    const date = moment()
    date.hours(0)
    date.minutes(0)
    date.second(0)
    date.milliseconds(0)

    const times: string[] = []

    const nextDay = date.clone().add(1, "day")
    
    // This will generate values from 0000 to 2350
    while(date.valueOf() < nextDay.valueOf()) {
        times.push(dateToTimeslot(date))
        date.add(timeslotDuration, "minutes")
    }

    return times
}
export const defaultTimeslotDuration = 10
export const defaultTimeOptions = generateTimeslotOptions(defaultTimeslotDuration)


export function fetchPhoneConsultSchedule(uid: string): Promise<PhoneConsult.Schedule | undefined> {
    return fbFirestore.collection(dbNames.specialistAvailabilitySettings).doc(uid).get().then((snapshot) => {
        if(!snapshot.exists)
            return;
        
        const data = snapshot.data() as SpecialistAvailability.AvailabilitySettings;
            
        if(data?.phoneConsultSchedule === undefined)
            return;

        return data.phoneConsultSchedule
    })
}

/**
 * Convert blocks into schedule and save to the database
 * @param uid User's UID
 * @param oldSchedule Old schedule to retrieve timezones and days from
 * @param newBlocks New blocks to convert into a schedule
 * @returns The new schedule after saving
 */
export function savePhoneConsultSchedule(uid: string, oldSchedule: PhoneConsult.ScheduleDay[], newBlocks: PhoneConsult.Timeslot[][]): Promise<PhoneConsult.ScheduleDay[]> {
    const newSchedule = oldSchedule.map((scheduleDay, index) => {
        return { 
            timeslots: convertBlocksToTimeslots(newBlocks[index], defaultTimeslotDuration),
            timezone: scheduleDay.timezone
        }
    })
    
    // Save to database
    const newScheduleObj: SpecialistAvailability.AvailabilitySettings = { phoneConsultSchedule: { schedule: newSchedule }}
    return fbFirestore.collection(dbNames.specialistAvailabilitySettings).doc(uid).set(newScheduleObj, { merge: true })
    .then(() => newSchedule)
}

/**
 * Convert timeslots into N blocks that have no discontinuities
 * @param timeslots 
 */
export function convertTimeslotsToBlocks(timeslots: PhoneConsult.Timeslot[]): PhoneConsult.Timeslot[] {
    const blocks: PhoneConsult.Timeslot[] = []

    // Fill in the initial block with the first timeslot 
    if(timeslots.length > 0) {
        blocks.push({
            start: timeslots[0].start,
            end: timeslots[0].end // Initialize end with first timeslot end value
        })
    }

    //Assume timeslots are ordered are properly formatted, and don't overlap
    for(let i = 1; i < timeslots.length; i++) {
        if(parseInt(timeslots[i].start) !== parseInt(timeslots[i-1].end)) {
            blocks[blocks.length - 1].end = timeslots[i-1].end
            blocks.push({
                start: timeslots[i].start,
                end: timeslots[i].end// Initialize end with timeslot end value
            })
        }
    }

    // Add the final end timeslot to complete the last block
    if(blocks.length > 0) {
        blocks[blocks.length - 1].end = timeslots[timeslots.length - 1].end
    }

    return blocks;
}

/**
 * Converts blocks of timeslots into individual timeslots to be saved to the specialist's schedule object in firestore
 * @param blocks Blocks to convert
 * @param timeslotDuration Duration of each timeslot to be output
 * @returns List of Timeslots
 */
export function convertBlocksToTimeslots(blocks: PhoneConsult.Timeslot[], timeslotDuration: number): PhoneConsult.Timeslot[] {
    const timeslots: PhoneConsult.Timeslot[] = []

    // Go through each block. Each block is a continuous group of timeslots
    blocks.forEach((block) => {
        // Get start and end as a date object so that we can add time properly
        const startDate = timeslotToDate(block.start)
        const endDate = timeslotToDate(block.end)

        const currentTime = moment(startDate)

        // While current time is less than the end of the block
        while(currentTime.valueOf() < endDate.valueOf()) {
            // Get timeslot start for current time
            const startTime = dateToTimeslot(currentTime)

            // Add timeslot duration
            currentTime.add(timeslotDuration, "minutes")

            // Get timeslot end. The next timeslot's start time will also be equal to this value since currentTime doesn't change until after
            const endTime = dateToTimeslot(currentTime)

            const newTimeslot = {
                start: startTime,
                end: endTime
            }
            
            /* 
                Don't allow two timeslots that are equal to be created
                This can happen when a user drags a block onto two side-by-side blocks.
                This case is hard to handle, and is rare
                This code ensures that there will never be any duplicate time slots, even if there are other unknown bugs that could cause it
            */
            if(!timeslots.some((timeslot) => timeslot.start === newTimeslot.start && timeslot.end === newTimeslot.end)) {
                // Create timeslot
                timeslots.push(newTimeslot)
            }
        }
    })

    return timeslots;
}

/**
 * Sorts timeslots/blocks by start time from earliest to latest in-place
 * @param timeslots timeslots/blocks to sort
 */
export function sortTimeslots (timeslots: PhoneConsult.Timeslot[]) {
    timeslots.sort((a, b) => {
        if(a.start < b.start) return -1
        else if(a.start > b.start) return 1
        else return 0
    })
}

/**
 * Check if time occurs in a block
 * @param time Time to check
 * @param blocks Block to check if time is inside of
 * @returns true if time is in block
 */
export function isTimeInBlock(time: string, blocks: PhoneConsult.Timeslot[]): boolean {
    return blocks.some((b) => b.start <= time && b.end > time)
}

/**
 * Formats timeslot (ex: 1340) as a readable string (1:30 PM)
 * @param timeslot Timeslot string to format
 * @returns Formatted string
 */
export function formatTimeslot(timeslot: string): string {
    let hours = parseInt(timeslot.substring(0, 2))
    const minutes = parseInt(timeslot.substring(2, 4))
    const suffix = hours >=12 ? "PM" : "AM"
    hours = ((hours + 11) % 12 + 1);


    return `${hours}:${minutes.toString().padStart(2, "0")} ${suffix}`
}