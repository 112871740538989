import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperModal } from "@alethea-medical/alethea-components";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import { DropdownMenuItem } from '../../../components/DropdownMenuButton/DropdownMenuButton';

import { useState } from 'react';
import AveiroInvoicePage from './AveiroInvoicePage';
import { Activity } from '../../../../shared/types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
        },  
    })
)

interface AveiroInvoiceButtonProps {
    activityId: string,
    econsult: Activity.Econsult,
    variant: "button" | "dropdown",
}

const AveiroInvoiceButton = ({ activityId, econsult, variant }: AveiroInvoiceButtonProps) => {
    const classes = useStyles();

    const [show, setShow] = useState(false)

    const openModalHandler = () => {
        setShow(true)
    }

    return (
        <>
            {variant === "button" &&
                <Tooltip
                    title="Create Invoice"
                    children={
                        <Button 
                            onClick={openModalHandler}
                            color="primary"
                            variant="outlined"
                            startIcon={<DescriptionIcon />}
                        >
                            Create Invoice
                        </Button>
                    }
                />
            }
            {variant === "dropdown" &&
                <DropdownMenuItem label="Create Invoice" icon={<DescriptionIcon color="primary" />} onClick={openModalHandler} color='primary' />            
            }
            <PaperModal className={classes.modal} show={show} setShow={setShow} enablePadding flexHeight flexWidth>
                {show && 
                    <AveiroInvoicePage activityId={activityId} econsult={econsult}/>
                }
            </PaperModal>
        </>

    );
}

export default AveiroInvoiceButton;