import palette from "../../palette";

const sharedStyles = (theme: any) => {

    return {
      canDisable: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: palette.disabledTextColor,
            backgroundColor: palette.disabledBGColor
        }
      }
    };
};

export default sharedStyles;