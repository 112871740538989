import { Payment } from "../../../../../shared/types";
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from "react";
import { fbFunctions } from "../../../../firebase";
import parseJSONTimestamp from "../../../../models/parseJSONTimestamp";
import EconsultCard from "./EconsultCard";
import Collapse from '@material-ui/core/Collapse';
import EconsultCardExpandHeader from "./EconsultCardExpandHeader";
import ActivityPayment from "./ActivityPayment";
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/RefreshOutlined'
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import Typography from '@material-ui/core/Typography';


interface ActivityPaymentListProps {
    activityId: string,
}

export interface GetPaymentsResponse { payment: Payment.Payment, paymentId: string, recordedBy: string }


const ActivityPaymentList = ({ activityId }: ActivityPaymentListProps) => {  
    const [expandPayments, setExpandPayments] = useState(true)

    const [prevPayments, setPrevPayments] = useState<GetPaymentsResponse[]>([])

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ });

    useEffect(() => {
        getPayments()
    }, [])
    
    const getPayments = () => {
        setProcessState(ProcessState.running)
        fbFunctions.httpsCallable("secureMessaging-getActivityPayments")({ activityId })
        .then((response) => {
            setProcessState(ProcessState.idle)
            setPrevPayments(response.data.map((p: GetPaymentsResponse) => {
                p.payment.created = parseJSONTimestamp(p.payment.created)
                p.payment.statusUpdatedAt = parseJSONTimestamp(p.payment.statusUpdatedAt)
                return p
            }))
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error fetching payment history",
                hideErrorMessage: true
            })
        })
    }

    return (
        <EconsultCard
            titleComponent={
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <EconsultCardExpandHeader title="Payments" expand={expandPayments} setExpand={setExpandPayments}/>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={getPayments}><RefreshIcon/></IconButton>
                    </Grid>
                </Grid>
            }
        >
            {prevPayments.length > 0 &&
                <Collapse in={expandPayments}>
                    <Grid container spacing={1}>
                        {prevPayments.map((p, i) => (
                            <Grid item xs={12} key={`payments_${p.payment.name}_${i}`}>
                                <ActivityPayment payment={p}/>
                            </Grid>
                        ))}
                    </Grid>   
                </Collapse>
            }
            {(prevPayments.length === 0 && processState === ProcessState.idle) &&    
                <Typography variant="subtitle1">No Payments</Typography>
            }
            <ProcessStatus state={processState} errorMessage={processErrorMessage} loadingMessage="Loading Payments..."/>
        </EconsultCard>
            
    )
}

export default ActivityPaymentList;