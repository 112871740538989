import React, { useContext, useState } from 'react';
import { ProcessState, useProcessState } from '@alethea-medical/alethea-components';
import analyticsLogs from '../../../analyticsLogs';
import { AuthContext } from '../../../AuthProvider';
import { logAnalyticsEvent } from '../../../firebase';
import { deleteMedia, UserMediaMetadataItem } from '../Models/GalleryModel';
import { updateNotes, updateTags } from '../Models/MediaEditModel';

interface MediaEditControllerProps {
    mediaItem: UserMediaMetadataItem,
    closeMediaItem: () => void,
    modifyItemHandler: (id: string, newItem: UserMediaMetadataItem) => void,
    removeMediaItems?: (ids: string[]) => void
}

const MediaEditController = ({ mediaItem, closeMediaItem, modifyItemHandler, removeMediaItems }: MediaEditControllerProps) => {
    const authContext = useContext(AuthContext);
    const [tags, setTags] = useState<string[]>(mediaItem.data.tags ?? []);
    const [notes, setNotes] = useState<string>(mediaItem.data.notes ?? "");

    const { processState: deleteState, setProcessState: setDeleteState, processErrorMessage: deleteError, errorHandler } = useProcessState({ logAnalyticsEvent });
    
    const tagsChangedHandler = (newTags: string[]) => {
        if(authContext.uid !== "") 
        {
            setTags(newTags)
            //Update firestore
            updateTags(authContext.uid, mediaItem.id, newTags)
            .then(() => {
                //Update state
                logAnalyticsEvent(analyticsLogs.gallery.addTags);
                mediaItem.data.tags = newTags;
                modifyItemHandler(mediaItem.id, mediaItem)
            })
        }
    }

    const notesChangedHandler = (newNotes: string, updateFiretore: boolean) => {
        if(authContext.uid !== "") 
        {
            setNotes(newNotes)
            //Update firestore
            //Add option to update firestore. Only update firestore on textfield blur to reduce database writes
            if(updateFiretore) {
                updateNotes(authContext.uid, mediaItem.id, newNotes)
                .then(() => {
                    //Update state
                    logAnalyticsEvent(analyticsLogs.gallery.addNotes);
                    mediaItem.data.notes = newNotes
                    modifyItemHandler(mediaItem.id, mediaItem)                
                })
            }
        }
    }

    const mediaDeleteHandler = () => {
        if(authContext.uid !== "" && removeMediaItems !== undefined) 
        {
            setDeleteState(ProcessState.running)
            //Delete from firestore
            deleteMedia(authContext.uid, [mediaItem])
            .then(() => {
                setDeleteState(ProcessState.idle)
                logAnalyticsEvent(analyticsLogs.gallery.delete);
                //Update state
                removeMediaItems([mediaItem.id])
                closeMediaItem();
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: "Error deleting file.",
                    analyticsLog: analyticsLogs.gallery.deleteFail
                })
            })
        }        
    }

    return {
        // states
        tags,
        notes,
        // handlers
        tagsChangedHandler,
        notesChangedHandler,
        mediaDeleteHandler,
        // process
        deleteState, deleteError
    }
}

export default MediaEditController;