import { fbStorage } from "../../../../../firebase";
import { generateRandomString } from "@alethea-medical/utilities";
import { FileDict } from "../../../../../components/useFileDrop/useFileDrop";
import { UserMediaMetadataSelectedDict } from "../../../../../components/Gallery/Controllers/SelectGalleryController";


export function uploadFilesToEconsult (files: FileDict, selectedMedia: UserMediaMetadataSelectedDict): 
Promise<{uploadURIs: string[], uploadFilenames: string[], successFileUploadKeys: string[], successGalleryUploadKeys: string[]}> {
    
    const uploadURIs: string[] = [];
    const uploadFilenames: string[] = []
    const successFileUploadKeys: string[] = []
    const successGalleryUploadKeys: string[] = []
    
    
    return Promise.all(Object.keys(files).map((key) => {
        //Upload from uploaded files
        const file = files[key];
        return file.file.arrayBuffer()
        .then((fileContents) => {
            return fbStorage.ref(`activityMedia/${generateRandomString(16)}_${file.filename}`).put(fileContents, {contentType: file.file.type})  
        })
        .then((res) => {
            uploadURIs.push(res.ref.fullPath);
            uploadFilenames.push(file.filename);
            successFileUploadKeys.push(key);
        })
        .catch((error: Error) => {
            console.error(error);
        })
    }))
    .then(() => {
        //Upload from gallery
        return Promise.all(Object.keys(selectedMedia).map((key) => {
            const selectedFile = selectedMedia[key];
            const filename = selectedFile.data.filePath.replace(/^.*[\\/]/, '')//Get filename from path
            return fetch(selectedFile.data.fileDownloadUrl ?? "").then(r => r.blob())
            .then((blob) => {
                return fbStorage.ref(`activityMedia/${generateRandomString(16)}_${filename}`).put(blob, {contentType: blob.type})  
            })
            .then((res) => {
                uploadURIs.push(res.ref.fullPath);
                uploadFilenames.push(filename);
                successGalleryUploadKeys.push(key);
            })
            .catch((error: Error) => {
                console.error(error);
            })
        }))       
    })
    .then(() => {
        return {
            uploadURIs,
            uploadFilenames,
            successFileUploadKeys,
            successGalleryUploadKeys
        }
    })
}