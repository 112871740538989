import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react'
import { HeightLayout, HeightLayoutChild, useScreenSize } from "@alethea-medical/alethea-components";
import WebCamera from "./WebCamera";

const Camera =  () =>
{

    const useStyles = makeStyles( ( theme: Theme ) =>
        createStyles({
            container: {
                overflowX: "hidden"
            },
        }),
    );

    const classes = useStyles()
    const { heightMinusAppBar } = useScreenSize({});

    return (
        <HeightLayout
            className={classes.container}
            height={heightMinusAppBar}
        >
            <HeightLayoutChild flexDriven>
                    <WebCamera height={heightMinusAppBar}/>
            </HeightLayoutChild>
        </HeightLayout>
    );


}

export default Camera
