import globalStrings from "../../../../globalStrings";
import { Plan, Role } from "@alethea-medical/aletheamd-types";

const strings = {
    provinceInfo: (selectedPlan: Plan.Plan) => <>Alethea {Plan.getShortDisplayName(selectedPlan)} is only available in select provinces. If your Province is not available, please contact us at {globalStrings.salesEmailAsLink} for more information.</>,
    loginInformationNote: "This email will be used for your login and notifications.",
    pracIdNote: (fieldName: string) => <><b>{fieldName}: </b>We use your {fieldName} to verify you and as the referring practitioner identifier for eConsults sent through Alethea.</>,
    invalidPracIdNote: (fieldName: string) => <>We are unable to validate your {fieldName}, or it may be already registered under another user. Please check that you have entered it correctly.<br/>If you are still unable to validate your {fieldName} please continue through the sign up process by selecting <b>Proceed without {fieldName}</b>.</>,
    proceedWithoutPracIdNote: (fieldName: string) => <><b>Note: </b>If you proceed without a {fieldName}, you will not be able to send eConsults until we have validated your {fieldName}. We will contact you through email to validate your {fieldName}.</>,
    proceedWithoutPracIdNoteNoValidation: (fieldName: string) => <><b>Note: </b>You will not be able to send eConsults until we have validated your {fieldName}. We will contact you through email to validate your {fieldName}.</>,
    systemErrorValidatingPracId: (fieldName: string) => `An error ocurred while validating your ${fieldName}. If this error continues to occur please select 'Proceed without ${fieldName}'.`,
    clinicNote: <><b>Note:</b> If you work out of more than one clinic, you can add multiple locations once you have created your account.</>,
    userAgreementNote: <><b>Note: </b>Signing up with Alethea will require your signature on the Alethea Platform User Agreement as acceptance of the appropriate use of the Alethea platform, as well as on an Information Management Agreement for the appropriate use and management of personal information.</>,
    pickAPlan: <>Alethea offers different pricing plans to ensure you find the best fit for your practice.<br/>Follow the link below to the sign up page to select a plan.</>,
    invalidRoleAndPlan: (roleName: string) => <>We are currently not offering Alethea Plus to {roleName}s.<br/>Please follow the link below to the sign up page to select a valid plan.</>,
    signupSuccess: <>Welcome to Alethea!</>,
    autoLoginFail: <>Click here if you are not logged in automatically</>,
    informationSectionHeader: (role: Role.Role) => `${Role.getShortDisplayName(role)} Information`,
}

export default strings;