import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../types";
import FormCheckbox from "./FormCheckbox";

function TypedFormCheckbox<T extends FieldValues>(
  props: TypedControlledInputProps<T>,
) {
  return (
    <FormCheckbox
      {...props}
      control={props.control as any}
      name={props.name as string}
      onChange={props.onChange}
    />
  );
}

export default TypedFormCheckbox;
