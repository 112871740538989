import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import EditIconOutlined from '@material-ui/icons/EditOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface UseEditButtonsProps {
    unsavedChanges: boolean,
    alwaysEnableEditing?: boolean,
}
const useEditButtons = ({ unsavedChanges, alwaysEnableEditing=false }: UseEditButtonsProps) => {
    
    const [isEditing, setIsEditing] = useState(alwaysEnableEditing);

    const handleEdit = () => {
        setIsEditing(!isEditing)
    }

    return {
        EditButton: 
            <Button onClick={handleEdit} 
                disabled={isEditing && unsavedChanges}
                startIcon={isEditing ? <ArrowBackIcon color="primary"/> : <EditIconOutlined color="primary"/>}
            >
                {isEditing ? "Done" : "Edit"}
            </Button>,
        isEditing
    }
}

export default useEditButtons;