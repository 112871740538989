import { useContext, useRef } from "react";
import {
  config,
  fbFirestore,
  fbStorage,
  logAnalyticsEvent,
} from "../../../firebase";
import { AuthContext } from "../../../AuthProvider";
import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Filesystem } from "@capacitor/filesystem";
import { AletheaMedicalCapacitorFirebase } from "@alethea-medical/capacitor-firebase";
import isIOS from "../../../models/isIOS";
import isNativeMobile from "../../../models/isNativeMobile";
import { UserMediaMetadata } from "../../../../shared/types";
import firebase from "firebase";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";

type FileType = "image" | "video";

export function base64StringToDataURI(
  base64String: string,
  fileType: FileType,
): string {
  switch (fileType) {
    case "image":
      return "data:image/jpeg;base64," + base64String;
    case "video":
      return "data:video/mp4;base64," + base64String;
  }
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

interface UseUploadMediaFileProps {
  /** (Optional) Callback to update the number of uploads remaining */
  updateRemainingUploads?: (uploadsRemaining: number) => void;
  /** (Optional) Callback to show error message if one or more uploads failed. */
  mobileUploadErrorOccured?: (message: string) => void;
}

function useUploadMediaFile(props?: UseUploadMediaFileProps) {
  const {
    updateRemainingUploads = () => {},
    mobileUploadErrorOccured: mobileUploadErrorOccurred = () => {},
  } = { ...props };

  const authContext = useContext(AuthContext);

  const { processState, processErrorMessage, setProcessState, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const uploadRef = useRef(Promise.resolve());
  const pendingUploadsRef = useRef(0);
  const prevFileNameTimestampRef = useRef<number | null>(null);

  const folderNameImage = "images";
  const fileExtensionImage = "jpg";
  const folderNameVideo = "videos";
  const fileExtensionVideo = "mp4";
  const mobileUploadErrorMessage =
    "An error occurred while uploading some of your files.";

  /** Call before starting an upload. Manages upload count and state */
  function preUploadHandler() {
    //Set status to running if its the first upload
    if (pendingUploadsRef.current === 0) {
      setProcessState(ProcessState.running);
    }

    //Update number of remaining uploads
    pendingUploadsRef.current += 1;
    updateRemainingUploads(pendingUploadsRef.current);
  }

  /** Call after finishing an upload (success or fail). Manages upload count and state */
  function postUploadHandler() {
    //Update number of remaining uploads
    pendingUploadsRef.current -= 1;
    updateRemainingUploads(pendingUploadsRef.current);

    //Set status to idle if no uploads left
    if (pendingUploadsRef.current === 0) setProcessState(ProcessState.idle);
  }

  /** Upload a file from a datURI*/
  function uploadFileFromURI(dataURI: string, fileType: FileType) {
    const data = dataURItoBlob(dataURI);
    uploadFileFromBlob(data, fileType);
  }

  /** Upload a file from a blob*/
  function uploadFileFromBlob(data: Blob, fileType: FileType) {
    preUploadHandler();

    uploadRef.current = uploadRef.current
      .then(() => {
        return upload(data, fileType).catch((error) => {
          errorHandler({
            error: error,
            userMessage: "An error occurred while uploading your file.",
            hideErrorMessage: true,
            analyticsLog: `portal_camera_${fileType}_upload_failed`,
          });
        });
      })
      .finally(() => {
        postUploadHandler();
      });
  }

  /** Upload a file from a URL on a mobile device. Reads the file using the capacitor FileSystem plugin. Deletes the file after uploading. */
  function uploadFileFromMobileURL(url: string, fileType: FileType) {
    preUploadHandler();

    uploadRef.current = uploadRef.current
      .then(() => {
        if (isNativeMobile()) {
          return uploadNative(url, fileType).catch((error) => {
            console.error(error);
            logAnalyticsEvent(`portal_camera_${fileType}_upload_failed`);
            mobileUploadErrorOccurred(mobileUploadErrorMessage);
          });
        } else {
          return Filesystem.readFile({ path: url }).then((file) => {
            const data = dataURItoBlob(
              base64StringToDataURI(file.data as string, fileType),
            );
            return upload(data, fileType).catch((error) => {
              console.error(error);
              logAnalyticsEvent(`portal_camera_${fileType}_upload_failed`);
              mobileUploadErrorOccurred(mobileUploadErrorMessage);
            });
          });
        }
      })
      .then(() => {
        return Filesystem.deleteFile({ path: url }).catch((error: Error) => {
          console.error(error);
          logAnalyticsEvent(`portal_camera_${fileType}_file_delete_failed`);
        });
      })
      .catch((error: Error) => {
        console.error(error);
        logAnalyticsEvent(`portal_camera_${fileType}_file_read_failed`);
        mobileUploadErrorOccurred(mobileUploadErrorMessage);
      })
      .finally(() => {
        postUploadHandler();
      });
  }
  function uploadNative(url: string, fileType: FileType) {
    if (authContext.uid === "")
      return Promise.reject(
        new Error("You must be signed in to upload images or videos"),
      );

    logAnalyticsEvent(`portal_camera_${fileType}_upload_start`);

    const storagePath = createStoragePath(fileType);
    //User must be logged in on native layer for this to work
    return AletheaMedicalCapacitorFirebase.uploadFileToStorageFromURL({
      url: url,
      path: `${isIOS() ? `gs://${config.storageBucket}/` : ""}${storagePath}`,
    })
      .then(() => {
        return createMetadata(fileType, storagePath);
      })
      .then(() => {
        logAnalyticsEvent(`portal_camera_${fileType}_upload_success`);
      });
  }

  /** Upload a file to firebase storage. Chooses the appropriate folder and file extension based on file type. Names file with current timestamp */
  function upload(blob: Blob, fileType: FileType) {
    if (authContext.uid === "")
      return Promise.reject(
        new Error("You must be signed in to upload images or videos"),
      );

    logAnalyticsEvent(`portal_camera_${fileType}_upload_start`);

    return fbStorage
      .ref(createStoragePath(fileType))
      .put(blob)
      .then((result) => {
        return createMetadata(fileType, result.ref.fullPath);
      })
      .then(() => {
        logAnalyticsEvent(`portal_camera_${fileType}_upload_success`);
      });
  }

  function createMetadata(fileType: FileType, filePath: string) {
    const metadata: UserMediaMetadata = {
      created: firebase.firestore.Timestamp.now(),
      filePath: filePath,
      fileType: fileType,
      notes: "",
      tags: [],
    };
    return fbFirestore
      .collection(dbNames.userMediaMetadata)
      .doc(authContext.uid)
      .collection(dbNames.userMediaMetadata_media)
      .add(metadata);
  }

  function createStoragePath(fileType: FileType) {
    let timestamp = new Date().getTime();
    // Make sure we aren't uploading files with the same name
    if (prevFileNameTimestampRef.current !== null) {
      //If timestamp is equal to the previous timestamp
      //OR If timestamp is less than the previous timestamp, then set to prev timestamp + 1

      //Example:
      // 1. Upload timestamp 0
      // 2. Upload timestamp 0. Equal to prev, set to prev + 1 = 1. Set prev to 1
      // 3. Upload timestamp 0. Less than prev, set to prev + 1 = 2. Set prev to 2
      // 4. Upload timestamp 1. Less than prev, set to prev + 1 = 3. Set prev to 3
      if (
        timestamp === prevFileNameTimestampRef.current ||
        timestamp < prevFileNameTimestampRef.current
      ) {
        timestamp = prevFileNameTimestampRef.current + 1;
      }
    }

    prevFileNameTimestampRef.current = timestamp;

    const extension =
      fileType === "image" ? fileExtensionImage : fileExtensionVideo;
    const folder = fileType === "image" ? folderNameImage : folderNameVideo;
    return `${folder}/${authContext.uid}/${timestamp}.${extension}`;
  }

  return {
    uploadFileFromURI,
    uploadFileFromBlob,
    uploadFileFromBlobSynchronous: upload,
    uploadFileFromMobileURL,
    pendingUploadsRef,
    processState,
    setProcessState,
    processErrorMessage,
    errorHandler,
  };
}

export default useUploadMediaFile;
