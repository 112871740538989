import { MutableRefObject, useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import { PhoneConsult } from "../../../../../../shared/types"
import ScheduleBlockPaper from "./ScheduleBlockPaper"
import Grid from '@material-ui/core/Grid';
import TimeslotRangeDisplay from "./TimeslotRangeDisplay";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { timeRowHeight } from "./TimeRow";
import BlockDragController from "../../Controllers/BlockDragController";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        onTop: {
            zIndex: 100
        },
        deleteButton: {
            padding: 0,
        },
        deleteIconLarge: {
            height: "20px"
        },
        deleteIconSmall: {
            height: "15px",
        },
        handleContainer: {
            position: "relative",
            width: "100%",
            height: "100%"
        },
        blockDragContainer: {
            width: "100%",
            height: "100%",
            cursor: "pointer"
        },
        blockDragContainerDragging: {
            cursor: "all-scroll"
        },
        blockContainerPaddingLarge: {
            padding: theme.spacing(1)
        },
        blockContainerPaddingSmall: {
            padding: theme.spacing(0, 0.5)
        },
        blockHandle: {
            position: "absolute",
            backgroundColor: "white",
            height: `${Math.floor(timeRowHeight*0.25)}px`,
            width: "80px",
            left: `calc(50% - 40px)`,//Should be 50% - width/2
            cursor: "ns-resize",
            borderRadius: "5px"
        },
        blockHandleTop: {
            top: 2
        },
        blockHandleBottom: {
            bottom: 2
        },
        hideHandle: {
            display: "none"
        },
        whiteText: {
            color: "white"
        },
        textLarge: {
            fontSize: "0.9em"
        },
        textSmall: {
            fontSize: "0.7em"
        }
    })
)

interface ScheduleBlockProps {
    block: PhoneConsult.Timeslot,
    blockIndex: number,
    times: string[],
    dragContainerRef: MutableRefObject<HTMLDivElement | null>,
    removeBlockHandler: (index: number) => void
    updateBlockHandler: (index: number, newBlock: PhoneConsult.Timeslot, dragReverse?: boolean) => PhoneConsult.Timeslot | undefined,
}

const ScheduleBlock = ({ block, blockIndex, times, dragContainerRef, removeBlockHandler, updateBlockHandler }: ScheduleBlockProps) => {
    const classes = useStyles();

    // Ref is used to show value while dragging
    const startIndexRef = useRef<number>(times.indexOf(block.start))
    const endIndexRef = useRef<number>(times.indexOf(block.end))
    // State is used to show current value
    const [startIndex, setStartIndex] = useState(startIndexRef.current)
    const [endIndex, setEndIndex] = useState(endIndexRef.current)

    const [showHandles, setShowHandles] = useState(false)

    const dragFinishedHandler = (dragReverse?: boolean) => {
        if(startIndexRef.current === startIndex && endIndexRef.current === endIndex)
            return;

        // New block will be different from what we provide based on if the block overlaps existing blocks or not
        const newBlock = updateBlockHandler(blockIndex, {start: times[startIndexRef.current], end: times[endIndexRef.current]}, dragReverse)

        if(newBlock !== undefined) {
            // Manually update values, rather than relying on the "block" prop changing, since its possible it doesn't update
            // This would result in the refs having the wrong value if the newBlock is cut off, but results in a block the same as the initial block
            updateBlock(newBlock)
        }
        else {
            // Invalid operation occurred
            // ex: dragging a block completely inside of another block
            // Reset to state before drag happened
            updateBlockWithIndexes(startIndex, endIndex)
        }
    }

    const updateBlock = (newBlock: PhoneConsult.Timeslot) => {
        const newStartIndex = times.indexOf(newBlock.start)
        const newEndIndex = times.indexOf(newBlock.end)
        updateBlockWithIndexes(newStartIndex, newEndIndex)
    }

    const updateBlockWithIndexes = (newStartIndex: number, newEndIndex: number) => {
        setStartIndex(newStartIndex)
        setEndIndex(newEndIndex)
        startIndexRef.current = newStartIndex
        endIndexRef.current = newEndIndex
    }

    // Use ref instead of passing state for better performance
    const { mouseDownHandler: dragTopHandler, isDragging: isDraggingTop } = BlockDragController({ 
        currentDragIndexRef: startIndexRef,
        pxPerIndex: timeRowHeight,
        minDragIndex: 0,
        maxDragIndex: endIndexRef.current - 1,
        dragFinishedHandler,
        dragContainerRef
    })

    const { mouseDownHandler: dragBottomHandler, isDragging: isDraggingBottom } = BlockDragController({ 
        currentDragIndexRef: endIndexRef,
        pxPerIndex: timeRowHeight,
        minDragIndex: startIndexRef.current + 1,
        maxDragIndex: times.length - 1,
        dragFinishedHandler,
        dragContainerRef
    })

    const { mouseDownHandler: dragBlockHandler, isDragging: isDraggingBlock } = BlockDragController({ 
        currentDragIndexRef: startIndexRef,
        currentDragEndIndexRef: endIndexRef,
        pxPerIndex: timeRowHeight,
        minDragIndex: 0,
        maxDragIndex: times.length - 1,
        dragFinishedHandler,
        dragContainerRef
    })

    useEffect(() => {
        updateBlock(block)
    }, [block])

    const showHandlesHandler = () => {
        setShowHandles(true)
    }
    const hideHandlesHandler = () => {
        setShowHandles(false)
    }

    const isSmall = (): boolean => {
        return endIndex - startIndex <= 3
    }

    const isDraggingAny = (): boolean => {
        return isDraggingTop || isDraggingBottom || isDraggingBlock
    }

    return (
        <ScheduleBlockPaper 
            className={clsx({
                [classes.onTop]: isDraggingAny()
            })}
            // Use state while not dragging to highlight bugs where ref and state aren't in-sync
            startIdx={isDraggingTop || isDraggingBlock ? startIndexRef.current : startIndex} 
            endIdx={isDraggingBottom || isDraggingBlock ? endIndexRef.current : endIndex}
        >
            <div className={classes.handleContainer} onMouseOver={showHandlesHandler} onMouseLeave={hideHandlesHandler}>
                <div className={clsx(classes.blockHandle, classes.blockHandleTop, {
                    [classes.hideHandle]: (!showHandles && !isDraggingTop && !isDraggingBottom)
                })} onMouseDown={dragTopHandler}></div>
                    <div className={clsx(classes.blockDragContainer, {
                        [classes.blockDragContainerDragging]: isDraggingBlock
                    })} onMouseDown={dragBlockHandler}>
                        <Grid container justifyContent='space-between' alignItems="center" className={clsx({
                            [classes.blockContainerPaddingLarge]: !isSmall(),
                            [classes.blockContainerPaddingSmall]: isSmall()
                        })}>
                            <Grid item>
                                <TimeslotRangeDisplay className={clsx(classes.whiteText, {
                                    [classes.textLarge]: !isSmall(),
                                    [classes.textSmall]: isSmall()
                                })} start={times[isDraggingAny() ? startIndexRef.current : startIndex]} end={times[isDraggingAny() ? endIndexRef.current : endIndex]}/>
                            </Grid>
                            <Grid item>
                                <IconButton className={classes.deleteButton} onClick={() => {removeBlockHandler(blockIndex)}}>
                                    <DeleteIcon className={clsx(classes.whiteText, {
                                        [classes.deleteIconLarge]: !isSmall(),
                                        [classes.deleteIconSmall]: isSmall()
                                    })}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                <div className={clsx(classes.blockHandle, classes.blockHandleBottom, {
                    [classes.hideHandle]: (!showHandles && !isDraggingBottom && !isDraggingBottom)
                })} onMouseDown={dragBottomHandler}></div>
            </div>
        </ScheduleBlockPaper>
    )
}

export default ScheduleBlock;