import SpecialistSelect from "./SpecialistSelect";
import Grid from '@material-ui/core/Grid';
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";
import SubsiteSelect from "./SubsiteSelect";
import { Control, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import SpecialtySubsiteSpecialistSelectController, { SpecialtySubsiteSpecialistSelectControlType } from "./SpecialtySubsiteSpecialistSelectController";
import { ProcessStatus } from "@alethea-medical/alethea-components";

interface SpecialtySubsiteSpecialistSelectProps {
    control: Control<SpecialtySubsiteSpecialistSelectControlType>,
    setValue: UseFormSetValue<SpecialtySubsiteSpecialistSelectControlType>,
    trigger: UseFormTrigger<SpecialtySubsiteSpecialistSelectControlType>
    isDisabled?: boolean,
}

const SpecialtySubsiteSpecialistSelect = ({ control, setValue, trigger, isDisabled=false }: SpecialtySubsiteSpecialistSelectProps) => {
    
    const {
        routeTree, specialists, subsites, processState, processErrorMessage, isRunning
    } = SpecialtySubsiteSpecialistSelectController({ control, setValue, trigger });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
            </Grid>
            <Grid item xs={12}>
                <TypedFormSelect
                    name="specialty"
                    control={control}
                    label="Specialty"
                    options={routeTree ? Object.keys(routeTree).sort() : []}
                    disabled={isDisabled || isRunning()}
                />
            </Grid>
            <Grid item xs={12}>
                <SubsiteSelect
                    control={control}
                    subsites={subsites}
                    disabled={isDisabled || isRunning()}
                />
            </Grid>
            <Grid item xs={12}>
                <SpecialistSelect
                    control={control}
                    specialists={specialists}
                    disabled={isDisabled || isRunning()}
                    loading={isRunning()}
                />
            </Grid>

        </Grid>
    );
}

export default SpecialtySubsiteSpecialistSelect;