import { useState, useEffect } from "react";
import { Control, useWatch, UseFormSetValue } from "react-hook-form";
import { TypedFormTextField } from "../../../components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "../../../components/FormInputFields/PhoneInput";
import { TypedFormCheckbox } from "../../../components/FormInputFields/FormCheckbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormattedInputLabel from "../../../components/FormattedInputLabel";
import { UserProfile, Location } from "../../../../shared/types";
import useCms from "../../../utils/useCms";
import { TypedFormSelectAutocomplete } from "../../../components/FormInputFields/FormSelectAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clinic: {
      padding: theme.spacing(1),
    },
  }),
);

interface ProfileLocationsProps {
  control: Control<UserProfile>;
  setValue: UseFormSetValue<Partial<UserProfile>>;
}

const ProfileLocations = ({ control, setValue }: ProfileLocationsProps) => {
  const classes = useStyles();

  // Get the form values
  const initialLocations = useWatch({
    control,
    name: "locations",
    defaultValue: [],
  });

  // Turn locations into a state so it can be modified
  const [locations, setLocations] = useState<Location[]>([]);

  // Set the locations once they are loaded in
  useEffect(() => {
    setLocations(initialLocations);
  }, [initialLocations]);

  const emrContent = useCms({ cmsId: "emrs" });
  const clinicsContent = useCms({ cmsId: "clinics" });

  const addNewClinic = () => {
    const newClinic: Location = {
      clinicName: "",
      clinicPhone: "",
      emr: "",
      fax: "",
      city: "",
      province: locations[0].province || "", // Has to have a province, so default to first location initially
      billable: true,
      disabled: false,
    };

    const updatedLocations = [...locations, newClinic];
    setLocations(updatedLocations);
    setValue("locations", updatedLocations); // Update the form values
  };

  // Ensure only 1 location is set to default
  const handleDefaultChange = (index: number) => {
    const updatedLocations = locations.map((loc, i) => ({
      ...loc,
      default: i === index ? !loc.default : false,
    }));

    setLocations(updatedLocations);
    setValue("locations", updatedLocations);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormattedInputLabel>Your Clinics</FormattedInputLabel>
      </Grid>
      {locations.map(
        (loc: any, index: number) =>
          !loc.disabled && (
            <Grid item xs={12} key={`location_${index}`}>
              <Paper elevation={1} className={classes.clinic}>
                <Grid container>
                  <Grid item xs={12}>
                    <TypedFormTextField
                      name={`locations.${index}.clinicName`}
                      control={control}
                      label="Clinic Name"
                      rules={{
                        required: {
                          value: true,
                          message: "Clinic name is required",
                        },
                      }}
                      {...{ freeSolo: true, autoSelect: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedPhoneInput
                      name={`locations.${index}.clinicPhone`}
                      control={control}
                      label="Clinic Phone Number"
                      rules={{
                        required: {
                          value: true,
                          message: "Clinic phone number is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedFormSelectAutocomplete
                      name={`locations.${index}.emr`}
                      control={control}
                      label="EMR"
                      options={emrContent.getArray<string>("emrList")}
                      {...{ freeSolo: true, autoSelect: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedPhoneInput
                      name={`locations.${index}.fax`}
                      control={control}
                      label="Fax Number"
                      rules={{
                        required: {
                          value: true,
                          message: "Fax number is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedFormTextField
                      name={`locations.${index}.city`}
                      control={control}
                      label="City"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedFormTextField
                      name={`locations.${index}.province`}
                      control={control}
                      label="Province"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TypedFormCheckbox
                      name={`locations.${index}.default`}
                      control={control}
                      label="Default Clinic"
                      onChange={() => handleDefaultChange(index)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ),
      )}

      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={addNewClinic}>
          Add Clinic
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileLocations;
