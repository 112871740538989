import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MediaEditTagsController from '../../Controllers/MediaEditTagsController';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import isNativeMobile from '../../../../models/isNativeMobile';
import isWeb from '../../../../models/isWeb';


interface MediaEditTagsProps {
    tags: string[],
    tagsChanged: (tags: string[]) => void,
    placeholder?: string,
    saveNewTags?: boolean
}

const MediaEditTags = ({ tags, tagsChanged, placeholder, saveNewTags=true }: MediaEditTagsProps) => {
    
    const { userTags, updateTagsHandler, deleteTagHandler } = MediaEditTagsController();

    const getHelperText = (inputValue: string, hasFocus: boolean) => {
        //If the textfield value could exist in the list, don't show helper text
        //If not focused, don't show helper text
        if(!hasFocus || inputValue.length === 0 || userTags.some((t) => t.toLowerCase().includes(inputValue.toLowerCase())))
            return undefined

        //If no results showing, show helper text to add a new tag
        return "Press enter to add a new tag"
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            autoSelect={isNativeMobile()}
            options={userTags}
            value={tags}
            clearOnBlur
            filterSelectedOptions
            onChange={(e, value) => {
                tagsChanged(value as string[]);
                if(saveNewTags)
                    updateTagsHandler(value as string[])
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={tags.length > 0 ? "" : placeholder}
                    variant="outlined"
                    size="small"
                    helperText={isWeb() && getHelperText((params?.inputProps as any)?.value, document.activeElement === (params?.inputProps as any)?.ref?.current)}
                    inputProps={{
                        ...params.inputProps,
                        enterkeyhint: "done",//Allow enter to add tag on mobile
                    }}
                />
            }
            renderOption={(option) => {
                return (
                    <Grid container justifyContent='space-between' alignItems="center">
                        <Grid item>
                            {option}
                        </Grid>
                        <Grid item>
                            <IconButton onClick={(e) => { e.stopPropagation(); deleteTagHandler(option)}} size="small">
                                <CloseIcon fontSize='small'/>
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            }}
        />
    );
}

export default MediaEditTags;