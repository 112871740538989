import isMobileDevice from "../../../../models/isMobileDevice";
import useConsultFormStyles from "../useConsultFormStyles";

interface ConsultAttachmentsProps {
  createDropzone: (
    text?: string | undefined,
    dropZoneMinHeight?: string | undefined,
  ) => JSX.Element;
  createFileList: () => JSX.Element;
  createFileThumbs: () => JSX.Element;
  createUploadFromClipboardButton: () => JSX.Element | null;
}

const ConsultAttachments = ({
  createDropzone,
  createFileList,
  createFileThumbs,
  createUploadFromClipboardButton,
}: ConsultAttachmentsProps) => {
  const classes = useConsultFormStyles();

  return (
    <div>
      <div className={classes.spacing}>{createUploadFromClipboardButton()}</div>
      <div className={classes.spacing}>
        {createDropzone(
          isMobileDevice()
            ? "Tap to Upload"
            : "Click Here or Drag and Drop to Upload",
          "150px",
        )}
      </div>
      <div className={classes.spacing}>{createFileList()}</div>
      <div className={classes.spacing}>{createFileThumbs()}</div>
    </div>
  );
};

export default ConsultAttachments;
