import { Location } from "@alethea-medical/aletheamd-types";

export default function buildSignoff(
  name: { first: string | undefined; last: string | undefined },
  location: Location | undefined,
): string {
  let signoff = "--";
  if (name.first && name.last) signoff += `\r\nDr. ${name.first} ${name.last}`;
  if (location) {
    signoff += `\r\n\r\n${location.clinicName}`;
    if (location.clinicPhone) signoff += `\r\nPhone: ${location.clinicPhone}`;
    signoff += `\r\nFax: ${location.fax}`;
  }

  return signoff;
}
