import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperModal } from '@alethea-medical/alethea-components';
import { Button } from '@material-ui/core';
import sharedStyles from '../../../../sharedStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
    })
)


interface MediaEditDeleteProps {
    mediaDelete: () => void
}

const MediaEditDelete = ({ mediaDelete }: MediaEditDeleteProps) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);

    const showConfirmationHandler = () => {
        setShow(true)
    }

    const handleDelete = () => {
        mediaDelete();
        setShow(false)
    }

    return (
        <>
            <PaperModal show={show} setShow={setShow} enablePadding flexHeight flexWidth>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Are you sure?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" className={classes.dangerButton} onClick={handleDelete}>Delete</Button>
                    </Grid>
                </Grid>
            </PaperModal>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={1}>
                    <IconButton onClick={showConfirmationHandler}><DeleteIcon/></IconButton>
                </Grid>
            </Grid>
        </>
    );
}

export default MediaEditDelete;