import Grid from '@material-ui/core/Grid';
import FormattedInputLabel from '../../../../../components/FormattedInputLabel';
import { TypedFormTextField } from '../../../../../components/FormInputFields/FormTextField';
import { TypedFormRoundedRadioButtons } from '../../../../../components/FormInputFields/FormRoundedRadioButtons';
import { Control, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import HelpModal from '../../../../../components/HelpModal';
import { TypedFormSelect } from '../../../../../components/FormInputFields/FormSelect';
import useCms from '../../../../../utils/useCms';
import { TypedFormSelectAutocomplete } from '../../../../../components/FormInputFields/FormSelectAutocomplete';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import SectionContainer from '../../Helpers/Container/SectionContainer';
import { SignupInfo } from '@alethea-medical/aletheamd-types';

interface OnboardingProps {
    setValue: UseFormSetValue<SignupInfo.SignupInfo>,
    trigger: UseFormTrigger<SignupInfo.SignupInfo>,
    control: Control<SignupInfo.SignupInfo>,
    disabled: boolean,
    number: number
}


interface ExtraInformationProps {
    setValue: UseFormSetValue<SignupInfo.SignupInfo>,
    trigger: UseFormTrigger<SignupInfo.SignupInfo>,
    control: Control<SignupInfo.SignupInfo>,
    name: keyof SignupInfo.SignupInfo,
    options: SelectedOption[]
}

interface SelectedOption {
    header: string,
    value: string,
    extraInputRequired?: boolean,
    extraInputType?: string,
    dropDownOptions?: string[],
}

const ExtraInformation = ({ control, setValue, trigger, name, options }: ExtraInformationProps) => {
    const [selectedOption, setSelectedOption] = useState<SelectedOption>();
    const value = useWatch({ control, name: "howDidYouHearAboutUs" })

    useEffect(() => {
        // Find the option where option.value === value
        const selectedOption = options.find((option: SelectedOption) => option.value === value);
        setSelectedOption(selectedOption);

    }, [value])

    useEffect(() => {
        if (selectedOption === undefined) return;

        setValue("howDidYouHearAboutUsExtraInfo", '')
        if (!selectedOption.extraInputRequired)
            trigger("howDidYouHearAboutUsExtraInfo")
    }, [selectedOption])

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
                <TypedFormSelect
                    control={control}
                    name={name}
                    options={options.map((option: SelectedOption) => option.value)}
                />
            </Grid>
            <Grid item xs={12}>
                {selectedOption?.extraInputType === 'textfield' &&
                    <>
                        <FormattedInputLabel>{selectedOption.header}</FormattedInputLabel>
                        <TypedFormTextField
                            control={control}
                            name="howDidYouHearAboutUsExtraInfo"
                            rules={{ required: { value: selectedOption.extraInputRequired, message: "This is a required field." } }}
                        />
                    </>}
                {selectedOption?.extraInputType === 'dropdown' && selectedOption?.dropDownOptions &&
                    <>
                        <FormattedInputLabel>{selectedOption.header}</FormattedInputLabel>
                        <TypedFormSelect
                            control={control}
                            name="howDidYouHearAboutUsExtraInfo"
                            options={selectedOption.dropDownOptions}
                            rules={{ required: { value: selectedOption.extraInputRequired, message: "This is a required field." } }}
                        />
                    </>}
            </Grid>
        </Grid>
    )
};

const Onboarding = ({ setValue, trigger, control, disabled, number }: OnboardingProps) => {
    const signUpContent = useCms({ cmsId: "signup" })
    const emrContent = useCms({ cmsId: "emrs" })

    return (
        <SectionContainer title="Onboarding" number={number}>
            <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12}>
                    <HelpModal
                        buttonText="Would you like to book an onboarding tutorial with one of our team members?"
                        helpText={["In this session we will help you get started with the platform. You do not need to book a session if you are comfortable on your own."]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormattedInputLabel />
                    <TypedFormRoundedRadioButtons
                        control={control}
                        name={"scheduleOnboarding"}
                        options={["Yes", "No"]}
                        rules={{ required: { value: true, message: "Onboarding preference is required." } }}
                        errorTextPadding
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormattedInputLabel style={{ marginBottom: "10px" }}>I prefer to be contacted by:</FormattedInputLabel>
                    <TypedFormRoundedRadioButtons
                        control={control}
                        name={"contactPref"}
                        options={["Email", "Text", "Phone"]}
                        rules={{ required: { value: true, message: "Contact preference is required." } }}
                        errorTextPadding
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormattedInputLabel>How did you hear about us?</FormattedInputLabel>
                    <ExtraInformation
                        control={control}
                        setValue={setValue}
                        trigger={trigger}
                        name={"howDidYouHearAboutUs"}
                        options={signUpContent.getArray<SelectedOption>("howDidYouHearAboutUsNew")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormattedInputLabel>What EMR do you use?</FormattedInputLabel>
                    <TypedFormSelectAutocomplete
                        control={control}
                        name="emr"
                        options={emrContent.getArray<string>("emrList")}
                        errorTextPadding
                        {...{ freeSolo: true, autoSelect: true }}
                    />
                </Grid>
            </Grid>
        </SectionContainer>
    );
}

export default Onboarding;