import React from "react";
import Grid from '@material-ui/core/Grid';
import clsx from "clsx";
import Typography from '@material-ui/core/Typography';
import Label from "../../Label";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ProcessStatus, useMobileSizes } from "@alethea-medical/alethea-components";
import { AIType } from "../../../../shared/types";
import AIPredictionsController, { AIPredictionsControllerProps } from "../Controllers/AIPredictionsController";
import palette from "../../../palette";
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getAITypeDisplayString } from "../Models/AIPredictionsModel";
import { projectEnv } from "../../../firebase";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontWeight: 600,
            color: palette.orange
        },
        cyaText: {
            fontSize: "0.7em",
        },
        paper: {
            padding: theme.spacing(1)
        },
        center: {//Center in grid
			display:'flex',
			justifyContent:'center',
			alignItems:'center'
		},
        centerText:{
			textAlign: "center"
		},
        placeholder: {
            color: "#999"
        }
    })
);

const VIDEO_PREDICTION_TEXT = "AI prediction is not available for video files.";
const cyaStr = "This is not a diagnosis. Our AI is a visual prediction tool intended for educational purposes only."

interface AIPredictionsViewProps extends AIPredictionsControllerProps{
    fullWidth?: boolean
}

const AIPredictionsView = ({ mediaItem, doctorLabels, modifyItem, allowAIDatabaseCaching, fullWidth}: AIPredictionsViewProps) => {
	const classes = useStyles();

    const isMobileSize = useMobileSizes();

    const {
        //states
        predictions,
        labelType,
        predictionType,
        //handlers
        predictionTypeChangeHandler,
        clearPredictions,
        //process
        aiState: processState, aiError: processErrorMessage
    } = AIPredictionsController({ mediaItem, doctorLabels, modifyItem, allowAIDatabaseCaching })

    const renderLabels = () => (
        <Grid container alignItems="center" justifyContent={"center"} spacing={1}>
            {predictions.map((prediction: string) => {
                if(prediction !== '') {
                    return (
                        <Grid item key={`ai_label_${prediction}`}>
                            <Label text={prediction} color={labelType === "AI" ? "orange" : "dark"}/>
                        </Grid>
                    )
                }
                else {
                    return null;
                }
                
            })}
        </Grid>
    )

    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid item lg={fullWidth ? 12 : 6} md={fullWidth ? 12 : 8} sm={fullWidth ? 12 : 10} xs={12}>
                {mediaItem.data.fileType === "image" && (
                    <>
                        {labelType === "AI" && (
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.header}>AI Support</Typography>
                                </Grid>
                                {!isMobileSize && 
                                    <Grid item xs={6}>{/* Empty */}</Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <Select
                                        value={predictionType}
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        displayEmpty
                                        renderValue={(value) => value !== "" ? <>{getAITypeDisplayString(value as AIType)}</> : <Typography className={classes.placeholder}>Select a Diagnostic Category</Typography>}
                                        onChange={predictionTypeChangeHandler}
                                        endAdornment={
                                            predictionType !== "" ? <IconButton onClick={clearPredictions}><CloseIcon/></IconButton> : undefined
                                        }
                                    >
                                        <MenuItem value={"ear"}>{getAITypeDisplayString("ear")}</MenuItem>
                                        <MenuItem value={"nasal"}>{getAITypeDisplayString("nasal")}</MenuItem>
                                        <MenuItem value={"derm"}>{getAITypeDisplayString("derm")}</MenuItem>
                                        {(projectEnv === "dev" || projectEnv === "local") ? <MenuItem value={"lvl1"}>{getAITypeDisplayString("lvl1")}</MenuItem> : null}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Collapse in={predictions.length > 0}>
                                        {renderLabels()}
                                    </Collapse>
                                </Grid>
                                {predictions.length > 0 && 
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" className={clsx(classes.centerText, classes.cyaText)}>{cyaStr}</Typography>        
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                                </Grid>
                            </Grid>
                        )}
                        {labelType === "Doctor" && (
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography className={classes.centerText}>{"Specialist Labels"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {renderLabels()}
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
                {mediaItem.data.fileType === "video" && (
                    <Typography variant="subtitle1" className={classes.centerText}>
                        {VIDEO_PREDICTION_TEXT}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
}

export default AIPredictionsView;