import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noSelect: {
            "-webkit-user-select": "none", /* Safari */
            "-ms-user-select": "none", /* IE 10 and IE 11 */
            "user-select": "none" /* Standard syntax */
        },
        expandButton: {
            padding: theme.spacing(1)
        }
    })
)

interface EconsultCardExpandHeaderProps {
    title: string,
    expand: boolean,
    setExpand: (expand: boolean) => void
}

const EconsultCardExpandHeader = ({ title, expand, setExpand }: EconsultCardExpandHeaderProps) => {
    const classes = useStyles();
    return (
        <Typography className={classes.noSelect} onClick={() => {setExpand(!expand)}}>
            {title}
            <IconButton className={classes.expandButton}>
                {expand ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
        </Typography>
    );
}

export default EconsultCardExpandHeader;