import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
            borderRadius: "5px"
        }
    })
)

const InformationBox: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={0}>
            {children}
        </Paper>
    );
}

export default InformationBox;