import { useMobileSizes } from "@alethea-medical/alethea-components";
import Grid, { GridSize } from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import clsx from "clsx";
import React from "react";
import { Activity } from "../../../../../../shared/types";
import { formatTimeShort } from "../../../../../models/formatTime";
import palette from "../../../../../palette";
import ConsultStatus from "../../Toolbar/ConsultStatus";
import { ActivityItem } from "../../types";
import { ProcessState } from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldText: {
      fontWeight: 700,
    },
    labelComponentItem: {
      margin: theme.spacing(0, 1),
    },
    phoneText: {
      color: palette.orange,
      fontWeight: 600,
      fontSize: "1.0em",
    },
    timestamp: {
      whiteSpace: "nowrap",
    },
    wrapText: {
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
  }),
);

interface PrimarySectionProps {
  activity: ActivityItem<Activity.UserActivity>;
  econsult: any;
  filters: string[];
  gridSizes: { [key: string]: GridSize };
  read: boolean;
  overdue: boolean;
  permissions: {
    hasEditStatusPermissions: boolean;
  };
  activityRole?: Activity.ActivityRole.ActivityRole;
  processState: ProcessState;
}

const PrimarySection: React.FC<PrimarySectionProps> = ({
  activity,
  econsult,
  filters,
  gridSizes,
  read,
  overdue,
  permissions,
  activityRole,
  processState,
}) => {
  const classes = useStyles();
  const isMobileSize = useMobileSizes();

  const columnOrder = [
    "Specialty",
    "Subsite",
    "PHN",
    "Patient Name",
    "Referrer Name",
    "Consultant Name",
  ];

  // Given the column name, return the content for that column
  const getColumnContent = (
    section: string,
    econsult: any,
    activity: ActivityItem<Activity.UserActivity>,
  ) => {
    switch (section) {
      case "Specialty":
        return econsult.specialty;
      case "Subsite":
        return econsult.subsite;
      case "PHN":
        return econsult.phn;
      case "Patient Name":
        return `${econsult.patientFirstName} ${econsult.patientLastName}`;
      case "Referrer Name": {
        const referrer = Object.values(activity.sharedActivity.profiles).find(
          (profile) => profile?.activityRole === "referrer",
        );
        return `${referrer?.firstName ?? ""}${referrer?.firstName ? " " : ""}${
          referrer?.lastName ?? ""
        }`;
      }
      case "Consultant Name": {
        const consultant = Object.values(activity.sharedActivity.profiles).find(
          (profile) => profile?.activityRole === "consultant",
        );
        return `${consultant?.firstName ?? ""}${
          consultant?.firstName ? " " : ""
        }${consultant?.lastName ?? ""}`;
      }
      default:
        return null;
    }
  };

  // Returns the string, given the column filters
  const buildSubjectString = () => {
    const parts: string[] = [];

    filters.forEach((filter) => {
      const content = getColumnContent(filter, econsult, activity);
      if (content) parts.push(content);
    });

    return parts.join(", ");
  };

  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={isMobileSize ? 12 : gridSizes["Status"]}
        className={classes.wrapText}
      >
        {activity.metadataActivity.status !== undefined &&
          permissions.hasEditStatusPermissions &&
          activityRole && (
            <ConsultStatus
              status={activity.metadataActivity.status ?? ""}
              viewerRole={activityRole}
              className={classes.labelComponentItem}
              style={{
                width: "auto",
                display: "inline-block",
              }}
            />
          )}
      </Grid>
      {processState === ProcessState.running ? (
        <>Loading...</>
      ) : isMobileSize ? (
        <Grid item xs style={{ marginLeft: "8px" }}>
          {econsult ? (
            <>{buildSubjectString()}</>
          ) : (
            activity.sharedActivity.subject
          )}
        </Grid>
      ) : econsult ? (
        <>
          {columnOrder.map((filter) =>
            filters.includes(filter) ? (
              <Grid
                item
                xs={gridSizes[filter]}
                key={filter}
                container
                alignItems="center"
                className={classes.wrapText}
              >
                {getColumnContent(filter, econsult, activity)}
              </Grid>
            ) : null,
          )}
        </>
      ) : (
        <Grid item>{activity.sharedActivity.subject}</Grid>
      )}
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        xs={isMobileSize ? false : true}
      >
        <Grid item>
          <Typography
            className={clsx(classes.timestamp, {
              [classes.boldText]: !read,
            })}
          >
            {" "}
            {formatTimeShort(
              activity.sharedActivity.recentMessage.sentAt.toDate(),
            )}
          </Typography>
        </Grid>
        <Grid item>{overdue && <AccessTimeIcon />}</Grid>
      </Grid>
    </Grid>
  );
};

export default PrimarySection;
