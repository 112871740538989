import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useMobileSizes } from "@alethea-medical/alethea-components";
import { Button } from "@material-ui/core";

interface ViewSecureMessageNavigationProps {
  showToolbar: boolean;
  setShowToolbar: (show: boolean) => void;
  goBack: () => void;
  goToMessages: () => void;
}

const ViewSecureMessageNavigation = ({
  showToolbar,
  setShowToolbar,
  goBack,
  goToMessages,
}: ViewSecureMessageNavigationProps) => {
  const isMobileSize = useMobileSizes();

  const handleCollapseToolbar = () => {
    setShowToolbar(!showToolbar);
  };
  return isMobileSize ? (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button onClick={goBack} startIcon={<ChevronLeftIcon />}>
            Back to Inbox
          </Button>
        </Grid>
        <Grid item>
          <IconButton onClick={handleCollapseToolbar}>
            {showToolbar ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
        <Grid item>
          <Button onClick={goToMessages} endIcon={<ChevronRightIcon />}>
            Messages
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid item>
      <Button onClick={goBack} startIcon={<ChevronLeftIcon />}>
        Back to Inbox
      </Button>
    </Grid>
  );
};

export default ViewSecureMessageNavigation;
