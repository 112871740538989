import { useContext, useEffect } from "react";
import { LogoutButton } from "../../Auth/components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Location } from "history";

import { AuthContext } from "../../../AuthProvider";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";

import AssignmentIcon from "@material-ui/icons/Assignment";
import EmailIcon from "@material-ui/icons/Email";
import InfoIcon from "@material-ui/icons/Info";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ChatIcon from "@material-ui/icons/Chat";
import HelpIcon from "@material-ui/icons/Help";
import InboxIcon from "@material-ui/icons/Inbox";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TocIcon from "@material-ui/icons/Toc";
import Description from "@material-ui/icons/Description";
import {
  useMobileSizes,
  AppBar,
  useSidebar,
  thinScrollbar,
} from "@alethea-medical/alethea-components";
import routes from "../../../config/routes";
import sharedStyles from "../../../sharedStyles";
import clsx from "clsx";
import MobileCameraButton from "../../Pages/Camera/MobileCameraButton";
import isNativeMobile from "../../../models/isNativeMobile";
import OneTimeTooltip from "../../../components/OneTimeTooltip";
import isAndroid from "../../../models/isAndroid";
import AndroidAPKUpdateSidebar from "../../../config/AndroidAPKUpdateSidebar";
import versionStrings from "../../../versionStrings";
import palette from "../../../palette";
import { projectEnv } from "../../../firebase";
import CollapseSidebarItem from "./CollapseSidebarItem";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EventIcon from "@material-ui/icons/Event";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SidebarItem from "./SidebarItem";
import ConsultButton from "./ConsultButton";
import InviteAColleagueButton from "../../Pages/InviteAColleague";
import UnreadNotificationsCounter from "../../Pages/SecureMessaging/NotificationCenter/UnreadNotificationsCounter";
import AletheaSidebar from "./components/AletheaSidebar";
import AletheaSidebarItem from "./components/AletheaSidebarItem";

const useStyles = makeStyles((theme: Theme) => ({
  ...sharedStyles(theme),
  thinScrollbar: {
    ...thinScrollbar.thinScrollbar, //Override default thin scrollbar to make it even thinner
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
  },
  hide: {
    display: "none",
  },
  header: {
    marginLeft: theme.spacing(2),
  },
  username: {
    whiteSpace: "normal",
  },
  versionString: {
    color: palette.darkGreen,
    fontSize: "0.75em",
  },
}));

const Sidebar = withRouter(({ location }: { location: Location }) => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const isMobileSize = useMobileSizes();

  const {
    open,
    setOpen,
    showText,
    drawerWidthOpen,
    drawerWidthClosed,
    renderOpenCloseButtons,
    hideSidebarIfMobile,
  } = useSidebar({
    isMobileSize,
    drawerWidthOpen: 270,
    drawerWidthClosed: 57,
  });

  useEffect(() => {
    if (isMobileSize) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobileSize]);

  const showUserName = (): string => {
    const name = `${authContext?.profile?.firstName ? authContext.profile.firstName : ""} ${authContext?.profile?.lastName ? authContext.profile.lastName : ""}`;
    if (!open) return "";
    if (showText) return name;
    return "";
  };

  return (
    <>
      <AppBar
        className="safeAreaTop safeAreaSides"
        open={open}
        setOpen={setOpen}
        header={
          Object.values(routes).find((r) => {
            return r.path === location.pathname;
          })?.name
        }
        drawerWidthOpen={drawerWidthOpen}
        drawerWidthClosed={drawerWidthClosed}
        isMobileSize={isMobileSize}
        topRightControls={
          isNativeMobile() && (
            <OneTimeTooltip
              tooltipName="mobile_new_camera_button"
              title="Take photos for your eConsult using the camera"
            >
              <MobileCameraButton />
            </OneTimeTooltip>
          )
        }
      />
      <AletheaSidebar
        paperClassName={clsx(
          classes.thinScrollbar,
          "safeAreaTop safeAreaSides safeAreaBottom",
          "sidebar",
        )}
        open={open}
        setOpen={setOpen}
        isMobileSize={isMobileSize}
        drawerWidthOpen={drawerWidthOpen}
        drawerWidthClosed={drawerWidthClosed}
      >
        <List color="primary">
          <ListItem
            divider
            button
            component={Link}
            to={routes.notificationCenter.path}
            onClick={hideSidebarIfMobile}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item style={{ position: "relative" }}>
                <UnreadNotificationsCounter
                  style={{ position: "absolute", top: 0, right: 0 }}
                />
                <img
                  className={classes.imgFluid}
                  src="/logo_big.png"
                  alt="Alethea Logo"
                  width="40"
                  height="40"
                />
              </Grid>
              {showText && (
                <Grid item xs={1}>
                  <Fade in={showText}>
                    <Typography
                      className={classes.header}
                      variant="h6"
                      color="primary"
                    >
                      Alethea Portal
                    </Typography>
                  </Fade>
                </Grid>
              )}
            </Grid>
          </ListItem>
          {isAndroid() && <AndroidAPKUpdateSidebar showText={showText} />}

          <OneTimeTooltip
            tooltipName={"granular_fax_prefs_july_6_2023"}
            header={"Improved Fax Preferences"}
            title={
              <>
                You now have more control over which faxes you receive, and what
                they should contain.
                <br />
                Edit your preferences in your{" "}
                <a href="/dashboard/profile">Profile</a>.
              </>
            }
            placement="right"
          >
            <SidebarItem
              route={routes.profile}
              label={showUserName()}
              textClass={classes.username}
              showText={showText}
              icon={<AccountCircleIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
              {...{ divider: true }}
            />
          </OneTimeTooltip>
          <ConsultButton
            route={routes.consult}
            onClick={hideSidebarIfMobile}
            showText={showText}
            icon={<AddCircleIcon />}
            currentPath={location.pathname}
            linkComponent={Link}
          />
          <Divider />
          <SidebarItem
            route={routes.secureMessaging}
            showText={showText}
            icon={<ChatIcon />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <SidebarItem
            route={routes.moaConsultInbox}
            showText={showText}
            icon={<InboxIcon />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <SidebarItem
            route={routes.validationRequired}
            showText={showText}
            icon={<InfoIcon />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <OneTimeTooltip
            tooltipName={"gallery_ava_integration_feb_17"}
            header={"New Feature for Ava EMR users!"}
            title={
              "You can now upload photos to Ava by selecting photos in your gallery and pressing the 'Upload to Ava' button."
            }
            placement="right"
            visible={authContext?.profile?.integrations?.avaUuid !== undefined}
            actionText={"Go to your Gallery"}
            action={() => {
              history.push("/dashboard/gallery");
            }}
          >
            <CollapseSidebarItem title="Imaging" showCollapse={open}>
              <SidebarItem
                route={routes.camera}
                showText={showText}
                icon={<PhotoCameraIcon />}
                currentPath={location.pathname}
                onClick={hideSidebarIfMobile}
                linkComponent={Link}
              />
              <SidebarItem
                route={routes.gallery}
                showText={showText}
                icon={<PhotoLibraryIcon />}
                currentPath={location.pathname}
                onClick={hideSidebarIfMobile}
                linkComponent={Link}
              />
            </CollapseSidebarItem>
          </OneTimeTooltip>

          <CollapseSidebarItem
            title="Patient Communications"
            showCollapse={open}
          >
            <SidebarItem
              route={routes.patientMessages}
              showText={showText}
              icon={<InboxIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.forms}
              showText={showText}
              icon={<AssignmentIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.oneWayMessaging}
              showText={showText}
              icon={<EmailIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <CollapseSidebarItem title="Specialist Tools" showCollapse={open}>
            <SidebarItem
              route={routes.specialistTemplates}
              showText={showText}
              icon={<TocIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.editResources}
              showText={showText}
              icon={<LibraryBooksIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.specialistSchedule}
              showText={showText}
              icon={<EventIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.imageAnnotation}
              showText={showText}
              icon={<VisibilityIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <CollapseSidebarItem title="Other" showCollapse={open}>
            <SidebarItem
              route={routes.billingReport}
              showText={showText}
              icon={<Description />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={routes.resources}
              showText={showText}
              icon={<LocalLibraryIcon />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <InviteAColleagueButton showText={showText} />

          <LogoutButton showText={showText} />

          <AletheaSidebarItem
            label="Support"
            tooltip="Support"
            showText={showText}
            icon={<HelpIcon color="primary" />}
            linkComponent="a"
            {...{
              href: "https://aletheamedical.com/support",
              target: "_blank",
              rel: "noreferrer noopener",
              className: "aletheaSidebarItem",
            }}
            {...{ divider: true }}
          />

          {renderOpenCloseButtons()}
          {showText && (
            <ListItem>
              <Typography className={classes.versionString}>
                Version {versionStrings.version}
                {projectEnv === "prod"
                  ? ""
                  : `, Build ${versionStrings.devBuild}`}
              </Typography>
            </ListItem>
          )}
          {showText && projectEnv !== "prod" && (
            <ListItem>
              <Typography className={classes.versionString}>
                Environment: {projectEnv.toUpperCase()}
              </Typography>
            </ListItem>
          )}
        </List>
      </AletheaSidebar>
    </>
  );
});

export default Sidebar;
