import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import useConfirmDestructiveModal from "../../../../components/useConfirmDestructiveModal";


interface DraftsToolbarProps {
    atLeastOneSelected: boolean,
    moreThanOneSelected: boolean,
    discard: () => void
}

const DraftsToolbar = ({ atLeastOneSelected, moreThanOneSelected, discard }: DraftsToolbarProps) => {
    const { showConfirmDestructiveModal, ConfirmDestructiveModal } = useConfirmDestructiveModal({
        action: discard, confirmText: `Discard Draft${moreThanOneSelected ? "s" : ""}?`, confirmButtonText: "Discard" 
    })
    return (
        <>
            {ConfirmDestructiveModal}
            <Collapse in={atLeastOneSelected} unmountOnExit>
                <ButtonGroup>
                    <Tooltip
                        title="Discard"
                        children={
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={showConfirmDestructiveModal}
                                startIcon={<DeleteIcon/>}
                            >
                                Discard
                            </Button>      
                        }
                    />      
                </ButtonGroup>  
            </Collapse>

        </>
    );
}

export default DraftsToolbar;