import Grid from '@material-ui/core/Grid';
import RoundedButton from '../../../../../components/RoundedButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import sharedStyles from '../../../../../sharedStyles';
import Typography from '@material-ui/core/Typography';
import strings from '../../Common/strings';
import palette from '../../../../../palette';
import { useHistory } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...sharedStyles(theme),
        container: {
            paddingTop: theme.spacing(7),
        },
        title: {
            color: palette.darkGreen,
            fontSize: "2em",
            textAlign: "center"
        },
        center: {
            textAlign: "center"
        }
    })
)
interface SignupSuccessPageProps {
    forwardingToOnboarding: boolean,
    forwardToOnboardingManually: () => void
}

const SignupSuccessPage = ({ forwardingToOnboarding, forwardToOnboardingManually }: SignupSuccessPageProps) => {
    const classes = useStyles();
    const history = useHistory();

    const onClickLogin = () => {
        // If login is not working for some reason, just forward to the desired location
        if(forwardingToOnboarding)
            forwardToOnboardingManually()
        else
            history.push("/login");
    }

    return (
        <Grid className={classes.container} container spacing={2} justifyContent="center">
            <Grid item xs={3} md={1} className={classes.center}>
                <img src="/logo_big.png" className={classes.imgFluid}/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.title}>{strings.signupSuccess}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.center}>
                <Typography>Logging you in... {forwardingToOnboarding ? "You will be redirected to schedule an onboarding session shortly." : ""}</Typography>
            </Grid>
            <Grid item xs={10} md={3}>
                <LinearProgress/>
            </Grid>
            <Grid item xs={12} className={classes.center}>
                <RoundedButton onClick={onClickLogin}>{strings.autoLoginFail}</RoundedButton>
            </Grid>
        </Grid>
    );
}

export default SignupSuccessPage;