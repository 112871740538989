import React, { useContext, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useHistory, withRouter } from 'react-router-dom';
import routes from '../../config/routes';
import { AuthContext } from '../../AuthProvider';
import { defaultRoles } from '@alethea-medical/aletheamd-db-keys';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(5),
        },
        text: {
            textAlign: "center"
        }
    }),
);

const NoPermissionsPage = withRouter((location) => {
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if(location.location.pathname.includes(routes.validationRequired.path) && authContext?.profile?.userRoles !== undefined && !authContext.profile.userRoles.includes(defaultRoles.pracIdValidationRequired)) {
            history.push('/dashboard/consult');
        }
    }, [authContext.profile])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Typography className={classes.text}>Unable to load this page.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text}>This page may not be available on your device, or your account lacks the permissions to view this page.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text}>Please contact <a href="mailto:sales@aletheamedical.com">sales@aletheamedical.com</a> to gain access to this feature.</Typography>
            </Grid>
        </Grid>
    );
})

export default NoPermissionsPage;