import { useContext, useState } from 'react';
import clsx from "clsx";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';

import sharedStyles from "../sharedStyles";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ProcessStatus } from '@alethea-medical/alethea-components';
import isNativeMobile from '../../../../models/isNativeMobile'
import ViewSecureMessageNavigation from '../ViewSecureMessageNavigation';
import useExportPDF from '../Utilities/useExportPDF';
import AveiroPaymentButton from '../../Aveiro/AveiroPaymentButton';
import usePermissions from '../../../../components/usePermissions';
import { resourceKeys } from '@alethea-medical/aletheamd-db-keys';
import AddConsultantButton from '../Toolbar/AddConsultantButton';
import { ActivityContext } from '../Inbox/Contexts/ActivityProvider';
import ConsultStatusDropdownButton from '../Toolbar/ConsultStatusDropdownButton';
import AveiroInvoiceButton from '../../Aveiro/AveiroInvoiceButton';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		...sharedStyles(theme),
	}),
);

interface MOAViewSecureMessageToolbarProps {
	goToMessages: () => void,
}

const MOAViewSecureMessageToolbar = ({ goToMessages }: MOAViewSecureMessageToolbarProps) => {

	const classes = useStyles();
	const activityContext = useContext(ActivityContext)

	const [showToolbar, setShowToolbar] = useState(true)
	const { exportPdfState, exportPdfErrorMessage, setExportPdfState, renderExportPdfButton} = useExportPDF({ activityId: activityContext.activityId })
	const { granted: hasAddSpecialistPermission } = usePermissions({ resourceKey: resourceKeys.addUserToActivity })
	const { granted: hasAveiroPaymentPermission } = usePermissions({ resourceKey: resourceKeys.createAveiroCharge })


	return (
		<>
			<Box
				className={clsx(classes.boxPadding, classes.boxBorderColor)}
				borderBottom={1}
			>
				<Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
					<ViewSecureMessageNavigation 
						showToolbar={showToolbar} setShowToolbar={setShowToolbar}
						goBack={activityContext.closeActivity} goToMessages={goToMessages}
					/>
					<Grid item>
						<Collapse in={showToolbar}>
							<Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
								{!isNativeMobile() && 
									<Grid item>
										{renderExportPdfButton()}
									</Grid>
								}
								{hasAveiroPaymentPermission && 
									<Grid item>
										<AveiroPaymentButton variant="button" activityId={activityContext.activityId}/>
									</Grid>
								}
								{hasAveiroPaymentPermission && 
									<Grid item>
										<AveiroInvoiceButton variant="button" activityId={activityContext.activityId} econsult={activityContext.econsult}/>
									</Grid>
								}
								{hasAddSpecialistPermission &&
									<Grid item>
										<AddConsultantButton viewType="moa" variant="button" activityId={activityContext.activityId} sharedActivity={activityContext.sharedActivity} />
									</Grid>
								}
								<Grid item>
									<ConsultStatusDropdownButton viewerRole={"moa"}/>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
				</Grid>
			</Box>
			<ProcessStatus state={exportPdfState} setState={setExportPdfState} errorMessage={exportPdfErrorMessage} useSnackbar={true} />
		</>
	);
}

export default MOAViewSecureMessageToolbar;