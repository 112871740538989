import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import palette from '../../../../../palette';
import { ImageAnnotationSelectedDict } from './EconsultImageAnnotationModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        aiConfirm: {
            color: "white",
            backgroundColor: palette.orange,
            "&:hover": {
                backgroundColor: palette.orangeLight
            }
        }
    })
)
interface ConfirmAIPredictionButtonProps {
    confirmHandler: (items: ImageAnnotationSelectedDict) => void,
    unselectAllHandler: () => void,
    selectedMedia: ImageAnnotationSelectedDict
}

const ConfirmAIPredictionButton = ({ confirmHandler, unselectAllHandler, selectedMedia }: ConfirmAIPredictionButtonProps) => {
    const classes = useStyles();
    const handleConfirm = () => {
        confirmHandler(selectedMedia);
        unselectAllHandler();
    }
    return (
        <Button fullWidth variant='contained' className={classes.aiConfirm} onClick={handleConfirm} disabled={Object.keys(selectedMedia).length === 0}>Use AI Labels</Button>
    );
}

export default ConfirmAIPredictionButton;