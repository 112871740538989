import { Location } from "../../shared/types";

// Filters out disabled locations and sorts them by default location
export function sortAndFilterLocations(locations: Location[]) {
  const enabledLocations = locations
    .map((loc, index) => ({ ...loc, originalIndex: index })) // Track their original index
    .filter((loc) => !loc.disabled); // Filter out disabled locations

  // Set default location
  if (enabledLocations && enabledLocations.length > 1) {
    // Is there a clinic with default location set?
    const defaultLocationIndex = enabledLocations.findIndex(
      (loc) => loc.default,
    );

    // If there is, move it to the top
    if (defaultLocationIndex !== -1) {
      const defaultLocation = enabledLocations[defaultLocationIndex];
      enabledLocations.splice(defaultLocationIndex, 1);
      enabledLocations.unshift(defaultLocation);
    }
  }

  return enabledLocations;
}

// Returns the index of the default location, if there isn't one, return 0
// Note: This is the "original" index, before filtering for disabled clinics & moving default to the top
export function getDefaultClinicIndex(locations: Location[]) {
  const idx = locations.findIndex((loc) => loc.default);
  return idx === -1 ? 0 : idx;
}
