import { useScreenSize } from "@alethea-medical/alethea-components";
import Grid from "@material-ui/core/Grid";
import ResourcesController from "../ResourcesController";
import ResourcesInputController from "../ResourcesInputController";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Category from "./Category";
import AddCategoryButton from "../../SpecialistTemplates/AddCategoryButton"; // Component is the same, its okay to import from SpecialistTemplates
import LeavePagePrompt from "../../../../components/LeavePagePrompt";
import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useProcessState,
  ProcessState,
} from "@alethea-medical/alethea-components";
import useEditButtons from "../../SpecialistTemplates/useEditButtons"; // Component is the same, its okay to import from SpecialistTemplates
import SaveDiscardToolbar from "../../../../components/SaveDiscardToolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: theme.spacing(1),
    },
    container: {
      margin: 0,
      width: "100%", //Stops overflow x
    },
  }),
);
interface ResourcesPageViewProps {
  /** If true, edit will be enabled by default and edit button will not show */
  alwaysEnableEditing?: boolean;
  /** Optional height value to pass to HeightLayout  */
  height?: number | string;
  /** If true, drag handle is hidden even when editing */
  disableDrag?: boolean;
}

const ResourcesPageView = ({
  alwaysEnableEditing,
  height,
  disableDrag,
}: ResourcesPageViewProps) => {
  const classes = useStyles();

  const controller = ResourcesController({});
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const modifyProcessState = (newState: ProcessState) => {
    setProcessState(newState);
  };

  const inputController = ResourcesInputController({ modifyProcessState });
  const { isEditing, EditButton } = useEditButtons({
    unsavedChanges: controller.unsavedChanges,
    alwaysEnableEditing,
  });

  return (
    <>
      <HeightLayout height={height}>
        {!alwaysEnableEditing && (
          <Typography variant="h4"> Resources </Typography>
        )}
        <HeightLayoutChild flexDriver className={classes.toolbar}>
          {!alwaysEnableEditing && EditButton}
          {(alwaysEnableEditing || isEditing) && (
            <SaveDiscardToolbar
              show={controller.unsavedChanges}
              modalText={"Discard changes to your templates?"}
              saveHandler={controller.saveTemplates}
              discardHandler={controller.discardChanges}
            />
          )}
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven allowOverflowY>
          <DragDropContext onDragEnd={controller.onDragEndHandler}>
            <Droppable droppableId={"category"} type="category">
              {(provided) => (
                <Grid
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                  container
                  className={classes.container}
                  spacing={2}
                >
                  {controller.categoryOrder.map((categoryId, index) => {
                    if (controller.categories[categoryId] === undefined)
                      return null;
                    const id = `category_${categoryId}`;
                    return (
                      <Grid item xs={12} key={id}>
                        <Category
                          index={index}
                          category={controller.categories[categoryId]}
                          deleteCategoryHandler={
                            controller.deleteCategoryHandler
                          }
                          updateCategoryHandler={
                            controller.updateCategoryHandler
                          }
                          resources={controller.resources}
                          addResourceHandler={controller.addResourceHandler}
                          deleteResourceHandler={
                            controller.deleteResourceHandler
                          }
                          updateResourceHandler={
                            controller.updateResourceHandler
                          }
                          isEditing={isEditing}
                          disableDrag={disableDrag}
                          resourceInsertHandler={
                            inputController.resourceInsertHandler
                          }
                        />
                      </Grid>
                    );
                  })}
                  {provided.placeholder}
                  {isEditing && (
                    <Grid item xs={12}>
                      <AddCategoryButton
                        addCategoryHandler={controller.addCategoryHandler}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </HeightLayoutChild>
        <ProcessStatus
          state={processState}
          successMessage="Resource shared successfully"
          hideProgressBar
        />
      </HeightLayout>
      <LeavePagePrompt isDirty={controller.unsavedChanges} />
    </>
  );
};

export default ResourcesPageView;
