import {  Button, Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, MenuItem, Select, Switch, Theme, Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react'
import { ProcessState, ProcessStatus, useMobileSizes } from '@alethea-medical/alethea-components';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import VideocamIcon from '@material-ui/icons/Videocam';
import InfoIcon from '@material-ui/icons/Info';
import palette from '../../../palette';

const useStyles = makeStyles( ( theme: Theme ) =>
    createStyles( {

        captureButton: {

            height: '100px',
            backgroundColor: palette.orange,
            width: '100px',
            borderRadius: '50%',
        },
        mobileCaptureButton: {
            height: '62px',
            backgroundColor: palette.orange,
            width: '50px',
            borderRadius: '50%',
        },

        mobileRecordButton: {
            height: '62x',
            backgroundColor: palette.orange,
            width: '50px',
            borderRadius: '50%',
        },
        recordingButton: {
            height: '100px',
            backgroundColor: 'red',
            width: '100px',
            borderRadius: '50%',

        },
        mobileRecordingButton: {
            height: '62px',
            backgroundColor: 'red',
            width: '50px',
            borderRadius: '50%',
        },
        refreshDevices: {
            height: '62px',
            width: '50px',
            borderRadius: '50%',

        },
        refreshIcon: {
            height: '32px',
            width: '32px',

        },
        cameraMode: {
            height: '50px'
        },
        videoCameraIcon: {

            // marginTop: '-6px',
            // marginRight: '10px',

            height: '32px',
            width: '32px',

        },
        cameraModeContainer: {
            display: 'inline-block',
        },
        cameraModeIcon: {
            color: theme.palette.text.secondary
        },
        toggle: {
            position: 'relative',
            color: 'black',
            left: '10px'
        },
        captureIcon: {
            marginTop: theme.spacing(1)
        },
        fullHeight: {
            height: "100%"
        }
    } ),
);
interface ICameraButtonsProps
{
    capture: any,
    setDevices: any,
    webcamRef: React.MutableRefObject<any>,
    recordingModeActive: boolean,
    setrecordingModeActive: React.Dispatch<React.SetStateAction<boolean>>,
    handleStopRecordClick: any,
    handleStartRecordClick: any,
    capturing: boolean,
    processState: any,
    processErrorMessage: any,
    devices: any[],
    setDeviceId: any,
    deviceId: any,
    setMirrored: React.Dispatch<React.SetStateAction<boolean>>,
    mirrored: boolean
}

interface ICameraDevices
{
    ChangeDevice: ( ( event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode ) => void ) | undefined
    isDisabledSwitchDevice: () => boolean | undefined
    devices: any[]
    deviceId: any
}

interface ICameraCaptureButtonProps
{
    isMobileSize: boolean
    classes: { mobileCaptureButton: any; captureButton: any; cameraModeContainer: any, captureIcon: any; }
    webcamRef: any
    capture: () => void
    isDisabledCaptureAndSwitchMode: () => boolean | undefined
}

interface IStopRecordingButtonProps
{
    isMobileSize: boolean
    classes: { recordingButton: any, mobileRecordingButton: any, cameraModeContainer: any, captureIcon: any }
    current: any
    handleStopRecordClick: () => void
}

interface IStartRecordingButton
{
    isMobileSize: boolean
    classes: { mobileCaptureButton: any; captureButton: any; cameraModeContainer: any, captureIcon: any; }
    current: any
    handleStartRecordClick: () => void
    isDisabledCaptureAndSwitchMode: () => boolean | undefined

}
//drop down menu that allows users to change the camera that they are currently using 
const CameraDevicesDropDown = ( { ChangeDevice, isDisabledSwitchDevice, devices, deviceId }: ICameraDevices ) =>
{
    return (
       
        <FormControl fullWidth >
            <Select labelId="deviceListLabel"
                id="deviceList"
                variant="outlined"
                margin="dense"
                onChange={ ChangeDevice }
                value={ deviceId }
                disabled={ isDisabledSwitchDevice() }>
                { devices.map( ( device: any ) => <MenuItem key={ device.deviceLabel } value={ device.deviceId }>
                    { device.deviceLabel }</MenuItem> ) }
            </Select>
        </FormControl> );
}

//Take a picture
const CameraCaptureButton = ( { classes, isMobileSize, webcamRef, capture, isDisabledCaptureAndSwitchMode }: ICameraCaptureButtonProps ) => 
{
    return (
        <Button
            className={ `${ isMobileSize ? classes.mobileCaptureButton : classes.captureButton } ${ classes.cameraModeContainer }` }
            color="primary"
            variant="contained"
            onClick={ e =>
            {
                if ( webcamRef !== undefined )
                {
                    e.preventDefault();
                    capture();
                }
            } } 
            disabled={ isDisabledCaptureAndSwitchMode() }
            >
            <PhotoCameraIcon className={classes.captureIcon}/> 
        </Button> );
}


//stop recording 
const StopRecordingButton = ( { classes, current, handleStopRecordClick, isMobileSize }: IStopRecordingButtonProps ) =>
{
    return (
        <Button
            className={ `${ isMobileSize ? classes.mobileRecordingButton : classes.recordingButton } ${ classes.cameraModeContainer }` }
            onClick={ e =>
            {
                if ( current !== undefined )
                {
                    e.preventDefault();
                    handleStopRecordClick();
                }
            } } color="primary" variant="contained"><StopIcon className={classes.captureIcon}/>
        </Button> );
}

//start recording
const StartRecordingButton = ( { isMobileSize, classes, current, handleStartRecordClick, isDisabledCaptureAndSwitchMode }: IStartRecordingButton ) =>
{
    return (
        <Button
            className={ `${ isMobileSize ? classes.mobileCaptureButton : classes.captureButton } ${ classes.cameraModeContainer }` }
            onClick={ e =>
            {
                if ( current !== undefined )
                {
                    e.preventDefault();
                    handleStartRecordClick();
                }
            } }
            color="primary"
            variant="contained"
            disabled={ isDisabledCaptureAndSwitchMode() }>
            <FiberManualRecordIcon className={classes.captureIcon}/>
        </Button> );
}


export const CameraButtons = ( { capture, webcamRef, recordingModeActive,
    setrecordingModeActive, capturing, handleStopRecordClick, handleStartRecordClick, processState, processErrorMessage,
    devices, setDeviceId, deviceId, setMirrored, mirrored }: ICameraButtonsProps ) =>
{


    const classes = useStyles();
    //prevent page scrolling by space bar on this page
    const spacebarDownListener = ( e: any ) =>
    {
        if ( e.keyCode === 32 )
        {
            e.preventDefault()
        }
    }

    //listen for space bar presses start and stop recording or take pictures 
    const spacebarUpListener = ( e: any ) =>
    {
        if ( !isDisabledNoDevice() )
        {
            if ( e.keyCode === 32 )
            {
                e.preventDefault()
                if ( recordingModeActive )
                {
                    if ( capturing )
                    {
                        handleStopRecordClick()
                    } else
                    {
                        handleStartRecordClick()
                    }
                } else
                {
                    if ( webcamRef !== undefined )
                    {
                        capture();
                    }
                }
            }
        }
    };

    //add and remove spacebar listeners on entry/exit 
    useEffect( () =>
    {

        document.body.addEventListener( 'keydown', spacebarDownListener )
        document.body.addEventListener( 'keyup', spacebarUpListener )
        return () =>
        {
            document.body.removeEventListener( 'keydown', spacebarDownListener )
            document.body.removeEventListener( 'keyup', spacebarUpListener )
        }
    } )//TODO find a way to make this get called less 
    // this is so bad 

    //changes from Single frame image capture to video recording
    const changeMode = () =>
    {
        setrecordingModeActive( !recordingModeActive )
    }


    //change the camera to use when selected from the drop down menu 
    const ChangeDevice = ( event: any ) =>
    {
        setDeviceId( event.target.value )
    }
    //!what even is this variable naming 
    const isDisabledNoDevice = () =>
    {
        return deviceId === undefined || webcamRef.current === undefined || isDisabled()
    }
    //!what even is this variable naming
    const isDisabledCapturing = () =>
    {
        return capturing || webcamRef.current === undefined ||isDisabled();
    }

    const isDisabled = () =>
    {
        return processState === ProcessState.running;
    }
    const isMobileSize = useMobileSizes();

    return (
        <>
            <Grid container alignItems='center' justifyContent='center' spacing={1} className={classes.fullHeight}>
                <Grid item xs={12}>
                    <Grid container>
                        {/* CAPTURE BUTTON */ }
                        <Grid container alignItems='center' justifyContent='center' direction='column-reverse'>
                            <Grid item>
                                { !recordingModeActive ?
                                    <CameraCaptureButton
                                        classes={ classes }
                                        isMobileSize={ isMobileSize }
                                        capture={ capture }
                                        webcamRef={ webcamRef }
                                        isDisabledCaptureAndSwitchMode={ isDisabledNoDevice } />
                                    :
                                    <>  { capturing ? (
                                        <StopRecordingButton
                                            classes={ classes }
                                            current={ webcamRef.current }
                                            handleStopRecordClick={ handleStopRecordClick }
                                            isMobileSize={ isMobileSize } />
                                    ) : ( <StartRecordingButton
                                        classes={ classes }
                                        isDisabledCaptureAndSwitchMode={ isDisabledNoDevice }
                                        isMobileSize={ isMobileSize }
                                        current={ webcamRef.current }
                                        handleStartRecordClick={ handleStartRecordClick } />
                                    ) }</> }
                            </Grid>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <ProcessStatus
                                state={ processState }
                                errorMessage={ processErrorMessage }
                            />
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems='center' justifyContent='center'>
                        {/* DEVICES DROP DOWN */ }          
                        <Grid item>
                            { !isMobileSize && <Tooltip title="If the camera image is not displaying it is possible that the camera is being used by another application.">
                                <InfoIcon color="primary"/>
                            </Tooltip>}
                        </Grid>
                        <Grid item xs={8}>
                            <CameraDevicesDropDown
                                ChangeDevice={ ChangeDevice }
                                deviceId={deviceId}
                                isDisabledSwitchDevice={ isDisabledCapturing }
                                devices={devices}
                            />
                        </Grid>


                        <Grid item >
                            <Tooltip title="Mirroring is only available for photos and not video">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={ mirrored }
                                        onChange={ () => setMirrored( !mirrored ) } /> }
                                        label="Mirror Image" />
                            </FormGroup>
                        </Tooltip>
                        </Grid>

                        <Grid item>
                            <Grid container direction='row' alignItems='center' justifyContent='center' >
                                <Grid item>
                                    <VideocamIcon className={ `${ classes.videoCameraIcon } ${ classes.cameraModeContainer } ${ classes.cameraModeIcon }` }/>
                                </Grid>
                                <Grid item >
                                    <FormGroup className={ `${ classes.cameraModeContainer } ${ classes.toggle }` }>

                                        <FormControlLabel
                                            control={ <Switch color='default' /> }
                                            label={ '' }
                                            disabled={ isDisabledCapturing() }
                                            checked={ !recordingModeActive }
                                            onMouseUp={ ( e ) =>
                                            {
                                                if ( !capturing )
                                                {
                                                    e.preventDefault()
                                                    changeMode()
                                                }
                                            } } />

                                    </FormGroup>
                                </Grid>
                                <Grid item >
                                    <CameraAltIcon className={ `${ classes.cameraModeContainer } ${ classes.cameraModeIcon }` } />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* refresh button in case auto refresh doesnt work */}
                {/* <Grid item xs={ 12 } >
                    <Button
                        fullWidth
                        variant='contained'
                        color="primary"
                        //onClick={ isolateMainCameras }
                        disabled={ isDisabledSwitchDevice() }
                    ><RefreshIcon /></Button>
                </Grid> */}
            </Grid>
        </>
    )
}

export default CameraButtons
