import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
//import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from '@date-io/moment';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";


interface FormDatePickerProps extends ControlledInputProps {
  rules?: any,
  label?: string,
  labelAsPlaceHolder?: boolean,
  errors?:any,
  required?:boolean
  
}

const FormDatePicker =(props: FormDatePickerProps) => {
  const { name, control, defaultValue, rules, label, labelAsPlaceHolder, errorTextPadding, ...rest } = props;
  const [open, setOpen] = useState(false);
 
  return (

    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Controller     
        control={control}     
        defaultValue={defaultValue ? defaultValue : ""}
        name={name}
        rules={rules}       
        render={({ field, fieldState}) => (
          <KeyboardDatePicker
            placeholder={labelAsPlaceHolder && label ? label : "Select a date"}
            openTo="year"
            open={open}
            onClick={() => setOpen(true)}//Open dialog whenever the input field is clicked
            onClose={() => setOpen(false)}
            autoOk          
            variant="inline"
            inputVariant="outlined"       
            format="MM/DD/yyyy"          
            error={fieldState.error !== undefined}
            helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
            value= {field.value}
            onChange={field.onChange}
            InputProps={{ readOnly: true }}//Disallow typing in date field (causes massive lag issues)
            fullWidth
            size="small"
            {...rest}
          />
        )}
        />
        </MuiPickersUtilsProvider>     

  );
}

export default FormDatePicker


