import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import palette from '../../../palette';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addButton: {
            justifyContent: "flex-start"
        },
        newCategoryPaper: {
            backgroundColor: palette.lightGreenLight,
            padding: theme.spacing(1)
        }
    })
)

interface AddCategoryButtonProps {
    addCategoryHandler: (name: string) => void   
}

const AddCategoryButton = ({ addCategoryHandler }: AddCategoryButtonProps) => {
    const classes = useStyles();
    const [categoryName, setCategoryName] = useState("")
    const [showTextField, setShowTextField] = useState(false)
    const textFieldRef = useRef<HTMLInputElement | null>(null)

    const handleAddClicked = () => {
        setShowTextField(true)
    }
    // When enter is pressed or "create" is clicked
    const handleSubmit = () => {
        if(categoryName === "")
            return;

        addCategoryHandler(categoryName)
        closeTextField()
    }
    
    // Listen for enter key press to submit
    const onKeyPress = (e: { keyCode: number; }) => {
        if(e.keyCode == 13) {
            handleSubmit()
        }
    }

    const closeTextField = () => {
        setShowTextField(false)
        setCategoryName("")
    }

    // Automatically focus textfield when textfield shows
    useEffect(() => {
        if(showTextField && textFieldRef.current !== null) {
            textFieldRef.current.focus()
        }
    }, [showTextField])

    return (
        <>
            {!showTextField && 
                <Button 
                    className={classes.addButton}
                    variant="text" 
                    color="primary" 
                    onClick={handleAddClicked}
                    startIcon={<AddIcon/>}
                    fullWidth
                >
                    New Category
                </Button>
            }
            {showTextField && 
                <Paper className={classes.newCategoryPaper}>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item xs={true}>
                            <TextField
                                label="Category Name"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                inputRef={(input) => {
                                    textFieldRef.current = input;
                                }}
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value as string)}
                                onKeyDown={onKeyPress}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text" color="primary"     
                                onClick={handleSubmit}
                                disabled={categoryName === ""}
                            >
                                Create
                            </Button>
                        </Grid>
                        <Grid item>
                            <IconButton     
                                onClick={closeTextField}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            }
  
        </>
    );
}

export default AddCategoryButton;