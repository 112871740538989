import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
import { firebaseConfigProd, firebaseConfigDev, firebaseConfigStaging, firebaseConfigDemo } from "./firebase-config"
import isNativeMobile from "./models/isNativeMobile";

interface FirebaseConfig {
	apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId?: string
}

type EnvType = "local" | "dev" | "staging" | "prod" | "demo"

let config: FirebaseConfig;
let projectEnv: EnvType

if(isNativeMobile()) {
	//If on mobile, use NODE_ENV
	console.log(`REACT_APP_ENV=${process.env.REACT_APP_ENV}`);
	switch(process.env.REACT_APP_ENV as "prod" | "demo" | "staging" | "dev") {
		case 'prod':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'demo':
			console.log("Using demo config")
			config = firebaseConfigDemo;
			projectEnv = "demo";
			break;
		case 'staging':
			console.log("Using staging config");
			config = firebaseConfigStaging;
			projectEnv = "staging";
			break;
		case 'dev':
			console.log("Using dev config");
			config = firebaseConfigDev;
			projectEnv = "dev";
			break;
		default:
			console.log("No matching env, falling back to dev config");
			config = firebaseConfigDev;
			projectEnv = "local";
	}	
}
else {
	//If on web, use the URL
	const hostname = window.location.hostname;//i.e. localhost, entid-dev-9c711.web.app, aletheamd.com
	switch(hostname) {
		case 'portal.entid.ca':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'aletheamd.com':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'aletheadental.com':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'aletheasleep.com':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'alethea-medical.web.app':
			console.log("Using production config");
			config = firebaseConfigProd;
			projectEnv = "prod";
			break;
		case 'entid-dev-9c711.web.app':
			console.log("Using dev config");
			config = firebaseConfigDev;
			projectEnv = "dev";
			break;
		case 'entid-staging.web.app':
			console.log("Using staging config");
			config = firebaseConfigStaging;
			projectEnv = "staging";
			break;
		case 'alethea-medical-demo.web.app':
			console.log("Using demo config")
			config = firebaseConfigDemo;
			projectEnv = "demo";
			break;
		case 'localhost':
			console.log("Localhost: using dev config");
			config = firebaseConfigDev;
			projectEnv = "local";
			break;
		default:
			console.log("No matching hostname, falling back to dev config");
			config = firebaseConfigDev;
			projectEnv = "dev";
	}
}


const app: firebase.app.App = firebase.initializeApp(config);

const fbAuth = app.auth();
const fbAnalytics = app.analytics();
const fbFirestore = app.firestore();
const fbStorage = app.storage();
const fbFunctions = app.functions();

if(process.env.NODE_ENV === "development") {
    console.log("Using functions emulator");
    fbFunctions.useEmulator("localhost", 5001)

	if(process.env.REACT_APP_USE_FIRESTORE_EMULATOR === "true") {
		
		console.log("Using firestore emulator")
		fbFirestore.useEmulator("localhost", 8080);
	}
}


//Default update remote config every 12 hours
const fbRemoteConfig = app.remoteConfig();

const logAnalyticsEvent = (eventName: string, eventObj?: any) => {
	if(isNativeMobile()) {
		eventName = `mobile_${eventName}`;
	}
	if(process.env.NODE_ENV === "production")
	{
		if(eventObj)
			fbAnalytics.logEvent(eventName, eventObj);
		else
			fbAnalytics.logEvent(eventName);
	}
	else
	{
		if(eventObj)
			console.log(`environment not in production, not logging analytics event: ${eventName} => ${JSON.stringify(eventObj)}`);
		else
		console.log(`environment not in production, not logging analytics event: ${eventName}`);
	}

}


export { app, fbAuth, fbAnalytics, fbFirestore, fbStorage, fbRemoteConfig, fbFunctions, logAnalyticsEvent, config, projectEnv };