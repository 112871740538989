import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: "white",
            fontSize: "1.8em"
        }
    }),
);
interface LinkPreviewerHeaderProps {
    title: string
}

const LinkPreviewerHeader = ({ title }: LinkPreviewerHeaderProps) => {
    const classes = useStyles();
    return (
        <Typography variant="h6" className={classes.title}>
            {title}
        </Typography>
    );
}

export default LinkPreviewerHeader;